import { Theme, useMediaQuery } from '@mui/material';
import {
  Typography,
  IconButton,
  Link,
  Popover,
  ColorPicker,
  Box,
  WHITE_TEXT_BACKGROUND,
} from 'src/components/ui';
import { Share, Eyedropper } from 'src/assets/icons';
import { PERMISSIONS } from 'src/constants';
import { AuthService, RolePermissionService } from 'src/services';
import { formatServerDate, isAdmin, isKamPlus } from 'src/utils';
import { ICompany, TVariantBusinessRequest } from 'src/models';
import { useEffect, useState } from 'react';
import { useUpdateBusinessRequestColorMutation } from 'src/api/business-request/business-request';
import styles from './request-number.module.scss';

interface IRequestNumber {
  businessRequest: TVariantBusinessRequest;
  manager: ICompany['assignedManagers']['kam' | 'kamPlus'];
}

export const RequestNumber = ({ businessRequest, manager }: IRequestNumber) => {
  const [updateColor] = useUpdateBusinessRequestColorMutation();

  const [activeColor, setActiveColor] = useState(businessRequest.color);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  const isDownLg = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  useEffect(() => {
    setActiveColor(businessRequest.color);
  }, [businessRequest]);

  const open = Boolean(anchorEl);
  const whiteText = WHITE_TEXT_BACKGROUND[activeColor!];

  const authUserId = AuthService.userId;
  const canEditColor = authUserId === manager?.id || isAdmin() || isKamPlus();

  const handleOpenPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePicker = () => {
    setAnchorEl(undefined);
    if (activeColor !== businessRequest.color) {
      updateColor({
        id: businessRequest.businessApplicationId!,
        color: activeColor!,
      });
    }
  };

  const handlePick = (color: string) => {
    setActiveColor(color);
  };

  return (
    <>
      <Box
        className={styles.containerBidNumber}
        sx={(theme) => ({
          color: whiteText ? '#fff' : '#000',
          backgroundColor: activeColor ?? 'transparent',
          border: '1px solid',
          borderColor: activeColor
            ? 'transparent'
            : theme.palette.customTheme.borderGrayPrimary,
        })}>
        <Typography color="inherit" className={styles.bidNumberText}>
          <span>№ {businessRequest.amocrmNumber}</span>
          {!isDownLg && (
            <span>
              от {formatServerDate(businessRequest.createdAt, 'dd.MM.yyyy')}
            </span>
          )}
        </Typography>
        {RolePermissionService.can(PERMISSIONS.VIEW_CRM_LINK) &&
          businessRequest.amocrmId !== -1 && (
            <div className={styles.bidNumberBtn}>
              <Link
                href={`${process.env.REACT_APP_AMO_CRM_URL}/leads/detail/${businessRequest.amocrmId}`}
                target="_blank">
                <IconButton>
                  <Share />
                </IconButton>
              </Link>
            </div>
          )}
        {canEditColor && (
          <IconButton
            color="inherit"
            onClick={handleOpenPicker}
            sx={{
              pl: 1,
            }}>
            <Eyedropper />
          </IconButton>
        )}
      </Box>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 50,
        }}
        onClose={handleClosePicker}
        open={open}
        PaperProps={{
          sx: {
            marginTop: '14px',
          },
        }}>
        <ColorPicker
          title="Изменить цвет заявки"
          activeColor={activeColor}
          onClick={handlePick}
        />
      </Popover>
    </>
  );
};
