import { Stack } from '@mui/material';
import classNames from 'classnames';
import { useMemo } from 'react';
import { Pencil } from 'src/assets/icons';
import { IconButton, Typography } from 'src/components/ui';
import { ROLES } from 'src/constants';
import { TVariantBusinessRequest } from 'src/models';
import { AuthService } from 'src/services';
import {
  formatServerDate,
  isAdmin,
  isBackOfficeRole,
  isManager,
  joinFullName,
} from 'src/utils';
import styles from './request-back-office.module.scss';

export interface IRequestBackOfficeProps {
  businessRequest: TVariantBusinessRequest;
  onEditButtonClick: () => void;
}

export const RequestBackOffice = ({
  businessRequest,
  onEditButtonClick,
}: IRequestBackOfficeProps) => {
  const name = useMemo(
    () =>
      joinFullName(
        businessRequest.assignedManagers?.backOffice?.lastName,
        businessRequest.assignedManagers?.backOffice?.firstName,
        businessRequest.assignedManagers?.backOffice?.middleName
      ),
    [businessRequest]
  );

  const isShowEditBackOfficeForRequest =
    !businessRequest.orderProcessingStarted;

  const isUserCanChangeBackOffice =
    isBackOfficeRole() || isAdmin() || isManager();

  return (
    <Stack direction="column" gap="10px">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="column" gap="4px">
          <Typography variant="body2" color="#7A8694">
            Бэкофис:
          </Typography>
          <Typography color="#292929">{name || '-'}</Typography>
        </Stack>
        <Stack direction="row" gap="4px">
          <IconButton
            className={classNames(
              styles.actionButton,
              (!isShowEditBackOfficeForRequest || !isUserCanChangeBackOffice) &&
                styles.actionButtonDisabled
            )}
            disabled={
              !isShowEditBackOfficeForRequest || !isUserCanChangeBackOffice
            }
            onClick={onEditButtonClick}>
            <Pencil
              sx={{
                color:
                  !isShowEditBackOfficeForRequest || !isUserCanChangeBackOffice
                    ? '#7A8694'
                    : '#292929',
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
