import React from 'react';
import { Auction, UsersGroup } from 'src/assets/icons';
import { Box, Stack, Typography } from 'src/components/ui';
import { IContractorRequest, IManagerBusinessRequest } from 'src/models';
import { formatServerDate } from 'src/utils';

export const RebiddingInfo = ({
  businessRequest,
}: {
  businessRequest: IManagerBusinessRequest | IContractorRequest;
}) => {
  const { rebidding, refusal, status } = businessRequest;
  const { exclusion } = businessRequest as IContractorRequest;

  if (
    !rebidding ||
    !rebidding.rebiddingInfo ||
    !rebidding.rebiddingInfo.endAt ||
    refusal ||
    exclusion ||
    status.code !== 'REBIDDING_IN_PROGRESS'
  ) {
    return null;
  }

  if (new Date() > new Date(rebidding.rebiddingInfo.endAt)) {
    return null;
  }

  return (
    <Stack
      direction="column"
      sx={{ backgroundColor: '#EAF3FF', borderRadius: '8px' }}>
      <Stack
        direction="row"
        gap="12px"
        sx={{
          flex: 1,
          padding: '12px',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            height: '44px',
            width: '44px',
            display: 'flex',
            backgroundColor: '#CDE3FF',
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
            color: '#0041A0',
            svg: {
              height: '24px !important',
              width: '24px !important',
            },
          }}>
          <UsersGroup />
        </Box>
        <Typography flex="1">
          Идет переторжка до:{' '}
          {formatServerDate(
            new Date(rebidding.rebiddingInfo.endAt),
            'dd.MM.yyyy'
          )}
        </Typography>
      </Stack>
    </Stack>
  );
};
