import { useMemo } from 'react';
import {
  CloseRequest,
  MoveRequestToExpected,
  RejectApplication,
  TakeWorkBusinessRequest,
  TakeWorkContractorRequest,
} from 'src/components/layout/bids-layout/main-bids-layout/components/header/components';
import { RemoveRequest } from 'src/components/layout/bids-layout/main-bids-layout/components/header/components/remove-request';
import { Stack } from 'src/components/ui';
import { DropdownList } from 'src/components/widgets';
import { PERMISSIONS } from 'src/constants';
import {
  BusinessRequestMode,
  IContractorBusinessRequest,
  IContractorRequest,
  TVariantBusinessRequest,
} from 'src/models';
import { RefusalApprovalStatuses } from 'src/models/bids-details';
import { RolePermissionService } from 'src/services';
import { isTerminalStatus } from 'src/utils/business-request';

export interface IButtonsSectionProps {
  businessRequest: TVariantBusinessRequest;
  mode: BusinessRequestMode;
}

export const ButtonsSection = ({
  businessRequest,
  mode,
}: IButtonsSectionProps) => {
  const isRefusalApprovalStatus =
    businessRequest.refusal &&
    'refusalApprovalStatus' in businessRequest.refusal;

  const isRefusalApprovalExpecting =
    isRefusalApprovalStatus &&
    businessRequest!.refusal!.refusalApprovalStatus &&
    businessRequest!.refusal!.refusalApprovalStatus ===
      RefusalApprovalStatuses.EXPECTING_APPROVAL;

  const refusalRequestData = Boolean(
    'customerBusinessApplicationRefusalRequest' in businessRequest &&
      businessRequest.customerBusinessApplicationRefusalRequest
  );

  const showDeleteButtonOutsideOfDropdown =
    (RolePermissionService.can(PERMISSIONS.VIEW_ADMIN_PANEL) &&
      businessRequest.status &&
      (isTerminalStatus(
        businessRequest.status.code,
        businessRequest.orderProcessingStarted
      ) ||
        (mode === BusinessRequestMode.CUSTOMER && refusalRequestData))) ||
    isRefusalApprovalExpecting;

  const dropDownList = useMemo(() => {
    const itemsList = [];

    if (
      RolePermissionService.can(PERMISSIONS.VIEW_ADMIN_PANEL) &&
      !!businessRequest &&
      !showDeleteButtonOutsideOfDropdown
    ) {
      itemsList.push({
        component: <RemoveRequest businessRequest={businessRequest} />,
      });
    }

    return itemsList;
  }, [businessRequest, showDeleteButtonOutsideOfDropdown]);

  return (
    <Stack flex={1} direction="row" gap="8px" justifyContent="end">
      {RolePermissionService.can(PERMISSIONS.CLOSE_REQUEST) && (
        <CloseRequest businessRequest={businessRequest} />
      )}

      <MoveRequestToExpected businessRequest={businessRequest} />

      {showDeleteButtonOutsideOfDropdown && (
        <RemoveRequest businessRequest={businessRequest} primaryButton />
      )}

      {!isRefusalApprovalExpecting &&
        (mode === BusinessRequestMode.CONTRACTOR_ALL ? (
          <TakeWorkBusinessRequest
            businessRequest={businessRequest as IContractorBusinessRequest}
          />
        ) : (
          <>
            {mode === BusinessRequestMode.CONTRACTOR && (
              <TakeWorkContractorRequest
                contractorRequest={businessRequest as IContractorRequest}
              />
            )}
            <RejectApplication mode={mode} businessRequest={businessRequest} />
          </>
        ))}
      <DropdownList items={dropDownList} newLargeVariant />
    </Stack>
  );
};
