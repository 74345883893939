import { RequestBackOfficeRoles } from 'src/constants/request-back-office-roles';
import { AuthService } from 'src/services';
import { ROLES } from 'src/constants';

export const isContractor = () => AuthService.currentRole === ROLES.CONTRACTOR;
export const isCustomer = () => AuthService.currentRole === ROLES.CUSTOMER;
export const isContragent = () => isContractor() || isCustomer();

export const isKam = () => AuthService.currentRole === ROLES.KAM;
export const isKamPlus = () => AuthService.currentRole === ROLES.KAM_PLUS;
export const isManager = () => isKam() || isKamPlus();

export const isAdmin = () => AuthService.currentRole === ROLES.ADMIN;
export const isSystem = () => AuthService.currentRole === ROLES.SYSTEM;

export const isBackOfficeRole = () =>
  RequestBackOfficeRoles.includes(AuthService.currentRole!);

export const isTechExpert = () => AuthService.currentRole === ROLES.TECH_EXPERT;
export const isLogistic = () => AuthService.currentRole === ROLES.LOGISTIC;
