import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { businessRequestApi } from 'src/api/business-request/business-request';
import { contactsApi } from 'src/api/contacts';
import {
  contractorApi,
  useGetByIdQuery as useGetContractorByIdQuery,
  useLazyGetByIdQuery as useLazyGetContractorByIdQuery,
  useUpdateBankDetailsMutation as useUpdateContractorBankDetailsMutation,
  useUpdateCompanyDetailsMutation as useUpdateContractorCompanyDetailsMutation,
} from 'src/api/contractor';
import {
  customerApi,
  useGetByIdQuery as useGetCustomerByIdQuery,
  useLazyGetByIdQuery as useLazyGetCustomerByIdQuery,
  useUpdateBankDetailsMutation as useUpdateCustomerBankDetailsMutation,
  useUpdateCompanyDetailsMutation as useUpdateCustomerCompanyDetailsMutation,
} from 'src/api/customer';
import { TContactInfo } from 'src/components/pages/company/contacts/types';
import {
  CompanyStatuses,
  CompanyTypes,
  ICompany,
  IKonturFocusData,
  IOrganizationsNamesLite,
} from 'src/models';
import { baseQueryWithReauth } from 'src/store/utils';
import { TInvokeEmployeeFormProps } from '../components/pages/company/contract/components/step-draft-upload/components/upload-form-help/components/invoke-employee/invoke-employee.types';
import { TSendContractFormProps } from '../components/pages/company/contract/components/step-draft-upload/components/upload-form-help/components/send-contract/send-contract.types';
import {
  IAddCompanyRoleResponse,
  IContractAssignmentsResponseDto,
  IOrganizationBankRequisites,
  IRefuseSegment,
  ISegment,
  TAddOrganizationBankRequisites,
} from '../models/company';
import { TMessageRoles } from './communications/models';
import { userApi } from './user';

export const useGetByIdQuery = (type: CompanyTypes) =>
  type === 'CUSTOMER' ? useGetCustomerByIdQuery : useGetContractorByIdQuery;

export const useLazyGetByIdQuery = (type: CompanyTypes) =>
  type === 'CUSTOMER'
    ? useLazyGetCustomerByIdQuery
    : useLazyGetContractorByIdQuery;

export const useUpdateCompanyDetails = (type: CompanyTypes) =>
  type === 'CUSTOMER'
    ? useUpdateCustomerCompanyDetailsMutation
    : useUpdateContractorCompanyDetailsMutation;

export const useUpdateBankDetails = (type: CompanyTypes) =>
  type === 'CUSTOMER'
    ? useUpdateCustomerBankDetailsMutation
    : useUpdateContractorBankDetailsMutation;

export interface IUpdateCompanyRequest {
  organizationId: number;
  skipBidApiReset?: boolean;
  body: Pick<
    ICompany,
    | 'organizationBankDetails'
    | 'organizationDetails'
    | 'technicalOpportunitiesCategories'
    | 'inn'
    | 'roles'
    | 'comment'
    | 'customerType'
    | 'segmentId'
    | 'esk'
  >;
}

export interface IInvokeEmployeeRequest extends TInvokeEmployeeFormProps {}

export interface IAddResponseOnInvokeResponse extends TSendContractFormProps {}

export type TCompanyRequestsCount = {
  activeBusinessApplicationsCount: number;
  totalBusinessApplicationsCount: number;
};

export const companyApi = createApi({
  reducerPath: 'companyApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['BankRequisites'],
  endpoints: (builder) => ({
    changeStatus: builder.mutation<
      void,
      {
        organizationId: number;
        status: CompanyStatuses.ACTIVE | CompanyStatuses.LOCKED;
      }
    >({
      query: (data) => ({
        url: `organizations/api/v1/organization/status`,
        method: 'PATCH',
        body: data,
      }),
      async onQueryStarted(
        { organizationId, status },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            contactsApi.util?.invalidateTags([
              {
                type: 'Contacts',
              },
            ])
          );
          dispatch(
            contractorApi.util.invalidateTags([
              {
                type: 'Contractor',
              },
            ])
          );

          dispatch(contractorApi.util.invalidateTags(['Contractor']));

          dispatch(customerApi.util.invalidateTags(['Customer']));
        } catch {
          // ignore
        }
      },
    }),

    addNewCompanyRole: builder.mutation<
      IAddCompanyRoleResponse,
      { organizationId: number; organizationRole: CompanyTypes }
    >({
      query: ({ organizationId, organizationRole }) => ({
        url: `organizations/api/v1/organization/${organizationId}/add-new-role?organizationRole=${organizationRole}`,
        method: 'PATCH',
      }),
      async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
        try {
          const res = await queryFulfilled;
          dispatch(
            contractorApi.util.updateQueryData(
              'getById',
              organizationId,
              (data) => ({
                ...data,
                roles: res.data.roles,
                organizationDetails: {
                  ...data.organizationDetails,
                },
              })
            )
          );
          dispatch(
            customerApi.util.updateQueryData(
              'getById',
              organizationId,
              (data) => ({
                ...data,
                roles: res.data.roles,
                organizationDetails: {
                  ...data.organizationDetails,
                },
              })
            )
          );
          dispatch(userApi.util.invalidateTags(['User']));
        } catch (error) {
          /* empty */
        }
      },
    }),

    getContractorSegmentList: builder.query<ISegment[], void>({
      query: () => ({
        url: 'organizations/api/v1/segment',
        method: 'GET',
      }),
    }),

    updateCompany: builder.mutation<ICompany, IUpdateCompanyRequest>({
      query: ({ body, organizationId }) => ({
        url: `organizations/api/v1/organization/${organizationId}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted(
        { organizationId, skipBidApiReset },
        { dispatch, queryFulfilled }
      ) {
        try {
          const res = await queryFulfilled;

          dispatch(
            contractorApi.util.updateQueryData(
              'getById',
              organizationId,
              (data) => ({
                ...data,
                roles: res.data.roles,
                organizationDetails: {
                  ...data.organizationDetails,
                  ...res.data.organizationDetails,
                },
                comment: res.data.comment,
                esk: res.data.esk,
                segmentId: res.data.segmentId,
                segmentName: res.data.segmentName,
              })
            )
          );

          dispatch(
            customerApi.util.updateQueryData(
              'getById',
              organizationId,
              (data) => ({
                ...data,
                roles: res.data.roles,
                organizationDetails: {
                  ...data.organizationDetails,
                  ...res.data.organizationDetails,
                },
                comment: res.data.comment,
                customerType: res.data.customerType,
              })
            )
          );

          // TODO: Подумать, можно ли сбросить заказчиков, не зная businessRequestId?
          // https://tracker.yandex.ru/DEVINHOUSE-994
          if (!skipBidApiReset) {
            dispatch(businessRequestApi.util?.resetApiState());
          }

          dispatch(contractorApi.util?.invalidateTags(['ContractorSobStatus']));

          // TODO: Бек не успевает обновить
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve(0);
            }, 3000);
          });

          dispatch(
            contractorApi.util.invalidateTags([
              {
                type: 'ContractorStatusHistoryForKam',
              },
            ])
          );

          dispatch(
            contractorApi.util.invalidateTags([
              { type: 'ContractorStatusHistoryForContractor' },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),

    getContractorNamesLite: builder.query<IOrganizationsNamesLite[], void>({
      query: () => ({
        url: 'organizations/api/v1/organization/contractor/names/lite',
        method: 'POST',
      }),
    }),

    mergeCompanyByInn: builder.mutation<
      { organizationId: number; roles: Array<CompanyTypes> },
      { id: number; inn: string }
    >({
      query: (body) => ({
        url: `organizations/api/v2/organization/${body.id}/merge?inn=${body.inn}`,
        method: 'POST',
      }),
    }),

    getKonturFocusData: builder.query<IKonturFocusData, string>({
      query: (inn: string) => ({
        url: `kontur-focus-adapter/api/v1/organization/${inn}`,
      }),
    }),

    getOrganizationsByIds: builder.query<ICompany[], string[]>({
      queryFn: async (ids, _queryApi, _extraOptions, baseQuery) => {
        const organizationsData = await Promise.all(
          ids.map((id) => baseQuery(`organizations/api/v1/organization/${id}`))
        );

        return {
          data: organizationsData.map(
            (organizationData) => organizationData.data as ICompany
          ),
        };
      },
    }),

    getOrganizationByUserId: builder.query<
      { organizationId: number; organizationName: string },
      number
    >({
      query: (id: number) => ({
        url: `organizations/api/v1/organization/${id}/organization-id`,
      }),
    }),

    getCompanyRequestsCount: builder.query<
      TCompanyRequestsCount,
      { organizationId: number }
    >({
      query: ({ organizationId }) => ({
        url: `business-requests/api/v1/business-application/count-applications/${organizationId}`,
      }),
    }),

    getCompanyHasActiveRequests: builder.query<
      {
        contractorOrganizationRoleHasActiveApplications: boolean;
        customerOrganizationRoleHasActiveApplications: boolean;
      },
      { organizationId: number }
    >({
      query: ({ organizationId }) => ({
        url: `business-requests/api/v1/business-application/${organizationId}/active-applications-exist`,
      }),
    }),

    getActiveOrganizationsByType: builder.query<
      TContactInfo[],
      {
        organizationRole: TMessageRoles;
        searchQuery: string;
      }
    >({
      query: (body) => ({
        url: 'organizations/api/v1/organization/info',
        method: 'POST',
        body,
      }),
    }),

    getOrganizationBankRequisites: builder.query<
      IOrganizationBankRequisites[],
      { organizationId: number }
    >({
      providesTags: ['BankRequisites'],
      query: ({ organizationId }) => ({
        url: `organizations/api/v1/organization/${organizationId}/bank-details`,
      }),
    }),

    getContractorSigningAssignments: builder.query<
      IContractAssignmentsResponseDto,
      { contractorSigningId?: number }
    >({
      query: ({ contractorSigningId }) => ({
        url: `organizations/api/v1/contractor-signing/assignments?contractorSigningId=${contractorSigningId}`,
      }),
    }),

    addOrganizationBankRequisites: builder.mutation<
      IOrganizationBankRequisites,
      TAddOrganizationBankRequisites
    >({
      invalidatesTags: ['BankRequisites'],
      query: ({ organizationId, organizationRole, ...body }) => ({
        url: `organizations/api/v1/organization/${organizationId}/bank-details?organizationRole=${organizationRole}`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // TODO: Бек не успевает обновить
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve(0);
            }, 3000);
          });

          dispatch(
            contractorApi.util.invalidateTags([
              {
                type: 'ContractorStatusHistoryForKam',
              },
            ])
          );

          dispatch(
            contractorApi.util.invalidateTags([
              { type: 'ContractorStatusHistoryForContractor' },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),

    deleteOrganizationBankRequisites: builder.mutation<
      void,
      {
        organizationId: number;
        bankDetailsId: number;
        organizationRole: CompanyTypes;
      }
    >({
      invalidatesTags: ['BankRequisites'],
      query: ({ organizationId, bankDetailsId, organizationRole }) => ({
        url: `organizations/api/v1/organization/${organizationId}/bank-details/${bankDetailsId}?organizationRole=${organizationRole}`,
        method: 'DELETE',
      }),
      async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // TODO: Бек не успевает обновить
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve(0);
            }, 3000);
          });

          dispatch(
            contractorApi.util.invalidateTags([
              {
                type: 'ContractorStatusHistoryForKam',
              },
            ])
          );

          dispatch(
            contractorApi.util.invalidateTags([
              { type: 'ContractorStatusHistoryForContractor' },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),

    updateOrganizationBankRequisites: builder.mutation<
      IOrganizationBankRequisites,
      TAddOrganizationBankRequisites & {
        bankDetailsId: number;
      }
    >({
      invalidatesTags: ['BankRequisites'],
      query: ({
        organizationId,
        bankDetailsId,
        organizationRole,
        ...body
      }) => ({
        url: `organizations/api/v1/organization/${organizationId}/bank-details/${bankDetailsId}?organizationRole=${organizationRole}`,
        method: 'PATCH',
        body,
      }),
      async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // TODO: Бек не успевает обновить
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve(0);
            }, 3000);
          });

          dispatch(
            contractorApi.util.invalidateTags([
              {
                type: 'ContractorStatusHistoryForKam',
              },
            ])
          );

          dispatch(
            contractorApi.util.invalidateTags([
              { type: 'ContractorStatusHistoryForContractor' },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),

    invokeEmployee: builder.mutation<[], IInvokeEmployeeRequest>({
      query: ({ ...body }) => ({
        url: 'organizations/api/v1/contractor-signing/assignment',
        method: 'POST',
        body,
      }),
    }),

    addResponseOnInvoke: builder.mutation<[], IAddResponseOnInvokeResponse>({
      query: ({ ...body }) => ({
        url: 'organizations/api/v1/contractor-signing/assignment/response',
        method: 'POST',
        body,
      }),
    }),

    getContractorRefusedSegments: builder.query<
      IRefuseSegment[],
      { segmentName?: string }
    >({
      query: ({ segmentName }) => ({
        url: 'organizations/api/v1/contractor-signing/refusal/refused-segment',
        method: 'GET',
        params: { segmentName },
      }),
    }),

    refuseContractor: builder.mutation<
      [],
      {
        organizationId: number;
        causeId: number;
        commentForContractor: string;
        refusalSegmentName?: string;
        internalComment: string;
      }
    >({
      query: ({ ...body }) => ({
        url: 'organizations/api/v1/contractor-signing/refusal/refuse',
        method: 'POST',
        body,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // TODO: Бек не успевает обновить
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve(0);
            }, 3000);
          });

          dispatch(
            contractorApi.util.invalidateTags([
              {
                type: 'ContractorStatusHistoryForKam',
              },
              {
                type: 'Contractor',
              },
              {
                type: 'ContractorSobStatus',
              },
            ])
          );

          dispatch(
            contractorApi.util.invalidateTags([
              { type: 'ContractorStatusHistoryForContractor' },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),

    getContractorSigningRefuseInfo: builder.query<
      {
        id: number;
        internalCauseName?: string;
        internalComment?: string;
        nameForContractor: string;
        commentForContractor: string;
        refusalSegmentName?: string;
        createdAt: string;
      },
      { contractorSigningId: number; contractorMode?: boolean }
    >({
      query: ({ contractorSigningId, contractorMode }) => ({
        url: `organizations/api/v1/contractor-signing/refusal/${contractorSigningId}/for-${
          contractorMode ? 'contractor' : 'kam'
        }`,
        method: 'GET',
      }),
    }),

    restoreRefusedContractor: builder.mutation<
      void,
      {
        organizationId: number;
      }
    >({
      query: ({ organizationId }) => ({
        url: `organizations/api/v1/contractor-signing/refusal/${organizationId}/delete`,
        method: 'DELETE',
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          // TODO: Бек не успевает обновить
          await new Promise((resolve) => {
            setTimeout(() => {
              resolve(0);
            }, 3000);
          });

          dispatch(
            contractorApi.util.invalidateTags([
              {
                type: 'ContractorStatusHistoryForKam',
              },
              {
                type: 'Contractor',
              },
              {
                type: 'ContractorSobStatus',
              },
            ])
          );

          dispatch(
            contractorApi.util.invalidateTags([
              { type: 'ContractorStatusHistoryForContractor' },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),
  }),
});
export const {
  useChangeStatusMutation,
  useGetContractorNamesLiteQuery,
  useMergeCompanyByInnMutation,
  useGetKonturFocusDataQuery,
  useLazyGetKonturFocusDataQuery,
  useGetOrganizationsByIdsQuery,
  useUpdateCompanyMutation,
  useGetCompanyHasActiveRequestsQuery,
  useLazyGetOrganizationByUserIdQuery,
  useGetCompanyRequestsCountQuery,
  useLazyGetActiveOrganizationsByTypeQuery,
  useAddNewCompanyRoleMutation,
  useGetOrganizationBankRequisitesQuery,
  useGetContractorSigningAssignmentsQuery,
  useLazyGetContractorSigningAssignmentsQuery,
  useAddOrganizationBankRequisitesMutation,
  useDeleteOrganizationBankRequisitesMutation,
  useUpdateOrganizationBankRequisitesMutation,
  useLazyGetContractorSegmentListQuery,
  useInvokeEmployeeMutation,
  useAddResponseOnInvokeMutation,
  useLazyGetContractorRefusedSegmentsQuery,
  useRefuseContractorMutation,
  useGetContractorSigningRefuseInfoQuery,
  useRestoreRefusedContractorMutation,
} = companyApi;
