import { FC, useState } from 'react';
import { Cross, SquareRoundedX } from 'src/assets/icons';
import { Button, SvgIcon } from 'src/components/ui';
import {
  BusinessRequestMode,
  IContractorRequest,
  TVariantBusinessRequest,
} from 'src/models';
import { AuthService } from 'src/services';
import {
  isExpiredCommercialOfferDueDate,
  isTerminalStatus,
} from 'src/utils/business-request';
import {
  ContractorRejectApplication,
  CustomerRejectApplication,
  ManagerRejectApplication,
} from './components';

interface IRejectApplicationProps {
  mode: BusinessRequestMode;
  businessRequest: TVariantBusinessRequest;
}

const getRequestIdFromBusinessRequest = (
  data: TVariantBusinessRequest,
  mode: BusinessRequestMode
) => {
  if (
    mode === BusinessRequestMode.MANAGER ||
    mode === BusinessRequestMode.CUSTOMER
  ) {
    return data?.businessApplicationId || 0;
  }

  if (mode === BusinessRequestMode.CONTRACTOR) {
    return data?.contractorRequestId || 0;
  }

  return 0;
};

export const RejectApplication: FC<IRejectApplicationProps> = ({
  mode,
  businessRequest,
}) => {
  const [isShowModalWindow, setIsShowModalWindow] = useState(false);
  const requestId = getRequestIdFromBusinessRequest(businessRequest, mode);

  const isSpecificRole = AuthService.isSpecificRole();

  const refusalRequestData = Boolean(
    'customerBusinessApplicationRefusalRequest' in businessRequest &&
      businessRequest.customerBusinessApplicationRefusalRequest
  );

  const handleShowModalWindow = () => {
    setIsShowModalWindow((prevState) => !prevState);
  };

  if (businessRequest?.status) {
    const currentStatus = businessRequest.status.code;

    if (
      isTerminalStatus(currentStatus, businessRequest.orderProcessingStarted) ||
      (mode === BusinessRequestMode.CUSTOMER && refusalRequestData)
    ) {
      return null;
    }
  }

  return (
    <>
      {!isSpecificRole && (
        <Button
          color="secondary"
          size="medium"
          sx={{ flex: 1 }}
          applyNewIconSizing
          onClick={handleShowModalWindow}
          disabled={refusalRequestData}
          startIcon={<SquareRoundedX />}>
          Отклонить заявку
        </Button>
      )}

      {isShowModalWindow && (
        <>
          {mode === BusinessRequestMode.MANAGER && (
            <ManagerRejectApplication
              isShowModalWindow={isShowModalWindow}
              handleShowModalWindow={handleShowModalWindow}
              businessApplicationId={requestId}
              businessRequestId={businessRequest.businessRequestId!}
            />
          )}

          {mode === BusinessRequestMode.CUSTOMER && (
            <CustomerRejectApplication
              isShowModalWindow={isShowModalWindow}
              handleShowModalWindow={handleShowModalWindow}
              businessApplicationId={requestId}
              businessRequestId={businessRequest.businessRequestId!}
            />
          )}

          {mode === BusinessRequestMode.CONTRACTOR && (
            <ContractorRejectApplication
              isShowModalWindow={isShowModalWindow}
              handleShowModalWindow={handleShowModalWindow}
              contractorRequestId={requestId}
            />
          )}
        </>
      )}
    </>
  );
};
