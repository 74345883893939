import { FC, MouseEvent, ReactElement, useMemo, useState } from 'react';
import { TContact } from 'src/components/pages/admin/company/contacts/types';
import {
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OverflowTooltip,
  PhoneInput,
  SvgIcon,
  Typography,
} from 'src/components/ui';
import {
  Ellipsis,
  Email,
  Pencil,
  Phone,
  SignIn,
  Star,
  ArrowDown,
} from 'src/assets/icons';
import { Box, Stack } from '@mui/material';
import { TVariantContactItem } from 'src/components/widgets/contact-item/types';
import { TCardManager } from 'src/components/widgets/manager-card/manager-card';
import { joinName, openGuestPath } from 'src/utils';
import classNames from 'classnames';
import { Whois } from 'src/components/widgets';
import { IBidsCustomerContact, IBusinessRequestCustomerInfo } from 'src/models';
import { AuthService } from 'src/services';
import { ROLES } from 'src/constants';
import styles from './contact-card.module.scss';

type TContactCardTypes = 'DEFAULT' | 'SMALL' | 'SMALL_GRAY';

interface IContactCardProps {
  item: TVariantContactItem | TCardManager['manager'];
  onChangeContact?: () => void;
  onSignIn?: () => void;
  onEmailClick?: (email: string | null) => void;
  className?: string;
  children?: ReactElement;
  onEdit?: (item?: IBidsCustomerContact) => void;
  businessRequest?: IBusinessRequestCustomerInfo;
  showGuestAuthAction?: boolean;
  type?: TContactCardTypes;
  positionParam?: string;
  disabled?: boolean;

  // Строка с периодом отсутствия  на время отпуска
  vacationInfo?: string;
}

// TODO: Кнопки лучше через children передавать, как чат
// TODO: Добавить перечисление номеров? или контактов?

const isContactItem = (
  item: TVariantContactItem | TCardManager['manager']
): item is TVariantContactItem => !!item && 'email' in item;

export const ContactCard: FC<IContactCardProps> = ({
  item,
  onChangeContact,
  onSignIn,
  onEmailClick,
  className,
  children,
  onEdit,
  businessRequest,
  showGuestAuthAction,
  positionParam,
  disabled,
  vacationInfo,
  type = 'DEFAULT',
}) => {
  const name = joinName(
    item?.lastName,
    item?.firstName,
    item?.middleName
  ).fullName;

  const position = isContactItem(item)
    ? item?.position || 'Должность не указана'
    : item?.position || 'Ответственный менеджер';

  const email = isContactItem(item)
    ? item?.email || 'email не указан'
    : item?.login || 'email не указан';

  const { currentRole } = AuthService;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEditAddress = () => {
    if (isContactItem(item)) {
      onEdit?.(item);
    }

    handleCloseMenu();
  };

  // TODO: wtf with currentRole?
  const isEditButtonVisible = useMemo(
    () =>
      onEdit &&
      currentRole &&
      [ROLES.ADMIN, ROLES.KAM, ROLES.KAM_PLUS].includes(currentRole as any),
    [onEdit, currentRole]
  );

  const [anchorElPhones, setAnchorElPhones] = useState<null | HTMLElement>(
    null
  );
  const isPhonesMenuOpen = Boolean(anchorElPhones);

  const handleOpenPhonesMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorElPhones(event.currentTarget);
  };

  const handleClosePhonesMenu = () => {
    setAnchorElPhones(null);
  };

  return (
    <div
      className={classNames(
        styles.card,
        className,
        styles[`card${type}`],
        disabled && styles.cardDisabled
      )}>
      <Stack direction="column" gap="12px">
        {vacationInfo && (
          <Typography
            className={styles.vacationInfo}
            fontSize="10px"
            lineHeight="12px">
            {vacationInfo}
          </Typography>
        )}

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <div style={{ width: type === 'SMALL' ? '500px' : '260px' }}>
            <Stack
              direction="row"
              alignItems="center"
              gap="4px"
              justifyContent="space-between">
              {isContactItem(item) && item?.isMainContact && (
                <SvgIcon width="16px" height="16px" icon={Star} />
              )}
              <OverflowTooltip
                text={name}
                fontWeight="600"
                style={{ flexGrow: '1' }}
              />
              {isEditButtonVisible && (
                <IconButton
                  onClick={handleOpenMenu}
                  className={styles.actionBtn}>
                  <Ellipsis />
                </IconButton>
              )}
            </Stack>

            <OverflowTooltip
              fontSize="10px"
              color="#838383"
              text={positionParam || position}
              mt={type === 'SMALL' ? '0' : '4px'}
            />

            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}>
              <MenuItem onClick={handleEditAddress}>
                <ListItemIcon>
                  <Pencil fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="primary.dark">Редактировать</Typography>
                </ListItemText>
              </MenuItem>
              {showGuestAuthAction && item && (
                <MenuItem
                  onClick={() => openGuestPath((item as TContact).userId)}>
                  <ListItemIcon>
                    <Box
                      sx={(theme) => ({
                        '& path': {
                          stroke:
                            theme.palette.customTheme.blueContentSecondary,
                        },
                      })}>
                      <SignIn />
                    </Box>
                  </ListItemIcon>
                  <ListItemText>
                    <Typography color="primary.dark">Авторизоваться</Typography>
                  </ListItemText>
                </MenuItem>
              )}
            </Menu>
          </div>
          {!isContactItem(item) && (
            <Stack direction="row" alignItems="center" gap="4px">
              {onChangeContact && (
                <IconButton
                  className={styles.actionButton}
                  onClick={onChangeContact}>
                  <Pencil />
                </IconButton>
              )}
              {onSignIn && (
                <IconButton className={styles.actionButton} onClick={onSignIn}>
                  <SignIn className={styles.icon} />
                </IconButton>
              )}
              {children}
            </Stack>
          )}
        </Stack>
      </Stack>

      <Stack gap="4px" mt={type === 'SMALL' ? '0' : '12px'}>
        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          justifyContent="space-between"
          maxWidth={type === 'SMALL' ? '144px' : 'auto'}>
          <Stack gap="4px">
            {item?.contactInfoDto.phones &&
              item?.contactInfoDto.phones.length > 0 &&
              item?.contactInfoDto.phones.map((phone) => (
                <Stack direction="row" alignItems="center" gap="8px">
                  {phone.number ? (
                    <Link href={`tel:${phone.number}`}>
                      <SvgIcon
                        width="16px"
                        height="16px"
                        strokeHexColor="#0041A0"
                        icon={Phone}
                      />
                    </Link>
                  ) : (
                    <SvgIcon
                      width="16px"
                      height="16px"
                      strokeHexColor="#0041A0"
                      icon={Phone}
                    />
                  )}
                  {phone.type !== 'MOBILE' && (
                    <Link href={`tel:${phone.number}`}>
                      <Typography fontSize="12px" color="#000000">
                        <PhoneInput
                          displayType="text"
                          value={phone}
                          textSx={{
                            fontSize: 12,
                            color: '#000',
                          }}
                        />
                      </Typography>
                    </Link>
                  )}
                  {phone.type === 'MOBILE' && (
                    <Typography fontSize="12px" color="#000000">
                      <PhoneInput
                        displayType="text"
                        value={phone}
                        textSx={{
                          fontSize: 12,
                          color: '#000',
                        }}
                      />
                    </Typography>
                  )}
                </Stack>
              ))}

            {!item?.contactInfoDto.phones[0] && (
              <Typography fontSize="12px" color="#000000">
                Не указано
              </Typography>
            )}
          </Stack>
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          gap="8px"
          onClick={() => onEmailClick?.(email)}
          sx={{
            cursor: onEmailClick ? 'pointer' : 'default',
          }}>
          <SvgIcon
            width="16px"
            height="16px"
            strokeHexColor="#0041A0"
            icon={Email}
          />
          <Stack
            direction="row"
            alignItems="center"
            gap="4px"
            className={styles.emailContainer}>
            <OverflowTooltip
              className={styles.contact}
              text={email}
              tooltipProps={{
                slotProps: {
                  tooltip: {
                    style: {
                      maxWidth: 400,
                    },
                  },
                },
              }}
            />
            {!!(item as IBidsCustomerContact)?.domainCheckDto &&
              type === 'DEFAULT' && (
                <Whois
                  item={item as TVariantContactItem}
                  businessRequest={businessRequest}
                />
              )}
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};
