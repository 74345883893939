import { useLogoutMutation } from 'src/api/auth';
import { AuthService } from 'src/services';
import { useAppDispatch } from 'src/store';
import { logout as logoutAction } from 'src/store/auth';
import { notificationsSocket, socket } from 'src/utils';

export const useLogout = () => {
  const dispatch = useAppDispatch();
  const [logout] = useLogoutMutation();

  return () => {
    logout();
    AuthService.logout();
    dispatch(logoutAction());
    socket.disconnect();
    notificationsSocket.disconnect();
  };
};
