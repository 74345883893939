import classNames from 'classnames';
import { NestedMenuItem } from 'mui-nested-menu';
import {
  FC,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavLink } from 'react-router-dom';
import { useGetAllCommunicationsChainQuery } from 'src/api/communications';
import { filterSpecificRoleTabs } from 'src/components/layout/bids-layout/main-bids-layout/components/header/components/tabs/utils';
import { useUnreadCounter } from 'src/components/pages/communications/hooks';
import { NotificationDot } from 'src/components/ui';
import { businessRequestMenuItemsByMode } from 'src/configs';
import { PERMISSIONS } from 'src/constants';
import {
  BusinessRequestMode,
  IManagerBusinessRequest,
  IStatusHistory,
  KamStatusCode,
  TVariantBusinessRequest,
} from 'src/models';
import { TNotification } from 'src/models/notifications';
import { AuthService, RolePermissionService } from 'src/services';
import { useAppSelector } from 'src/store';
import useFontFaceObserver from 'use-font-face-observer';
import styles from './tabs.module.scss';

const checkUnreadNotifications = (notifications: TNotification[]): boolean =>
  notifications.length > 0 && notifications.some((elem) => !elem.watched);

type TTabItem = { path: string; title: ReactNode };

export const Tabs: FC<{
  mode: BusinessRequestMode;
  businessRequest: TVariantBusinessRequest;
  statusHistory: IStatusHistory | undefined;
}> = ({ mode, statusHistory, businessRequest }) => {
  const {
    KP,
    AUCTION,
    PAYMENTS,
    REBIDDING,
    REQUEST,
    SHIPMENTS,
    SPECIFICATION,
  } = useAppSelector((state) => state.requestNotifications.notifications);

  const ref = useRef<HTMLDivElement>(null);
  const [visibleTabsItems, setVisibleTabsItems] = useState<TTabItem[]>([]);
  const [hiddenTabsItems, setHiddenTabsItems] = useState<TTabItem[]>([]);

  const tabsItemsCount = useRef<number>(0);

  const [menuItemWidths, setMenuItemWidths] = useState<number[] | undefined>();

  // отслеживаем загрузку шрифта, т.к. без этого шрифта размеры menuItemWidthList
  // в useLayoutEffect не правильные
  const isFontLoaded = useFontFaceObserver([{ family: 'Grtsk Peta' }]);

  const canViewCommunications = RolePermissionService.can(
    PERMISSIONS.VIEW_COMMUNICATION_CHAT_WIDGET
  );

  const canViewUnreadCounter = RolePermissionService.can(
    PERMISSIONS.VIEW_COMMUNICATION_UNREAD_COUNTER
  );

  // TODO: Не самое оптимальное решение в табах делать запросы
  const { data: chainData } = useGetAllCommunicationsChainQuery(
    {
      applicationId:
        businessRequest?.businessApplicationId?.toString() as string,
    },
    {
      pollingInterval: 5000,
      skip:
        !businessRequest?.amocrmNumber ||
        (!canViewCommunications && !canViewUnreadCounter),
    }
  );

  const [unreadCounterCount, unreadCounterColor] = useUnreadCounter(
    chainData?.notViewedCount
  );

  const getCounterData = (tabPath: string) => {
    if (tabPath === 'communications') {
      return +unreadCounterCount;
    }

    return undefined;
  };

  const getNotificationSign = (tabPath: string): boolean => {
    if (tabPath === 'customer-request' || tabPath === 'about') {
      return checkUnreadNotifications(REQUEST);
    }
    if (tabPath === 'auction') return checkUnreadNotifications(AUCTION);
    if (tabPath === 'rebidding') return checkUnreadNotifications(REBIDDING);
    if (tabPath === 'commercial-offers') return checkUnreadNotifications(KP);
    if (tabPath === 'specifications') {
      return checkUnreadNotifications(SPECIFICATION);
    }
    if (tabPath === 'payment') return checkUnreadNotifications(PAYMENTS);
    if (tabPath === 'shipping') return checkUnreadNotifications(SHIPMENTS);
    return false;
  };

  const tabsItems = useMemo(() => {
    let tabItems = businessRequestMenuItemsByMode[mode];

    if (
      mode === BusinessRequestMode.SPECIFIC_ROLE &&
      AuthService.isSpecificRole()
    ) {
      const request = businessRequest as IManagerBusinessRequest;
      tabItems = filterSpecificRoleTabs(tabItems, request);
    }

    const isAuctionStatusPresent = statusHistory?.passedStatuses?.find(
      (item) => item.status.code === KamStatusCode.AUCTION_IN_PROGRESS
    );

    const isRebiddingStatusPresent = statusHistory?.passedStatuses?.find(
      (item) => item.status.code === KamStatusCode.REBIDDING_IN_PROGRESS
    );

    if (
      mode === BusinessRequestMode.CONTRACTOR_ALL ||
      (mode === BusinessRequestMode.CONTRACTOR && !isAuctionStatusPresent)
    ) {
      tabItems = tabItems.filter((item) => item.path !== 'auction');
    }

    if (
      mode === BusinessRequestMode.CONTRACTOR_ALL ||
      (mode === BusinessRequestMode.CONTRACTOR && !isRebiddingStatusPresent)
    ) {
      tabItems = tabItems.filter((item) => item.path !== 'rebidding');
    }

    setVisibleTabsItems(tabItems);

    tabsItemsCount.current = tabItems.length;

    return tabItems;
  }, [mode, statusHistory]);

  // Получаем ширины всех пунктов меню
  useEffect(() => {
    const menuElement = ref.current;
    if (menuElement !== null && isFontLoaded) {
      const menuItemElementList =
        menuElement.getElementsByClassName('tab-item');
      const menuItemWidthList = Array.from(menuItemElementList).map((item) =>
        item instanceof HTMLElement ? item.offsetWidth : 0
      );
      setMenuItemWidths(menuItemWidthList);
    }
  }, [ref, tabsItems, isFontLoaded]);

  const arrangeMenuItems = () => {
    const menu = ref.current;

    if (!menuItemWidths || menu === null) {
      return;
    }

    const visibleItems: TTabItem[] = [];
    const lastIndex = menuItemWidths.length - 1;

    const dotItemWidth = 36;
    const gapWidth = 12;

    const maxMenuWidth = menu.clientWidth - (dotItemWidth * 2 + gapWidth);

    let menuItemsWidth = 0;
    let i;

    for (i = 0; i <= lastIndex && menuItemsWidth < maxMenuWidth; i++) {
      menuItemsWidth += menuItemWidths[i] + gapWidth;

      if (menuItemsWidth < maxMenuWidth) {
        visibleItems.push(tabsItems[i]);
      }
    }

    if (i === 0) {
      i = 1;
    }

    setVisibleTabsItems(visibleItems);
    if (visibleItems.length !== tabsItems.length) {
      setHiddenTabsItems(tabsItems.slice(i - 1));
    } else {
      setHiddenTabsItems([]);
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', arrangeMenuItems);
    arrangeMenuItems();
    return () => window.removeEventListener('resize', arrangeMenuItems);
  }, [menuItemWidths, isFontLoaded, tabsItems, visibleTabsItems.length]);

  return (
    <div ref={ref} className={styles.container}>
      <ul className={styles.containerTabs}>
        {visibleTabsItems.map((tab, index) => (
          <li key={index} className={classNames(styles.tab, 'tab-item')}>
            <NavLink to={tab.path}>
              <NotificationDot
                isShown={getNotificationSign(tab.path)}
                counter={getCounterData(tab.path)}
                color={
                  unreadCounterColor
                    ? (unreadCounterColor as string)
                    : undefined
                }>
                {tab.title}
              </NotificationDot>
            </NavLink>
          </li>
        ))}
      </ul>
      {hiddenTabsItems.length > 0 && (
        <NestedMenuItem
          label="..."
          parentMenuOpen
          rightIcon={false}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            transformOrigin: { vertical: 'top', horizontal: 'left' },
          }}>
          {hiddenTabsItems.map((item) => (
            <NavLink key={item.path} to={item.path} className={styles.moreItem}>
              <NotificationDot isShown={getNotificationSign(item.path)}>
                {item.title}
              </NotificationDot>
            </NavLink>
          ))}
        </NestedMenuItem>
      )}
    </div>
  );
};
