import { generatePath } from 'react-router-dom';
import {
  MenuHome,
  MenuBids,
  MenuCommunications,
  MenuCompanies,
} from 'src/assets/icons';
import { ROLES } from 'src/constants';
import { IUserState } from 'src/store/user/user-slice';

type TMenuItemKeys = 'HOME' | 'BIDS' | 'COMMUNICATIONS' | 'CHAT' | 'COMPANY';

type TItemKeyToPropsMap = Record<
  TMenuItemKeys,
  { label: string; icon: any; path: string }
>;

type TMenuItemKeyToPropsMap = Record<
  TMenuItemKeys,
  { label: string; icon: any; path: string }
>;

const MENU_ITEM_KEY_TO_PROPS_MAP: TItemKeyToPropsMap = {
  HOME: {
    label: 'Главная',
    icon: MenuHome,
    path: '/',
  },
  BIDS: {
    label: 'Заявки',
    icon: MenuBids,
    path: '/requests?statusGroup=ACTIVE',
  },
  COMMUNICATIONS: {
    label: 'Коммуникации',
    icon: MenuCommunications,
    path: '/communications/all',
  },
  CHAT: {
    label: 'Чат',
    icon: MenuCommunications,
    path: '/chat',
  },
  COMPANY: {
    label: 'Моя компания',
    icon: MenuCompanies,
    path: '/companies/:id',
  },
};

export const ROLE_TO_ITEMS_MAP = (
  user: IUserState
): Record<ROLES, Array<TMenuItemKeyToPropsMap[TMenuItemKeys]>> => ({
  [ROLES.ADMIN]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMMUNICATIONS,
  ],
  [ROLES.SYSTEM]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMMUNICATIONS,
  ],
  [ROLES.KAM]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMMUNICATIONS,
  ],
  [ROLES.KAM_PLUS]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMMUNICATIONS,
  ],
  [ROLES.CUSTOMER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    {
      ...MENU_ITEM_KEY_TO_PROPS_MAP.COMPANY,
      path: user.organizationId
        ? generatePath('/companies/:id', {
            id: user.organizationId.toString(10),
          })
        : '',
    },
  ],
  [ROLES.CONTRACTOR]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    {
      ...MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
      label: 'Мои заявки',
    },
    {
      ...MENU_ITEM_KEY_TO_PROPS_MAP.COMPANY,
      path: user.organizationId
        ? generatePath('/companies/:id', {
            id: user.organizationId.toString(10),
          })
        : '',
    },
  ],

  [ROLES.TECH_EXPERT]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.BACK_OFFICE]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.COMMERCIAL_DIRECTOR]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.LOGISTIC]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.PARTNER_RELATIONS_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.GENERAL_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.FINANCIAL_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.OFFICE_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
  [ROLES.LAWYER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
  ],
});
