import { FC, Ref } from 'react';
import classNames from 'classnames';
import {
  PatternInput,
  InputAdornment,
  Link,
  FormControl,
  useFormControl,
  FormHelperText,
} from 'src/components/ui';
import { SxProps, Theme } from '@mui/material';
import { AdditionalNumber, PhoneSelect, TPhone } from './components';
import styles from './phone-input.module.scss';

export type TPhoneInputValue = {
  type: TPhone;
  number?: string;
  extension?: string;
  createdAt?: string;
};

const phoneFormat = {
  format: '+7 ### ### ## ##',
  placeholder: '+7 ___ ___ __ __',
};

export const PhoneInput: FC<{
  value?: TPhoneInputValue;
  onChange?: (v: TPhoneInputValue) => void;
  displayType?: 'input' | 'text' | 'view';
  inputRef?: Ref<any>;
  id?: string;
  errorMessage?: string;
  textSx?: SxProps<Theme>;
  onlyMobileType?: boolean;
}> = ({
  value = { type: 'MOBILE' },
  onChange,
  displayType = 'input',
  inputRef,
  id,
  errorMessage,
  textSx,
  onlyMobileType,
}) => {
  const { error } = useFormControl() || {};

  const onTypeChange = (type: TPhoneInputValue['type']) => {
    onChange?.({
      type,
      number: value.number,
      extension: type === 'MOBILE' ? undefined : value.extension,
    });
  };

  const onPhoneChange = (phone: TPhoneInputValue['number']) => {
    onChange?.({
      ...value,
      number: phone,
    });
  };

  const onExtensionChange = (extension: string) => {
    onChange?.({
      ...value,
      extension,
    });
  };

  if (displayType === 'text' || displayType === 'view') {
    if (!value.number) {
      return null;
    }

    return value.type === 'MOBILE' && displayType === 'text' ? (
      <Link
        href={`tel:${value.number}`}
        sx={textSx}
        data-id="contact_card_phone">
        <PatternInput
          displayType="text"
          className={styles.textCode}
          valueIsNumericString
          format={phoneFormat.format}
          value={value.number}
        />
      </Link>
    ) : (
      <span data-id="contact_card_phone">
        <PatternInput
          displayType="text"
          className={classNames(styles.textCode, styles.mr)}
          valueIsNumericString
          format={phoneFormat.format}
          value={value.number}
        />
        {value.extension && (
          <span className={styles.textCode}>{`доб. ${value.extension}`}</span>
        )}
      </span>
    );
  }

  return (
    <div className={styles.phone}>
      <div className={styles.phoneValue}>
        <PatternInput
          {...phoneFormat}
          id={id}
          mask="_"
          valueIsNumericString
          fullWidth
          inputRef={inputRef}
          value={value.number}
          onChange={(e) =>
            onPhoneChange(e.target.value.replace(/\D/g, '').substring(1))
          }
          startAdornment={
            !onlyMobileType && (
              <InputAdornment position="start" className={styles.phoneLabel}>
                <PhoneSelect value={value.type} onChange={onTypeChange} />
              </InputAdornment>
            )
          }
          className={classNames(
            styles.phoneValueInput,
            onlyMobileType && styles.phoneValueMobileOnlyInput
          )}
        />
        {!!errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </div>

      {value.type !== 'MOBILE' && (
        <div className={styles.phoneCode}>
          {/* TODO MUI: There are multiple `InputBase` components inside a FormControl
      without extra FormControl wrap */}

          <FormControl error={error} className={styles.additionalPhoneWrapper}>
            <AdditionalNumber
              value={value.extension}
              id={`${id}extra`}
              placeholder="_______"
              onChange={(e) => onExtensionChange(e.target.value)}
            />
          </FormControl>
        </div>
      )}
    </div>
  );
};
