import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { TAssignedManager } from 'src/api/admin/company';
import {
  IApplicationListQueryParams,
  TApplicationListResult,
} from 'src/api/communications/models';
import { TListRequestParams } from 'src/api/models';
import { TContact } from 'src/components/pages/company/contacts/types';
import { ROLES } from 'src/constants';
import {
  BusinessRequestMode,
  ContractorStatusCode,
  CustomerStatusCode,
  IBusinessRequestAuctionDateStatistics,
  IBusinessRequestCustomerInfo,
  IBusinessRequestDeletePerformersDto,
  IBusinessRequestListPerformers,
  IBusinessRequestListPerformersDto,
  IBusinessRequestSearchPerformers,
  IBusinessRequestSearchPerformersDto,
  IBusinessRequestSetContractorRequestDto,
  ICargoDescriptions,
  IContractorBusinessRequest,
  IContractorRejectApplicationParams,
  IContractorRejectRequestReasons,
  IContractorRequest,
  IContractorRequestKanban,
  IContractorRequestKanbanAuctionDateStatistics,
  IContractorRequestKanbanCostIndicatorStatistics,
  IContractorRequestKanbanParams,
  ICustomerBusinessRequest,
  ICustomerRejectApplicationParams,
  ICustomerRejectApplicationReasons,
  ICustomerRequestKanban,
  ICustomerRequestKanbanCostIndicatorStatistics,
  ICustomerRequestKanbanParams,
  IManagerBusinessRequest,
  IManagerKanbanBusinessRequest,
  IManagerKanbanBusinessRequestParams,
  IManagerKanbanCostIndicatorStatistics,
  IManagerKanbanLastActivityBusinessRequest,
  IManagerKanbanLastActivityBusinessRequestParams,
  IManagerRejectApplicationParams,
  IManagerRejectApplicationReasons,
  IRejectApplicationReasonsParams,
  IRequestPerformersForm,
  IStatusHistory,
  KamStatusCode,
  TCargoParameters,
  TCargoParametersFull,
  TContractorBusinessRequestList,
  TGetAllBusinessRequestsByContractorParams,
  TGetAllBusinessRequestsByContractorResponse,
  TRequest,
} from 'src/models';
import {
  IRequestNumberWithId,
  ISaleDateHistory,
  TApplicationContractorsInfo,
  TApplicationInnerRolesInfo,
  TCompanyBusinessRequest,
  TRequestChangelogWindows,
} from 'src/models/bid';
import {
  BusinessRequestDocumentType,
  IAssignExpertData,
  InvokeExpertStage,
  InvokeExpertStatus,
  IRolesExcludedFromNotificationsGetParams,
  IRolesExcludedFromNotificationsParams,
  IRolesExcludedFromNotificationsResult,
  IThereIsBestKpStatusParams,
  IThereIsNoBestKpStatusParams,
  IUpdateBusinessRequestInfo,
  LogisticsCalculationDto,
  TGetAllCompanyRequestsParams,
} from 'src/models/bids-details';
import { TGetContractorRequestsInfoResponse } from 'src/models/contractor';
import { TCreateManagerRequest } from 'src/models/create-request';
import { baseQueryWithReauth } from 'src/store/utils';
import { formatRequestChangelogLine } from 'src/utils/business-request';
import { bidsSortingColumn } from '../utils';

export type TCustomerRequestListSortKey =
  | 'CREATED_AT'
  | 'COMMERCIAL_OFFER_DUE_DATE_AT'
  | 'TECHNICAL_OPPORTUNITIES_CATEGORIES'
  | 'NAME'
  | 'NUMBER';
export type TCustomerRequestListParams = Omit<
  TListRequestParams<TCustomerRequestListSortKey, { statusCategory?: string }>,
  'pagingDto'
>;

export type TContractorBusinessRequestListSortKey =
  | 'CREATED_AT'
  | 'COMMERCIAL_OFFER_DUE_DATE_AT'
  | 'TECHNICAL_OPPORTUNITIES_CATEGORIES'
  | 'NUMBER'
  | 'NAME';
export type TContractorBusinessRequestListParams = Omit<
  TListRequestParams<
    TContractorBusinessRequestListSortKey,
    { statusCategory?: string }
  >,
  'pagingDto' | 'searchQueries'
> & {
  paging: TListRequestParams['pagingDto'];
  searchQuery: TListRequestParams['searchQueries'];
};

export type TManagerRequestListSortKey =
  | 'CREATED_AT'
  | 'COMMERCIAL_OFFER_DUE_DATE_AT';

export const businessRequestApi = createApi({
  reducerPath: 'businessRequestApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    'getCustomerRequest',
    'getManagerRequest',
    'getContractorRequest',
    'getListPerformers',
    'getManagerStatusHistory',
    'getCustomerStatusHistory',
    'getContractorStatusHistory',
    'getContractorCommercialOffers',
    'getCustomerCommercialOffers',
    'getManagerContractorCommercialOffers',
    'getManagerCustomerCommercialOffers',
    'getManagerContractorSpecifications',
    'getManagerCustomerSpecifications',
    'getCustomerPayment',
    'getContractorPayment',
    'getManagerShipping',
    'getContractorShipping',
    'getCustomerShipping',
    'getManagerAuction',
    'getContractorAuction',
    'getContractorAuctionListBid',
    'getManagerAuctionListBidsFromContractor',
    'getRebiddingList',
    'getListPerformersRebidding',
    'getContractorRebidding',
    'getCustomerInfo',
    'getRolesExcludedFromNotifications',
    'getSaleDateHistory',
    'getManagerCustomerCommercialOffersForImprovement',
    'fieldHistoryLogs',
    'getRequestLogistics',
    'getRequestPositions',
    'getOkei',
    'getPaymentConditions',
    'getApplicationContractorContacts',
    'getPaymentConditionTemplates',
    'getRequestFieldsChangelog',
  ],

  endpoints: (builder) => ({
    getCustomerInfo: builder.query<IBusinessRequestCustomerInfo, number>({
      query: (id) => ({
        url: `business-requests/api/v1/kam/business-request/${id}/customer`,
      }),

      transformResponse: (
        response: Omit<IBusinessRequestCustomerInfo, 'businessRequestId'>,
        _,
        businessRequestId
      ) => ({
        ...{
          ...response,
          contacts: response.contacts.map((contact) => ({
            ...contact,
            isMainContact: contact.contactType.includes('MAIN'),
          })),
        },
        businessRequestId,
      }),

      providesTags: (result, error, arg) => [
        { type: 'getCustomerInfo', id: arg },
      ],
    }),

    updateBusinessRequestInfo: builder.mutation<
      unknown,
      IUpdateBusinessRequestInfo & {
        businessApplicationId: number;
        businessRequestId: number;
      }
    >({
      query: ({ businessApplicationId, businessRequestId, ...body }) => ({
        url: `business-requests/api/v1/business-application/${businessApplicationId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getRequestFieldsChangelog' },
        { type: 'getCustomerInfo', id: arg.businessRequestId },
        { type: 'getCustomerRequest', id: arg.businessRequestId },
        { type: 'getContractorRequest', id: arg.businessRequestId },
        { type: 'getManagerRequest', id: arg.businessRequestId },
        { type: 'getSaleDateHistory' },
      ],
    }),

    updateBusinessRequestContactList: builder.mutation<
      Array<TContact>,
      {
        contactIds: Array<number>;
        businessApplicationId: number;
        businessRequestId: number;
      }
    >({
      query: ({ businessApplicationId, contactIds }) => ({
        url: `business-requests/api/v1/business-application/${businessApplicationId}/contact`,
        method: 'PATCH',
        body: { contactIds },
      }),
      async onQueryStarted(
        { businessRequestId },
        { dispatch, queryFulfilled }
      ) {
        try {
          const { data: contacts } = await queryFulfilled;
          dispatch(
            businessRequestApi.util.updateQueryData(
              'getCustomerInfo',
              businessRequestId,
              (data) => ({
                ...data,
                contacts,
              })
            )
          );
        } catch {
          // ignore
        }
      },
    }),

    getManagerRequest: builder.query<IManagerBusinessRequest, number>({
      query: (id) => ({
        url: `business-requests/api/v1/kam/business-request/${id}`,
      }),
      transformResponse: (
        {
          deliveryParameters,
          ...response
        }: Omit<IManagerBusinessRequest, 'deliveryParameters'> & {
          deliveryParameters: TCargoParameters;
        },
        _,
        businessRequestId
      ) => ({
        ...response,
        businessRequestId,
        deliveryParameters: deliveryParameters && {
          deliveryType: deliveryParameters.type,
          ...deliveryParameters.params,
        },
        prospect: response.prospect.map((prospectItem) => ({
          ...prospectItem,
          prospectAnswerDuedate: `${prospectItem.prospectAnswerDuedate}Z`,
        })),
      }),
      providesTags: (result, error, arg) => [
        { type: 'getManagerRequest', id: arg },
      ],
    }),

    getCustomerRequest: builder.query<ICustomerBusinessRequest, number>({
      query: (id) => ({
        url: `business-requests/api/v1/customer/business-request/${id}`,
      }),
      transformResponse: (
        response: ICustomerBusinessRequest,
        _,
        businessRequestId
      ) => ({ ...response, businessRequestId }),
      providesTags: (result, error, arg) => [
        { type: 'getCustomerRequest', id: arg },
      ],
    }),

    getContractorRequest: builder.query<IContractorRequest, number>({
      query: (id) => ({
        url: `business-requests/api/v1/contractor/contractor-request/${id}`,
      }),
      providesTags: (result, error, arg) => [
        { type: 'getContractorRequest', id: arg },
      ],
      transformResponse: (
        data: Omit<IContractorRequest, 'documents'> & {
          documents: IContractorRequest['documents'][BusinessRequestDocumentType];
        }
      ) => ({
        ...data,
        documents: {
          [BusinessRequestDocumentType.REQUEST_FOR_CONTRACTORS]:
            data.documents ?? [],
        },
      }),
    }),
    startContractorRequest: builder.mutation<
      {
        currentStatus: {
          code: ContractorStatusCode;
          displayName: string;
        };
      },
      { id: number }
    >({
      query: ({ id }) => ({
        url: `business-requests/api/v1/contractor/contractor-request/${id}/start-progress`,
        method: 'PATCH',
      }),
    }),

    getContractorBusinessRequest: builder.query<
      IContractorBusinessRequest,
      number
    >({
      query: (id) => ({
        url: `business-requests/api/v1/contractor/business-request/${id}`,
        method: 'GET',
      }),
      transformResponse: (
        data: Omit<IContractorBusinessRequest, 'documents'> & {
          documents: IContractorBusinessRequest['documents'][BusinessRequestDocumentType];
        }
      ) => ({
        ...data,
        documents: {
          [BusinessRequestDocumentType.REQUEST_FOR_CONTRACTORS]: data.documents,
        },
      }),
    }),

    restoreBusinessApplication: builder.mutation<
      void,
      { businessApplicationId: number; businessRequestId: number }
    >({
      query: ({ businessApplicationId }) => ({
        url: `business-requests/api/v1/manager/refusal/business-application/${businessApplicationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getManagerRequest', id: arg.businessRequestId },
      ],
    }),

    restoreContractorBusinessApplication: builder.mutation<
      void,
      { contractorRequestId: number }
    >({
      query: ({ contractorRequestId }) => ({
        url: `business-requests/api/v1/contragent/refusal-request/contractor-request/cancel?contractorRequestId=${contractorRequestId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getContractorRequest', id: arg.contractorRequestId },
      ],
    }),

    removeBusinessApplication: builder.mutation<
      void,
      { businessApplicationId: number; businessRequestId: number }
    >({
      query: ({ businessRequestId }) => ({
        url: `business-requests/api/v1/business-request/${businessRequestId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, arg) => [
        { type: 'getManagerRequest', id: arg.businessRequestId },
      ],
    }),

    getContractorAllRequests: builder.mutation<
      TContractorBusinessRequestList,
      TContractorBusinessRequestListParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/contractor/business-request',
        method: 'POST',
        body,
      }),
    }),

    updateNameRequestById: builder.mutation<
      { name: string; id: number },
      { name: string; id: number }
    >({
      query: ({ id, name }) => ({
        url: `business-requests/api/v1/business-request/${id}/name`,
        method: 'PATCH',
        body: { name },
      }),

      async onQueryStarted({ id, name }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            businessRequestApi.util.updateQueryData(
              'getManagerRequest',
              id,
              (data) => ({
                ...data,
                name,
              })
            )
          );
        } catch {
          throw new Error();
        }
      },
    }),

    getSearchPerformers: builder.query<
      IBusinessRequestSearchPerformers,
      IBusinessRequestSearchPerformersDto
    >({
      query: ({ id, body }) => ({
        url: `business-requests/api/v1/business-request/${id}/matching-contractors`,
        method: 'POST',
        body,
      }),
    }),
    setContractorRequest: builder.mutation<
      unknown,
      IBusinessRequestSetContractorRequestDto
    >({
      query: ({ id, body }) => ({
        url: `business-requests/api/v1/business-request/${id}/matching-contractors/contractor-request`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            businessRequestApi.util.invalidateTags([
              { type: 'getApplicationContractorContacts' },
              {
                type: 'getListPerformers',
                id,
              },
              {
                type: 'getManagerRequest',
                id,
              },
            ])
          );

          const currentStatusHistory = (await dispatch(
            businessRequestApi.endpoints.getManagerStatusHistory.initiate(
              Number(id)
            )
          ).unwrap()) as IStatusHistory;

          if (
            currentStatusHistory.passedStatuses.at(-1)?.status.code ===
            KamStatusCode.QUALIFIED
          ) {
            dispatch(
              businessRequestApi.util.invalidateTags([
                {
                  type: 'getManagerStatusHistory',
                  id,
                },
              ])
            );
          }
        } catch {
          throw new Error();
        }
      },
    }),

    getManagerStatusHistory: builder.query<IStatusHistory<KamStatusCode>, any>({
      query: (businessRequestId) => ({
        url: `business-requests/api/v1/business-request/${businessRequestId}/status-history`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: 'getManagerStatusHistory', id: arg },
      ],
      async onQueryStarted(businessRequestId, { dispatch, queryFulfilled }) {
        try {
          const { data: statusHistory } = await queryFulfilled;

          const managerRequest = await dispatch(
            businessRequestApi.endpoints.getManagerRequest.initiate(
              businessRequestId
            )
          ).unwrap();

          const lastStatusHistory = statusHistory.passedStatuses.at(-1)?.status;
          const lastManagerRequestHistory = managerRequest.status;

          if (
            lastStatusHistory &&
            lastStatusHistory?.code !== lastManagerRequestHistory?.code
          ) {
            dispatch(
              businessRequestApi.util.updateQueryData(
                'getManagerRequest',
                businessRequestId,
                (data) => ({
                  ...data,
                  status: lastStatusHistory,
                })
              )
            );
          }
        } catch (err) {
          console.error(err);
        }
      },
      // Сделано, поскольку бек не передает дату в правильном формате, хотя хранит в UTC (без Z на конце)
      transformResponse: (data: IStatusHistory<KamStatusCode>) => ({
        upcomingStatuses: data.upcomingStatuses,
        passedStatuses: data.passedStatuses.map((item) => ({
          status: item.status,
          statusHistoryId: item.statusHistoryId,
          createdAt: item.createdAt.endsWith('Z')
            ? item.createdAt
            : `${item.createdAt}Z`,
        })),
      }),
    }),

    getContractorStatusHistory: builder.query<
      IStatusHistory<ContractorStatusCode>,
      any
    >({
      query: (contractorRequestId) => ({
        url: `business-requests/api/v1/contractor-request/${contractorRequestId}/status-history`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        { type: 'getContractorStatusHistory', id: arg },
      ],
      async onQueryStarted(contractorRequestId, { dispatch, queryFulfilled }) {
        try {
          const { data: statusHistory } = await queryFulfilled;

          const contractorRequest = await dispatch(
            businessRequestApi.endpoints.getContractorRequest.initiate(
              contractorRequestId
            )
          ).unwrap();

          const lastStatusHistory = statusHistory.passedStatuses.at(-1)?.status;
          const lastContractorRequestHistory = contractorRequest.status;

          if (
            lastStatusHistory &&
            lastStatusHistory?.code !== lastContractorRequestHistory?.code
          ) {
            dispatch(
              businessRequestApi.util.updateQueryData(
                'getContractorRequest',
                contractorRequestId,
                (data) => ({
                  ...data,
                  status: lastStatusHistory,
                })
              )
            );
          }
        } catch {
          throw new Error();
        }
      },
    }),

    getCustomerStatusHistory: builder.query<
      IStatusHistory<CustomerStatusCode>,
      { businessRequestId: number; businessApplicationId: number } | number
    >({
      query: (ids) => ({
        url: `business-requests/api/v1/business-application/${
          typeof ids === 'number' ? 0 : ids.businessApplicationId
        }/status-history`,
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [
        {
          type: 'getCustomerStatusHistory',
          id: typeof arg === 'number' ? 0 : arg.businessApplicationId,
        },
      ],
      async onQueryStarted(ids, { dispatch, queryFulfilled }) {
        try {
          const { data: statusHistory } = await queryFulfilled;
          const businessRequestId =
            typeof ids === 'number' ? 0 : ids.businessRequestId!;
          const businessApplicationId =
            typeof ids === 'number' ? 0 : ids.businessApplicationId!;

          const customerRequest = await dispatch(
            businessRequestApi.endpoints.getCustomerRequest.initiate(
              businessRequestId
            )
          ).unwrap();

          const lastStatusHistory = statusHistory.passedStatuses.at(-1)?.status;
          const lastCustomerRequestHistory = customerRequest.status;

          if (
            lastStatusHistory &&
            lastStatusHistory?.code !== lastCustomerRequestHistory?.code
          ) {
            dispatch(
              businessRequestApi.util.updateQueryData(
                'getCustomerStatusHistory',
                { businessApplicationId, businessRequestId },
                (data) => ({
                  ...data,
                  status: lastStatusHistory,
                })
              )
            );
          }
        } catch {
          throw new Error();
        }
      },
    }),
    getListPerformers: builder.query<
      IBusinessRequestListPerformers,
      IBusinessRequestListPerformersDto
    >({
      query: ({ id, body }) => ({
        url: `business-requests/api/v1/business-request/${id}/contractor-request`,
        method: 'POST',
        body,
      }),
      providesTags: (result, error, arg) => [
        { type: 'getListPerformers', id: arg.id },
      ],
    }),

    deletePerformers: builder.query<
      unknown,
      IBusinessRequestDeletePerformersDto
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/contractor-exclusion',
        method: 'POST',
        body,
      }),
    }),

    takeRequestToWork: builder.mutation<{ id: number }, number>({
      query: (id) => ({
        url: `business-requests/api/v1/contractor/business-request/${id}/contractor-request`,
        method: 'POST',
      }),
    }),

    setCustomerRejectApplication: builder.mutation<
      void,
      ICustomerRejectApplicationParams
    >({
      query: ({ businessRequestId, ...body }) => ({
        url: 'business-requests/api/v1/contragent/refusal-request/business-application',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getCustomerRequest', id: arg.businessRequestId },
      ],
    }),

    setContractorRejectRequest: builder.mutation<
      void,
      IContractorRejectApplicationParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/contragent/refusal-request/contractor-request',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getContractorRequest', id: arg.contractorRequestId },
      ],
    }),

    setManagerRejectApplication: builder.mutation<
      void,
      IManagerRejectApplicationParams
    >({
      query: ({ businessRequestId, ...body }) => ({
        url: 'business-requests/api/v1/manager/refusal/business-application',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getManagerRequest', id: arg.businessRequestId },
      ],
    }),

    getManagerRejectApplicationReasons: builder.query<
      IManagerRejectApplicationReasons,
      IRejectApplicationReasonsParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/admin/business-application-refusal-cause-by-kam/search',
        method: 'POST',
        body,
      }),
    }),

    getCustomerRejectApplicationReasons: builder.query<
      ICustomerRejectApplicationReasons,
      IRejectApplicationReasonsParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/admin/business-application-refusal-request-cause-by-customer/search',
        method: 'POST',
        body,
      }),
    }),

    getContractorRejectRequestReasons: builder.query<
      IContractorRejectRequestReasons,
      IRejectApplicationReasonsParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/admin/contractor-request-refusal-cause-by-contractor/search',
        method: 'POST',
        body,
      }),
    }),

    updateBusinessRequest: builder.mutation<
      IManagerBusinessRequest,
      Omit<
        IRequestPerformersForm,
        | 'commercialOfferDueDate'
        | 'documents'
        | 'cargoDescriptions'
        | 'implementationStartDate'
        | 'implementationEndDate'
      > & {
        id: number;
        commercialOfferDuedate: string | null;
        implementationStartDate: string | null;
        implementationEndDate: string | null;
        cargoDescriptions?: ICargoDescriptions['parameters'];
      }
    >({
      query: ({ id, ...body }) => ({
        url: `business-requests/api/v1/business-request/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              { type: 'getRequestFieldsChangelog' },
              { type: 'fieldHistoryLogs' },
              { type: 'getManagerRequest' },
              {
                type: 'getManagerContractorCommercialOffers',
                id: arg.id,
              },
              {
                type: 'getRebiddingList',
                id: arg.id,
              },
            ],
      transformResponse: ({
        commercialOfferDuedate,
        cargoDescriptions,
        statusDto,
        ...data
      }: Omit<
        IManagerBusinessRequest,
        'commercialOfferDueDate' | 'deliveryParameters' | 'status'
      > & {
        commercialOfferDuedate: IManagerBusinessRequest['commercialOfferDueDate'];
        cargoDescriptions?: IManagerBusinessRequest['deliveryParameters'];
        statusDto: IManagerBusinessRequest['status'];
      }): IManagerBusinessRequest => ({
        ...data,
        status: statusDto,
        commercialOfferDueDate: commercialOfferDuedate,
        deliveryParameters: cargoDescriptions,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: resultData } = await queryFulfilled;

          dispatch(
            businessRequestApi.util.updateQueryData(
              'getManagerRequest',
              id,
              (data) => ({
                ...data,
                ...resultData,
              })
            )
          );
        } catch {
          // ignore
        }
      },
    }),

    updateBusinessRequestColor: builder.mutation<
      { color: string; businessRequestId: number },
      { id: number; color: string }
    >({
      query: ({ id, ...params }) => ({
        url: `business-requests/api/v1/business-application/${id}/color`,
        method: 'PATCH',
        params,
      }),

      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { color, businessRequestId },
          } = await queryFulfilled;
          dispatch(
            businessRequestApi.util.updateQueryData(
              'getManagerRequest',
              businessRequestId,
              (data) => ({
                ...data,
                color,
              })
            )
          );
        } catch {
          // ignore
        }
      },
    }),

    rejectRefusalRequest: builder.mutation<
      void,
      { applicationId: number; businessRequestId: number }
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/manager/refusal-request/business-application/reject',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getManagerRequest', id: arg.businessRequestId },
      ],
    }),

    approvalRefusalRequest: builder.mutation<
      void,
      { applicationId: number; businessRequestId: number }
    >({
      query: ({ applicationId }) => ({
        url: `business-requests/api/v1/manager/refusal/approval/${applicationId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getManagerRequest', id: arg.businessRequestId },
      ],
    }),

    discardRefusalRequest: builder.mutation<
      void,
      { applicationId: number; businessRequestId: number }
    >({
      query: ({ applicationId }) => ({
        url: `business-requests/api/v1/manager/refusal/approval/discard/business-application/${applicationId}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getManagerRequest', id: arg.businessRequestId },
      ],
    }),

    confirmRefusalRequest: builder.mutation<
      void,
      IManagerRejectApplicationParams
    >({
      query: ({ businessRequestId, ...body }) => ({
        url: 'business-requests/api/v1/manager/refusal-request/business-application/confirm',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getManagerRequest', id: arg.businessRequestId },
      ],
    }),
    customerCreateBid: builder.mutation<
      { businessApplicationId: number; businessRequestId: number },
      {
        name: string;
        commentForContractor: string;
        deliveryAddressId: string | number;
      }
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/customer/business-application/create',
        method: 'POST',
        body,
      }),
    }),
    createRequestByManager: builder.mutation<TRequest, TCreateManagerRequest>({
      query: (body) => ({
        url: 'business-requests/api/v1/manager/business-application',
        method: 'POST',
        body,
      }),
    }),
    closeRequestByManager: builder.mutation<
      {
        businessApplicationId: number;
        status: string;
      },
      { id: number; businessRequestId: number }
    >({
      query: ({ id }) => ({
        url: `business-requests/api/v1/business-application/${id}/close`,
        method: 'PATCH',
      }),
      async onQueryStarted(
        { businessRequestId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            businessRequestApi.util.invalidateTags([
              {
                type: 'getManagerStatusHistory',
                id: businessRequestId,
              },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),
    setAssignmentManager: builder.mutation<
      void,
      {
        businessRequestId: number;
        userId: number;
        type: 'kam' | 'kam_plus';
      }
    >({
      query: ({ businessRequestId, userId, type }) => ({
        url: `business-requests/api/v1/business-request/${businessRequestId}/assignment/${type}/${userId}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getManagerRequest', id: arg.businessRequestId },
      ],
    }),
    // То же, что и setAssignmentManager, но для "новых ролей"
    setAssignmentSpecific: builder.mutation<
      void,
      {
        businessRequestId: number;
        userId: number;
        role: ROLES;
      }
    >({
      query: ({ businessRequestId, role, userId }) => ({
        url: userId
          ? `business-requests/api/v1/business-request/${businessRequestId}/assignment/${role}?userId=${userId}`
          : `business-requests/api/v1/business-request/${businessRequestId}/assignment/${role}`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getManagerRequest', id: arg.businessRequestId },
      ],
    }),
    calculateCargoPrice: builder.query<
      { shippingCost: number },
      {
        cargoDescription: TCargoParametersFull;
        kladrIdFrom: string;
        kladrIdTo: string;
      }
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/business-request/delivery/cargo-price',
        method: 'POST',
        body,
      }),
    }),
    getReadiness: builder.query<
      {
        businesApplicationId: number;
        availableForClosing: boolean;
      },
      { businessApplicationId: number }
    >({
      query: ({ businessApplicationId }) => ({
        url: `business-requests/api/v1/business-application/${businessApplicationId}/readiness`,
      }),
    }),
    getContractorRequestsInfo: builder.query<
      TGetContractorRequestsInfoResponse,
      { organizationIds: number[] }
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/business-request/contractor-requests-info',
        method: 'POST',
        body,
      }),
    }),
    getBusinessRequestAuctionDateStatistics: builder.query<
      IBusinessRequestAuctionDateStatistics[],
      { businessRequestIds: number[] }
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/manager/kanban/business-requests/auction-date-statistics',
        method: 'POST',
        body,
      }),
    }),

    getManagerBusinessRequest: builder.mutation<
      IManagerKanbanBusinessRequest,
      IManagerKanbanBusinessRequestParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/manager/kanban/business-requests',
        method: 'POST',
        body,
      }),
      transformResponse: (data: IManagerKanbanBusinessRequest) =>
        bidsSortingColumn(data, BusinessRequestMode.MANAGER),
    }),

    getManagerLastActivityStatistics: builder.query<
      IManagerKanbanLastActivityBusinessRequest[],
      IManagerKanbanLastActivityBusinessRequestParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/manager/kanban/business-requests/last-activity-statistics',
        method: 'POST',
        body,
      }),
    }),

    getManagerCostIndicatorStatistics: builder.query<
      IManagerKanbanCostIndicatorStatistics[],
      { businessRequestIds: number[] }
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/manager/kanban/cost-indicator',
        method: 'POST',
        body,
      }),
    }),

    getContractorRequests: builder.mutation<
      IContractorRequestKanban,
      IContractorRequestKanbanParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/contractor/kanban/contractor-request',
        method: 'POST',
        body,
      }),
      transformResponse: (data: IContractorRequestKanban) =>
        bidsSortingColumn(data, BusinessRequestMode.CONTRACTOR),
    }),

    getContractorCostIndicatorStatistics: builder.query<
      IContractorRequestKanbanCostIndicatorStatistics[],
      { contractorRequestIds: number[] }
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/contractor/kanban/cost-indicator',
        method: 'POST',
        body,
      }),
    }),

    getContractorAuctionDateStatistics: builder.query<
      IContractorRequestKanbanAuctionDateStatistics[],
      { contractorRequestIds: number[] }
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/kanban/contractor-requests/auction-date-statistics',
        method: 'POST',
        body,
      }),
    }),

    getAllBusinessRequestsByContractor: builder.mutation<
      TGetAllBusinessRequestsByContractorResponse,
      TGetAllBusinessRequestsByContractorParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v2/contractor/business-requests',
        method: 'POST',
        body,
      }),
    }),

    getCustomerRequests: builder.mutation<
      ICustomerRequestKanban,
      ICustomerRequestKanbanParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/customer/kanban/business-applications',
        method: 'POST',
        body,
      }),
      transformResponse: (data: ICustomerRequestKanban) =>
        bidsSortingColumn(data, BusinessRequestMode.CUSTOMER),
    }),

    getRequestNumbersWithIds: builder.query<IRequestNumberWithId[], void>({
      query: () => ({
        url: 'business-requests/api/v1/manager/kanban/filters',
      }),
    }),

    getCustomerCostIndicatorStatistics: builder.query<
      ICustomerRequestKanbanCostIndicatorStatistics[],
      { businessRequestIds: number[] }
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/customer/kanban/cost-indicator',
        method: 'POST',
        body,
      }),
    }),

    getApplicationList: builder.query<
      TApplicationListResult,
      IApplicationListQueryParams
    >({
      query: (params) => ({
        url: 'business-requests/api/v1/business-request/search-for-aggregator',
        method: 'GET',
        params,
      }),
    }),

    getApplicationListV2: builder.query<
      TApplicationListResult,
      IApplicationListQueryParams
    >({
      query: (params) => ({
        url: 'business-requests/api/v2/business-request/search-for-aggregator',
        method: 'GET',
        params,
      }),
    }),

    getRolesExcludedFromNotifications: builder.query<
      IRolesExcludedFromNotificationsResult,
      IRolesExcludedFromNotificationsGetParams
    >({
      query: ({ businessApplicationId, businessRequestName }) => ({
        url: `business-requests/api/v1/business-application/${businessApplicationId}/exclude-from-notifications?requestName=${businessRequestName}`,
      }),
      providesTags: (result, error, arg) => [
        {
          type: 'getRolesExcludedFromNotifications',
          id: arg.businessApplicationId,
        },
      ],
    }),
    updateRulesExcludedFromNotifications: builder.mutation<
      IRolesExcludedFromNotificationsResult['excludedRolesFromNotifications'],
      IRolesExcludedFromNotificationsParams
    >({
      query: ({ businessApplicationId, ...body }) => ({
        url: `business-requests/api/v1/business-application/${businessApplicationId}/exclude-from-notifications`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => [
        {
          type: 'getRolesExcludedFromNotifications',
          id: arg.businessApplicationId,
        },
      ],
    }),

    updateThereIsNoBestKpStatus: builder.mutation<
      void,
      IThereIsNoBestKpStatusParams
    >({
      query: ({ businessApplicationId, body }) => ({
        url: `business-requests/api/v1/business-application/${businessApplicationId}/no-best-commercial-offer`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [
        'getManagerCustomerCommercialOffers',
        'getManagerCustomerCommercialOffersForImprovement',
      ],
      async onQueryStarted(
        { body: { businessRequestId } },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;

          dispatch(
            businessRequestApi.util.invalidateTags([
              {
                type: 'getManagerRequest',
                id: businessRequestId,
              },
              {
                type: 'getManagerStatusHistory',
                id: businessRequestId,
              },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),

    setThereIsBestKpStatus: builder.mutation<void, IThereIsBestKpStatusParams>({
      query: ({ businessApplicationId, contractorCommercialOfferId }) => ({
        url: `business-requests/api/v1/business-application/${businessApplicationId}/status-for-kam/best-commercial-offer`,
        method: 'PATCH',
        body: { contractorCommercialOfferId },
      }),
      async onQueryStarted(
        { businessRequestId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;

          dispatch(
            businessRequestApi.util.invalidateTags([
              {
                type: 'getManagerContractorCommercialOffers',
                id: businessRequestId,
              },
              {
                type: 'getManagerRequest',
                id: businessRequestId,
              },
              {
                type: 'getManagerStatusHistory',
                id: businessRequestId,
              },
              { type: 'getManagerCustomerCommercialOffersForImprovement' },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),

    removeThereIsBestKpStatus: builder.mutation<
      void,
      IThereIsBestKpStatusParams
    >({
      query: ({ businessApplicationId }) => ({
        url: `business-requests/api/v1/business-application/${businessApplicationId}/status-for-kam/best-commercial-offer/remove`,
        method: 'PATCH',
      }),
      async onQueryStarted(
        { businessRequestId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;

          dispatch(
            businessRequestApi.util.invalidateTags([
              {
                type: 'getManagerContractorCommercialOffers',
                id: businessRequestId,
              },
              {
                type: 'getManagerRequest',
                id: businessRequestId,
              },
              {
                type: 'getManagerStatusHistory',
                id: businessRequestId,
              },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),

    updateStatusRequestCommercialQualification: builder.mutation<
      void,
      {
        businessApplicationId: number;
        businessRequestId: number;
        'Current-Role': ROLES | null;
      }
    >({
      query: ({ businessApplicationId, ...params }) => ({
        url: `business-requests/api/v1/business-application/${businessApplicationId}/commercial-qualification`,
        method: 'PATCH',
        params,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getManagerRequest', id: arg.businessRequestId },
      ],
    }),

    updateStatusRequestTechnicalQualification: builder.mutation<
      void,
      {
        businessApplicationId: number;
        businessRequestId: number;
        'Current-Role': ROLES | null;
      }
    >({
      query: ({ businessApplicationId, ...params }) => ({
        url: `business-requests/api/v1/business-application/${businessApplicationId}/technical-qualification`,
        method: 'PATCH',
        params,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getManagerRequest', id: arg.businessRequestId },
        {
          type: 'getManagerStatusHistory',
          id: arg.businessRequestId,
        },
      ],
    }),

    updateStatusRequestQualify: builder.mutation<
      void,
      {
        businessApplicationId: number;
        businessRequestId: number;
        'Current-Role': ROLES | null;
      }
    >({
      query: ({ businessApplicationId, ...params }) => ({
        url: `business-requests/api/v1/business-application/${businessApplicationId}/qualify`,
        method: 'PATCH',
        params,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'getManagerRequest', id: arg.businessRequestId },
      ],
    }),

    updateBusinessRequestDeliveryAddress: builder.mutation<
      void,
      {
        businessRequestId: number;
        'Current-Role': ROLES | null;
        deliveryAddress: string;
      }
    >({
      query: ({ businessRequestId, ...body }) => ({
        url: `business-requests/api/v1/business-request/${businessRequestId}/delivery-address`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              {
                type: 'getManagerContractorCommercialOffers',
                id: arg.businessRequestId,
              },
              {
                type: 'getRebiddingList',
                id: arg.businessRequestId,
              },
            ],
    }),

    getRoleSpecificBusinessRequest: builder.mutation<
      IManagerKanbanBusinessRequest,
      IManagerKanbanBusinessRequestParams
    >({
      query: (body) => ({
        url: 'business-requests/api/v1/role-specific/kanban/business-requests',
        method: 'POST',
        body,
      }),
      transformResponse: (data: IManagerKanbanBusinessRequest) =>
        bidsSortingColumn(data, BusinessRequestMode.SPECIFIC_ROLE),
    }),

    assignExpertToBusinessRequest: builder.mutation<
      { assignedRoleIds: number[] },
      IAssignExpertData & { shouldInvalidateBusinessRequest?: boolean }
    >({
      query: ({ requestId, ...body }) => ({
        url: `business-requests/api/v1/business-request/${requestId}/assignment/assign-role`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        const shouldInvalidateBusinessRequest =
          arg.shouldInvalidateBusinessRequest === undefined
            ? true
            : arg.shouldInvalidateBusinessRequest;

        const tagsToInvalidate: any = [];

        tagsToInvalidate.push({ type: 'getRequestLogistics' });

        if (arg.commercialOfferId) {
          tagsToInvalidate.push({ type: 'getManagerCustomerCommercialOffers' });
        }

        if (arg.customerSpecificationId) {
          tagsToInvalidate.push({ type: 'getManagerCustomerSpecifications' });
        }

        if (arg.contractorSpecificationId) {
          tagsToInvalidate.push({ type: 'getManagerContractorSpecifications' });
        }

        if (shouldInvalidateBusinessRequest) {
          tagsToInvalidate.push({
            type: 'getManagerRequest',
            id: arg.requestId,
          });
        }

        return tagsToInvalidate;
      },
    }),

    updateInvokedExpertRequestStatus: builder.mutation<
      void,
      {
        requestId: number;
        requestStatus?: InvokeExpertStage;
        approvalStatus?: InvokeExpertStatus;
        commercialOfferId?: number;
        customerSpecificationId?: number;
        contractorSpecificationId?: number;
        note?: string;
        assignedRoleId?: number;
        logisticsCalculations?: LogisticsCalculationDto[];
      }
    >({
      query: ({ requestId, ...body }) => ({
        url: `business-requests/api/v1/business-request/${requestId}/assignment/role-status/`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        if (arg.customerSpecificationId) {
          return [
            { type: 'getManagerRequest', id: arg.requestId },
            { type: 'getManagerCustomerSpecifications' },
          ];
        }

        if (arg.contractorSpecificationId) {
          return [
            { type: 'getManagerRequest', id: arg.requestId },
            { type: 'getManagerContractorSpecifications' },
          ];
        }

        return [
          { type: 'getManagerRequest', id: arg.requestId },
          { type: 'getManagerCustomerCommercialOffers' },
          { type: 'getRequestLogistics' },
        ];
      },
    }),

    getCompanyBusinessRequests: builder.mutation<
      TCompanyBusinessRequest[],
      TGetAllCompanyRequestsParams & {
        organizationId: number;
      }
    >({
      query: ({ organizationId, ...body }) => ({
        url: `business-requests/api/v1/business-application/history-applications/${organizationId}/search`,
        method: 'POST',
        body,
      }),
    }),

    getSaleDateHistory: builder.query<ISaleDateHistory, number>({
      query: (businessApplicationId) => ({
        url: `business-requests/api/v1/history-applications-sale-date/${businessApplicationId}`,
      }),
      providesTags: ['getSaleDateHistory'],
    }),

    getRequestExpertsListByRole: builder.query<TAssignedManager[], ROLES[]>({
      query: (roles) => ({
        url: `users-service/api/v1/user/with-roles`,
        method: 'GET',
        params: {
          userRoles: roles,
        },
      }),
    }),

    getApplicationContractors: builder.query<
      TApplicationContractorsInfo,
      number
    >({
      providesTags: ['getApplicationContractorContacts'],
      query: (applicationId) => ({
        url: `business-requests/api/v1/business-application-contact/${applicationId}/contractor`,
        method: 'GET',
      }),
      transformResponse: (data: TApplicationContractorsInfo) => ({
        organizations: data.organizations.map((organization) => ({
          ...organization,
          contacts: organization.contacts.map((contact) => ({
            ...contact,
            email: (contact as any).login,
            id: (contact as any).userId,
            contactInfoDto: {
              phones: (contact as any).phones,
            },
          })),
        })),
      }),
    }),
    getApplicationInnerRoles: builder.query<TApplicationInnerRolesInfo, number>(
      {
        query: (applicationId) => ({
          url: `business-requests/api/v1/business-application-contact/${applicationId}/internal`,
          method: 'GET',
        }),
        transformResponse: (data: TApplicationInnerRolesInfo) => ({
          contacts: data.contacts.map((contact) => ({
            ...contact,
            email: (contact as any).login,
            id: (contact as any).userId,
            contactInfoDto: {
              phones: (contact as any).phones,
            },
          })),
          groups: data.groups.map((group) => ({
            ...group,
            contacts: group.contacts.map((contact) => ({
              ...contact,
              email: (contact as any).login,
              id: (contact as any).userId,
              contactInfoDto: {
                phones: (contact as any).phones,
              },
            })),
          })),
        }),
      }
    ),
    moveRequestToExpected: builder.mutation<
      void,
      {
        businessRequestId: number;
        causeId: number;
        prospectAnswerDuedate: Date;
        comment: string;
      }
    >({
      invalidatesTags: ['getManagerRequest'],
      query: (body) => ({
        url: `business-requests/api/v1/business-request/prospect`,
        method: 'PUT',
        body,
      }),
    }),
    moveRequestToExpectedWithApproval: builder.mutation<
      void,
      {
        businessRequestId: number;
        causeId: number;
        prospectAnswerDuedate: Date;
        comment: string;
      }
    >({
      invalidatesTags: ['getManagerRequest'],
      query: (body) => ({
        url: `business-requests/api/v1/business-request/prospect/with-approval`,
        method: 'PUT',
        body,
      }),
    }),
    editRequestExpectedInfo: builder.mutation<
      void,
      {
        businessRequestId: number;
        causeId?: number;
        prospectAnswerDuedate: Date;
        comment?: string;
      }
    >({
      invalidatesTags: ['getManagerRequest'],
      query: (body) => ({
        url: `business-requests/api/v1/business-request/prospect`,
        method: 'PATCH',
        body,
      }),
    }),

    returnRequestFromExpectedStatus: builder.mutation<void, number>({
      invalidatesTags: ['getManagerRequest'],
      query: (businessRequestId) => ({
        url: `business-requests/api/v1/business-request/${businessRequestId}/prospect/return`,
        method: 'PATCH',
      }),
    }),

    handleExpectedRequestApproval: builder.mutation<
      void,
      {
        businessRequestProspectId: number;
        status: 'REFUSED' | 'APPROVED';
        refusalComment?: string;
      }
    >({
      invalidatesTags: ['getManagerRequest'],
      query: (body) => ({
        url: `business-requests/api/v1/business-request/prospect/handle-approval`,
        method: 'PATCH',
        body,
      }),
    }),

    moveRequestFromProspectToTechnicalQualification: builder.mutation<
      void,
      number
    >({
      invalidatesTags: ['getManagerRequest'],
      query: (businessRequestId) => ({
        url: `business-requests/api/v1/business-request/${businessRequestId}/prospect/technical-qualification`,
        method: 'PATCH',
      }),
    }),

    updateProspectRequestForContractors: builder.mutation<void, number>({
      invalidatesTags: ['getManagerRequest'],
      query: (businessRequestId) => ({
        url: `business-requests/api/v1/business-request/${businessRequestId}/prospect/back-to-work`,
        method: 'PATCH',
      }),
    }),

    getBusinessRequestFieldsChangelogWindows: builder.query<
      TRequestChangelogWindows,
      number
    >({
      query: (businessRequestId) => ({
        url: `business-requests/api/v1/business-request/windows-log`,
        method: 'GET',
        params: {
          businessRequestId,
        },
      }),
      transformResponse: (data: TRequestChangelogWindows) => ({
        ...data,
        windows: data.windows.map((window) => ({
          ...window,
          changeLines: window.changeLines.map((changeLine) => ({
            ...changeLine,
            change: formatRequestChangelogLine(changeLine.change),
          })),
        })),
      }),
    }),
  }),
});

export const {
  useGetCustomerInfoQuery,
  useGetCustomerRequestQuery,
  useGetContractorRequestQuery,
  useGetManagerRequestQuery,
  useUpdateNameRequestByIdMutation,
  useGetContractorAllRequestsMutation,
  useLazyGetSearchPerformersQuery,
  useSetContractorRequestMutation,
  useLazyGetListPerformersQuery,
  useGetListPerformersQuery,
  useLazyDeletePerformersQuery,
  useSetCustomerRejectApplicationMutation,
  useSetContractorRejectRequestMutation,
  useSetManagerRejectApplicationMutation,
  useGetManagerRejectApplicationReasonsQuery,
  useGetCustomerRejectApplicationReasonsQuery,
  useGetContractorRejectRequestReasonsQuery,
  useLazyGetManagerStatusHistoryQuery,
  useLazyGetContractorStatusHistoryQuery,
  useLazyGetCustomerStatusHistoryQuery,
  useRestoreBusinessApplicationMutation,
  useGetContractorBusinessRequestQuery,
  useTakeRequestToWorkMutation,
  useUpdateBusinessRequestMutation,
  useRejectRefusalRequestMutation,
  useApprovalRefusalRequestMutation,
  useDiscardRefusalRequestMutation,
  useConfirmRefusalRequestMutation,
  useUpdateBusinessRequestContactListMutation,
  useStartContractorRequestMutation,
  useCustomerCreateBidMutation,
  useCreateRequestByManagerMutation,
  useCloseRequestByManagerMutation,
  useSetAssignmentManagerMutation,
  useSetAssignmentSpecificMutation,
  useLazyCalculateCargoPriceQuery,
  useGetReadinessQuery,
  useRestoreContractorBusinessApplicationMutation,
  useLazyGetContractorRequestsInfoQuery,
  useLazyGetBusinessRequestAuctionDateStatisticsQuery,
  useGetManagerBusinessRequestMutation,
  useLazyGetManagerLastActivityStatisticsQuery,
  useGetCompanyBusinessRequestsMutation,
  useLazyGetManagerCostIndicatorStatisticsQuery,
  useGetContractorRequestsMutation,
  useLazyGetContractorCostIndicatorStatisticsQuery,
  useLazyGetContractorAuctionDateStatisticsQuery,
  useGetCustomerRequestsMutation,
  useLazyGetCustomerCostIndicatorStatisticsQuery,
  useGetAllBusinessRequestsByContractorMutation,
  useLazyGetApplicationListQuery,
  useUpdateBusinessRequestColorMutation,
  useRemoveBusinessApplicationMutation,
  useUpdateBusinessRequestInfoMutation,
  useGetRolesExcludedFromNotificationsQuery,
  useUpdateRulesExcludedFromNotificationsMutation,
  useUpdateThereIsNoBestKpStatusMutation,
  useSetThereIsBestKpStatusMutation,
  useRemoveThereIsBestKpStatusMutation,
  useUpdateStatusRequestCommercialQualificationMutation,
  useUpdateStatusRequestTechnicalQualificationMutation,
  useUpdateStatusRequestQualifyMutation,
  useUpdateBusinessRequestDeliveryAddressMutation,
  useLazyGetRequestNumbersWithIdsQuery,
  useGetRoleSpecificBusinessRequestMutation,
  useAssignExpertToBusinessRequestMutation,
  useUpdateInvokedExpertRequestStatusMutation,
  useGetSaleDateHistoryQuery,
  useLazyGetRequestExpertsListByRoleQuery,
  useGetRequestExpertsListByRoleQuery,
  useGetApplicationContractorsQuery,
  useGetApplicationInnerRolesQuery,
  useMoveRequestToExpectedMutation,
  useMoveRequestToExpectedWithApprovalMutation,
  useEditRequestExpectedInfoMutation,
  useReturnRequestFromExpectedStatusMutation,
  useHandleExpectedRequestApprovalMutation,
  useMoveRequestFromProspectToTechnicalQualificationMutation,
  useUpdateProspectRequestForContractorsMutation,
  useGetBusinessRequestFieldsChangelogWindowsQuery,
  useLazyGetApplicationListV2Query,
} = businessRequestApi;

export const useGetBusinessRequestStatusHistory = (
  mode: BusinessRequestMode
) => {
  if (
    mode === BusinessRequestMode.MANAGER ||
    mode === BusinessRequestMode.SPECIFIC_ROLE
  ) {
    return useLazyGetManagerStatusHistoryQuery;
  }

  if (mode === BusinessRequestMode.CUSTOMER) {
    return useLazyGetCustomerStatusHistoryQuery;
  }

  return useLazyGetContractorStatusHistoryQuery;
};

export const useSetManagerRejectApplicationQuery = (
  isCustomerBusinessApplicationRefusal: boolean
) => {
  if (isCustomerBusinessApplicationRefusal) {
    return useConfirmRefusalRequestMutation;
  }

  return useSetManagerRejectApplicationMutation;
};
