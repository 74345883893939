import cn from 'classnames';
import {
  Box,
  Form,
  IconButton,
  OutlinedInput,
  MenuItem,
  Select,
  Typography,
  NumberInput,
} from 'src/components/ui';
import { Check, CloseIcon } from 'src/assets/icons';
import { useForm } from 'react-hook-form';
import { maxNumber, minNumber, number, required } from 'src/utils/form-rules';
import {
  DAY_TYPES,
  PAYMENT_MOMENTS,
  PAYMENT_MOMENTS_BY_TYPE,
  PAYMENT_TYPES,
} from 'src/components/pages/business-request-detail/components/payment-conditions/payment-conditions.const';
import { PaymentType } from 'src/models';
import {
  IPaymentConditionFormData,
  IPaymentConditionProps,
} from './payment-condition.types';
import styles from './styles.module.scss';
import { buttonStyles } from './payment-condition.const';

export const EditPaymentCondition = (props: IPaymentConditionProps) => {
  const {
    id,
    handleSubmit,
    handleCancelEditPaymentCondition,
    paymentType,
    paymentPercentage,
    paymentInDays,
    dayType,
    paymentMoment,
    index = 0,
    maxPercentage = 100,
  } = props;

  const form = useForm<IPaymentConditionFormData>({
    defaultValues: {
      paymentType,
      paymentPercentage,
      paymentInDays,
      paymentMoment,
      dayType,
    },
  });

  // TODO: Править компонент??

  return (
    <Form form={form} footer={null} btnFollowScroll={false}>
      <Box
        display="flex"
        className={styles.row}
        gap="12px"
        alignItems="flex-start">
        <Box
          width="80px"
          className={cn(styles.item, styles.hasWidth)}
          display="flex"
          alignItems="center"
          height="60px">
          {index + 1} часть
        </Box>
        <Box className={styles.item}>
          <Form.Item
            rules={{
              required,
            }}
            name="paymentType"
            className={styles.formInput}>
            <Select
              placeholder="Выберите тип оплаты"
              displayEmpty
              renderValue={
                form.watch('paymentType')
                  ? undefined
                  : () => (
                      <Typography color="#7A8694">
                        Выберите тип оплаты
                      </Typography>
                    )
              }>
              {PAYMENT_TYPES.map((pt) => (
                <MenuItem key={pt.value} value={pt.value}>
                  {pt.label}
                </MenuItem>
              ))}
            </Select>
          </Form.Item>
        </Box>
        <Box width="80px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    maxPercentage,
                    `% не может быть выше ${maxPercentage}`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `% не может быть меньше 1`)(value),
              },
            }}
            name="paymentPercentage"
            className={styles.formInput}>
            <NumberInput placeholder="Введите % оплаты" />
          </Form.Item>
        </Box>
        <Box
          display="flex"
          gap="12px"
          width="340px"
          alignItems="flex-startf"
          className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            name="dayType"
            rules={{
              required,
            }}
            className={styles.formInput}>
            <Select
              placeholder="Выберите тип дня"
              displayEmpty
              renderValue={
                form.watch('dayType')
                  ? undefined
                  : () => (
                      <Typography color="#7A8694">Выберите тип дня</Typography>
                    )
              }>
              {DAY_TYPES.map((dt) => (
                <MenuItem key={dt.value} value={dt.value}>
                  {dt.label}
                </MenuItem>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(365, `Дней не может быть больше 365`)(value),
                minNumber: (value) =>
                  minNumber(1, `Дней не может быть меньше 1`)(value),
              },
            }}
            name="paymentInDays"
            className={styles.formInput}>
            <OutlinedInput placeholder="Введите количество дней" />
          </Form.Item>
        </Box>
        <Box width="240px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
            }}
            name="paymentMoment"
            className={styles.formInput}>
            <Select
              placeholder="Выберите момент оплаты"
              displayEmpty
              renderValue={
                form.watch('paymentMoment')
                  ? undefined
                  : () => (
                      <Typography color="#7A8694">
                        Выберите момент оплаты
                      </Typography>
                    )
              }>
              {(form.getValues(`paymentType`)
                ? PAYMENT_MOMENTS_BY_TYPE[
                    form.getValues(`paymentType`) as PaymentType
                  ]
                : PAYMENT_MOMENTS
              ).map((pm) => (
                <MenuItem key={pm.value} value={pm.value}>
                  {pm.label}
                </MenuItem>
              ))}
            </Select>
          </Form.Item>
        </Box>
        <Box
          display="flex"
          width="80px"
          className={cn(styles.item, styles.hasWidth)}
          justifyContent="flex-end"
          gap="8px">
          <IconButton
            color="primary"
            onClick={() => {
              handleSubmit(form, id);
            }}>
            <Box {...buttonStyles}>
              <Check width={20} height={20} />
            </Box>
          </IconButton>
          <IconButton
            color="secondary"
            onClick={handleCancelEditPaymentCondition}>
            <Box {...buttonStyles}>
              <CloseIcon width={12} height={12} />
            </Box>
          </IconButton>
        </Box>
      </Box>
    </Form>
  );
};
