import { useMemo } from 'react';
import classNames from 'classnames';
import { Pencil } from 'src/assets/icons';
import { IconButton, Typography, Stack } from 'src/components/ui';
import { TVariantBusinessRequest } from 'src/models';
import { isAdmin, isManager, isTechExpert, joinFullName } from 'src/utils';
import styles from './request-expert.module.scss';

export interface IRequestExpertProps {
  businessRequest: TVariantBusinessRequest;
  onEditButtonClick: () => void;
}

export const RequestExpert = ({
  businessRequest,
  onEditButtonClick,
}: IRequestExpertProps) => {
  const nameTechExpert = useMemo(
    () =>
      joinFullName(
        businessRequest.assignedManagers?.techExpert?.lastName,
        businessRequest.assignedManagers?.techExpert?.firstName,
        businessRequest.assignedManagers?.techExpert?.middleName
      ),
    [businessRequest]
  );

  const isShowEditTechExportForRequest =
    !businessRequest.orderProcessingStarted;
  const isUserCanChangeTechExpert = isTechExpert() || isAdmin() || isManager();

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="column" gap="4px">
        <Typography variant="body2" color="#7A8694">
          Техэксперт:
        </Typography>
        <Typography color="#292929">{nameTechExpert || '-'}</Typography>
      </Stack>
      <Stack direction="row" gap="4px">
        <IconButton
          className={classNames(
            styles.actionButton,
            (!isShowEditTechExportForRequest || !isUserCanChangeTechExpert) &&
              styles.actionButtonDisabled
          )}
          disabled={
            !isShowEditTechExportForRequest || !isUserCanChangeTechExpert
          }
          onClick={onEditButtonClick}>
          <Pencil
            sx={{
              color:
                !isShowEditTechExportForRequest || !isUserCanChangeTechExpert
                  ? '#7A8694'
                  : '#292929',
            }}
          />
        </IconButton>
      </Stack>
    </Stack>
  );
};
