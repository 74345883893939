import { TFilterTab } from 'src/components/ui/filters/types';
import { SvgIcon } from 'src/components/ui/svg-icon';
import { Chat, Letter, Telegram, Whatsapp, Phone } from 'src/assets/icons';

export const tabList: TFilterTab<string>[] = [
  {
    label: 'Все', // #{count}
    key: '/communications/all',
    active: false,
  },
  // {
  //   label: 'Чат', // #{count}
  //   key: '/communications/chat',
  //   active: false,
  //   icon: (
  //     <SvgIcon width="18px" height="18px" icon={Chat} strokeColor="#7A8694" />
  //   ),
  //   disabled: true,
  // },
  {
    label: 'Телефония', // #{count}
    key: '/communications/telephony',
    active: false,
    icon: (
      <SvgIcon width="18px" height="18px" icon={Phone} strokeColor="#7A8694" />
    ),
  },
  {
    label: 'Почта', // #{count}
    key: '/communications/email',
    active: true,
    icon: <SvgIcon width="18px" height="18px" icon={Letter} />,
  },
  // {
  //   label: 'WhatsApp', // #{count}
  //   key: 'WHATSAPP',
  //   active: false,
  //   icon: <SvgIcon width="18px" height="18px" icon={Whatsapp} />,
  // },
  // {
  //   label: 'Телеграм', // #{count}
  //   key: 'TELEGRAM',
  //   active: false,
  //   icon: (
  //     <SvgIcon
  //       width="18px"
  //       height="18px"
  //       icon={Telegram}
  //       strokeColor="#7A8694"
  //     />
  //   ),
  // },
];

export const rolesTabList = [
  {
    label: 'Все',
    value: 'ALL',
  },
  {
    label: 'Заказчики',
    value: 'CUSTOMER',
  },
  {
    label: 'Исполнители',
    value: 'CONTRACTOR',
  },
];

export const relationToRequestsTabList = [
  {
    label: 'Все',
    value: 'ALL',
  },
  {
    label: 'Связанные с заявками',
    value: 'RELATED',
  },
  {
    label: 'Не связанные с заявками',
    value: 'NOT_RELATED',
  },
];
