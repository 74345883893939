import { Card } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  useGetRolesExcludedFromNotificationsQuery,
  useUpdateRulesExcludedFromNotificationsMutation,
} from 'src/api/business-request/business-request';
import { ChatIconCompany } from 'src/assets/icons';
import { TModeChangeManager } from 'src/components/layout/bids-layout/main-bids-layout/components/header/components/assigned-managers';
import { ChangeManager } from 'src/components/layout/bids-layout/main-bids-layout/components/header/components/assigned-managers/components';
import { RequestBackOffice } from 'src/components/layout/bids-layout/main-bids-layout/components/status-history-block/components/request-back-office';
import { RequestExpert } from 'src/components/layout/bids-layout/main-bids-layout/components/status-history-block/components/request-expert';
import { RequestKam } from 'src/components/layout/bids-layout/main-bids-layout/components/status-history-block/components/request-kam';
import { RequestKamPlus } from 'src/components/layout/bids-layout/main-bids-layout/components/status-history-block/components/request-kam-plus';
import { RequestLogistician } from 'src/components/layout/bids-layout/main-bids-layout/components/status-history-block/components/request-logistician';
import styles from 'src/components/layout/bids-layout/main-bids-layout/components/status-history-block/status-history-block.module.scss';
import {
  FormControlLabel,
  IconButton,
  Stack,
  Switch,
  Typography,
} from 'src/components/ui';
import { PERMISSIONS } from 'src/constants';
import {
  CompanyTypes,
  IManagerBusinessRequest,
  TVariantBusinessRequest,
} from 'src/models';
import { RolePermissionService } from 'src/services';

export interface IStatusDescriptionSectionProps {
  businessRequest: TVariantBusinessRequest;
  isLoading: boolean;
}

export const StatusDescriptionSection = ({
  businessRequest,
  isLoading,
}: IStatusDescriptionSectionProps) => {
  const [modeChangeManager, setModeChangeManager] =
    useState<TModeChangeManager>('kam');
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setModeChangeManager('kam');
    setOpen(false);
  };

  const handleOpenModal = (type: TModeChangeManager) => {
    setModeChangeManager(type);
    setOpen(true);
  };

  const { data: rolesExcludedFromNotifications } =
    useGetRolesExcludedFromNotificationsQuery(
      {
        businessApplicationId: businessRequest?.businessApplicationId as number,
        businessRequestName: businessRequest?.name,
      },
      {
        skip: !businessRequest.businessApplicationId || !businessRequest.name,
      }
    );

  const [isNotificationsForCustomer, setIsNotificationsForCustomer] =
    useState(false);
  const [isNotificationsForContractor, setIsNotificationsForContractor] =
    useState(false);

  useEffect(() => {
    if (!rolesExcludedFromNotifications) {
      return;
    }

    setIsNotificationsForCustomer(
      !rolesExcludedFromNotifications?.excludedRolesFromNotifications.includes(
        CompanyTypes.CUSTOMER
      )
    );

    setIsNotificationsForContractor(
      !rolesExcludedFromNotifications?.excludedRolesFromNotifications.includes(
        CompanyTypes.CONTRACTOR
      )
    );
  }, [rolesExcludedFromNotifications]);

  const [updateRolesExcludedFromNotifications] =
    useUpdateRulesExcludedFromNotificationsMutation();

  const changeNotifications = (type: CompanyTypes) => {
    if (!businessRequest.businessApplicationId) {
      return;
    }

    const excluded = [] as CompanyTypes[];

    if (type === CompanyTypes.CUSTOMER) {
      if (!isNotificationsForContractor) {
        excluded.push(CompanyTypes.CONTRACTOR);
      }

      if (isNotificationsForCustomer) {
        excluded.push(CompanyTypes.CUSTOMER);
      }
    } else {
      if (!isNotificationsForCustomer) {
        excluded.push(CompanyTypes.CUSTOMER);
      }

      if (isNotificationsForContractor) {
        excluded.push(CompanyTypes.CONTRACTOR);
      }
    }

    const result = {
      businessApplicationId: businessRequest.businessApplicationId,
      excludedRolesFromNotifications: excluded,
    };

    updateRolesExcludedFromNotifications(result)
      .unwrap()
      .then(() => {
        if (type === CompanyTypes.CUSTOMER) {
          setIsNotificationsForCustomer((prev) => !prev);
        } else {
          setIsNotificationsForContractor((prev) => !prev);
        }
      })
      .catch(() => {});
  };

  const handleChangeNotificationForCustomer = () => {
    changeNotifications(CompanyTypes.CUSTOMER);
  };

  const handleChangeNotificationForContractor = () => {
    changeNotifications(CompanyTypes.CONTRACTOR);
  };

  return (
    <>
      {false && (
        <Card sx={{ marginTop: '12px', boxShadow: 'none' }}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ padding: '12px', backgroundColor: '#FFE6E6' }}>
            <Stack gap="4px">
              <Typography variant="body2" color="#7A8694">
                На проверке техэкспертом с 01.10.2023:
              </Typography>
              <Typography color="#292929">НЕ РЕАЛИЗОВАНО НА БЕКЕ</Typography>
            </Stack>
            <IconButton className={styles.actionButton}>
              <ChatIconCompany />
            </IconButton>
          </Stack>
        </Card>
      )}

      <Stack gap="12px" sx={{ marginTop: '12px', padding: '0 12px' }}>
        <RequestKam
          businessRequest={businessRequest}
          onEditButtonClick={() => handleOpenModal('kam')}
        />
        <RequestKamPlus
          businessRequest={businessRequest}
          onEditButtonClick={() => handleOpenModal('kam_plus')}
        />
        <RequestExpert
          businessRequest={businessRequest}
          onEditButtonClick={() => handleOpenModal('tech_expert')}
        />
        <RequestBackOffice
          businessRequest={businessRequest}
          onEditButtonClick={() => handleOpenModal('back_office')}
        />
        <RequestLogistician
          businessRequest={businessRequest}
          onEditButtonClick={() => handleOpenModal('logistic')}
        />
      </Stack>

      {RolePermissionService.can(
        PERMISSIONS.VIEW_AND_EDIT_APPLICATION_EMAIL_SENDING_TOGGLE
      ) && (
        <Stack
          sx={{
            marginTop: '4px',
            marginLeft: '-14px',
            padding: '0 12px',
          }}>
          <FormControlLabel
            disabled={isLoading}
            control={
              <Switch
                checked={isNotificationsForCustomer}
                onChange={handleChangeNotificationForCustomer}
                sx={{ transform: 'scale(0.7, 0.7)', cursor: 'pointer' }}
              />
            }
            label={
              <Typography sx={{ marginLeft: '-4px' }}>
                Рассылка для заказчика
              </Typography>
            }
          />
          <FormControlLabel
            disabled={isLoading}
            sx={{ marginTop: '-8px' }}
            control={
              <Switch
                checked={isNotificationsForContractor}
                onChange={handleChangeNotificationForContractor}
                sx={{ transform: 'scale(0.7, 0.7)' }}
              />
            }
            label={
              <Typography sx={{ marginLeft: '-4px' }}>
                Рассылка для исполнителя
              </Typography>
            }
          />
        </Stack>
      )}
      {open && (
        <ChangeManager
          open={open}
          businessRequest={businessRequest as IManagerBusinessRequest}
          mode={modeChangeManager}
          close={handleClose}
        />
      )}
    </>
  );
};
