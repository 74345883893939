import { useState } from 'react';
import { RedTrash } from 'src/assets/icons';
import { Button, ConfirmDialog } from 'src/components/ui';

export type TDeleteAll = {
  title?: string;
  subtitle?: string;
  removeHandlers?: (() => void)[];
};

export const DeleteAll = ({
  title = 'Удалить все файлы?',
  subtitle = 'Это действие невозможно будет отменить.',
  removeHandlers = [],
}: TDeleteAll) => {
  const [isShowDeleteAllModal, setIsShowDeleteAllModal] = useState(false);

  const toggleDeleteAllModal = () => {
    setIsShowDeleteAllModal((prevState) => !prevState);
  };

  const deleteItems = async () => {
    if (!removeHandlers.length) {
      toggleDeleteAllModal();
      return;
    }

    const promises = await removeHandlers.map(
      (item) =>
        new Promise((resolve, reject) => {
          try {
            item();
          } catch (err) {
            reject(err);
          }
        })
    );

    try {
      await Promise.all([...promises]);
    } catch {
      // ignore
    }

    toggleDeleteAllModal();
  };

  return (
    <>
      <Button
        color="error"
        aria-label="delete"
        title="Удалить все"
        variant="text"
        onClick={toggleDeleteAllModal}
        startIcon={<RedTrash />}>
        Удалить все
      </Button>

      <ConfirmDialog
        open={isShowDeleteAllModal}
        close={toggleDeleteAllModal}
        title={title}
        confirmText="Да, удалить"
        onConfirm={deleteItems}>
        {subtitle}
      </ConfirmDialog>
    </>
  );
};
