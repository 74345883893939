import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { useLazyGetFilteredOkeiListQuery } from 'src/api/business-request/positions';
import { PlusRounded } from 'src/assets/icons';
import { Box, Button, Form, Stack, Typography } from 'src/components/ui';
import { IPosition } from 'src/models';
import { Position } from './position';
import { TPositionsFormData } from './position/position.types';
import styles from './styles.module.scss';

interface IPositionsProps {
  isEditMode: boolean;
  businessRequestId: number;
  form?: UseFormReturn<TPositionsFormData>;
  setCostByContractor?: () => void;
  positions?: IPosition[];
  isCreatePositionMode?: boolean;
}

export const Positions = ({
  positions,
  isEditMode,
  businessRequestId,
  setCostByContractor,
  form,
  isCreatePositionMode = false,
}: IPositionsProps) => {
  const { control } = form || {};

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'positions',
  });

  const [getFilteredOkeiList] = useLazyGetFilteredOkeiListQuery();

  const handleGetOkeiOptionsList = async (measure: string = '') => {
    const okeiOptionsList = await getFilteredOkeiList({
      measure,
    }).unwrap();
    return okeiOptionsList;
  };

  const handleChange = () => {
    setCostByContractor?.();
  };

  const handleAddPosition = () => {
    append({
      name: '',
      quantity: null,
      okei: null,
      price: null,
      total: null,
      id: null,
      netTotal: null,
      netPrice: null,
      vat: null,
      revenuePerPosition: null,
    } as any);
  };

  const handleRemovePosition = (index: number) => {
    if (fields.length > 0 && index >= 0 && index < fields.length) {
      remove(index);
    }
  };

  if (!isCreatePositionMode && !isEditMode && !positions?.length) {
    return null;
  }

  return (
    <div>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3" mb="12px">
          Позиции
        </Typography>
        {isCreatePositionMode && (
          <Button
            variant="text"
            applyNewIconSizing
            startIcon={<PlusRounded />}
            onClick={handleAddPosition}>
            Добавить позицию
          </Button>
        )}
      </Stack>
      {fields.length > 0 && (
        <Box className={styles.content}>
          {form ? (
            <Form form={form} footer={null} btnFollowScroll={false}>
              {/* <Box */}
              {/*  display="flex" */}
              {/*  className={cn(styles.row, styles.thead)} */}
              {/*  alignItems="center" */}
              {/*  gap="12px"> */}
              {/*  <Box className={styles.item}>Наименование</Box> */}
              {/*  <Box width="200px" className={cn(styles.item, styles.hasWidth)}> */}
              {/*    Количество */}
              {/*  </Box> */}
              {/*  <Box width="200px" className={cn(styles.item, styles.hasWidth)}> */}
              {/*    Ед. изм. */}
              {/*  </Box> */}
              {/*  <Box width="90px" className={cn(styles.item, styles.hasWidth)} /> */}
              {/* </Box> */}

              {fields.map((field, index) => (
                <Position
                  key={field.id}
                  position={{
                    ...field,
                    quantity: field.quantity ?? 0,
                    price: field.price ?? 0,
                    entityRef: {
                      entityType: 'BUSINESS_REQUEST',
                      id: businessRequestId,
                    },
                    okeiId: 0,
                    okeiDto: undefined,
                  }}
                  businessRequestId={businessRequestId}
                  isEditMode={isEditMode}
                  isCreateMode={isCreatePositionMode}
                  handleChange={handleChange}
                  index={index}
                  form={form}
                  handleGetOkeiOptionsList={handleGetOkeiOptionsList}
                  handleRemovePosition={handleRemovePosition}
                  view="line"
                />
              ))}
            </Form>
          ) : (
            positions?.map((position, index) => (
              <Position
                key={position.id || index}
                position={position}
                businessRequestId={businessRequestId}
                isEditMode={isEditMode}
                handleChange={handleChange}
                index={index}
                form={form}
              />
            ))
          )}
        </Box>
      )}
    </div>
  );
};
