import { FC, useState } from 'react';
import { Box } from 'src/components/ui';
import {
  AttachFiles,
  CompanyContractStatus,
  OpenChat,
} from 'src/components/widgets';
import {
  ContractStatuses,
  ConversationEntityType,
  DocumentEntityTypes,
  DocumentTypes,
  ICargoDescriptions,
  IManagerContractorCommercialOffer,
  IPaymentCondition,
  IPosition,
} from 'src/models';
import { arrayRequired } from 'src/utils/form-rules';
import { IChangeLogs } from 'src/models/changelogs';
import { isKam } from 'src/utils';
import { UseFormReturn } from 'react-hook-form';
import { Finance, Deadlines, Logistics, Comments } from '../components';
import { TPositionsFormData } from '../components/positions/position/position.types';
import { Positions } from '../components/positions';
import { PaymentConditions } from '../components/payment-conditions';

interface IContractorCommercialOfferProps {
  isEditing: boolean;
  shippingIncluded: boolean;

  logistics: {
    deliveryAddress: string;
    shippingAddress: string;

    cargoDescription: ICargoDescriptions | null;

    costsLogistics: number;
    calculatedShippingCost: number;
  };
  isAuction: boolean;

  contractorDetails: {
    contractStatusCode: ContractStatuses;
    organizationId: number;
    contractorRequestId: number;
    commercialOfferId: number;
  };

  handleSubmitTransportCalculation: (value: ICargoDescriptions) => void;

  orderProcessingStarted: boolean;

  costByContractorHistory?: IChangeLogs;
  paymentConditionsHistory?: IChangeLogs;
  supplyDurationHistory?: IChangeLogs;
  dueDateAtHistory?: IChangeLogs;

  item?: IManagerContractorCommercialOffer;

  paymentConditions?: IPaymentCondition[];

  positions?: IPosition[];
  positionsForm?: UseFormReturn<TPositionsFormData>;
}

const mode = 'contractor';

export const ContractorCommercialOffer: FC<IContractorCommercialOfferProps> = ({
  isEditing,
  shippingIncluded,
  logistics,
  isAuction,
  contractorDetails,
  handleSubmitTransportCalculation,
  orderProcessingStarted,
  costByContractorHistory,
  paymentConditionsHistory,
  supplyDurationHistory,
  dueDateAtHistory,
  item,
  positions = [],
  paymentConditions = [],
  positionsForm,
}) => (
  <>
    {!(isKam() && item?.status !== 'BEST') && (
      <Finance
        isEditing={isEditing}
        shippingIncluded={shippingIncluded}
        isAuction={isAuction}
        mode={mode}
        costsLogisticsIsManually={
          Number(logistics?.costsLogistics) !==
          Number(logistics?.calculatedShippingCost)
        }
        costByContractorHistory={costByContractorHistory}
        readonlyCostByContractor
      />
    )}
    {shippingIncluded && (
      <Logistics
        isEditing={isEditing}
        mode={mode}
        handleSubmitTransportCalculation={handleSubmitTransportCalculation}
      />
    )}
    <Deadlines
      supplyDurationHistory={supplyDurationHistory}
      dueDateAtHistory={dueDateAtHistory}
      isEditing={isEditing}
      mode={mode}
    />
    {!(isKam() && item?.status !== 'BEST') && (
      <Box mb="24px">
        <CompanyContractStatus
          contractStatus={contractorDetails.contractStatusCode}
          organizationId={contractorDetails.organizationId}
        />
      </Box>
    )}
    {!orderProcessingStarted && !(isKam() && item?.status !== 'BEST') && (
      <OpenChat
        entityType={ConversationEntityType.CONTRACTOR_REQUEST}
        entityId={contractorDetails.contractorRequestId}
        mode="manager"
        sx={{ marginBottom: '24px' }}
      />
    )}
    {positions && (
      <Positions
        form={positionsForm}
        positions={positions}
        isEditMode={false}
        businessRequestId={contractorDetails.contractorRequestId}
      />
    )}
    {paymentConditions && (
      <PaymentConditions
        isEditMode={false}
        businessRequestId={contractorDetails.contractorRequestId}
        paymentConditions={paymentConditions}
        maxWidth="480px"
      />
    )}
    <Comments
      isEditing={isEditing}
      mode={mode}
      paymentConditionsHistory={paymentConditionsHistory}
      isPaymentConditionsEmpty={!paymentConditions?.length}
    />
    {!(isKam() && item?.status !== 'BEST') && (
      <Box mb="24px">
        <AttachFiles
          noMargin
          formItemName="documents"
          isEditMode={isEditing}
          rules={{ validate: arrayRequired }}
          documentOptions={{
            entityType: DocumentEntityTypes.CONTRACTOR_COMMERCIAL_OFFER,
            entityId: contractorDetails.commercialOfferId,
            documentType: DocumentTypes.COMMERCIAL_OFFER,
          }}
          fullScreen={false}
        />
      </Box>
    )}
  </>
);
