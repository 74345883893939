export type TParams = Partial<
  {
    bidId: string;
    bidNumber: string;
    bidTitle: string;
    kamEmail: string;
    kamFullName: string;
    kamPhone: string;
    companyName: string;
    companyId: string;
    kpCost: string;
    kpNumber: string;
    declineUserFullName: string;
    declineUser: string;
    companyRole: string;
    userFullName: string;
    startAt: string;
    commercialOfferDuedate: string;
    paymentDate: string;
    causeName: string;
    minutes: string;
    date: string;
    time: string;
    downloadLink: string;
    downloadLinkTitle: string;
    shippingStatus: string;
    cost: string;
    declineReason: string;
    shippingDate: string;
    customerCompanyId: string;
    customerCompanyName: string;
    contractorCompanyId: string;
    contractorCompanyName: string;
    roleUrl: string;
    contactFullName: string;
    attributes: string;

    initiatorFullName: string;
    businessApplicationNumber: string;
    businessRequestId: string;
    businessRequestName: string;

    cargoDescription: string;
    cargoParameters: string;
    deliveryAddress: string;
    organizationName: string;
    shipmentAddress: string;
    totalCost: string;
    bidNumberWithTitle: string;
    contractUrl: string;
    contractId: string;
    organizationId: string;
    organizationType: string;
    tenderDate: string;
    allRequestsLink: string;
    amocrmName: string;
    contractNumber: string;
  } & { [key in `file_${number}_${string}`]: string } & {
    [key in `file_deleted_${number}`]: string;
  }
>;

export type THref = {
  [key in keyof TParams]: string;
};

export type TLink = {
  link: string;
  label: string;
};

export enum ECompanyRoleType {
  'CONTRACTOR, CUSTOMER' = 'CONTRACTOR, CUSTOMER',
  'CONTRACTOR' = 'CONTRACTOR',
  'CUSTOMER' = 'CUSTOMER',
}
