import { useState } from 'react';
import { ChangePassword as ChangePasswordForm } from 'src/components/layout/page-layout/components/change-password';
import { MobileDrawer, Typography } from 'src/components/ui';
import { Stack } from '@mui/material';
import { UserProfile as UserProfileForm } from 'src/components/widgets/user-profile';
import { IUserState } from 'src/store/user/user-slice';
import { ROLES } from 'src/constants';
import styles from './actions-block.module.scss';
import { ROLE_TO_ITEMS_MAP, TMenuDrawerKeys } from './config';

interface IActionsBlockProps {
  user: IUserState;
  onClick?: () => void;
}

export const ActionsBlock = ({ user, onClick }: IActionsBlockProps) => {
  const items = ROLE_TO_ITEMS_MAP[user.roles[0] as ROLES];

  const [drawerKey, setDrawerKey] = useState<TMenuDrawerKeys | undefined>();

  return (
    <div className={styles.wrapper}>
      {items?.length &&
        items.map((item) => {
          const callback = item.callbackReturnFunction?.();

          return (
            <Stack
              key={item.title}
              direction="row"
              gap="12px"
              alignItems="center"
              sx={{ padding: '12px' }}
              onClick={() => {
                if (item.drawerKey) {
                  setDrawerKey(item.drawerKey);
                }

                if (callback) {
                  callback();
                } else if (!item.drawerKey && onClick) {
                  onClick();
                }
              }}>
              {item.icon}
              <Typography color={item.color}>{item.title}</Typography>
            </Stack>
          );
        })}
      <MobileDrawer
        title={drawerKey === 'PROFILE' ? 'Профиль' : 'Смена пароля'}
        open={!!drawerKey}
        close={() => setDrawerKey(undefined)}>
        <Stack
          flex={1}
          sx={{ background: '#fff', padding: '20px', borderRadius: '8px' }}>
          {drawerKey === 'PROFILE' ? (
            <UserProfileForm />
          ) : (
            <ChangePasswordForm />
          )}
        </Stack>
      </MobileDrawer>
    </div>
  );
};
