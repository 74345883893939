import { formatServerDate } from 'src/utils/date-format';

// Ищет все iso даты в строке и приводит к нужному формату
export const formatRequestChangelogLine = (value: string) => {
  const withMs = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{6}/g;
  const withoutMs = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/g;

  let newValue = value.replaceAll(withMs, (date) =>
    formatServerDate(new Date(date), 'dd.MM.yyyy HH:mm:ss:SSS')
  );

  newValue = newValue.replaceAll(withoutMs, (date) =>
    formatServerDate(new Date(date), 'dd.MM.yyyy HH:mm:ss')
  );

  return newValue;
};
