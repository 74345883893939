// TODO: Сделать однообразно, чтобы на каждый тип коммуникации не дублировать
import { TEvent } from 'src/models/admin';
import { TRequestChangelogItem } from 'src/models/bid';
import {
  IEmailMessage,
  IMessage,
  INote,
  IStatus,
  ITelephonyCall,
} from 'src/models/communications';
import {
  isCallItem,
  isEmailItem,
  isMessageItem,
  isNoteItem,
  isStatusItem,
} from './get-communication-type';

type TCommunications =
  | IEmailMessage
  | ITelephonyCall
  | INote
  | TEvent[]
  | IMessage
  | IStatus
  | TRequestChangelogItem;

export const checkIfLastEmail = (
  message: IEmailMessage,
  nextCommunicationItem: TCommunications
) => {
  if (!nextCommunicationItem || !isEmailItem(nextCommunicationItem)) {
    return true;
  }

  return message.from.id !== nextCommunicationItem?.from?.id;
};

export const checkIfLastCall = (
  call: ITelephonyCall,
  nextCommunicationItem: TCommunications
) => {
  if (!nextCommunicationItem || !isCallItem(nextCommunicationItem)) {
    return true;
  }

  return call.from !== nextCommunicationItem?.from;
};

export const checkIfLastMessage = (
  message: IMessage,
  nextCommunicationItem: TCommunications
) => {
  if (!nextCommunicationItem || !isMessageItem(nextCommunicationItem)) {
    return true;
  }

  return message.owner.id !== nextCommunicationItem?.owner?.id;
};

export const checkIfLastNote = (
  note: INote,
  nextCommunicationItem: TCommunications
) => {
  if (!nextCommunicationItem || !isNoteItem(nextCommunicationItem)) {
    return true;
  }

  return note.owner.id !== nextCommunicationItem?.owner?.id;
};

export const checkIfLastStatus = (
  note: IStatus,
  nextCommunicationItem: TCommunications
) => {
  if (!nextCommunicationItem || !isStatusItem(nextCommunicationItem)) {
    return true;
  }

  return note.owner.id !== nextCommunicationItem?.owner?.id;
};
