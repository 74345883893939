import { TFile } from 'src/components/ui/file-upload/file-upload';
import { getErrorMessage } from 'src/store/utils';

type TSaveFiles = {
  files: TFile[];
  uploadHandler: (file: FormData) => Promise<{ id: number }>;
  removeHandler?: (id: number) => Promise<void>;
};

export const saveFiles = async ({
  files,
  uploadHandler,
  removeHandler,
}: TSaveFiles) => {
  let saveError = false;
  const promiseAddList: Promise<{ id: number } | string>[] = [];
  const promiseDeleteList: Promise<{ id: number } | string>[] = [];

  files.forEach((file) => {
    if (file.isNew) {
      promiseDeleteList.push(Promise.resolve(''));
    } else if (file.deleted && removeHandler) {
      promiseDeleteList.push(
        removeHandler(file.id)
          .then(() => ({ id: file.id }))
          .catch((e) => {
            saveError = true;
            return getErrorMessage(e);
          })
      );
    }
  });

  const deleteResult = await Promise.all(promiseDeleteList);

  files.forEach((file) => {
    if (file.isNew) {
      const formData = new FormData();
      formData.append('file', file.file);

      formData.append('cleaned', file.clenead ? 'true' : 'false');

      promiseAddList.push(
        uploadHandler(formData).catch((e) => {
          saveError = true;
          return getErrorMessage(e);
        })
      );
    } else if (file.deleted && removeHandler) {
      promiseAddList.push(Promise.resolve(''));
    }
  });

  const addResult = await Promise.all(promiseAddList);

  const saveResult = files.reduce((acc: TFile[], item, i) => {
    if (item.isNew || item.deleted) {
      const resultData = item.isNew ? addResult[i] : deleteResult[i];

      if (typeof resultData === 'object') {
        if (item.isNew) {
          const { isNew, file, ...resultFile } = item;
          acc.push({
            ...resultFile,
            id: resultData.id,
            deleted: false,
          });
        }
      } else {
        acc.push({
          ...item,
          error: resultData,
        });
      }
    } else {
      acc.push(item);
    }

    return acc;
  }, []);

  return { saveResult, error: saveError };
};
