import { IPaymentConditionProps } from './payment-condition.types';
import { EditPaymentCondition } from './edit';
import { PaymentConditionDefault } from './default';
import { PaymentConditionView } from './view';
import { AddPaymentCondition } from './add';

export const PaymentCondition = (props: IPaymentConditionProps) => {
  const { isEditPaymentCondition, isEditModeEnabled, mode = 'view' } = props;
  const renderContent = () => {
    if (mode === 'view') {
      return <PaymentConditionView {...props} />;
    }
    return <PaymentConditionDefault {...props} />;
  };

  const renderEditMode = () => {
    if (mode === 'create') {
      return <AddPaymentCondition {...props} isEditPaymentCondition />;
    }
    return <EditPaymentCondition {...props} isEditPaymentCondition />;
  };

  return isEditPaymentCondition && isEditModeEnabled
    ? renderEditMode()
    : renderContent();
};
