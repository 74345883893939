import { fillNotificationTemplate, notificationsSocket } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
  setNotificationsHistory,
  setSocketConnectionStatus,
  addNewNotification,
} from 'src/store/notifications';
import { AuthService } from 'src/services';
import { useEffect } from 'react';
import { TNotification, TNotificationsHistory } from 'src/models/notifications';
import { sendToastNotification } from '../utils';

export const useNotificationEventListeners = () => {
  const dispatch = useAppDispatch();
  const { userId, accessToken } = AuthService;

  const { socketConnectedStatus } = useAppSelector(
    (state) => state.notifications
  );

  const handleConnect = () => {
    dispatch(setSocketConnectionStatus(true));
  };

  const handleDisconnect = () => {
    dispatch(setSocketConnectionStatus(false));
  };

  useEffect(() => {
    if (accessToken) {
      notificationsSocket.io.opts.query.token = `Bearer ${accessToken}`;
      if (!notificationsSocket.connected) notificationsSocket.connect();
      notificationsSocket.on('connect', handleConnect);
      notificationsSocket.on('disconnect', handleDisconnect);
      notificationsSocket.emit('AUTHENTICATE', { userId });
      notificationsSocket.on('AUTHENTICATE', (data: TNotificationsHistory) => {
        dispatch(setNotificationsHistory(data));
      });
      dispatch(setSocketConnectionStatus(notificationsSocket.connected));
    }
    return () => {
      notificationsSocket.off('connect');
      notificationsSocket.off('disconnect');
    };
  }, [socketConnectedStatus, dispatch, accessToken]);

  useEffect(() => {
    notificationsSocket.on('NEW_NOTIFICATION', (data: TNotification) => {
      dispatch(addNewNotification(data));
      sendToastNotification(fillNotificationTemplate(data));
    });
    notificationsSocket.on(
      'GET_NOTIFICATIONS_HISTORY',
      (data: TNotificationsHistory) => {
        dispatch(setNotificationsHistory(data));
      }
    );
    return () => {
      notificationsSocket.off('NEW_NOTIFICATION');
      notificationsSocket.off('GET_NOTIFICATIONS_HISTORY');
    };
  }, [dispatch, socketConnectedStatus]);
};
