import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { compareAsc } from 'date-fns';
import { businessRequestApi } from 'src/api/business-request/business-request';
import { CompanyTypes, TContactRequest } from 'src/models';
import { TUpdateContact } from 'src/models/contacts';
import { AuthService } from 'src/services';
import { baseQueryWithReauth } from 'src/store/utils';
import {
  TContact,
  TContactWithSigningBasis,
  TTemporaryContact,
} from '../components/pages/company/contacts/types';

export type TOptionsUpdate = {
  contactId: number;
  shouldInvalidate?: boolean;
  shouldInvalidateAll?: boolean;
  body: TUpdateContact;
};

export type TUpdateContactWithSigningBasis = TUpdateContact & {
  hasActualSigningRight?: boolean;
  currentSigningBasisId?: number | null;
};

export type TOptionsUpdateWithSigningBasis = {
  contactId: number;
  shouldInvalidate?: boolean;
  shouldInvalidateAll?: boolean;
  body: TUpdateContactWithSigningBasis;
};

export const contactsApi = createApi({
  reducerPath: 'contactsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Contacts'],
  endpoints: (builder) => ({
    getContacts: builder.query<
      Array<TContact>,
      { companyType: CompanyTypes; idCompany: number }
    >({
      query: ({ companyType, idCompany }) =>
        `/organizations/api/v1/organization/${idCompany}/${companyType.toLowerCase()}/contact`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Contacts' as const, id })),
              'Contacts',
            ]
          : ['Contacts'],
      transformResponse: (response: Array<TContact>) =>
        response
          .filter((item) => item.contactStatus !== 'DELETED')
          .map((item) => ({
            ...item,
            isMainContact: item.contactType.includes('MAIN'),
            isSendEmail: item.notificationChannels.includes('EMAIL'),
          }))
          .sort((a, b) => {
            if (a.userId === AuthService.userId) return -1;

            if (
              a.userId !== AuthService.userId &&
              b.userId !== AuthService.userId
            ) {
              return a.isMainContact
                ? -1
                : compareAsc(new Date(a.createdAt), new Date(b.createdAt));
            }

            return 0;
          }),
    }),
    getContactsWithSigningBasis: builder.query<
      Array<TContactWithSigningBasis>,
      { companyType: CompanyTypes; idCompany: number }
    >({
      query: ({ companyType, idCompany }) =>
        `/organizations/api/v1/organization/${idCompany}/${companyType.toLowerCase()}/contacts/signing-basis`,
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Contacts' as const, id })),
              'Contacts',
            ]
          : ['Contacts'],
      transformResponse: (response: Array<TContactWithSigningBasis>) =>
        response
          .filter((item) => item.contactStatus !== 'DELETED')
          .map((item) => ({
            ...item,
            isMainContact: item.contactType.includes('MAIN'),
            isSendEmail: item.notificationChannels.includes('EMAIL'),
          }))
          .sort((a, b) => {
            if (a.userId === AuthService.userId) return -1;

            if (
              a.userId !== AuthService.userId &&
              b.userId !== AuthService.userId
            ) {
              return a.isMainContact
                ? -1
                : compareAsc(new Date(a.createdAt), new Date(b.createdAt));
            }

            return 0;
          }),
    }),
    addContact: builder.mutation<
      { userId: number; contactId: number },
      { skipInvalidateListTag?: boolean } & TContactRequest
    >({
      query: ({ skipInvalidateListTag, ...body }) => ({
        url: '/mph-security-provider/api/v1/registration/contragent',
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, { skipInvalidateListTag }) =>
        skipInvalidateListTag ? [] : [{ type: 'Contacts' }],
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;

          dispatch(
            businessRequestApi.util.invalidateTags([
              {
                type: 'getApplicationContractorContacts',
              },
            ])
          );
        } catch {
          // ignore
        }
      },
    }),
    updateContact: builder.mutation<{ id: number }, TOptionsUpdate>({
      query: ({ contactId, body, shouldInvalidate = true }) => ({
        url: `/organizations/api/v1/organization/contact/${contactId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        if (arg.shouldInvalidateAll) {
          return ['Contacts'];
        }

        return arg.shouldInvalidate
          ? [{ type: 'Contacts', id: arg.contactId }]
          : [];
      },
    }),
    updateContactWithSigningBasis: builder.mutation<
      { id: number },
      TOptionsUpdateWithSigningBasis
    >({
      query: ({ contactId, body, shouldInvalidate = true }) => ({
        url: `/organizations/api/v1/organization/contact/${contactId}/signing-basis`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result, error, arg) => {
        if (arg.shouldInvalidateAll) {
          return ['Contacts'];
        }

        return arg.shouldInvalidate
          ? [{ type: 'Contacts', id: arg.contactId }]
          : [];
      },
    }),
    removeContact: builder.mutation<void, { userId: number }>({
      query: (params) => ({
        url: '/organizations/api/manager/v1/contact/delete',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: ['Contacts'],
    }),

    lockContact: builder.mutation<void, { userId: number }>({
      query: (params) => ({
        url: '/organizations/api/manager/v1/contact/lock',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: ['Contacts'],
    }),
    unlockContact: builder.mutation<void, { userId: number }>({
      query: (params) => ({
        url: '/organizations/api/manager/v1/contact/unlock',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: ['Contacts'],
    }),

    rebaseContactToAnotherCompany: builder.mutation<
      void,
      {
        sourceOrganizationId: number;
        contactId: number;
        targetOrganizationId: number;
        newMainContactId?: number;
      }
    >({
      query: (body) => ({
        url: '/organizations/api/v1/organization/contact/rebase',
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Contacts'],
    }),
    trustContact: builder.mutation<void, { contactId: number }>({
      query: (params) => ({
        url: '/organizations/api/manager/v1/contact/trust',
        method: 'PATCH',
        params,
      }),
      invalidatesTags: ['Contacts'],
    }),

    getTemporaryContacts: builder.query<
      TTemporaryContact[],
      { organizationId: number }
    >({
      providesTags: ['Contacts'],
      query: (params) => ({
        url: '/organizations/api/v1/contact-temporary',
        method: 'GET',
        params,
      }),
      transformResponse: (data: TTemporaryContact[]) =>
        data.map((temporaryContact) => ({
          ...temporaryContact,
          isTemporary: true,
        })),
    }),

    registerTemporaryContact: builder.mutation<
      void,
      { temporaryContactId: number; organizationId: number }
    >({
      invalidatesTags: ['Contacts'],
      query: ({ temporaryContactId, ...params }) => ({
        url: `/organizations/api/v1/contact-temporary/${temporaryContactId}`,
        method: 'POST',
        params,
      }),
    }),

    deleteTemporaryContact: builder.mutation<void, number>({
      invalidatesTags: ['Contacts'],
      query: (temporaryContactId) => ({
        url: `/organizations/api/v1/contact-temporary/${temporaryContactId}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetContactsQuery,
  useGetContactsWithSigningBasisQuery,
  useLazyGetContactsQuery,
  useUpdateContactMutation,
  useUpdateContactWithSigningBasisMutation,
  useAddContactMutation,
  useLockContactMutation,
  useUnlockContactMutation,
  useRemoveContactMutation,
  useRebaseContactToAnotherCompanyMutation,
  useTrustContactMutation,
  useGetTemporaryContactsQuery,
  useRegisterTemporaryContactMutation,
  useDeleteTemporaryContactMutation,
} = contactsApi;
