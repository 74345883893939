import { createApi } from '@reduxjs/toolkit/dist/query/react';
import {
  ERefusalReasonType,
  TCommonReasonRequest,
  TKamReasonRequest,
} from 'src/components/pages/admin/directory/refusal-reason/types';
import { DIRECTION } from 'src/components/ui/filters/types';
import { ERefusalStatuses } from 'src/configs/admin/refusal-reason-status';
import {
  TCommonReason,
  TReasonKam,
  TReasonList,
} from 'src/models/admin/refusal-reason';
import { baseQueryWithReauth } from 'src/store/utils';

export type TSortKey = 'STATUS';

export type TRefusalListParams = {
  sort: {
    field: TSortKey;
    order: DIRECTION;
  };
  paging: {
    page: number;
    limit: number;
  };
  searchQuery: string[];
};

export type TUpdateRefusalStatusParams = {
  status: ERefusalStatuses;
  refusalCauseIds?: Array<number>;
  prospectCauseIds?: Array<number>;
};

const SERVICE_URL = {
  [ERefusalReasonType.KAM]: 'business-requests',
  [ERefusalReasonType.CUSTOMER]: 'business-requests',
  [ERefusalReasonType.CONTRACTOR]: 'business-requests',
  [ERefusalReasonType.MANAGER]: 'business-requests',
  [ERefusalReasonType.CUSTOMER_CO]: 'business-requests',
  [ERefusalReasonType.CONTRACTOR_REFUSAL]: 'organizations',
  [ERefusalReasonType.REQUEST_PROSPECT]: 'business-requests',
};

const GET_LIST_URL = {
  [ERefusalReasonType.KAM]: 'business-application-refusal-cause-by-kam',
  [ERefusalReasonType.CUSTOMER]:
    'business-application-refusal-request-cause-by-customer',
  [ERefusalReasonType.CONTRACTOR]:
    'contractor-request-refusal-cause-by-contractor',
  [ERefusalReasonType.MANAGER]:
    'contractor-commercial-offer-refusal-cause-by-manager',
  [ERefusalReasonType.CUSTOMER_CO]:
    'commercial-offer-for-customer-refusal-cause-by-customer',
  [ERefusalReasonType.CONTRACTOR_REFUSAL]:
    'contractor-signing-refusal-cause-by-kam-plus',
  [ERefusalReasonType.REQUEST_PROSPECT]:
    'business-request-prospect-cause-by-kam',
};

const CREATE_URL = {
  [ERefusalReasonType.KAM]: 'business-application-refusal-cause-by-kam',
  [ERefusalReasonType.CUSTOMER]:
    'business-application-refusal-request-cause-by-customer',
  [ERefusalReasonType.CONTRACTOR]:
    'contractor-request-refusal-cause-by-contractor',
  [ERefusalReasonType.MANAGER]:
    'contractor-commercial-offer-refusal-cause-by-manager',
  [ERefusalReasonType.CUSTOMER_CO]:
    'commercial-offer-for-customer-refusal-cause-by-customer',
  [ERefusalReasonType.CONTRACTOR_REFUSAL]:
    'contractor-signing-refusal-cause-by-kam-plus',
  [ERefusalReasonType.REQUEST_PROSPECT]:
    'business-request-prospect-cause-by-kam',
};

const UPDATE_URL = {
  [ERefusalReasonType.KAM]: 'business-application-refusal-cause-by-kam',
  [ERefusalReasonType.CUSTOMER]:
    'business-application-refusal-request-cause-by-customer',
  [ERefusalReasonType.CONTRACTOR]:
    'contractor-request-refusal-cause-by-contractor',
  [ERefusalReasonType.MANAGER]:
    'contractor-commercial-offer-refusal-cause-by-manager',
  [ERefusalReasonType.CUSTOMER_CO]:
    'commercial-offer-for-customer-refusal-cause-by-customer',
  [ERefusalReasonType.CONTRACTOR_REFUSAL]:
    'contractor-signing-refusal-cause-by-kam-plus',
  [ERefusalReasonType.REQUEST_PROSPECT]:
    'business-request-prospect-cause-by-kam',
};

const CHANGE_STATUS_URLS = {
  [ERefusalReasonType.KAM]: 'business-application-refusal-cause-by-kam',
  [ERefusalReasonType.CUSTOMER]:
    'business-application-refusal-request-cause-by-customer',
  [ERefusalReasonType.CONTRACTOR]:
    'contractor-request-refusal-cause-by-contractor',
  [ERefusalReasonType.MANAGER]:
    'contractor-commercial-offer-refusal-cause-by-manager',
  [ERefusalReasonType.CUSTOMER_CO]:
    'commercial-offer-for-customer-refusal-cause-by-customer',
  [ERefusalReasonType.CONTRACTOR_REFUSAL]:
    'contractor-signing-refusal-cause-by-kam-plus',
  [ERefusalReasonType.REQUEST_PROSPECT]:
    'business-request-prospect-cause-by-kam',
};

export const adminRefusalApi = createApi({
  reducerPath: 'adminRefusalApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getListAll: builder.mutation<
      TReasonList,
      {
        body: TRefusalListParams;
        type: ERefusalReasonType;
      }
    >({
      query: ({ body, type }) => ({
        url: `/${SERVICE_URL[type]}/api/v1/admin/${GET_LIST_URL[type]}/search`,
        method: 'POST',
        body,
      }),
    }),
    addReason: builder.mutation<
      TCommonReason | TReasonKam,
      {
        type: ERefusalReasonType;
        body: TCommonReasonRequest | TKamReasonRequest;
      }
    >({
      query: ({ type, body }) => ({
        url: `/${SERVICE_URL[type]}/api/v1/admin/${CREATE_URL[type]}`,
        method: 'POST',
        body,
      }),
    }),
    updateStatus: builder.mutation<
      void,
      { body: TUpdateRefusalStatusParams; type: ERefusalReasonType }
    >({
      query: ({ body, type }) => ({
        url: `/${SERVICE_URL[type]}/api/v1/admin/${CHANGE_STATUS_URLS[type]}/status`,
        method: 'PATCH',
        body,
      }),
    }),
    updateReason: builder.mutation<
      TCommonReason | TReasonKam,
      {
        type: ERefusalReasonType;
        id: number;
        body: TCommonReasonRequest | TKamReasonRequest;
      }
    >({
      query: ({ type, id, body }) => ({
        url: `/${SERVICE_URL[type]}/api/v1/admin/${UPDATE_URL[type]}/${id}`,
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useGetListAllMutation,
  useAddReasonMutation,
  useUpdateReasonMutation,
  useUpdateStatusMutation,
} = adminRefusalApi;
