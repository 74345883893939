import { SxProps, Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { TSearchFilters } from 'src/components/ui/table/types';
import { NewTableDesktop, NewTableMobile } from './components';
import { ColumnsType } from './types';

interface NewTableProps<RecordType> {
  loading?: boolean;
  className?: string;
  columns: ColumnsType<RecordType>;
  data?: RecordType[];
  emptyText?: ReactNode;
  sx?: SxProps<Theme>;
  onSelected?: (select: RecordType[]) => void;
  selectedItems?: RecordType[];
  page: number;
  totalCountItems?: number;
  countPerPage: number;
  onPageSelected?: (page: number) => void;
  onFilter?: (filters: TSearchFilters<RecordType>) => void;
  onRowClick?: (row: RecordType) => void;
  ignoreAdaptive?: boolean;

  // "Раскрытие" дополнительного содержимого строки
  renderSubRow?: () => ReactNode;
  subRowId?: number;
}

export const NewTable = <RecordType extends { key: number | string }>({
  columns,
  data,
  emptyText,
  className,
  loading,
  sx,
  onSelected,
  selectedItems,
  page,
  totalCountItems,
  countPerPage,
  onPageSelected,
  onFilter,
  onRowClick,
  ignoreAdaptive,
  renderSubRow,
  subRowId,
}: NewTableProps<RecordType>) => {
  const [selected, setSelected] = useState<RecordType[]>(selectedItems ?? []);

  useEffect(() => {
    if (selectedItems) {
      setSelected(selectedItems);
    }
  }, [selectedItems]);

  const handleSelected = useCallback(
    (select: RecordType[]) => {
      if (selectedItems === undefined) {
        setSelected(select);
      }

      onSelected?.(select);
    },
    [onSelected]
  );

  const handleSelectAllClick = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!data) {
        return;
      }

      if (event.target.checked) {
        handleSelected(data);
        return;
      }

      handleSelected([]);
    },
    [data, handleSelected]
  );

  const handleFilter = useCallback(
    (filters: TSearchFilters<RecordType>) => {
      onFilter?.(filters);
      setSelected([]);
    },
    [onFilter]
  );

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('lg')
  );

  // TODO: Денис - сделать нормально
  return isMobile && !ignoreAdaptive ? (
    <NewTableMobile data={data} columns={columns} page={page} />
  ) : (
    <NewTableDesktop
      loading={loading}
      className={className}
      columns={columns}
      data={data}
      sx={sx}
      onSelected={onSelected && handleSelected}
      onSelectedAllClick={onSelected && handleSelectAllClick}
      selected={selected}
      page={page}
      totalCountItems={totalCountItems}
      countPerPage={countPerPage}
      onPageSelected={onPageSelected}
      emptyText={emptyText}
      onFilter={handleFilter}
      onRowClick={onRowClick}
      renderSubRow={renderSubRow}
      subRowId={subRowId}
    />
  );
};
