import { lazy, Suspense } from 'react';

import { PageLoader } from 'src/components/ui';

const AdminSystemMail = lazy(
  () =>
    import(
      /* webpackChunkName: 'AdminSystemMail' */
      /* webpackMode: "lazy" */
      `./system-mail`
    )
);

export const LazySystemMail = () => (
  <Suspense fallback={<PageLoader />}>
    <AdminSystemMail />
  </Suspense>
);
