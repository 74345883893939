import cn from 'classnames';
import { TrashDeleteTableItemIcon } from 'src/assets/icons';
import {
  Autocomplete,
  Box,
  Button,
  Form,
  NumberInput,
  OutlinedInput,
  OverflowTooltip,
  Typography,
} from 'src/components/ui';
import { IOkei } from 'src/models';
import { maxNumber, minNumber, required } from 'src/utils/form-rules';
import { IPositionProps } from './position.types';
import styles from './styles.module.scss';

export const PositionEdit = ({
  index = 0,
  position,
  handleChange,
  form,
  isEditMode,
  isCreateMode,
  handleGetOkeiOptionsList,
  handleRemovePosition,
}: IPositionProps) => {
  const handleChangePrice = () => {
    const price = Number(form!.getValues(`positions.${index}.price`)) || 0;
    const quantity =
      Number(form!.getValues(`positions.${index}.quantity`)) || 0;

    form!.setValue(
      `positions.${index}.total`,
      parseFloat((price * quantity).toFixed(2))
    );
    if (handleChange) {
      handleChange();
    }

    return null;
  };

  const handleChangeTotal = () => {
    const total = Number(form!.getValues(`positions.${index}.total`) || 0);
    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );

    form!.setValue(
      `positions.${index}.price`,
      parseFloat((total / quantity).toFixed(2))
    );

    if (handleChange) {
      handleChange();
    }

    return null;
  };

  const handleChangeQuantity = () => {
    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );
    const price = Number(form!.getValues(`positions.${index}.price`)) || 0;

    form!.setValue(
      `positions.${index}.total`,
      parseFloat((quantity * price).toFixed(2))
    );

    if (handleChange) {
      handleChange();
    }

    return null;
  };

  return (
    <Box className={styles.row}>
      <Box display="flex" className={styles.row} alignItems="center" gap="12px">
        <Box className={styles.item}>
          <Form.Item
            rules={{
              required,
            }}
            name={`positions.${index}.name`}
            className={styles.formInput}
            renderView={(value) => (
              <Box alignSelf="center">
                <Box>
                  <Typography fontSize="12px" color="#7A8694">
                    Наименование позиции
                  </Typography>
                </Box>
                <OverflowTooltip text={value} maxWidth="200px" />
              </Box>
            )}
            label="Наименование позиции"
            viewMode={!isCreateMode}>
            <OutlinedInput placeholder="Введите наименование" />
          </Form.Item>
        </Box>
        <Box width="170px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
            }}
            name={`positions.${index}.quantity`}
            className={styles.formInput}
            renderView={(value) => (
              <Box alignSelf="center">
                <Box>
                  <Typography fontSize="12px" color="#7A8694">
                    Кол-во
                  </Typography>
                </Box>
                {value}
              </Box>
            )}
            label="Кол-во"
            viewMode={!isCreateMode}>
            <NumberInput
              mode="noDecimal"
              placeholder="Введите колличество"
              onBlur={handleChangeQuantity}
            />
          </Form.Item>
        </Box>
        <Box width="170px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
            }}
            name={`positions.${index}.okei`}
            viewMode={!isCreateMode}
            renderView={(value) => (
              <Box alignSelf="center">
                <Box>
                  <Typography fontSize="12px" color="#7A8694">
                    Ед. изм.
                  </Typography>
                </Box>
                {position.okei?.measure}
              </Box>
            )}
            label="Ед. изм"
            className={styles.formInput}>
            <Autocomplete<IOkei>
              key="okei"
              fullWidth
              popupIcon
              options={[]}
              fetchApi={async (value) =>
                handleGetOkeiOptionsList ? handleGetOkeiOptionsList(value) : []
              }
              getOptionLabel={(option) => option.measure || ''}
              placeholder="Выберите Ед. изм."
              noOptionsText="Начните вводить название"
            />
          </Form.Item>
        </Box>
        <Box width="170px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            name={`positions.${index}.price`}
            className={styles.formInput}
            label="Цена за ед.">
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={handleChangePrice}
            />
          </Form.Item>
        </Box>
        <Box width="170px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            name={`positions.${index}.total`}
            className={styles.formInput}
            label="Общая стоимость">
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={handleChangeTotal}
            />
          </Form.Item>
        </Box>
      </Box>
      {isCreateMode && !!handleRemovePosition && (
        <Button
          variant="text"
          applyNewIconSizing
          startIcon={<TrashDeleteTableItemIcon width={18} height={18} />}
          onClick={() => {
            handleRemovePosition(index);
          }}>
          Удалить позицию
        </Button>
      )}
    </Box>
  );
};
