import {
  IPaymentCondition,
  IPaymentConditionCreate,
  IPaymentConditionsFilter,
  IPaymentConditionsResponse,
  IPaymentConditionTemplate,
  IPaymentConditionTemplateCreatePayload,
  IPaymentConditionTemplateEditPayload,
  IPaymentConditionUpdate,
} from 'src/models';
import { businessRequestApi } from '../business-request';

export const paymentConditionsApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getPaymentConditions: builder.query<
      IPaymentConditionsResponse,
      IPaymentConditionsFilter
    >({
      query: (params) => ({
        url: `business-requests/api/v1/payment_conditions`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, arg) => [{ type: 'getPaymentConditions' }],
    }),

    createPaymentConditions: builder.mutation<
      { responses: IPaymentCondition[] },
      IPaymentConditionCreate
    >({
      query: (body) => ({
        url: `business-requests/api/v1/payment_conditions`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['getPaymentConditions'],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            paymentConditionsApi.util.updateQueryData(
              'getPaymentConditions',
              {
                businessRequestIds: [
                  data.responses[0].businessRequestId ??
                    body.metadata[0].businessRequestId!,
                ],
              },
              () => {}
            )
          );
        } catch {
          // ignore
        }
      },
    }),

    updatePaymentConditions: builder.mutation<
      { responses: IPaymentCondition[] },
      IPaymentConditionUpdate
    >({
      query: (body) => ({
        url: `business-requests/api/v1/payment_conditions`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['getPaymentConditions'],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            paymentConditionsApi.util.updateQueryData(
              'getPaymentConditions',
              {
                businessRequestIds: [
                  data.responses[0].businessRequestId ??
                    body.metadata.businessRequestId!,
                ],
              },
              () => {}
            )
          );
        } catch {
          // ignore
        }
      },
    }),

    deletePaymentConditions: builder.mutation<void, { ids: number[] }>({
      query: (body) => ({
        url: `business-requests/api/v1/payment_conditions?ids=${body.ids.join(
          ','
        )}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['getPaymentConditions'],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            paymentConditionsApi.util.updateQueryData(
              'getPaymentConditions',
              {
                businessRequestIds: [],
              },
              () => data
            )
          );
        } catch {
          // ignore
        }
      },
    }),

    getPaymentConditionsTemplates: builder.query<
      IPaymentConditionTemplate[],
      { templateNamePattern?: string; id?: number | null }
    >({
      query: (params) => ({
        url: `business-requests/api/v1/payment_conditions_templates`,
        method: 'GET',
        params,
      }),
      providesTags: () => [{ type: 'getPaymentConditionTemplates' }],
    }),

    deletePaymentConditionsTemplate: builder.mutation<void, { ids: number[] }>({
      query: (body) => ({
        url: `business-requests/api/v1/payment_conditions_templates?ids=${body.ids.join(
          ','
        )}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      invalidatesTags: ['getPaymentConditionTemplates'],
    }),

    createPaymentConditionTemplate: builder.mutation<
      { responses: IPaymentCondition[] },
      IPaymentConditionTemplateCreatePayload
    >({
      query: (body) => ({
        url: `business-requests/api/v1/payment_conditions_templates`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['getPaymentConditionTemplates'],
    }),

    editPaymentConditionTemplate: builder.mutation<
      { responses: IPaymentCondition[] },
      IPaymentConditionTemplateEditPayload
    >({
      query: (body) => ({
        url: `business-requests/api/v1/payment_conditions_templates`,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body,
      }),
      invalidatesTags: ['getPaymentConditionTemplates'],
    }),
  }),
});

export const {
  useGetPaymentConditionsQuery,
  useLazyGetPaymentConditionsQuery,
  useCreatePaymentConditionsMutation,
  useUpdatePaymentConditionsMutation,
  useDeletePaymentConditionsMutation,
  useGetPaymentConditionsTemplatesQuery,
  useLazyGetPaymentConditionsTemplatesQuery,
  useDeletePaymentConditionsTemplateMutation,
  useCreatePaymentConditionTemplateMutation,
  useEditPaymentConditionTemplateMutation,
} = paymentConditionsApi;
