import { Box, DialogContent, Stack, Typography } from '@mui/material';
import { Dialog, DialogTitle } from 'src/components/ui';
import { BUDGET_CHANGE_HISTORY_SOURCES_NAMES } from 'src/configs/';
import { IChangeLogsHistory } from 'src/models/changelogs';

export interface BudgetHistoryWindowProps {
  open: boolean;
  onClose: () => void;
  changeHistory?: IChangeLogsHistory[];
  title?: string;
}

export const ChangeLogsWindow = ({
  open,
  onClose,
  changeHistory,
  title,
}: BudgetHistoryWindowProps) => {
  const isDate = (value: string) => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}( \d{2}:\d{2}:\d{2}(\.\d{1,6})?)?$/;
    return dateRegex.test(value);
  };
  const isDateTime = (value: string) => {
    const dateTimeRegex =
      /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(Z|([+-]\d{2}:\d{2}))?$/;
    return dateTimeRegex.test(value);
  };

  const getFormattedDate = (date: string, isDateWithTime = true) => {
    const parsedDate = new Date(date);

    if (!parsedDate.getDate()) {
      return '-';
    }

    return new Date(parsedDate).toLocaleString('ru-RU', {
      dateStyle: 'short',
      timeStyle: isDateWithTime ? 'short' : undefined,
    });
  };

  return (
    <Dialog
      open={open}
      sx={{
        '.MuiPaper-root': {
          width: '100%',
          maxWidth: '700px',
          padding: '24px 28px',
        },
      }}
      close={onClose}>
      <DialogTitle sx={{ padding: '16px 0' }}>
        <Typography variant="h3" fontWeight="600">
          {`История изменений поля${title ? ` “${title}”` : ''}`}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          maxHeight: '460px',
          overflow: 'auto',
          padding: '0px',
        }}>
        <Stack
          sx={(theme) => ({
            background: theme.palette.primary.light,
            padding: '16px 20px',
            borderRadius: '12px',
          })}
          direction="row"
          gap="20px">
          <Box sx={{ width: '140px' }}>
            <Typography fontSize="13px" lineHeight="16px" fontWeight={500}>
              Дата изменения
            </Typography>
          </Box>
          <Box sx={{ width: '140px' }}>
            <Typography fontSize="13px" lineHeight="16px" fontWeight={500}>
              Пользователь
            </Typography>
          </Box>
          <Box sx={{ width: '284px' }}>
            <Typography fontSize="13px" lineHeight="16px" fontWeight={500}>
              Значение
            </Typography>
          </Box>
        </Stack>
        {changeHistory?.map((historyItem) => (
          <Stack
            sx={() => ({
              padding: '16px 20px',
              borderBottom: '1px dashed #DEDEDE',
            })}
            direction="row"
            gap="20px"
            key={historyItem.changedAt}>
            <Box sx={{ width: '140px' }}>
              <Typography fontSize="14px" lineHeight="16px" fontWeight={500}>
                {getFormattedDate(historyItem.changedAt)}
              </Typography>
            </Box>
            <Box sx={{ width: '140px' }}>
              <Typography fontSize="14px" lineHeight="16px" fontWeight={500}>
                {historyItem.changedBy}
              </Typography>
            </Box>
            <Box sx={{ width: '284px' }}>
              <Typography fontSize="14px" lineHeight="16px" fontWeight={500}>
                {isDate(historyItem.value)
                  ? getFormattedDate(
                      historyItem.value,
                      isDateTime(historyItem.value)
                    )
                  : historyItem.value}
              </Typography>
            </Box>
          </Stack>
        ))}
      </DialogContent>
    </Dialog>
  );
};
