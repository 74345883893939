import { OverflowTooltip, SvgIcon, Stack } from 'src/components/ui';
import { Star } from 'src/assets/icons';
import { joinName } from 'src/utils';
import { TVariantContactItem } from 'src/components/widgets/contact-item/types';
import { TCardManager } from 'src/components/widgets/manager-card/manager-card';

interface INameLabelProps {
  user: TVariantContactItem | TCardManager['manager'];
  isStar: boolean;
}

export const NameLabel = ({ user, isStar }: INameLabelProps) => {
  const name = joinName(
    user?.lastName,
    user?.firstName,
    user?.middleName
  ).fullName;

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="4px"
      justifyContent="space-between">
      {isStar && <SvgIcon width="16px" height="16px" icon={Star} />}
      <OverflowTooltip
        text={name}
        fontWeight="600"
        style={{ flexGrow: '1' }}
        data-id="contact_card_name"
      />
    </Stack>
  );
};
