import { FC, useState } from 'react';
import { Box, Typography } from 'src/components/ui';
import { ChangePasswordForm } from 'src/components/widgets';
import { TChangePasswordFormInput } from 'src/components/widgets/change-password-form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useChangePasswordMutation } from 'src/api/user';
import {
  getErrorMessage,
  isErrorWithCode,
  isFetchBaseQueryError,
} from 'src/store/utils';
import { ERROR_CODE } from 'src/constants';

export const ChangePassword: FC = () => {
  const form = useForm<TChangePasswordFormInput>({
    defaultValues: {
      newPassword: '',
      newPasswordConfirm: '',
      oldPassword: '',
    },
  });
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const [isChanged, setIsChanged] = useState<boolean>(false);

  const submitHandler: SubmitHandler<TChangePasswordFormInput> = async (
    data
  ) => {
    try {
      await changePassword({
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      }).unwrap();

      setIsChanged(true);
    } catch (e: unknown) {
      if (isFetchBaseQueryError(e) && isErrorWithCode(e.data)) {
        if (e.data.errorCode === ERROR_CODE.WRONG_OLD_PASSWORD) {
          form.setError('oldPassword', { message: 'Неверный старый пароль' });
        }

        if (e.data.errorCode === ERROR_CODE.WRONG_NEW_PASSWORD) {
          form.setError('newPassword', {
            message: 'Некорректный новый пароль',
          });
        }
      } else {
        form.setError('root.serverError', {
          type: 'custom',
          message: getErrorMessage(e),
        });
      }
    }
  };

  return isChanged ? (
    <>
      <Typography variant="h2" mb="24px">
        Пароль изменен
      </Typography>
      <Typography variant="subtitle2">
        Теперь вы можете использовать новый пароль для авторизации.
      </Typography>
    </>
  ) : (
    <Box pr="40px">
      <ChangePasswordForm
        form={form}
        onSubmit={submitHandler}
        isLoading={isLoading}
        checkOldPassword
      />
    </Box>
  );
};
