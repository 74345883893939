import { Stack, Typography } from 'src/components/ui';
import { LinearProgress } from '@mui/material';

export type ILinearCounterProgress = {
  value: number;
  className?: string;
};

export const LinearCounterProgress = ({
  value,
  className,
}: ILinearCounterProgress) => (
  <Stack direction="row" gap="8px" alignItems="center" className={className}>
    <Stack direction="row" gap="4px" alignItems="center">
      <Typography fontSize="12px" fontWeight="500">
        {value < 100 ? value : 100}
      </Typography>
      <Typography fontSize="12px" fontWeight="500" color="#7A8694">
        / 100 %
      </Typography>
    </Stack>
    <LinearProgress
      variant="determinate"
      value={value < 100 ? value : 100}
      sx={{ flexGrow: 1 }}
    />
  </Stack>
);
