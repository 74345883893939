interface ISorting {
  fieldName: string;
  direction: 'ASC' | 'DESC';
}

interface ISort {
  empty: boolean;
  unsorted: boolean;
  sorted: boolean;
}

interface IPagination {
  pageNumber: number;
  pageSize: number;
  sorting: ISorting;
}

export enum PaymentType {
  PREPAY = 'PREPAY',
  POSTPAY = 'POSTPAY',
}

export enum DayType {
  WORKING = 'WORKING',
  CALENDAR = 'CALENDAR',
}

export enum PaymentMoment {
  SPECIFICATION_SIGN_DATE = 'SPECIFICATION_SIGN_DATE',
  SPECIFICATION_SIGN_DATE_AND_BILL_RECEPTION = 'SPECIFICATION_SIGN_DATE_AND_BILL_RECEPTION',
  SCHEMES_APPROVAL = 'SCHEMES_APPROVAL',
  SHIPMENT_READY_NOTIFICATION = 'SHIPMENT_READY_NOTIFICATION',
  SHIPMENT_READY_NOTIFICATION_ACCORDING_TO_BILL = 'SHIPMENT_READY_NOTIFICATION_ACCORDING_TO_BILL',
  SHIPMENT_DATE = 'SHIPMENT_DATE',
  SHIPMENT_DATE_ACCORDING_TO_BILL = 'SHIPMENT_DATE_ACCORDING_TO_BILL',
  LAST_PIECE_SHIPMENT_DATE = 'LAST_PIECE_SHIPMENT_DATE',
  LAST_PIECE_SHIPMENT_DATE_ACCORDING_TO_BILL = 'LAST_PIECE_SHIPMENT_DATE_ACCORDING_TO_BILL',
}

export interface IPaymentTypesOption<T> {
  value: T;
  label: string;
  short?: string;
}

export interface IPaymentCondition {
  id?: number;
  paymentType?: PaymentType;
  paymentPercentage?: number;
  paymentInDays?: number;
  dayType?: DayType;
  paymentMoment?: PaymentMoment;
  businessRequestId?: number;
  contractorCommercialOfferId?: number;
  commercialOfferForCustomerId?: number;
  contractorSpecificationId?: number;
  customerSpecificationId?: number;
}

export interface IPaymentConditionsFilter {
  ids?: number[];
  paymentType?: PaymentType;
  paymentPercentage?: number;
  paymentInDays?: number;
  dayType?: DayType;
  paymentMoment?: PaymentMoment;
  businessRequestIds?: number[];
  contractorCommercialOfferIds?: number[];
  commercialOfferForCustomerIds?: number[];
  contractorSpecificationIds?: number[];
  customerSpecificationIds?: number[];
  pagination?: IPagination;
}

export interface IPaymentConditionCreate {
  metadata: IPaymentCondition[];
  templateName?: string;
}

export interface IPaymentConditionUpdate {
  id: number;
  metadata: IPaymentCondition;
}

export interface IPaymentConditionsResponse {
  totalElements: number;
  totalPages: number;
  size: number;
  number: number;
  content: IPaymentCondition[];
  sort: ISort;
  numberOfElements: number;
  pageable: {
    offset: number;
    sort: ISort;
    paged: boolean;
    unpaged: boolean;
    pageNumber: number;
    pageSize: number;
  };
  first: boolean;
  last: boolean;
  empty: boolean;
}

export interface IPaymentConditionTemplate {
  templateName: string;
  id: number;
  paymentConditions: IPaymentConditionTemplateData[];
}

export interface IPaymentConditionTemplateData {
  id: number;
  paymentType: PaymentType;
  paymentPercentage: number;
  paymentInDays: number;
  dayType?: DayType;
  paymentMoment?: PaymentMoment;
}

export interface IPaymentConditionTemplateCreatePayload {
  name: string;
  initialPaymentConditions: IPaymentCondition[];
}

export interface IPaymentConditionTemplateEditPayload {
  id: number;
  templateName: string;
  paymentConditions: IPaymentCondition[];
}
