import { Box, OverflowTooltip, InfoRow } from 'src/components/ui';
import cn from 'classnames';
import styles from './styles.module.scss';
import { IPositionViewProps } from './position.types';

export const PositionView = ({
  position,
  view,
  index = 0,
}: IPositionViewProps) => {
  if (view === 'line') {
    return (
      <>
        <Box
          display="flex"
          className={cn(styles.row, styles.inner)}
          alignItems="center"
          gap="12px">
          <Box className={styles.item}>
            <InfoRow
              variant="secondary"
              label="Наименование позиции"
              value={`${index + 1}. ${position.name}`}
            />
          </Box>
        </Box>
        <Box className={styles.row}>
          <Box
            display="flex"
            className={styles.row}
            alignItems="center"
            gap="12px">
            <Box width="80px" className={cn(styles.item, styles.hasWidth)}>
              <InfoRow
                variant="secondary"
                label="Кол-во"
                value={`${position.quantity}`}
              />
            </Box>
            <Box className={cn(styles.item, styles.equal)}>
              <InfoRow
                variant="secondary"
                label="Цена за ед."
                value={`${position.price}`}
              />
            </Box>
            <Box className={cn(styles.item, styles.equal)}>
              <InfoRow
                variant="secondary"
                label="Ед. изм."
                value={`${position.okei?.measure}`}
              />
            </Box>
            <Box className={cn(styles.item, styles.equal)}>
              <InfoRow
                variant="secondary"
                label="Общая стоимость"
                value={`${position.total}`}
              />
            </Box>
          </Box>
        </Box>
      </>
    );
  }
  return (
    <Box className={styles.row}>
      <Box display="flex" className={styles.row} alignItems="center" gap="12px">
        <Box className={styles.item}>
          <OverflowTooltip text={position.name} maxWidth={200} />
        </Box>
        <Box width="170px" className={cn(styles.item, styles.hasWidth)}>
          {position.quantity}
        </Box>
        <Box width="170px" className={cn(styles.item, styles.hasWidth)}>
          {position.okei?.measure}
        </Box>
        <Box width="170px" className={cn(styles.item, styles.hasWidth)}>
          {position.price ?? 0}
        </Box>
        <Box width="170px" className={cn(styles.item, styles.hasWidth)}>
          {position.total ?? 0}
        </Box>
      </Box>
    </Box>
  );
};
