import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { customerApi } from 'src/api';
import { TAssignedManager } from 'src/api/admin/company';
import {
  businessRequestApi,
  TManagerRequestListSortKey,
} from 'src/api/business-request/business-request';
import { DIRECTION, TSort } from 'src/components/ui/filters/types';
import { TVariant } from 'src/components/ui/new-filters/types';
import { COUNT_PER_PAGE } from 'src/configs';
import {
  IBusinessRequestAuctionDateStatistics,
  IManagerKanbanBusinessRequest,
  IManagerKanbanBusinessRequestParams,
  IManagerKanbanLastActivityBusinessRequest,
  TCustomerOrganization,
} from 'src/models';
import { LastPageVisitService } from 'src/services';
import { formatServerDate } from 'src/utils';

export interface IFilterVariantsData {
  kamVariants: TVariant[];
  kamPlusVariants: TVariant[];
  techExpertVariants: TVariant[];
  backOfficeVariants: TVariant[];
  logisticsVariants: TVariant[];
  organizationVariants: TVariant[];
  requestNumberVariants: TVariant[];
}

interface IInitialState {
  requests: IManagerKanbanBusinessRequest;
  requestParams: IManagerKanbanBusinessRequestParams;
  pageCount: number;
  fromDate: string;
  organizations: { [key: number]: TCustomerOrganization } | null;
  lastActivity: {
    [key: number]: IManagerKanbanLastActivityBusinessRequest;
  } | null;
  auctionStatistics: {
    [key: number]: IBusinessRequestAuctionDateStatistics;
  } | null;
  isInitialState: boolean;
  isInitialSortSelected: boolean;
  filterTagsPositions: Record<string, number>;
  // Данные для отображения фильтров
  filterVariantsData?: IFilterVariantsData;
}

export const defaultSort: TSort<TManagerRequestListSortKey> = {
  field: 'CREATED_AT',
  direction: DIRECTION.DESC,
};

const initialState: IInitialState = {
  requests: {
    countByStatus: null,
    items: [],
  },
  requestParams: {
    filter: {
      statusGroup: 'ACTIVE',
      isAssigned: true,
      technicalOpportunitiesCategories: [],
      startDate: '',
      endDate: '',
      tenderStartDate: '',
      tenderEndDate: '',
      saleStartDate: '',
      saleEndDate: '',
      requestStatuses: [],
      creationSources: [],
      kamUserIds: [],
      kamPlusUserIds: [],
      techExpertUserIds: [],
      backOfficeUserIds: [],
      logisticUserIds: [],
      organizationIds: [],
      minBudget: '',
      maxBudget: '',
      numbers: [],
    },

    sort: {
      field: defaultSort.field,
      order: defaultSort.direction,
    },

    searchQueries: [],

    paging: {
      vertical: {
        page: 1,
        limit: COUNT_PER_PAGE.REQUEST,
      },
    },
  },
  pageCount: 0,
  fromDate: LastPageVisitService.get('request'),
  organizations: null,
  lastActivity: null,
  auctionStatistics: null,
  isInitialState: true,
  isInitialSortSelected: false,
  filterTagsPositions: {},
};

const getBusinessRequestsItems = (
  items: IManagerKanbanBusinessRequest['items']
) =>
  items.reduce(
    (acc, element) => ({
      ...acc,
      [element.statusCode]: element,
    }),
    {}
  ) as {
    [key: string]: IManagerKanbanBusinessRequest['items'][number];
  };

const requestsManagerSlice = createSlice({
  name: 'requestsManager',
  initialState,

  extraReducers: (builder) => {
    builder.addMatcher(
      customerApi.endpoints.getCustomerOrganizations.matchFulfilled,
      (state, { payload }) => {
        const newOrganizations = payload.reduce(
          (acc, element) => ({ ...acc, [element.id]: element }),
          {}
        );

        state.organizations = { ...state.organizations, ...newOrganizations };
      }
    );

    builder.addMatcher(
      businessRequestApi.endpoints.getManagerLastActivityStatistics
        .matchFulfilled,
      (state, { payload }) => {
        const newLastActivity = payload.reduce(
          (acc, element) => ({ ...acc, [element.businessRequestId]: element }),
          {}
        );

        state.lastActivity = { ...state.lastActivity, ...newLastActivity };
      }
    );

    builder.addMatcher(
      businessRequestApi.endpoints.getBusinessRequestAuctionDateStatistics
        .matchFulfilled,
      (state, { payload }) => {
        const newAuctionStatistics = payload.reduce(
          (acc, element) => ({
            ...acc,
            [element.businessRequestId]: {
              ...element,
              auctionStartDate: formatServerDate(element.auctionStartDate),
              auctionEndDate: formatServerDate(element.auctionEndDate),
            },
          }),
          {}
        );

        state.auctionStatistics = {
          ...state.auctionStatistics,
          ...newAuctionStatistics,
        };
      }
    );
  },

  reducers: {
    setManagerCurrentData(
      state,
      {
        payload: { requests, requestParams, filterTagsPositions },
      }: PayloadAction<{
        requests: IManagerKanbanBusinessRequest;
        requestParams: IManagerKanbanBusinessRequestParams;
        filterTagsPositions: Record<string, number>;
      }>
    ) {
      state.requestParams = requestParams;
      state.filterTagsPositions = filterTagsPositions;
      state.isInitialState = false;

      const isFirstPage = requestParams.paging.vertical.page === 1;

      if (
        isFirstPage ||
        !requests.items.length ||
        !state.requests.items.length
      ) {
        state.requests = requests;
      } else {
        const oldBusinessRequests = getBusinessRequestsItems(
          state.requests.items
        );

        state.requests = {
          ...requests,
          countByStatus: requests.countByStatus || state.requests.countByStatus,
          items: requests.items.map((item) => {
            const businessRequestsItemByStatus =
              oldBusinessRequests[item.statusCode];

            return businessRequestsItemByStatus
              ? {
                  ...item,
                  businessRequestsCountByStatus:
                    businessRequestsItemByStatus.businessRequestsCountByStatus,
                  businessRequests: [
                    ...businessRequestsItemByStatus.businessRequests,
                    ...item.businessRequests,
                  ],
                }
              : item;
          }),
        };
      }

      if (isFirstPage) {
        const maxTotalCountColumn = Math.max(
          ...state.requests.items.map(
            (element) => element.businessRequestsCountByStatus || 0
          )
        );

        state.pageCount = Math.ceil(
          maxTotalCountColumn / COUNT_PER_PAGE.REQUEST
        );
        state.organizations = null;
        state.lastActivity = null;
        state.auctionStatistics = null;
      }
    },

    setFromDate(state, { payload }: PayloadAction<string>) {
      state.fromDate = payload;
    },

    setFilterVariantsData(
      state,
      { payload }: PayloadAction<IFilterVariantsData>
    ) {
      state.filterVariantsData = payload;
    },

    setIsInitialSortSelected(state, { payload }: PayloadAction<boolean>) {
      state.isInitialSortSelected = payload;
    },
  },
});

export const {
  setManagerCurrentData,
  setFromDate,
  setIsInitialSortSelected,
  setFilterVariantsData,
} = requestsManagerSlice.actions;

export const requestsManagerReducer = requestsManagerSlice.reducer;
