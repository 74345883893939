import { MouseEvent, useEffect, useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { Ellipsis, Message, Pencil, SignIn } from 'src/assets/icons';
import { TContact } from 'src/components/pages/admin/company/contacts/types';
import {
  Box,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from 'src/components/ui';
import { TVariantContactItem } from 'src/components/widgets/contact-item/types';
import { TCardManager } from 'src/components/widgets/manager-card/manager-card';
import { IBidsCustomerContact, IManagerBusinessRequest } from 'src/models';
import { IClientContact } from 'src/models/communications';
import { openGuestPath } from 'src/utils';
import styles from './actions-bar.module.scss';

interface IActionsBarProps {
  user: TVariantContactItem | TCardManager['manager'];
  isContactItem: boolean;
  onEdit?: (item?: IBidsCustomerContact) => void;
  showGuestAuthAction?: boolean;
  onSelectContact: (user: IClientContact) => void;
  className?: string;
  isEditButtonVisible?: boolean;
  businessRequest?: IManagerBusinessRequest;
  isSelected?: boolean;
  isHovering: boolean;
  hideEditButton?: boolean;
}

export const ActionsBar = ({
  user,
  isContactItem,
  onEdit,
  showGuestAuthAction,
  onSelectContact,
  className,
  isEditButtonVisible,
  hideEditButton,
  businessRequest,
  isSelected,
  isHovering,
}: IActionsBarProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (!isHovering) {
      setAnchorEl(null);
    }
  }, [isHovering]);

  // TODO: Баг с открыванием/закрыванием карточки
  const handleCloseMenu = (event?: any) => {
    event?.stopPropagation();
    setAnchorEl(null);
  };

  const handleEditUser = (event: MouseEvent<HTMLElement>) => {
    event?.stopPropagation();

    if (isContactItem) {
      onEdit?.(user as IBidsCustomerContact);
    }

    handleCloseMenu();
  };

  const location = useLocation();
  const navigate = useNavigate();

  const isCommunicationPage = () =>
    location.pathname.includes('communications');

  const handleMessageClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();

    if (isCommunicationPage()) {
      onSelectContact(user as any); // TODO: !!!

      return;
    }

    if (businessRequest?.businessRequestId) {
      navigate(
        `/requests/${businessRequest.businessRequestId}/communications`,
        {
          state: {
            selectedContact: user,
          },
        }
      );
    }
  };

  return (
    <>
      <Stack className={className} gap="4px">
        {isEditButtonVisible && (
          <>
            <IconButton
              size="extraSmall"
              color={isSelected ? 'primary' : 'white'}
              onClick={(event) => handleMessageClick(event)}>
              <Message width="16px" height="16px" />
            </IconButton>
            {/* <IconButton className={styles.actionButton} disabled> */}
            {/*  <CallOutgoing color="#292929" width="16px" height="16px" /> */}
            {/* </IconButton> */}
          </>
        )}
        {isHovering && (!hideEditButton || showGuestAuthAction) && (
          <IconButton onClick={handleOpenMenu} className={styles.actionButton}>
            <Ellipsis color="#292929" width="16px" height="16px" />
          </IconButton>
        )}
      </Stack>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        {isEditButtonVisible && !hideEditButton && (
          <MenuItem onClick={handleEditUser}>
            <ListItemIcon>
              <Pencil fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography color="primary.dark">Редактировать</Typography>
            </ListItemText>
          </MenuItem>
        )}

        {showGuestAuthAction && user && (
          <MenuItem onClick={() => openGuestPath((user as TContact).userId)}>
            <ListItemIcon>
              <Box
                sx={(theme) => ({
                  '& path': {
                    stroke: theme.palette.customTheme.blueContentSecondary,
                  },
                })}>
                <SignIn />
              </Box>
            </ListItemIcon>
            <ListItemText>
              <Typography color="primary.dark">Авторизоваться</Typography>
            </ListItemText>
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
