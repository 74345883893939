import { FC, lazy, Suspense } from 'react';
import { PageLoader } from 'src/components/ui';

const ContractorCommunication = lazy(
  () =>
    import(
      /* webpackChunkName: 'CustomerCommunication' */
      /* webpackMode: "lazy" */
      `./communication`
    )
);

export const LazyContractorCommunication: FC = () => (
  <Suspense fallback={<PageLoader />}>
    <ContractorCommunication />
  </Suspense>
);
