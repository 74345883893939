import { businessRequestApi } from 'src/api/business-request/business-request';
import {
  IBusinessRequestSearchPerformers,
  IBusinessRequestSearchPerformersDto,
  IBusinessRequestSetContractorRequestDto,
  TCommonRebidding,
  TRebidding,
  TRebiddingListPerformers,
  TRebiddingListPerformersDto,
} from 'src/models';

type TCommonRebiddingWithoutCost = Omit<TCommonRebidding, 'initialMaximumCost'>;

export const managerRebiddingApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getRebiddingList: builder.query<TRebidding[], number>({
      query: (businessRequestId) => ({
        url: `/business-requests/api/v1/manager/rebidding?businessRequestId=${businessRequestId}`,
      }),
      transformResponse: (response: TRebidding[]) =>
        response.sort((a, b) => {
          if (a.number > b.number) {
            return -1;
          }
          return 0;
        }),
      providesTags: (result, error, arg) => [
        { type: 'getRebiddingList', id: arg },
      ],
    }),
    getSearchPerformersRebidding: builder.query<
      IBusinessRequestSearchPerformers,
      IBusinessRequestSearchPerformersDto
    >({
      query: ({ id, body }) => ({
        url: `/business-requests/api/v1/manager/rebidding/${id}/matching-contractors?rebiddingId=${id}`,
        method: 'POST',
        body,
      }),
    }),
    setContractorsRebidding: builder.mutation<
      unknown,
      IBusinessRequestSetContractorRequestDto
    >({
      query: ({ id, body }) => ({
        url: `/business-requests/api/v1/manager/rebidding/${id}/matching-contractors/rebidding-contractor-request`,
        method: 'POST',
        body,
      }),
    }),
    createRebidding: builder.mutation<
      TRebidding,
      { body: TCommonRebidding; businessRequestId: number }
    >({
      query: ({ body, businessRequestId }) => ({
        url: `/business-requests/api/v1/manager/rebidding?businessRequestId=${businessRequestId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              { type: 'getRequestFieldsChangelog' },
              { type: 'fieldHistoryLogs' },
              { type: 'getRebiddingList', id: arg.businessRequestId },
              { type: 'getManagerRequest', id: arg.businessRequestId },
            ],
    }),
    updateRebidding: builder.mutation<
      TRebidding,
      { body: TCommonRebiddingWithoutCost; rebiddingId: number }
    >({
      query: ({ rebiddingId, body }) => ({
        url: `/business-requests/api/v1/manager/rebidding/${rebiddingId}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: (result) =>
        result
          ? [
              { type: 'getRequestFieldsChangelog' },
              { type: 'fieldHistoryLogs' },
              { type: 'getRebiddingList', id: result.businessRequestId },
              { type: 'getManagerRequest', id: result.businessRequestId },
            ]
          : [],
    }),
    closeRebidding: builder.mutation<
      void,
      {
        rebiddingId: number;
        businessRequestId: number;
      }
    >({
      query: ({ rebiddingId }) => ({
        url: `/business-requests/api/v1/manager/rebidding/${rebiddingId}/close`,
        method: 'PATCH',
      }),
      invalidatesTags: (result, error, arg) =>
        error
          ? []
          : [
              { type: 'getRebiddingList', id: arg.businessRequestId },
              { type: 'getManagerStatusHistory', id: arg.businessRequestId },
            ],
      async onQueryStarted(
        { businessRequestId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;

          dispatch(
            businessRequestApi.util.updateQueryData(
              'getManagerRequest',
              businessRequestId,
              (data) => ({
                ...data,
                rebidding: null,
              })
            )
          );
        } catch {
          // ignore
        }
      },
    }),
    getBestCost: builder.query<
      {
        lowestOffer: number;
      },
      number
    >({
      query: (businessRequestId) => ({
        url: `/business-requests/api/v1/manager/business-request/${businessRequestId}/lowest-commercial-offer`,
      }),
    }),
    getListPerformersRebidding: builder.query<
      TRebiddingListPerformers,
      TRebiddingListPerformersDto
    >({
      query: ({ id, body }) => ({
        url: `business-requests/api/v1/manager/rebidding/${id}/rebidding-contractor-request`,
        method: 'POST',
        body,
      }),
      providesTags: () => [{ type: 'getListPerformersRebidding' }],
    }),
    deletePerformersRebidding: builder.query<
      void,
      {
        rebiddingId: number;
        rebiddingContractorRequestId: number;
        cause: string;
      }
    >({
      query: ({ rebiddingId, ...body }) => ({
        url: `business-requests/api/v1/manager/rebidding/${rebiddingId}/exclusion`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetRebiddingListQuery,
  useLazyGetSearchPerformersRebiddingQuery,
  useSetContractorsRebiddingMutation,
  useCreateRebiddingMutation,
  useUpdateRebiddingMutation,
  useCloseRebiddingMutation,
  useLazyGetBestCostQuery,
  useLazyGetListPerformersRebiddingQuery,
  useLazyDeletePerformersRebiddingQuery,
} = managerRebiddingApi;
