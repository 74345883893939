import { FC, useState } from 'react';
import { FIELD_LENGTH } from 'src/constants';
import { maxLength, required } from 'src/utils/form-rules';
import { Form, InfoRow, OutlinedInput, Typography } from 'src/components/ui';
import IconButton from '@mui/material/IconButton';
import { History } from 'src/assets/icons';
import { IChangeLogs } from 'src/models/changelogs';
import { ChangeLogsWindow } from 'src/components/widgets/change-logs-window';
import { IComponentsProp } from '../../types';

interface ICommentsProps extends IComponentsProp {
  paymentConditionsHistory?: IChangeLogs;
  isPaymentConditionsEmpty?: boolean;
}

export const Comments: FC<ICommentsProps> = ({
  isEditing,
  mode,
  paymentConditionsHistory,
  checkRequired = true,
  isPaymentConditionsEmpty = false,
}) => {
  const [isChangeModalOpen, setIsChangeModalOpen] = useState<boolean>(false);
  const toggleChangeModalOpen = () => {
    setIsChangeModalOpen(!isChangeModalOpen);
  };
  return (
    <>
      {isPaymentConditionsEmpty && (
        <Form.Item
          required
          viewMode={!isEditing}
          name="paymentConditions"
          label="Условия оплаты"
          rules={{
            required: checkRequired ? required : undefined,
            maxLength: maxLength(
              FIELD_LENGTH.commercialOffer.paymentConditions
            ),
          }}
          renderView={(value) => (
            <InfoRow
              label="Условия оплаты"
              value={
                value && (
                  <>
                    <Typography whiteSpace="pre-wrap">{value}</Typography>
                    {mode === 'contractor' &&
                      !!paymentConditionsHistory?.history.length && (
                        <IconButton onClick={toggleChangeModalOpen}>
                          <History />
                        </IconButton>
                      )}
                  </>
                )
              }
            />
          )}>
          <OutlinedInput
            multiline
            placeholder="Введите условия оплаты"
            maxRows={7}
            maxLength={FIELD_LENGTH.commercialOffer.paymentConditions}
          />
        </Form.Item>
      )}

      <Form.Item
        viewMode={!isEditing}
        name="comment"
        label="Комментарий"
        rules={{
          maxLength: maxLength(FIELD_LENGTH.commercialOffer.commentOffer),
        }}
        renderView={(value) => (
          <InfoRow
            label="Комментарий"
            value={
              value && <Typography whiteSpace="pre-wrap">{value}</Typography>
            }
          />
        )}>
        <OutlinedInput
          multiline
          placeholder="Введите комментарий"
          maxRows={7}
          maxLength={FIELD_LENGTH.commercialOffer.commentOffer}
        />
      </Form.Item>

      <ChangeLogsWindow
        onClose={toggleChangeModalOpen}
        changeHistory={paymentConditionsHistory?.history}
        title="Условия оплаты"
        open={isChangeModalOpen}
      />
    </>
  );
};
