import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import {
  ContractDocumentTypes,
  DocumentEntityTypes,
  DocumentTypes,
  TDocumentConfig,
} from 'src/models';
import { downloadFile } from 'src/api/utils';
import { ROLES } from '../constants';

const BASE_PATH = 'document-service/api';

export interface IOrganizationCardInfo {
  documentId: number;
  entityId: number;
  documentName: string;
}

export const documentApi = createApi({
  reducerPath: 'documentApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    uploadDocument: builder.mutation<
      { id: number },
      {
        entityType: DocumentEntityTypes;
        entityId: number;
        documentType: DocumentTypes;
        file: FormData;
        appCreation?: boolean;
        cleaned?: boolean;
      }
    >({
      query: ({ file, ...params }) => ({
        url: `${BASE_PATH}/v1/document`,
        method: 'POST',
        params,
        body: file,
      }),
    }),
    downloadDocument: builder.query<null, number>({
      query: (documentId) => ({
        url: `${BASE_PATH}/v1/document/${documentId}/download`,
        responseHandler: (response) =>
          response.headers.get('Content-Type') === 'application/octet-stream'
            ? response.blob()
            : response.json(),
      }),
      transformResponse(response: Blob, metaData) {
        downloadFile(response, metaData);

        return null;
      },
    }),
    getDocumentPreviewUrl: builder.query<string, number>({
      query: (documentId) => ({
        url: `${BASE_PATH}/v1/document/${documentId}/download`,
        responseHandler: (response) =>
          response.headers.get('Content-Type') === 'application/octet-stream'
            ? response.blob()
            : response.json(),
      }),
      transformResponse(response: Blob, metaData) {
        const blob = response.slice(0, response.size, 'application/pdf');

        const objectUrl = URL.createObjectURL(blob);

        // window.open(objectUrl);

        return objectUrl;
      },
    }),
    downloadArchive: builder.query<
      null,
      {
        entityType: DocumentEntityTypes;
        entityId: number;
        documentType: DocumentTypes | ContractDocumentTypes;
      }
    >({
      query: ({ entityType, entityId, ...params }) => ({
        url: `${BASE_PATH}/v1/document/archive/${entityType}/${entityId}`,
        params,
        responseHandler: (response) =>
          response.headers.get('Content-Type') === 'application/octet-stream'
            ? response.blob()
            : response.json(),
      }),
      transformResponse(response: Blob, metaData) {
        downloadFile(response, metaData);

        return null;
      },
    }),
    removeDocument: builder.mutation<void, number>({
      query: (documentId) => ({
        url: `${BASE_PATH}/v1/document/${documentId}`,
        method: 'delete',
      }),
    }),
    getConfig: builder.query<TDocumentConfig, void>({
      query: () => ({
        url: `${BASE_PATH}/v1/document/permissions`,
      }),
    }),
    getContractorPresentation: builder.query<null, number>({
      query: (id) => ({
        url: `${BASE_PATH}/v1/document/${id}/contractor-presentation/pdf`,
        responseHandler: (response) =>
          response.headers.get('Content-Type') === 'application/pdf'
            ? response.blob()
            : response.json(),
      }),
      transformResponse(response: Blob) {
        const objectUrl = URL.createObjectURL(response);
        window.open(objectUrl);

        return null;
      },
    }),

    getDocumentsShareLink: builder.query<
      { shareDocumentsLink: string },
      { entityId: number; entityType: DocumentEntityTypes }
    >({
      query: (entity) => ({
        url: `${BASE_PATH}/v1/share-documents/link`,
        method: 'GET',
        params: entity,
      }),
    }),

    shareDocumentsByEmail: builder.mutation<
      void,
      {
        entityType: DocumentEntityTypes;
        entityId: number;
        organizationIds: number[];
      }
    >({
      query: (body) => ({
        url: `${BASE_PATH}/v1/share-documents/send-email`,
        method: 'POST',
        body,
      }),
    }),

    getOrganizationCardInfoByIds: builder.query<
      IOrganizationCardInfo[],
      { entityIds: number[] }
    >({
      query: (body) => ({
        url: `${BASE_PATH}/v1/document/organization-card`,
        method: 'POST',
        body,
      }),
    }),

    // Данная api стирает часть данных чертежа с помощью нейросети
    cleanDocument: builder.mutation<
      void,
      {
        fileId: number;
        entityType: DocumentEntityTypes | 'CONTRACTOR' | 'CUSTOMER';
        entityId: number;
        documentType: DocumentTypes | ContractDocumentTypes;
        token: string;
        'Current-Role': ROLES | null;
      }
    >({
      query: (body) => ({
        url: `/file-processing-service/api/v1/processing`,
        method: 'POST',
        body: (() => {
          // TODO: Вынести в utils
          const removeTeleportFromUrl = (host: string) =>
            host.replace('teleport.', '');

          const currentHost = window.location.host;
          const isLocalHost = currentHost.startsWith('localhost');
          const host = isLocalHost
            ? 'lk-dev.i.cometal.ru'
            : removeTeleportFromUrl(currentHost);

          return {
            ...body,
            url: `${
              host.startsWith('http') ? host : `https://${host}`
            }/document-service/api/v1/document/${body.fileId}/download`,
          };
        })(),
      }),
    }),
  }),
});

export const {
  useUploadDocumentMutation,
  useLazyDownloadDocumentQuery,
  useLazyGetDocumentPreviewUrlQuery,
  useLazyDownloadArchiveQuery,
  useRemoveDocumentMutation,
  useGetConfigQuery,
  useLazyGetContractorPresentationQuery,
  useLazyGetDocumentsShareLinkQuery,
  useShareDocumentsByEmailMutation,
  useLazyGetOrganizationCardInfoByIdsQuery,
  useCleanDocumentMutation,
} = documentApi;
