import { ReactNode, useState } from 'react';
import { ControllerProps } from 'react-hook-form';
import classNames from 'classnames';
import {
  useCleanDocumentMutation,
  useLazyDownloadArchiveQuery,
  useLazyDownloadDocumentQuery,
  useLazyGetDocumentPreviewUrlQuery,
} from 'src/api/document';
import {
  Form,
  FileUpload,
  FileUploadCleaned,
  FileList,
  FileListCleaned,
  Button,
  Typography,
} from 'src/components/ui';
import { DocumentEntityTypes, DocumentTypes } from 'src/models';
import { BidsShare } from 'src/assets/icons';
import { TFile } from 'src/components/ui/file-upload';
import { ModalShareRequest, PreviewFileModal } from './components';
import styles from './attach-files.module.scss';

interface IAttachFilesProps {
  isEditMode?: boolean;
  formItemName?: string;
  documentOptions: {
    entityType: DocumentEntityTypes;
    entityId: number;
    documentType: DocumentTypes;
  };
  showShareButton?: boolean;
  showDownloadButton?: boolean;
  uploadFilesTitle?: React.ReactNode;
  fullScreen?: boolean;
  rules?: ControllerProps['rules'];
  subtitleComponent?: ReactNode;
  fileListTitle?: string;
  extraTitle?: ReactNode;
  noMargin?: boolean;
  onChange?: (files: TFile[]) => void;
  showDownloadAllButton?: boolean;
  variant?: 'default' | 'compact';
  showCleanButton?: boolean;
  canPreview?: boolean;
  isTwoColumns?: boolean;
  showCleaned?: boolean;
}

export const AttachFiles = ({
  isEditMode,
  formItemName = 'files',
  documentOptions,
  showShareButton = false,
  showDownloadButton = true,
  uploadFilesTitle = 'Прикрепите файлы',
  fullScreen = true,
  rules,
  subtitleComponent,
  fileListTitle = 'Прикрепленные файлы',
  extraTitle,
  noMargin,
  onChange,
  showDownloadAllButton = true,
  variant = 'default',
  showCleanButton,
  canPreview = false,
  isTwoColumns = false,
  showCleaned,
}: IAttachFilesProps) => {
  const [isShowShareRequest, setIsShowShareRequest] = useState(false);
  const [downloadDocument] = useLazyDownloadDocumentQuery();
  const [getDocumentPreviewUrl] = useLazyGetDocumentPreviewUrlQuery();
  const [downloadArchive] = useLazyDownloadArchiveQuery();
  const toggleShareRequest = () => {
    setIsShowShareRequest((prevState) => !prevState);
  };

  const handleDownloadDocument = async (id: number) => {
    try {
      await downloadDocument(id).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadAllDocument = () =>
    downloadArchive(documentOptions).unwrap();

  const [documentPreviewUrl, setDocumentPreviewUrl] = useState('');

  const [isPreviewFileModalVisible, setIsPreviewFileModalVisible] =
    useState(false);
  const togglePreviewFileModal = () => {
    setIsPreviewFileModalVisible((prev) => !prev);
  };

  const handlePreviewDocument = async (id: number) => {
    try {
      const url = await getDocumentPreviewUrl(id).unwrap();

      setDocumentPreviewUrl(url);
      setIsPreviewFileModalVisible(true);
    } catch (err) {
      console.log(err);
    }
  };

  const renderFileListActions = (values: any) => {
    if (extraTitle || (values?.length && showShareButton)) {
      return (
        <Button
          aria-label="to share"
          title="Поделиться"
          variant="text"
          onClick={toggleShareRequest}>
          <Typography color="#7A8694">Поделиться</Typography>
        </Button>
      );
    }

    return null;
  };

  const renderFileList = (values: any) => {
    if (isTwoColumns) {
      return (
        <FileListCleaned
          variant={variant}
          title={fileListTitle}
          subtitle={subtitleComponent}
          actions={renderFileListActions(values)}
          value={values}
          onDownload={handleDownloadDocument}
          onDownloadAll={
            showDownloadAllButton ? handleDownloadAllDocument : undefined
          }
          showCleanButton={showCleanButton}
          onPreview={canPreview ? handlePreviewDocument : undefined}
        />
      );
    }

    return (
      <FileList
        variant={variant}
        title={fileListTitle}
        subtitle={subtitleComponent}
        actions={
          extraTitle ||
          (values?.length && showShareButton ? (
            <Button
              aria-label="to share"
              title="Поделиться"
              variant="text"
              onClick={toggleShareRequest}
              startIcon={<BidsShare />}>
              Поделиться
            </Button>
          ) : null)
        }
        value={values}
        onDownload={handleDownloadDocument}
        onDownloadAll={
          showDownloadAllButton ? handleDownloadAllDocument : undefined
        }
        showCleanButton={showCleanButton}
        onPreview={canPreview ? handlePreviewDocument : undefined}
        showCleaned={showCleaned}
      />
    );
  };

  const renderFileUpload = () => {
    if (isTwoColumns) {
      return (
        <FileUploadCleaned
          variant={variant}
          onChange={onChange}
          title={uploadFilesTitle}
          fullScreen={fullScreen}
          subtitle={subtitleComponent}
          onDownload={handleDownloadDocument}
          removeMode="soft"
          documentType={documentOptions.documentType}
          showDownloadButton={showDownloadButton}
          showCleanButton={showCleanButton}
        />
      );
    }

    return (
      <FileUpload
        variant={variant}
        onChange={onChange}
        title={uploadFilesTitle}
        fullScreen={fullScreen}
        subtitle={subtitleComponent}
        onDownload={handleDownloadDocument}
        removeMode="soft"
        documentType={documentOptions.documentType}
        showDownloadButton={showDownloadButton}
        showCleanButton={showCleanButton}
      />
    );
  };

  return (
    <>
      <div
        className={classNames(
          styles.container,
          noMargin && styles.containerNoMargin
        )}>
        <Form.Item
          noMargin
          renderView={(values) => renderFileList(values)}
          viewMode={!isEditMode}
          name={formItemName}
          rules={rules}>
          {renderFileUpload()}
        </Form.Item>
      </div>

      {showShareButton && isShowShareRequest && (
        <ModalShareRequest
          shareByEmail
          open={isShowShareRequest}
          toggleShow={toggleShareRequest}
          entityId={documentOptions.entityId}
          entityType={documentOptions.entityType}
        />
      )}

      {canPreview && isPreviewFileModalVisible && (
        <PreviewFileModal
          open={isPreviewFileModalVisible}
          toggleShow={togglePreviewFileModal}
          url={documentPreviewUrl}
        />
      )}
    </>
  );
};
