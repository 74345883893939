import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Stack, Typography } from 'src/components/ui';
import { Avatar } from 'src/components/widgets';
import { DEFAULT_PAGE, ROLE_NAMES } from 'src/configs';
import { PERMISSIONS, ROLES } from 'src/constants';
import { useChangeRole } from 'src/hooks';
import { AuthService, RolePermissionService } from 'src/services';
import { IUserState } from 'src/store/user/user-slice';
import styles from './user-block.module.scss';

interface IUserBlockProps {
  user: IUserState;
}

export const UserBlock = ({ user }: IUserBlockProps) => {
  const isMultipleRole = user?.roles?.length && user?.roles?.length > 1;

  const isCustomer = AuthService.currentRole! === ROLES.CUSTOMER;

  const navigate = useNavigate();

  const location = useLocation();

  const { handleChangeRole } = useChangeRole({
    onChangeRole: () => {
      if (
        !location.pathname.includes('companies') ||
        !RolePermissionService.can(PERMISSIONS.VIEW_OWN_COMPANY)
      ) {
        navigate(DEFAULT_PAGE);
      }
    },
  });

  return (
    <Stack direction="column" className={styles.userBlock} gap="12px">
      <Stack direction="row" gap="12px" alignItems="center">
        <Avatar userInfo={user} />
        <Stack gap="8px">
          <Typography fontWeight="500" lineHeight="14px">
            {user.fullName}
          </Typography>
          <Typography fontSize="12px" color="#7A8694" lineHeight="12px">
            {ROLE_NAMES[AuthService.currentRole!]}
          </Typography>
        </Stack>
      </Stack>
      {isMultipleRole && (
        <Stack
          direction="row"
          flex={1}
          gap="4px"
          sx={{ padding: '4px', background: '#F3F6FB', borderRadius: '12px' }}>
          <Button
            sx={{ flex: 1 }}
            color={isCustomer ? 'primary' : 'secondary'}
            onClick={() => handleChangeRole(ROLES.CUSTOMER)}>
            Заказчик
          </Button>
          <Button
            sx={{ flex: 1 }}
            color={isCustomer ? 'secondary' : 'primary'}
            onClick={() => handleChangeRole(ROLES.CONTRACTOR)}>
            Исполнитель
          </Button>
        </Stack>
      )}
    </Stack>
  );
};
