/* eslint-disable jsx-a11y/control-has-associated-label */
import classNames from 'classnames';
import { Typography, Grid } from '@mui/material';
import styles from './color-picker.module.scss';
import { COLORS_PICKER } from './config';

interface IColorPickerProps {
  title?: string;
  activeColor?: string | null;
  onClick?: (color: string) => void;
}

export const ColorPicker = ({
  title,
  activeColor,
  onClick,
}: IColorPickerProps) => (
  <div className={styles.wrapper}>
    <Typography variant="body2" color="secondary">
      {title}
    </Typography>
    <Grid container rowGap={0.5}>
      {COLORS_PICKER.map((color) => (
        <Grid item xs={2} key={color}>
          <div className={styles.btn_wrapper}>
            <button
              type="button"
              onClick={() => onClick?.(color)}
              style={{
                backgroundColor: color,
              }}
              className={classNames(styles.color, {
                [styles.active]: activeColor === color,
              })}
            />
          </div>
        </Grid>
      ))}
    </Grid>
  </div>
);
