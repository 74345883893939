import cn from 'classnames';
import { useFormContext } from 'react-hook-form';
import {
  Check,
  CloseIcon,
  PencilEditTableItemIcon,
  TrashDeleteTableItemIcon,
} from 'src/assets/icons';
import {
  Box,
  Form,
  IconButton,
  MenuItem,
  NumberInput,
  OutlinedInput,
  Select,
  Typography,
} from 'src/components/ui';
import { PaymentType } from 'src/models';
import { maxNumber, minNumber, number, required } from 'src/utils/form-rules';
import {
  DAY_TYPES,
  PAYMENT_MOMENTS,
  PAYMENT_MOMENTS_BY_TYPE,
  PAYMENT_TYPES,
} from '../../payment-conditions.const';
import styles from '../../styles.module.scss';
import { buttonStyles } from './payment-condition.const';
import { IPaymentConditionProps } from './payment-condition.types';

export const EditPaymentCondition = (props: IPaymentConditionProps) => {
  const {
    handleSubmit,
    handleCancelEditPaymentCondition,
    index = 0,
    maxPercentage = 100,
    handleRemoveRow,
    isEditPaymentCondition,
    isEditModeEnabled,
    paymentCondition,
    handleEditPaymentCondition,
    isCustomerRequestPage,
    isPaymentTemplatesPage,
  } = props;

  const { getValues } = useFormContext();

  const printControlCell = () => {
    if (isEditPaymentCondition) {
      return (
        <>
          <IconButton
            color="primary"
            onClick={() => {
              handleSubmit(paymentCondition?.id);
            }}>
            <Box {...buttonStyles}>
              <Check width={20} height={20} />
            </Box>
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() =>
              handleCancelEditPaymentCondition(paymentCondition!.id, index)
            }>
            <Box {...buttonStyles}>
              <CloseIcon width={12} height={12} />
            </Box>
          </IconButton>
        </>
      );
    }

    if (isEditModeEnabled) {
      return (
        <>
          <IconButton
            color="secondary"
            onClick={() => handleEditPaymentCondition(paymentCondition?.id)}>
            <Box {...buttonStyles}>
              <PencilEditTableItemIcon width={20} height={20} />
            </Box>
          </IconButton>
          <IconButton
            color="secondary"
            onClick={() =>
              handleRemoveRow
                ? handleRemoveRow(paymentCondition?.id, index)
                : null
            }>
            <Box {...buttonStyles}>
              <TrashDeleteTableItemIcon width={18} height={18} />
            </Box>
          </IconButton>
        </>
      );
    }

    return null;
  };

  if (!isEditPaymentCondition) {
    const values = getValues(`metadata.${index}`);
    return (
      <Box display="flex" className={styles.row} gap="12px" alignItems="center">
        <Box width="80px" className={cn(styles.item, styles.hasWidth)}>
          <Typography color="primary">{index + 1} часть</Typography>
        </Box>
        <Box
          className={cn(
            styles.item,
            styles.itemWithoutMaxWidth,
            isPaymentTemplatesPage && styles.itemWithBigWidth
          )}>
          {
            PAYMENT_TYPES.find((type) => type.value === values?.paymentType)
              ?.label
          }{' '}
          {values?.paymentPercentage}% в течении {values?.paymentInDays}{' '}
          {DAY_TYPES.find((type) => type.value === values?.dayType)?.short} дней
          с момента{' '}
          {
            PAYMENT_MOMENTS.find(
              (moment) => moment.value === values?.paymentMoment
            )?.label
          }
        </Box>
        <Box
          display="flex"
          width="90px"
          className={cn(styles.item, styles.hasWidth)}
          justifyContent="flex-end"
          gap="8px">
          {printControlCell()}
        </Box>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      className={styles.row}
      gap="12px"
      alignItems="flex-start">
      <Box
        width="80px"
        className={cn(styles.item, styles.hasWidth)}
        display="flex"
        height="60px"
        alignItems="center">
        {index + 1} часть
      </Box>
      <Box width="180px" className={cn(styles.item, styles.hasWidth)}>
        <Form.Item
          rules={{
            required,
          }}
          viewMode={!isEditPaymentCondition}
          name={`metadata.${index}.paymentType`}
          className={styles.formInput}
          renderView={(value) => (
            <Box>
              {PAYMENT_TYPES.find((type) => type.value === value)?.label}
            </Box>
          )}>
          <Select>
            {PAYMENT_TYPES.map((pt) => (
              <MenuItem key={pt.value} value={pt.value}>
                {pt.label}
              </MenuItem>
            ))}
          </Select>
        </Form.Item>
      </Box>
      <Box width="80px" className={cn(styles.item, styles.hasWidth)}>
        <Form.Item
          rules={{
            required,
            validate: {
              number,
              maxNumber: (value) =>
                maxNumber(
                  maxPercentage,
                  `% не может быть выше ${maxPercentage}`
                )(value),
              minNumber: (value) =>
                minNumber(1, `% не может быть меньше 1`)(value),
            },
          }}
          viewMode={!isEditPaymentCondition}
          name={`metadata.${index}.paymentPercentage`}
          className={styles.formInput}
          renderView={(value) => <Box>{value}</Box>}>
          <NumberInput />
        </Form.Item>
      </Box>
      <Box
        display="flex"
        gap="12px"
        width="340px"
        alignItems="flex-startf"
        className={cn(styles.item, styles.hasWidth)}>
        <Form.Item
          name={`metadata.${index}.dayType`}
          rules={{
            required,
          }}
          viewMode={!isEditPaymentCondition}
          className={styles.formInput}
          renderView={(value) => (
            <Box>{DAY_TYPES.find((type) => type.value === value)?.label}</Box>
          )}>
          <Select autoWidth={false} fullWidth displayEmpty>
            {DAY_TYPES.map((dt) => (
              <MenuItem key={dt.value} value={dt.value}>
                {dt.label}
              </MenuItem>
            ))}
          </Select>
        </Form.Item>
        <Box
          className={cn(styles.item, styles.hasWidth, styles.inner)}
          width="80px">
          <Form.Item
            viewMode={!isEditPaymentCondition}
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(365, `Дней не может быть больше 365`)(value),
                minNumber: (value) =>
                  minNumber(1, `Дней не может быть меньше 1`)(value),
              },
            }}
            name={`metadata.${index}.paymentInDays`}
            className={styles.formInput}
            renderView={(value) => <Box>{value}</Box>}>
            <OutlinedInput />
          </Form.Item>
        </Box>
      </Box>
      <Box
        className={cn(
          styles.item,
          isCustomerRequestPage && styles.itemWithoutMaxWidth
        )}
        style={{ flexShrink: 1 }}>
        <Form.Item
          rules={{
            required,
          }}
          viewMode={!isEditPaymentCondition}
          name={`metadata.${index}.paymentMoment`}
          className={styles.formInput}
          renderView={(value) => (
            <Box>
              {PAYMENT_MOMENTS.find((type) => type.value === value)?.label}
            </Box>
          )}>
          <Select
            autoWidth={false}
            fullWidth
            disabled={!getValues(`metadata.${index}.paymentType`)}>
            {getValues(`metadata.${index}.paymentType`)
              ? PAYMENT_MOMENTS_BY_TYPE[
                  getValues(`metadata.${index}.paymentType`) as PaymentType
                ].map((pm) => (
                  <MenuItem key={pm.value} value={pm.value}>
                    {pm.label}
                  </MenuItem>
                ))
              : null}
          </Select>
        </Form.Item>
      </Box>
      <Box
        display="flex"
        width="90px"
        className={cn(styles.item, styles.hasWidth)}
        justifyContent="flex-end"
        gap="8px">
        {printControlCell()}
      </Box>
    </Box>
  );
};
