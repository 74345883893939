export const maxNumber =
  (maxValue: number, errorMessage?: string) => (value: number | string) => {
    const clearedValue = value.toString(10).replace(/,/g, '.');

    return (
      (!value && value !== 0) ||
      Number(clearedValue) <= maxValue ||
      errorMessage ||
      `Число не больше ${maxValue}`
    );
  };
