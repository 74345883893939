import { RequestInfoBlock } from 'src/components/layout/bids-layout/main-bids-layout/components/request-info-block';
import { ContractorStatusCode, IContractorBusinessRequest } from 'src/models';
import { formatServerDate } from 'src/utils';
import { isExpiredCommercialOfferDueDate } from 'src/utils/business-request';

export const ContractorAllRequestsStatus = ({
  contractorRequest,
}: {
  contractorRequest: IContractorBusinessRequest;
}) => {
  const currentStatus = contractorRequest.statusHistory.status?.code;

  if (
    isExpiredCommercialOfferDueDate(contractorRequest.commercialOfferDueDate) &&
    ContractorStatusCode.NEW_REQUEST === (currentStatus as ContractorStatusCode)
  ) {
    return (
      <RequestInfoBlock
        title={`Вышел срок подачи КП: ${formatServerDate(
          contractorRequest.commercialOfferDueDate,
          'dd.MM.yyyy HH:mm'
        )}`}
      />
    );
  }

  return null;
};
