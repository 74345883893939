export const joinFullAddress = (
  region: null | string,
  city: null | string
): string => {
  const fullAddressParts = [];

  if (city && region && city === region) return region;

  if (region) {
    fullAddressParts.push(region);
  }

  if (city) {
    fullAddressParts.push(city);
  }

  return fullAddressParts.join(', ');
};
