import { parseISO } from 'date-fns';

// TODO: Проверить корректность правки перед внесением в QA !!!
export const setTimeZone = (date: Date | undefined) => {
  if (!date) return null;

  const currentTimeZone = new Date().getTimezoneOffset() / 60;
  const adjustedDate = new Date(date);
  adjustedDate.setHours(adjustedDate.getHours() - currentTimeZone);

  return parseISO(adjustedDate.toISOString()).toISOString();

  // const currentTimeZone = Math.abs(new Date().getTimezoneOffset() / 60);
  //
  // return parseISO(
  //   new Date(date.setHours(date.getHours() + currentTimeZone)).toISOString()
  // ).toISOString();
};
