import {
  DayType,
  IPaymentTypesOption,
  PaymentMoment,
  PaymentType,
} from 'src/models';

export const PAYMENT_TYPES: IPaymentTypesOption<PaymentType>[] = [
  {
    value: PaymentType.PREPAY,
    label: 'Предоплата',
  },
  {
    value: PaymentType.POSTPAY,
    label: 'Постоплата',
  },
];

export const DAY_TYPES: IPaymentTypesOption<DayType>[] = [
  {
    value: DayType.WORKING,
    label: 'В рабочих днях',
    short: 'рабочих',
  },
  {
    value: DayType.CALENDAR,
    label: 'В календарных днях',
    short: 'календарных',
  },
];

export const PAYMENT_MOMENTS: IPaymentTypesOption<PaymentMoment>[] = [
  {
    value: PaymentMoment.SPECIFICATION_SIGN_DATE,
    label: 'даты подписания спецификации',
  },
  {
    value: PaymentMoment.SPECIFICATION_SIGN_DATE_AND_BILL_RECEPTION,
    label: 'даты подписания спецификации и получения счета',
  },
  {
    value: PaymentMoment.SCHEMES_APPROVAL,
    label: 'согласования чертежей КМД (по выставленному счету)',
  },
  {
    value: PaymentMoment.SHIPMENT_READY_NOTIFICATION,
    label: 'получения уведомлений о готовности к отгрузке',
  },
  {
    value: PaymentMoment.SHIPMENT_READY_NOTIFICATION_ACCORDING_TO_BILL,
    label:
      'получения уведомлений о готовности к отгрузке (по выставленному счету)',
  },
  {
    value: PaymentMoment.SHIPMENT_DATE,
    label: 'даты поставки',
  },
  {
    value: PaymentMoment.SHIPMENT_DATE_ACCORDING_TO_BILL,
    label: 'даты поставки (по выставленному счету)',
  },
  {
    value: PaymentMoment.LAST_PIECE_SHIPMENT_DATE,
    label: 'даты поставки последней партии',
  },
  {
    value: PaymentMoment.LAST_PIECE_SHIPMENT_DATE_ACCORDING_TO_BILL,
    label: 'даты поставки последней партии (по выставленному счету)',
  },
];

export const PAYMENT_MOMENTS_BY_TYPE: Record<
  PaymentType,
  IPaymentTypesOption<PaymentMoment>[]
> = {
  [PaymentType.PREPAY]: [
    {
      value: PaymentMoment.SPECIFICATION_SIGN_DATE,
      label: 'даты подписания спецификации',
    },
    {
      value: PaymentMoment.SPECIFICATION_SIGN_DATE_AND_BILL_RECEPTION,
      label: 'даты подписания спецификации и получения счета',
    },
    {
      value: PaymentMoment.SCHEMES_APPROVAL,
      label: 'согласования чертежей КМД (по выставленному счету)',
    },
    {
      value: PaymentMoment.SHIPMENT_READY_NOTIFICATION,
      label: 'получения уведомлений о готовности к отгрузке',
    },
    {
      value: PaymentMoment.SHIPMENT_READY_NOTIFICATION_ACCORDING_TO_BILL,
      label:
        'получения уведомлений о готовности к отгрузке (по выставленному счету)',
    },
  ],
  [PaymentType.POSTPAY]: [
    {
      value: PaymentMoment.SHIPMENT_DATE,
      label: 'даты поставки',
    },
    {
      value: PaymentMoment.SHIPMENT_DATE_ACCORDING_TO_BILL,
      label: 'даты поставки (по выставленному счету)',
    },
    {
      value: PaymentMoment.LAST_PIECE_SHIPMENT_DATE,
      label: 'даты поставки последней партии',
    },
    {
      value: PaymentMoment.LAST_PIECE_SHIPMENT_DATE_ACCORDING_TO_BILL,
      label: 'даты поставки последней партии (по выставленному счету)',
    },
  ],
};
