import classNames from 'classnames';
import { useCallback, useRef, Ref, useEffect, useState } from 'react';
import { Button, Backdrop, HiddenComponent } from 'src/components/ui';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import styles from './file-drop-zone.module.scss';

const isEventWithFiles = (event: any) => {
  if (!event.dataTransfer) {
    return !!event.target?.files;
  }

  return Array.prototype.some.call(
    event.dataTransfer.types,
    (type) => type === 'Files' || type === 'application/x-moz-file'
  );
};

interface IFileDropZoneProps {
  // onDrop: DropzoneOptions['onDrop'];
  onDrop: (acceptedFiles: File[], isCleaned?: boolean) => void;
  fullScreen?: boolean;
  isDisable?: boolean;
  inputRef?: Ref<any>;
  isCleaned?: boolean;
}

export const FileDropZone = ({
  onDrop,
  fullScreen,
  isDisable,
  inputRef,
  isCleaned,
}: IFileDropZoneProps) => {
  const dragCounter = useRef<number>(0);

  const [isDragging, setIsDragging] = useState<boolean>(false);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, isCleaned),
    noClick: true,
    noKeyboard: true,
    noDrag: isDisable,
    disabled: isDisable,
  });

  const handleDrag = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);

  const handleDragIn = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dragCounter.current++;

    if (isEventWithFiles(event)) {
      setIsDragging(true);
    }
  }, []);

  const handleDragOut = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dragCounter.current--;

    if (dragCounter.current > 0) {
      return;
    }
    setIsDragging(false);
  }, []);

  const handleDrop = useCallback((event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();

    dragCounter.current = 0;
    setIsDragging(false);
  }, []);

  useEffect(() => {
    if (fullScreen) {
      window.addEventListener('dragenter', handleDragIn);
      window.addEventListener('dragleave', handleDragOut);
      window.addEventListener('dragover', handleDrag);
      window.addEventListener('drop', handleDrop);
    }

    return () => {
      if (fullScreen) {
        window.removeEventListener('dragenter', handleDragIn);
        window.removeEventListener('dragleave', handleDragOut);
        window.removeEventListener('dragover', handleDrag);
        window.removeEventListener('drop', handleDrop);
      }
    };
  }, [fullScreen, handleDrag, handleDragIn, handleDragOut, handleDrop]);

  return (
    <div
      {...getRootProps({
        className: classNames(
          styles.fileUploader,
          isDisable && styles.disabled
        ),
      })}>
      <div
        className={classNames(
          styles.fileUploaderContent,
          isDragActive && styles.fileUploaderContentActive
        )}>
        <HiddenComponent inputRef={inputRef} />
        <input {...getInputProps()} />
        <div className={styles.fileUploaderContentText}>
          {isDragActive ? (
            <span>Перетащите файлы в эту область</span>
          ) : (
            <>
              <Button variant="text" onClick={open}>
                Выберите файлы
              </Button>
              <span>&nbsp;или перетащите в эту область</span>
            </>
          )}
        </div>
      </div>

      <Backdrop
        open={isDragging && !isDisable}
        className={styles.dropzoneBackdrop}>
        <div className={styles.dropzoneBackdropContent}>
          {`Перетащите файлы в эту область,
      чтобы загрузить документы`}
        </div>
      </Backdrop>
    </div>
  );
};
