import { SvgIcon } from 'src/components/ui/svg-icon';
import { Like, SmallClock } from '../assets/icons';
import {
  ContractorCommercialOfferStatus,
  ContractorCommercialOfferStatusByCost,
  CustomerCommercialOfferStatus,
} from '../models';
import vars from '../theme/scss/_vars.module.scss';

export const contractorCommercialOfferStatus: Record<
  ContractorCommercialOfferStatus,
  string
> = {
  [ContractorCommercialOfferStatus.WINNER]: 'Победитель',
  [ContractorCommercialOfferStatus.DRAFT]: 'Черновик',
  [ContractorCommercialOfferStatus.REFUSED]: 'Отклонено',
  [ContractorCommercialOfferStatus.RESERVE]: 'Резерв',
  [ContractorCommercialOfferStatus.SENT]: 'КП отправлено',
};

export const contractorCommercialOfferStatusByCostTooltip: Record<
  ContractorCommercialOfferStatusByCost,
  string
> = {
  [ContractorCommercialOfferStatusByCost.BEST_COST]: 'Лучшая цена',
  [ContractorCommercialOfferStatusByCost.RESERVED_OFFER]:
    'Резервное предложение',
};

export const contractorCommercialOfferStatusByCostIcon: Record<
  ContractorCommercialOfferStatusByCost,
  any
> = {
  [ContractorCommercialOfferStatusByCost.BEST_COST]: (
    <SvgIcon
      width="20"
      height="20"
      iconPadding="4px"
      strokeWidth="1px"
      strokeHexColor={vars.success}
      backgroundFill
      icon={Like}
    />
  ),
  [ContractorCommercialOfferStatusByCost.RESERVED_OFFER]: (
    <SvgIcon
      width="20"
      height="20"
      iconPadding="4px"
      strokeWidth="1px"
      strokeHexColor={vars.warningText}
      backgroundFill
      icon={SmallClock}
    />
  ),
};

export const customerCommercialOfferStatus: Record<
  CustomerCommercialOfferStatus,
  string
> = {
  [CustomerCommercialOfferStatus.APPROVED]: 'Согласовано',
  [CustomerCommercialOfferStatus.REFUSED]: 'Отклонено',
  [CustomerCommercialOfferStatus.RECEIVED]: 'Получено',
};
