import { useEffect } from 'react';
import { useFieldArray, UseFormReturn, useWatch } from 'react-hook-form';
import { useLazyGetFilteredOkeiListQuery } from 'src/api/business-request/positions';
import { PlusRounded } from 'src/assets/icons';
import { Box, Button, Form, Stack, Typography } from 'src/components/ui';
import { IPosition } from 'src/models';
import { ContractorPosition } from './position';
import { TPositionsFormData } from './position/position.types';
import styles from './styles.module.scss';

interface IPositionsProps {
  isEditMode: boolean;
  businessRequestId: number;
  contractorCommercialOfferId?: number;
  form?: UseFormReturn<TPositionsFormData>;
  setCostByContractor?: (value: number) => void;
  positions?: IPosition[];
  handleSetCostForCustomer?: () => void;
  isCreatePositionMode?: boolean;
  isHavePositions?: boolean;
  shortView?: boolean;
}

export const ContractorPositions = ({
  positions,
  isEditMode,
  businessRequestId,
  setCostByContractor,
  form,
  contractorCommercialOfferId,
  handleSetCostForCustomer,
  isCreatePositionMode = false,
  isHavePositions = false,
  shortView = false,
}: IPositionsProps) => {
  const { control } = form || {};
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'positions',
  });

  const watchedPositions = useWatch({
    control,
    name: 'positions',
    defaultValue: [],
  });

  const [getFilteredOkeiList] = useLazyGetFilteredOkeiListQuery();

  useEffect(() => {
    if (setCostByContractor) {
      const total = watchedPositions.reduce(
        (sum, pos) => sum + (pos?.total || 0),
        0
      );
      setCostByContractor(total);
    }
  }, [watchedPositions, setCostByContractor]);

  const handleGetOkeiOptionsList = async (measure: string = '') => {
    const okeiOptionsList = await getFilteredOkeiList({ measure }).unwrap();

    return okeiOptionsList;
  };

  const handleAddPosition = () => {
    append({
      name: '',
      quantity: null,
      price: null,
      total: null,
      id: null,
      netTotal: null,
      netPrice: null,
      vat: null,
      revenuePerPosition: null,
    } as any);
  };

  const handleRemovePosition = (index: number) => {
    remove(index);
  };

  if (!isCreatePositionMode && !isEditMode && !positions?.length) {
    return (
      <Box marginBottom="20px">
        <Typography variant="h3" mb="12px">
          Позиции
        </Typography>
        <Typography variant="h4">Позиции не добавлены</Typography>
      </Box>
    );
  }

  return (
    <div>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h3" mb="12px">
          Позиции
        </Typography>

        {isCreatePositionMode && (
          <Box
            mb="16px"
            gap="16px"
            display="flex"
            justifyContent="space-between">
            <Button
              variant="text"
              applyNewIconSizing
              startIcon={<PlusRounded />}
              onClick={handleAddPosition}>
              Добавить позицию
            </Button>
          </Box>
        )}
      </Stack>

      <Box className={styles.content}>
        {!!form && (
          <Form form={form} footer={null} btnFollowScroll={false}>
            {fields.map((field, index) => (
              <ContractorPosition
                key={`ContractorPosition_${field.id}`}
                position={{
                  ...field,
                  quantity: undefined,
                  price: field.price,
                  entityRef: {
                    entityType: 'BUSINESS_REQUEST',
                    id: businessRequestId,
                  },
                  okeiId: undefined,
                }}
                businessRequestId={businessRequestId}
                contractorCommercialOfferId={contractorCommercialOfferId}
                isEditMode={isEditMode}
                isCreateMode={isCreatePositionMode}
                handleChange={handleSetCostForCustomer}
                index={index}
                form={form}
                handleGetOkeiOptionsList={handleGetOkeiOptionsList}
                handleRemovePosition={() => handleRemovePosition(index)}
                isHavePositions={
                  isHavePositions && !!field.price && !!field.total
                }
                shortView={shortView}
              />
            ))}
          </Form>
        )}
      </Box>
    </div>
  );
};
