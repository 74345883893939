import React, { useState } from 'react';
import {
  ClockPerspective,
  RoundedChevronRight,
  SquareRoundedX,
} from 'src/assets/icons';
import { Box, Stack, Typography } from 'src/components/ui';

export interface IRequestInfoBlock {
  title: string | React.ReactNode;
  children?: React.ReactNode;
  color?: 'error' | 'warning';
}

export const RequestInfoBlock = ({
  title,
  children,
  color = 'error',
}: IRequestInfoBlock) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Stack
      direction="column"
      sx={{
        backgroundColor: color === 'error' ? '#FFF3F3' : '#FCF6F2',
        borderRadius: '8px',
      }}>
      <Stack
        direction="row"
        gap="12px"
        sx={{
          flex: 1,
          padding: '12px',
          alignItems: 'center',
          borderBottom: isOpen ? '1px solid #FFE6E6' : undefined,
        }}>
        <Box
          sx={{
            height: '44px',
            width: '44px',
            display: 'flex',
            backgroundColor: color === 'error' ? '#FFE6E6' : '#FFDCBF',
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
            color: color === 'error' ? '#E11111' : '#FF7400',
          }}>
          {color === 'error' ? <SquareRoundedX /> : <ClockPerspective />}
        </Box>
        <Typography flex="1">{title}</Typography>
        {children && (
          <Box
            display="flex"
            sx={{
              color: color === 'error' ? '#E11111' : '#FF7400',
              cursor: 'pointer',
              transform: isOpen ? 'rotate(90deg)' : undefined,
              '-webkit-user-select': 'none',
              '-ms-user-select': 'none',
              'user-select': 'none',
            }}
            onClick={() => setIsOpen(!isOpen)}>
            <RoundedChevronRight />
          </Box>
        )}
      </Stack>
      {isOpen && children}
    </Stack>
  );
};
