import { Card, CircularProgress } from '@mui/material';
import { differenceInDays } from 'date-fns';
import { useMemo } from 'react';
import { DotIcon } from 'src/assets/icons';
import styles from 'src/components/layout/bids-layout/main-bids-layout/components/status-history-block/status-history-block.module.scss';
import { Box, Stack, Typography } from 'src/components/ui';
import { PERMISSIONS } from 'src/constants';
import { IStatusHistory } from 'src/models';
import { RolePermissionService } from 'src/services';
import { plural } from 'src/utils';
import { getBidStatusColor } from 'src/utils/bids';

export interface ICircularProgressBarSectionProps {
  onClick: () => void;
  statusHistory?: IStatusHistory;
}

export const CircularProgressBarSection = ({
  onClick,
  statusHistory,
}: ICircularProgressBarSectionProps) => {
  const handleStatusBlockClick = () => {
    if (
      !RolePermissionService.can(
        PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT
      )
    ) {
      return;
    }

    onClick();
  };

  const passedLength = statusHistory?.passedStatuses.length || 0;
  const upcomingLength = statusHistory?.upcomingStatuses.length || 0;
  const listStatusHistoryLength = passedLength + upcomingLength;

  const currentStatusDaysCount = useMemo(() => {
    if (!statusHistory || !statusHistory?.passedStatuses.length) {
      return 0;
    }

    const currentStatus =
      statusHistory.passedStatuses[statusHistory.passedStatuses.length - 1];

    return Math.abs(
      differenceInDays(new Date(currentStatus.createdAt), new Date())
    );
  }, [statusHistory]);

  const currentDotIconColor = useMemo(
    () => getBidStatusColor(currentStatusDaysCount),
    [currentStatusDaysCount]
  );

  return (
    <Card
      sx={{
        display: 'flex',
        padding: '12px',
        gap: '12px',
        alignItems: 'center',
        backgroundColor: '#F3F6FB',
        boxShadow: 'none',
        position: 'relative',
        cursor: RolePermissionService.can(
          PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT
        )
          ? 'pointer'
          : 'default',
      }}
      onClick={handleStatusBlockClick}>
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress
          variant="determinate"
          value={Math.round((passedLength / listStatusHistoryLength) * 100)}
          size="80px"
          className={styles.foreground}
        />
        <CircularProgress
          variant="determinate"
          value={100}
          size="80px"
          className={styles.background}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '4px',
          }}>
          <Typography color="#292929" fontWeight="600">
            {passedLength}
          </Typography>
          <Typography variant="body2" color="#7A8694">
            {`из ${listStatusHistoryLength}`}
          </Typography>
        </Box>
      </Box>
      <Box>
        {RolePermissionService.can(
          PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT
        ) && (
          <Stack direction="row" gap="4px" alignItems="center">
            <DotIcon
              style={{
                color: currentDotIconColor,
              }}
            />
            <Typography color="#7A8694" fontSize="10px">
              В текущем статусе:{' '}
              {plural(currentStatusDaysCount, '$d день', '$d дня', '$d дней')}
            </Typography>
          </Stack>
        )}
        {statusHistory?.passedStatuses && (
          <>
            <Typography color="#0041A0" fontWeight="500">
              {
                statusHistory.passedStatuses[passedLength - 1].status
                  .displayName
              }
            </Typography>
            <Typography variant="body2" color="#7A8694">
              {
                statusHistory.passedStatuses[passedLength - 1].status
                  .description
              }
            </Typography>
          </>
        )}
      </Box>
    </Card>
  );
};
