import { TCommonReason, TReasonKam } from 'src/models/admin/refusal-reason';
import { configContractor, configCustomer, configKam } from './config/columns';

export type TCommonReasonRequest = {
  name: string;
};

export type TKamReasonRequest = TCommonReasonRequest & {
  nameForCustomer: string;
  nameForContractor: string;
  approvalNeeded?: boolean;
  isBlocking?: boolean;
  requiredComment?: boolean;
};

export enum ERefusalReasonType {
  'KAM' = 'KAM',
  'CONTRACTOR' = 'CONTRACTOR',
  'CUSTOMER' = 'CUSTOMER',
  'MANAGER' = 'MANAGER',
  'CUSTOMER_CO' = 'CUSTOMER_CO',
  'CONTRACTOR_REFUSAL' = 'CONTRACTOR_REFUSAL',
  'REQUEST_PROSPECT' = 'REQUEST_PROSPECT',
}

export enum EMode {
  'EmptyMode' = 'EmptyMode',
  'ExtendedMode' = 'ExtendedMode',
  'ConfirmMode' = 'ConfirmMode',
}

export type TRefusalReasonModalProps = {
  type: ERefusalReasonType;
  isOpen: boolean;
  closeModal: () => void;
  data: Array<TCommonReason | TReasonKam>;
};

export type TConfigColumn =
  | typeof configKam
  | typeof configContractor
  | typeof configCustomer;

export type TConfigTab = {
  type: ERefusalReasonType;
  title: string;
  config: TConfigColumn;
};

export type TRefusalWithKey =
  | (TCommonReason & { key: number | string })
  | (TReasonKam & { key: number | string });

export type TFieldForm = {
  name: keyof TKamReasonRequest | keyof TCommonReasonRequest;
  title: string;
};
