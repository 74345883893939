import { lazy, Suspense } from 'react';
import { PageLoader } from 'src/components/ui';

const AdminPaymentTemplates = lazy(
  () =>
    import(
      /* webpackChunkName: 'PaymentTemplates' */
      /* webpackMode: "lazy" */
      `./payment-templates`
    )
);

export const LazyPaymentTemplates = () => (
  <Suspense fallback={<PageLoader />}>
    <AdminPaymentTemplates />
  </Suspense>
);
