import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Хук для отображения модалки при выходе со страницы заявки
// через меню или нашу кнопку назад
export const useConfirmLeavePage = (
  shouldTrigger: boolean
): [boolean, () => void, (value: boolean) => void] => {
  const navigate = useNavigate();

  const { state, pathname } = useLocation();
  const backUrl = useRef(state);

  const [leaveConfirmation, setLeaveConfirmation] = useState(false);

  const [navHref, setNavHref] = useState('');

  const [clearListeners, setClearListeners] = useState(false);

  // Для обработки через кнопку назад
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (shouldTrigger || clearListeners) {
      const headerBackButton = document.getElementById('header-back-button');
      const backNavigationHandler = (event: any) => {
        const button = document.getElementById(
          'take-in-work-contractor-request-button'
        );

        if (button) {
          event.stopPropagation();
          event.preventDefault();
          setLeaveConfirmation(true);
        }
      };

      if (!clearListeners) {
        headerBackButton!.addEventListener('click', backNavigationHandler);
      }

      return () =>
        headerBackButton!.removeEventListener('click', backNavigationHandler);
    }
  }, [clearListeners]);

  // Для обработки ухода со страницы через Navigation Menu
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (shouldTrigger || clearListeners) {
      const topNavigationMenu = document.querySelector(
        'div[class*="header_wrapper"]'
      );

      const allNavigationMenuLinks = topNavigationMenu!.querySelectorAll('a');

      // eslint-disable-next-line func-names
      const linkClickHandler = function (
        this: HTMLAnchorElement,
        event: MouseEvent
      ) {
        const button = document.getElementById(
          'take-in-work-contractor-request-button'
        );
        // eslint-disable-next-line react/no-this-in-sfc
        const ref = this.href;
        const actualRef = ref.split('/')[ref.split('/').length - 1];

        if (button) {
          event.preventDefault();

          if (
            actualRef === 'admin' ||
            /u\d+/.test(actualRef) ||
            ref.split('/').length < 2
          ) {
            setNavHref('/');
          } else {
            setNavHref(`/${actualRef}`);
          }

          setLeaveConfirmation(true);
        }
      };

      if (!clearListeners && shouldTrigger) {
        allNavigationMenuLinks.forEach((navLink) => {
          navLink.addEventListener('click', linkClickHandler);
        });
      }

      return () => {
        allNavigationMenuLinks.forEach((navLink) => {
          navLink.removeEventListener('click', linkClickHandler);
        });
      };
    }
  }, [clearListeners]);

  const handleLeavePage = () => {
    setLeaveConfirmation(false);

    if (navHref) {
      navigate(navHref);
      return;
    }

    if (backUrl.current?.prevUrl) {
      navigate(backUrl.current.prevUrl, { state: { prevUrl: pathname } });
    } else {
      navigate(-1);
    }
  };

  return [leaveConfirmation, handleLeavePage, setClearListeners];
};
