import { Card } from '@mui/material';
import classNames from 'classnames';
import { AuctionInfo } from 'src/components/layout/bids-layout/main-bids-layout/components/auction-info';
import { ContractorAllRequestsStatus } from 'src/components/layout/bids-layout/main-bids-layout/components/contractor-all-requests-status';
import { ContractorRequestStatus } from 'src/components/layout/bids-layout/main-bids-layout/components/contractor-requests-status';
import { ExpectedRequestInfo } from 'src/components/layout/bids-layout/main-bids-layout/components/expected-request-info';
import { RebiddingInfo } from 'src/components/layout/bids-layout/main-bids-layout/components/rebidding-info';
import { RejectedApplicationBlock } from 'src/components/layout/bids-layout/main-bids-layout/components/rejected-application-block';
import { RejectedByCustomerApplicationBlock } from 'src/components/layout/bids-layout/main-bids-layout/components/rejected-by-customer-application-block';
import { RejectedWithApprovalApplicationBlock } from 'src/components/layout/bids-layout/main-bids-layout/components/rejected-with-approval-application-block';
import {
  ButtonsSection,
  CircularProgressBarSection,
  StatusDescriptionSection,
} from 'src/components/layout/bids-layout/main-bids-layout/components/status-history-block/components';
import { Result, Skeleton, Stack } from 'src/components/ui';
import { ManagerCard } from 'src/components/widgets';
import { PERMISSIONS } from 'src/constants';
import {
  BusinessRequestMode,
  ContractorStatusCode,
  IContractorBusinessRequest,
  IContractorRequest,
  IManagerBusinessRequest,
  IStatusHistory,
  TVariantBusinessRequest,
} from 'src/models';
import {
  IBidAssignedManager,
  RefusalApprovalStatuses,
} from 'src/models/bids-details';
import { RolePermissionService } from 'src/services';
import { getErrorMessage } from 'src/store/utils';
import { formatServerDate } from 'src/utils';
import { isExpiredCommercialOfferDueDate } from 'src/utils/business-request';
import styles from './status-history-block.module.scss';

interface IStatusHistoryBlockProps {
  statusHistory?: IStatusHistory;
  isLoading: boolean;
  error: any;
  businessRequest: TVariantBusinessRequest;
  onClick: () => void;
  mode: BusinessRequestMode;
  className?: string;
  managerForCard?: IBidAssignedManager;
}

export const StatusHistoryBlock = ({
  statusHistory,
  isLoading,
  error,
  businessRequest,
  onClick,
  mode,
  className,
  managerForCard,
}: IStatusHistoryBlockProps) => {
  const isRefusalApprovalStatus =
    businessRequest.refusal &&
    'refusalApprovalStatus' in businessRequest.refusal;

  const isRefusalApprovalExpecting =
    isRefusalApprovalStatus &&
    businessRequest!.refusal!.refusalApprovalStatus &&
    businessRequest!.refusal!.refusalApprovalStatus ===
      RefusalApprovalStatuses.EXPECTING_APPROVAL;

  const reverseStatusBlockOrder =
    (!isRefusalApprovalExpecting &&
      mode === BusinessRequestMode.CONTRACTOR_ALL &&
      !(
        businessRequest.contractorRequestId ||
        (businessRequest as IContractorBusinessRequest)?.statusHistory?.status
          ?.code !== 'NEW_REQUEST' ||
        isExpiredCommercialOfferDueDate(
          (businessRequest as IContractorBusinessRequest).commercialOfferDueDate
        )
      )) ||
    (mode === BusinessRequestMode.CONTRACTOR &&
      !(
        businessRequest.status?.code !== ContractorStatusCode.NEW_REQUEST ||
        isExpiredCommercialOfferDueDate(
          (businessRequest as IContractorBusinessRequest).commercialOfferDueDate
        )
      )) ||
    (businessRequest.status &&
      ['IN_PRODUCTION', 'DELIVERED'].includes(businessRequest.status.code));

  if (isLoading) {
    return (
      <Skeleton width="430px" height="300px" sx={{ marginBottom: '24px' }} />
    );
  }

  if (error) {
    return (
      <Result
        noContentPadding
        className={styles.containerError}
        title={getErrorMessage(
          error,
          'Произошла ошибка при получении истории статусов'
        )}
      />
    );
  }

  return (
    <Card className={classNames(styles.wrapper, className)}>
      <Stack
        direction={reverseStatusBlockOrder ? 'column-reverse' : 'column'}
        gap="8px">
        <Stack direction="column">
          <Stack direction="column" gap="8px">
            {mode === BusinessRequestMode.MANAGER && (
              <ExpectedRequestInfo businessRequest={businessRequest} />
            )}

            {(mode === BusinessRequestMode.MANAGER ||
              mode === BusinessRequestMode.CONTRACTOR) && (
              <>
                <AuctionInfo
                  businessRequest={
                    businessRequest as
                      | IManagerBusinessRequest
                      | IContractorRequest
                  }
                />
                <RebiddingInfo
                  businessRequest={
                    businessRequest as
                      | IManagerBusinessRequest
                      | IContractorRequest
                  }
                />
              </>
            )}

            {mode === BusinessRequestMode.CONTRACTOR && statusHistory && (
              <ContractorRequestStatus
                contractorRequest={businessRequest as IContractorRequest}
                statusHistory={statusHistory}
              />
            )}

            {mode === BusinessRequestMode.CONTRACTOR_ALL && (
              <ContractorAllRequestsStatus
                contractorRequest={
                  businessRequest as IContractorBusinessRequest
                }
              />
            )}

            {mode !== BusinessRequestMode.CONTRACTOR_ALL &&
              statusHistory &&
              !isRefusalApprovalExpecting && (
                <>
                  <RejectedApplicationBlock
                    businessRequest={businessRequest}
                    statusHistory={statusHistory}
                    contractorMode={mode === BusinessRequestMode.CONTRACTOR}
                    showRestoreButton={
                      mode === BusinessRequestMode.MANAGER ||
                      mode === BusinessRequestMode.CONTRACTOR
                    }
                  />
                  <RejectedByCustomerApplicationBlock
                    businessRequest={businessRequest}
                    mode={mode}
                  />
                </>
              )}

            {mode !== BusinessRequestMode.CONTRACTOR_ALL &&
              statusHistory &&
              isRefusalApprovalExpecting && (
                <RejectedWithApprovalApplicationBlock
                  businessRequest={businessRequest}
                  businessRequestId={businessRequest.businessRequestId}
                  applicationId={businessRequest.businessApplicationId}
                />
              )}
            <CircularProgressBarSection
              onClick={onClick}
              statusHistory={statusHistory}
            />
          </Stack>

          {/* временно убрано по решению аналитики */}
          {/* {(AuthService.isSpecificRole() || isManager() || isAdmin()) && ( */}
          {/*   <LogisticsInvocationInfoBlock */}
          {/*     businessRequest={businessRequest as IManagerBusinessRequest} */}
          {/*   /> */}
          {/* )} */}

          <Stack gap="12px" sx={{ marginTop: '12px' }}>
            {RolePermissionService.can(PERMISSIONS.VIEW_MANAGER_CARD) &&
              managerForCard?.substitute && (
                <ManagerCard
                  manager={managerForCard.substitute}
                  position="Замещающий ответственный менеджер"
                  compact
                />
              )}

            {RolePermissionService.can(PERMISSIONS.VIEW_MANAGER_CARD) &&
              managerForCard && (
                <ManagerCard
                  manager={managerForCard}
                  disabled={!!managerForCard.substitute}
                  compact
                  vacationInfo={
                    managerForCard.substitute
                      ? `Отсутствует с ${formatServerDate(
                          managerForCard.vacationStartDate,
                          'dd.MM.yyyy'
                        )} по ${formatServerDate(
                          managerForCard.vacationEndDate,
                          'dd.MM.yyyy'
                        )}`
                      : undefined
                  }
                />
              )}
          </Stack>
          {RolePermissionService.can(
            PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD
          ) && (
            <StatusDescriptionSection
              businessRequest={businessRequest}
              isLoading={isLoading}
            />
          )}
        </Stack>
        <ButtonsSection businessRequest={businessRequest} mode={mode} />
      </Stack>
    </Card>
  );
};
