import classNames from 'classnames';
import { useFormContext } from 'react-hook-form';
import { TrashDeleteTableItemIcon } from 'src/assets/icons';
import {
  Box,
  Form,
  IconButton,
  MenuItem,
  NumberInput,
  OutlinedInput,
  Select,
  Stack,
  Typography,
} from 'src/components/ui';
import { PaymentType } from 'src/models';
import { maxNumber, minNumber, number, required } from 'src/utils/form-rules';
import {
  DAY_TYPES,
  PAYMENT_MOMENTS,
  PAYMENT_MOMENTS_BY_TYPE,
  PAYMENT_TYPES,
} from '../../payment-conditions.const';
import styles from '../../styles.module.scss';
import { buttonStyles } from './payment-condition.const';
import { IPaymentConditionProps } from './payment-condition.types';

export const AddPaymentCondition = (props: IPaymentConditionProps) => {
  const { index = 0, maxPercentage = 100, view } = props;

  const { getValues } = useFormContext();
  if (view === 'line') {
    return (
      <>
        <Box
          display="flex"
          className={classNames(styles.line, styles.inner)}
          gap="12px"
          marginBottom="0"
          alignItems="flex-start">
          <Box className={styles.item}>
            <Form.Item
              rules={{
                required,
              }}
              name={`metadata.${index}.paymentType`}
              label="Тип оплаты"
              className={styles.formInput}>
              <Select
                placeholder="Выберите тип оплаты"
                displayEmpty
                renderValue={() => {
                  const selectedPaymentType = getValues(
                    `metadata.${index}.paymentType`
                  );

                  const selectedItem = selectedPaymentType
                    ? PAYMENT_TYPES.find(
                        (type) => type.value === selectedPaymentType
                      )
                    : '';

                  if (!selectedItem) {
                    return '';
                  }

                  return (
                    <Stack direction="row" gap="2px">
                      <Typography color="#7a8694">{`${index + 1}.`}</Typography>
                      <Typography>{`${selectedItem.label}`}</Typography>
                    </Stack>
                  );
                }}>
                {PAYMENT_TYPES.map((pt) => (
                  <MenuItem key={pt.value} value={pt.value}>
                    {pt.label}
                  </MenuItem>
                ))}
              </Select>
            </Form.Item>
          </Box>

          <Box
            width="120px"
            className={classNames(styles.item, styles.hasWidth)}>
            <Form.Item
              rules={{
                required,
                validate: {
                  number,
                  maxNumber: (value) =>
                    maxNumber(
                      maxPercentage,
                      `% не может быть выше ${maxPercentage}`
                    )(value),
                  minNumber: (value) =>
                    minNumber(1, `% не может быть меньше 1`)(value),
                },
              }}
              label="%, оплаты"
              name={`metadata.${index}.paymentPercentage`}
              className={styles.formInput}>
              <NumberInput placeholder="%, оплаты" />
            </Form.Item>
          </Box>

          <Box
            display="flex"
            gap="12px"
            alignItems="flex-start"
            width="120px"
            className={classNames(styles.item, styles.hasWidth)}>
            <Form.Item
              rules={{
                required,
                validate: {
                  number,
                  maxNumber: (value) =>
                    maxNumber(365, `Дней не может быть больше 365`)(value),
                  minNumber: (value) =>
                    minNumber(1, `Дней не может быть меньше 1`)(value),
                },
              }}
              name={`metadata.${index}.paymentInDays`}
              label="В течении (д)"
              className={styles.formInput}>
              <OutlinedInput placeholder="В течении (д)" />
            </Form.Item>
          </Box>

          {!!props?.handleRemovePaymentCondition && (
            <Box
              width="44px"
              alignSelf="flex-start"
              className={classNames(styles.item, styles.hasWidth)}>
              <IconButton
                color="secondary"
                onClick={() => {
                  props!.handleRemovePaymentCondition(index);
                }}
                style={{
                  marginTop: '24px',
                }}>
                <Box {...buttonStyles}>
                  <TrashDeleteTableItemIcon width={18} height={18} />
                </Box>
              </IconButton>
            </Box>
          )}
        </Box>
        <Box
          display="flex"
          className={styles.line}
          gap="12px"
          alignItems="flex-start">
          <Box
            display="flex"
            gap="12px"
            alignItems="flex-start"
            className={styles.item}>
            <Form.Item
              name={`metadata.${index}.dayType`}
              rules={{
                required,
              }}
              label="Тип срока оплаты"
              className={styles.formInput}>
              <Select
                placeholder="Тип срока оплаты"
                displayEmpty
                autoWidth={false}
                fullWidth>
                {DAY_TYPES.map((dt) => (
                  <MenuItem key={dt.value} value={dt.value}>
                    {dt.label}
                  </MenuItem>
                ))}
              </Select>
            </Form.Item>
          </Box>

          <Box className={styles.item} style={{ flexShrink: 1 }}>
            <Form.Item
              rules={{
                required,
              }}
              name={`metadata.${index}.paymentMoment`}
              label="Момент оплаты"
              className={styles.formInput}>
              <Select
                placeholder="Момент оплаты"
                autoWidth={false}
                fullWidth
                displayEmpty
                disabled={!getValues(`metadata.${index}.paymentType`)}>
                {(getValues(`metadata.${index}`).paymentType
                  ? PAYMENT_MOMENTS_BY_TYPE[
                      getValues(`metadata.${index}`).paymentType as PaymentType
                    ]
                  : PAYMENT_MOMENTS
                ).map((pm) => (
                  <MenuItem key={pm.value} value={pm.value}>
                    {pm.label}
                  </MenuItem>
                ))}
              </Select>
            </Form.Item>
          </Box>
        </Box>
      </>
    );
  }

  return (
    <Box
      display="flex"
      className={styles.row}
      gap="12px"
      alignItems="flex-start">
      <Box width="330px" className={classNames(styles.item, styles.hasWidth)}>
        <Form.Item
          rules={{
            required,
          }}
          name={`metadata.${index}.paymentType`}
          label="Тип оплаты"
          className={styles.formInput}>
          <Select
            placeholder="Выберите тип оплаты"
            displayEmpty
            renderValue={() => {
              const selectedPaymentType = getValues(
                `metadata.${index}.paymentType`
              );

              const selectedItem = selectedPaymentType
                ? PAYMENT_TYPES.find(
                    (type) => type.value === selectedPaymentType
                  )
                : '';

              if (!selectedItem) {
                return '';
              }

              return (
                <Stack direction="row" gap="2px">
                  <Typography color="#7a8694">{`${index + 1}.`}</Typography>
                  <Typography>{`${selectedItem.label}`}</Typography>
                </Stack>
              );
            }}>
            {PAYMENT_TYPES.map((pt) => (
              <MenuItem key={pt.value} value={pt.value}>
                {pt.label}
              </MenuItem>
            ))}
          </Select>
        </Form.Item>
      </Box>
      <Box width="150px" className={classNames(styles.item, styles.hasWidth)}>
        <Form.Item
          rules={{
            required,
            validate: {
              number,
              maxNumber: (value) =>
                maxNumber(
                  maxPercentage,
                  `% не может быть выше ${maxPercentage}`
                )(value),
              minNumber: (value) =>
                minNumber(1, `% не может быть меньше 1`)(value),
            },
          }}
          label="%, оплаты"
          name={`metadata.${index}.paymentPercentage`}
          className={styles.formInput}>
          <NumberInput placeholder="%, оплаты" />
        </Form.Item>
      </Box>

      <Box
        display="flex"
        gap="12px"
        alignItems="flex-start"
        width="150px"
        className={classNames(styles.item, styles.hasWidth)}>
        <Form.Item
          rules={{
            required,
            validate: {
              number,
              maxNumber: (value) =>
                maxNumber(365, `Дней не может быть больше 365`)(value),
              minNumber: (value) =>
                minNumber(1, `Дней не может быть меньше 1`)(value),
            },
          }}
          name={`metadata.${index}.paymentInDays`}
          label="В течении (д)"
          className={styles.formInput}>
          <OutlinedInput placeholder="В течении (д)" />
        </Form.Item>
      </Box>

      <Box
        display="flex"
        gap="12px"
        alignItems="flex-start"
        width="212px"
        className={classNames(styles.item, styles.hasWidth)}>
        <Form.Item
          name={`metadata.${index}.dayType`}
          rules={{
            required,
          }}
          label="Тип срока оплаты"
          className={styles.formInput}>
          <Select
            placeholder="Тип срока оплаты"
            displayEmpty
            autoWidth={false}
            fullWidth>
            {DAY_TYPES.map((dt) => (
              <MenuItem key={dt.value} value={dt.value}>
                {dt.label}
              </MenuItem>
            ))}
          </Select>
        </Form.Item>
      </Box>

      <Box
        className={styles.item}
        sx={{ flexGrow: 1 }}
        style={{ flexShrink: 1 }}>
        <Form.Item
          rules={{
            required,
          }}
          name={`metadata.${index}.paymentMoment`}
          label="Момент оплаты"
          className={styles.formInput}>
          <Select
            placeholder="Момент оплаты"
            autoWidth={false}
            fullWidth
            displayEmpty
            disabled={!getValues(`metadata.${index}.paymentType`)}>
            {(getValues(`metadata.${index}`).paymentType
              ? PAYMENT_MOMENTS_BY_TYPE[
                  getValues(`metadata.${index}`).paymentType as PaymentType
                ]
              : PAYMENT_MOMENTS
            ).map((pm) => (
              <MenuItem key={pm.value} value={pm.value}>
                {pm.label}
              </MenuItem>
            ))}
          </Select>
        </Form.Item>
      </Box>

      {!!props?.handleRemovePaymentCondition && (
        <Box
          width="44px"
          alignSelf="flex-start"
          className={classNames(styles.item, styles.hasWidth)}>
          <IconButton
            color="secondary"
            onClick={() => {
              props!.handleRemovePaymentCondition(index);
            }}
            style={{
              marginTop: '24px',
            }}>
            <Box {...buttonStyles}>
              <TrashDeleteTableItemIcon width={18} height={18} />
            </Box>
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
