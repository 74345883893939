import { IPaymentConditionProps } from './payment-condition.types';
import { EditPaymentCondition } from './edit';
import { PaymentConditionDefault } from './default';
import { PaymentConditionView } from './view';
import { AddPaymentCondition } from './add';

export const PaymentCondition = (props: IPaymentConditionProps) => {
  const {
    isEditPaymentCondition,
    isEditModeEnabled,
    mode = 'view',
    view = 'row',
    isCustomerRequestPage = false,
  } = props;

  const renderContent = () => {
    if (mode === 'view') {
      return <PaymentConditionView {...props} />;
    }
    return <PaymentConditionDefault {...props} />;
  };

  const renderEditMode = () => {
    if (mode === 'create') {
      return <AddPaymentCondition {...props} />;
    }
    return <EditPaymentCondition {...props} />;
  };

  return (isEditPaymentCondition && isEditModeEnabled) ||
    (mode === 'edit' && isEditModeEnabled)
    ? renderEditMode()
    : renderContent();
};
