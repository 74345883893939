import { TMenuItem } from 'src/models/menu';

export const adminMenuItems: TMenuItem[] = [
  {
    label: 'Заявки',
    path: '/requests?statusGroup=ACTIVE',
  },
  {
    label: 'Подключение исполнителей',
    path: '/contractors-signing',
  },
  {
    label: 'Отгрузки',
    path: '/shipments',
  },
  {
    label: 'Оплаты',
    path: '/payments',
  },
  {
    label: 'Пользователи',
    path: '/users',
  },
  {
    label: 'Компании',
    path: '/companies',
    getSearchParamsHandler: (
      pathname: string,
      searchParams: URLSearchParams
    ) => {
      if (pathname === '/companies') {
        return `?${searchParams.toString()}`;
      }

      return '';
    },
    children: [
      {
        label: 'Исполнители',
        path: '/companies/contractors?contractorStatus=SIGNED',
      },
      {
        label: 'Заказчики',
        path: '/companies/customers',
      },
    ],
  },
  {
    label: 'Новости',
    path: '/news',
  },
  {
    label: 'Коммуникации',
    path: '/communications/all',
  },
  {
    label: 'Настройки системы',
    path: '/system-settings/queues',
  },
];
