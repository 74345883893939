import { FC, forwardRef, SVGProps } from 'react';
import { Box } from '@mui/material';
import { deepFind } from 'src/utils/deep-find';

// https://stackoverflow.com/a/44870045
const hexToRgb = (hexInput: string, alpha?: number) => {
  const hex = hexInput.replace('#', '');
  const r = parseInt(
    hex.length === 3 ? hex.slice(0, 1).repeat(2) : hex.slice(0, 2),
    16
  );
  const g = parseInt(
    hex.length === 3 ? hex.slice(1, 2).repeat(2) : hex.slice(2, 4),
    16
  );
  const b = parseInt(
    hex.length === 3 ? hex.slice(2, 3).repeat(2) : hex.slice(4, 6),
    16
  );
  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  return `rgb(${r}, ${g}, ${b})`;
};

type TSvgIconProps = SVGProps<SVGSVGElement> & {
  icon: FC<SVGProps<SVGSVGElement>>;
  strokeWidth?: string;
  iconPadding?: string;
  fillHexColor?: string;
  borderRadius?: string;
} & (
    | {
        strokeHexColor?: string;
        strokeColor?: never;
        backgroundFill?: boolean | string;
      }
    | {
        strokeHexColor?: never;
        strokeColor?: string;
        backgroundFill?: false | string;
      }
  );

export const SvgIcon = forwardRef<SVGSVGElement, TSvgIconProps>(
  (
    {
      width = '24',
      height = '24',
      icon: Svg,
      fillHexColor,
      strokeWidth,
      iconPadding = '5px',
      strokeHexColor,
      strokeColor,
      backgroundFill,
      borderRadius = '50%',
      ...props
    },
    ref
  ) => {
    const backgroundStyle = backgroundFill
      ? {
          padding: iconPadding,
          borderRadius,
          backgroundColor:
            backgroundFill === true
              ? strokeHexColor && hexToRgb(strokeHexColor, 0.15)
              : backgroundFill,
        }
      : undefined;

    return (
      <Box
        sx={[
          {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...backgroundStyle,
          },
          (theme) => ({
            '& svg path': {
              stroke:
                (strokeColor &&
                  (strokeColor.includes('.')
                    ? deepFind(theme.palette, strokeColor)
                    : strokeColor)) ||
                strokeHexColor,
              strokeWidth,
              fill: fillHexColor ? hexToRgb(fillHexColor) : undefined,
            },
          }),
        ]}>
        <Svg ref={ref} width={width} height={height} {...props} />
      </Box>
    );
  }
);
