import { FC, useEffect, useRef, useState } from 'react';
import { FixedHeader } from 'src/components/ui';
import { TPhoneInputValue } from 'src/components/ui/phone-input';
import {
  BusinessRequestMode,
  IStatusHistory,
  TVariantBusinessRequest,
} from 'src/models';
import { NameRequest, RequestNumber, Tabs } from './components';

import styles from './header.module.scss';

interface IHeaderProps {
  businessRequest: TVariantBusinessRequest;
  statusHistory: IStatusHistory | undefined;
  mode: BusinessRequestMode;
  manager?: {
    id: number;
    login: string;
    lastName: string;
    firstName: string;
    middleName: string;
    position: string;
    contactInfoDto: {
      phones: TPhoneInputValue[];
    };
  };
  onClick: () => void;
  isVacationAlert?: boolean;
}

export const Header: FC<IHeaderProps> = ({
  businessRequest,
  statusHistory,
  mode,
  manager,
  onClick,
  isVacationAlert,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [reqInfoWidth, setReqInfoWidth] = useState('');

  useEffect(() => {
    const width = containerRef?.current?.offsetWidth
      ? containerRef.current.offsetWidth + 10
      : 10;

    setReqInfoWidth(`calc(100% - ${width}px)`);
  }, [setReqInfoWidth]);

  return (
    <>
      <FixedHeader
        onClick={onClick}
        className={styles.headerWrapper}
        wrapperClassName={styles.headerOuterWrapper}
        compactPadding
        isVacationAlert={!!isVacationAlert}>
        <div className={styles.header}>
          <div
            className={styles.requestInfo}
            style={{ maxWidth: reqInfoWidth }}>
            <RequestNumber
              businessRequest={businessRequest}
              manager={manager}
            />
            <NameRequest businessRequest={businessRequest} />
          </div>
        </div>
      </FixedHeader>

      <Tabs
        mode={mode}
        statusHistory={statusHistory}
        businessRequest={businessRequest}
      />
    </>
  );
};
