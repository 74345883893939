import { FC, ReactElement, useEffect, useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
  initAuth,
  initGuestAuth,
  logout as logoutAction,
  selectIsAuth,
  selectIsGuestAuth,
} from 'src/store/auth';
import { AuthService, RolePermissionService } from 'src/services';
import { useLazyGetCurrentUserQuery } from 'src/api/user';
import { useLogoutMutation, useLoginAsGuestMutation } from 'src/api/auth';
import { PERMISSIONS } from 'src/constants';
import { Button, IncorrectScreenModal, Link, Result } from 'src/components/ui';
import { getErrorMessage } from 'src/store/utils';
import { ADMIN_BASENAME } from 'src/configs';
import { notificationsSocket, socket } from 'src/utils';
import queryString from 'query-string';
import { useLogout, useMouseActivityMonitor } from 'src/hooks';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme } from '@mui/material';

const parseJsonQuery = (str: string) => {
  try {
    return JSON.parse(str);
  } catch (err) {
    //
  }

  return null;
};

export const Init: FC<{ children: ReactElement }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(selectIsAuth);
  const isGuestAuth = useAppSelector(selectIsGuestAuth);

  useMouseActivityMonitor();

  const searchParams = queryString.parse(window.location.search);

  const fromPathRef = useRef(parseJsonQuery(searchParams.from as string));

  const [isReady, setIsReady] = useState<boolean>(false);

  const [getCurrentUser, { error: userProfileError }] =
    useLazyGetCurrentUserQuery();
  const [loginAsGuest, { error: guestAuthError }] = useLoginAsGuestMutation();
  const logout = useLogout();

  useEffect(() => {
    const { pathname } = window.location;
    if (pathname !== '/' && pathname.endsWith('/')) {
      window.history.replaceState({}, '', pathname.replace(/\/+$/, ''));
    }
  }, []);

  useEffect(() => {
    // При старте приложения (isGuestAuth === undefined) инициируем состояние
    // При завершении гостевого сеанса (isGuestAuth === false) необходимо обновить роль
    if (!isGuestAuth) {
      dispatch(
        initAuth({
          isAuth: AuthService.isAuth,
          currentRole: AuthService.currentRole,
        })
      );
      const isAdmin = RolePermissionService.can(PERMISSIONS.VIEW_ADMIN_PANEL);
      const path =
        fromPathRef.current?.path ||
        (isAdmin ? `${ADMIN_BASENAME}/users` : '/');
      const query = fromPathRef.current?.query || undefined;

      const url = queryString.stringifyUrl({ url: path, query });

      if (isGuestAuth === false) {
        window.history.pushState({}, '', url);
      }
    }
  }, [dispatch, isGuestAuth]);

  useEffect(() => {
    if (isAuth !== undefined && !isGuestAuth) {
      const { pathname } = window.location;

      if (isAuth) {
        const guestId = Number(pathname.match(/^\/u(\d+)/)?.[1]);

        if (
          isGuestAuth === undefined &&
          guestId &&
          guestId !== AuthService.userId &&
          RolePermissionService.can(PERMISSIONS.LOGIN_AS_GUEST)
        ) {
          const getTokenPromise = AuthService.getGuestAccessToken(guestId)
            ? Promise.resolve({
                guestToken: AuthService.getGuestAccessToken(guestId),
              })
            : loginAsGuest({ onBehalfUserId: guestId }).unwrap();

          getTokenPromise
            .then((result) => {
              AuthService.loginAsGuest(guestId, result.guestToken!);
              dispatch(
                initGuestAuth({
                  isGuestAuth: AuthService.isGuestAuth,
                  currentRole: AuthService.currentRole,
                })
              );
            })
            .then(() => getCurrentUser().unwrap())
            .catch(() => {})
            .finally(() => {
              setIsReady(true);
            });
        } else {
          if (
            RolePermissionService.can(PERMISSIONS.VIEW_ADMIN_PANEL) &&
            !pathname.startsWith(ADMIN_BASENAME)
          ) {
            window.history.replaceState({}, '', `${ADMIN_BASENAME}${pathname}`);
          }

          getCurrentUser()
            .unwrap()
            .catch(() => {})
            .finally(() => {
              setIsReady(true);
            });
        }
      } else {
        setIsReady(false);
      }
    }
  }, [dispatch, getCurrentUser, isAuth, isGuestAuth, loginAsGuest]);

  const isUpLg = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const isDownXxl = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xxl')
  );

  if (isAuth !== false && !isReady) {
    return null;
  }

  if (guestAuthError) {
    return (
      <Result
        title={getErrorMessage(
          guestAuthError,
          'Произошла ошибка при старте гостевого сеанса'
        )}
      />
    );
  }

  if (isAuth && userProfileError) {
    const handleLogout = () => {
      logout();
    };

    return (
      <Result
        title={getErrorMessage(
          userProfileError,
          'Произошла ошибка при загрузке данных пользователя'
        )}
        subtitle={
          <>
            {`Попробуйте `}
            <Button variant="inlineText" onClick={handleLogout}>
              выйти
            </Button>
            {` и зайти позже или напишите письмо в тех.поддержку: `}
            <Link href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}>
              {process.env.REACT_APP_SUPPORT_EMAIL}
            </Link>
          </>
        }
      />
    );
  }

  if (
    isUpLg &&
    isDownXxl &&
    !localStorage.getItem('disable_small_screen_banner')
  ) {
    return <IncorrectScreenModal />;
  }

  return children;
};
