import { ReactNode, useState } from 'react';
import classNames from 'classnames';
import { TFile } from 'src/components/ui/file-upload';
import {
  Box,
  Button,
  Grid,
  Typography,
  SvgIcon,
  Stack,
} from 'src/components/ui';
import { DownloadAllArrow, DownloadArrow } from 'src/assets/icons';
import saveAs from 'file-saver';
import { getErrorMessage } from 'src/store/utils';
import { businessRequestApi } from 'src/api';
import { useAppDispatch } from 'src/store';
import { AuthService } from 'src/services';
import { useCleanDocumentMutation } from 'src/api/document';
import { EmptyFileList, FileItem } from './components';
import styles from './file-list-cleaned.module.scss';
import { FileItemPreview } from './components/file-item-preview';
import { prepareFiles } from './utils';

export interface IFileListProps {
  value?: TFile[];
  onChange?: (files: TFile[]) => void;
  onDownload?: (fileId: number) => void;
  onRemove?: (fileId: number) => void;
  removeMode?: 'default' | 'soft';
  removeConfirmText?: string;
  className?: string;
  title?: ReactNode;
  subtitle?: ReactNode;
  actions?: ReactNode;
  onDownloadAll?: () => void;
  emptyText?: string;
  showDownloadButton?: boolean;
  useEmptyComponent?: boolean;
  disableDelete?: boolean;
  imagesPreview?: boolean;
  variant?: 'default' | 'compact';
  showCleanButton?: boolean;
  onPreview?: (fileId: number) => void;
}

const variantConfig = {
  default: {
    variant: 'h3',
    lineHeight: undefined,
    marginBottom: '19px',
  },
  compact: {
    variant: 'body2',
    lineHeight: '20px',
    marginBottom: undefined,
  },
} as const;

// TODO: Add key for map

export const FileListCleaned = ({
  value,
  onChange,
  onDownload,
  onRemove,
  removeMode = 'default',
  removeConfirmText,
  className,
  title,
  subtitle,
  actions,
  onDownloadAll,
  emptyText = 'Файлы не загружены',
  showDownloadButton = true,
  useEmptyComponent = false,
  disableDelete = false,
  imagesPreview = false,
  variant = 'default',
  showCleanButton,
  onPreview,
}: IFileListProps) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onFileChange = (file: TFile) => {
    if (!value) {
      return;
    }

    if (file.isNew) {
      onChange?.(value.filter((item) => item.id !== file.id || !file.deleted));
    } else {
      onChange?.(value.map((item) => (item.id === file.id ? file : item)));
    }
  };

  const handleDownloadAll = async () => {
    if (!onDownloadAll) return;

    setIsLoading(true);
    await onDownloadAll();
    setIsLoading(false);
  };

  const getBottomOffset = () => {
    if (variant === 'default' && !title && !actions && !onDownloadAll) {
      return undefined;
    }

    return variantConfig[variant].marginBottom;
  };

  const handleDownload = async (fileItem: TFile) => {
    if (fileItem.isNew) {
      saveAs(fileItem.file);
    } else {
      await onDownload?.(fileItem.id);
    }
  };

  const handleRemove = async (fileItem: TFile) => {
    if (removeMode !== 'soft' && !fileItem.isNew) {
      if (!onRemove) {
        return;
      }

      try {
        await onRemove(fileItem.id);
      } catch (e) {
        onFileChange?.({ ...fileItem, error: getErrorMessage(e) });
        return;
      }
    }

    onFileChange?.({ ...fileItem, deleted: true });
  };

  const handlePreview = async (fileItem: TFile) => {
    await onPreview?.(fileItem.id);
  };

  const cleanedFiles = value
    ?.filter((item) => item.clenead && !item.deleted)
    ?.sort((a, b) => a.name.localeCompare(b.name));

  const uncleanedFiles = value
    ?.filter((item) => !item.clenead && !item.deleted)
    ?.sort((a, b) => a.name.localeCompare(b.name));

  const { left: processedUncleaned, right: processedCleaned } = prepareFiles(
    uncleanedFiles || [],
    cleanedFiles || []
  );

  const filesCount = value?.filter((item) => !item.deleted)?.length ?? 0;

  const [cleanDocument] = useCleanDocumentMutation();

  const [isProcessAllLoading, setIsProcessAllLoading] = useState(false);

  const handleProcessAll = () => {
    try {
      if (!uncleanedFiles?.length) {
        return;
      }

      setIsProcessAllLoading(true);

      const promises: Array<Promise<any>> = [];

      uncleanedFiles.forEach((file: TFile) => {
        promises.push(
          cleanDocument({
            fileId: file.id,
            entityType: file.entityType!,
            documentType: file.documentType!,
            entityId: file.entityId!,
            token: AuthService.accessToken!,
            'Current-Role': AuthService.parentCurrentRole,
          })
            .unwrap()
            .catch((error) => {
              console.error(error);
            })
        );
      });

      Promise.allSettled(promises).finally(() => {
        uncleanedFiles.forEach((file: TFile) => {
          dispatch(
            businessRequestApi.util.invalidateTags([
              {
                type: 'getManagerRequest',
                id: file.entityId,
              },
            ])
          );
        });

        setIsProcessAllLoading(false);
      });
    } catch (e) {
      console.log(e);
    }
  };

  const renderSection = (files: TFile[]) => (
    <Box width="calc(50% - 10px)">
      {files.length > 0 ? (
        <Grid container spacing={imagesPreview ? 2 : 0}>
          {files.map((file) =>
            imagesPreview ? (
              <Grid item xs={4} key={file.id}>
                <FileItemPreview
                  file={file}
                  onDownload={handleDownload}
                  onRemove={handleRemove}
                  removeMode={removeMode}
                  onChange={onFileChange}
                  removeConfirmText={removeConfirmText}
                  showDownloadButton={showDownloadButton}
                  disabledDeleteButton={disableDelete}
                  canRemove={!!onRemove}
                />
              </Grid>
            ) : (
              <Grid item xs={12} key={file.id}>
                <FileItem
                  file={file}
                  onDownload={handleDownload}
                  onRemove={handleRemove}
                  removeMode={removeMode}
                  onChange={onFileChange}
                  removeConfirmText={removeConfirmText}
                  showDownloadButton={showDownloadButton}
                  disabledDeleteButton={disableDelete}
                  canRemove={!!onRemove}
                  showCleanButton={showCleanButton}
                  onPreview={onPreview ? handlePreview : undefined}
                />
              </Grid>
            )
          )}
        </Grid>
      ) : (
        <Typography variant="h4">{emptyText}</Typography>
      )}
    </Box>
  );

  return (
    <div className={classNames(styles.fileList, className)}>
      <Typography
        variant={variantConfig[variant].variant}
        lineHeight={variantConfig[variant].lineHeight}
        mb="12px">
        {title}
      </Typography>

      <Box mb="12px">
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="600">Всего файлов: {filesCount}</Typography>
          <Box display="flex" gap="12px" alignItems="center">
            {actions}

            {onDownloadAll && value && value.length > 1 && (
              <Button
                aria-label="download"
                title="Скачать все"
                variant="text"
                loading={isLoading}
                onClick={handleDownloadAll}>
                <Typography color="#7A8694">Скачать все</Typography>
              </Button>
            )}

            {!!uncleanedFiles?.length && (
              <Button
                aria-label="process all docs"
                title="Обработать все"
                variant="text"
                loading={isProcessAllLoading}
                onClick={handleProcessAll}>
                <Typography color="#7A8694">Обработать все</Typography>
              </Button>
            )}
          </Box>
        </Stack>
      </Box>

      {subtitle}

      {value?.length && value.some((elem) => !elem.deleted) ? (
        <Stack>
          <Stack direction="row" gap="20px">
            <Typography fontSize="16px" fontWeight="500" flexBasis="50%">
              Необработанные
            </Typography>
            <Typography fontSize="16px" fontWeight="500" flexBasis="50%">
              Обработанные
            </Typography>
          </Stack>

          <Stack direction="row" gap="20px" className={styles.sectionWrapper}>
            {renderSection(processedUncleaned || [])}
            {renderSection(processedCleaned || [])}
          </Stack>
        </Stack>
      ) : (
        <div>
          {useEmptyComponent ? (
            <EmptyFileList text={emptyText} />
          ) : (
            <Typography variant="h4">{emptyText}</Typography>
          )}
        </div>
      )}
    </div>
  );
};
