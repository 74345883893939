import { Stack } from '@mui/material';
import classNames from 'classnames';
import { useMemo } from 'react';
import { Pencil } from 'src/assets/icons';
import { IconButton, Typography } from 'src/components/ui';
import { ROLES } from 'src/constants';
import { TVariantBusinessRequest } from 'src/models';
import { AuthService } from 'src/services';
import { formatServerDate, joinFullName } from 'src/utils';
import styles from './request-kam.module.scss';

export interface IRequestKamProps {
  businessRequest: TVariantBusinessRequest;
  onEditButtonClick: () => void;
}

export const RequestKam = ({
  businessRequest,
  onEditButtonClick,
}: IRequestKamProps) => {
  const isShowEditManagerForRequest = !businessRequest.orderProcessingStarted;

  const isUserAdmin = AuthService!.currentRole === ROLES.ADMIN;
  const isUserKAMOrKamPlus =
    AuthService!.currentRole === ROLES.KAM ||
    AuthService!.currentRole === ROLES.KAM_PLUS;

  const isAssignedKAMUserSpecificRole =
    AuthService!.isSpecificRole() &&
    businessRequest.assignedManagers?.kam?.id === AuthService.userId;

  const substituteManager = businessRequest.assignedManagers?.kam?.substitute;

  const isUserCanChangeKAM =
    isAssignedKAMUserSpecificRole || isUserAdmin || isUserKAMOrKamPlus;

  const nameKam = useMemo(
    () =>
      joinFullName(
        businessRequest.assignedManagers?.kam?.lastName,
        businessRequest.assignedManagers?.kam?.firstName,
        businessRequest.assignedManagers?.kam?.middleName
      ),
    [businessRequest]
  );

  const editButtonDisabled =
    !isShowEditManagerForRequest ||
    !isUserCanChangeKAM ||
    !!businessRequest.assignedManagers?.kam?.substitute;

  return (
    <Stack direction="column" gap="10px">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={classNames(!!substituteManager && styles.managerDisabled)}>
        <Stack className={styles.managerTitle} direction="column" gap="4px">
          <Typography variant="body2" color="#7A8694">
            Менеджер по заявке:
          </Typography>
          <Typography color="#292929">{nameKam}</Typography>
        </Stack>
        <Stack direction="row" gap="4px">
          <IconButton
            className={classNames(
              styles.actionButton,
              editButtonDisabled && styles.actionButtonDisabled
            )}
            disabled={editButtonDisabled}
            onClick={onEditButtonClick}>
            <Pencil
              sx={{
                color:
                  !isShowEditManagerForRequest || !isUserCanChangeKAM
                    ? '#7A8694'
                    : '#292929',
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
      {substituteManager && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Stack direction="column" gap="4px">
            <Typography fontSize="12px" lineHeight="12px" color="#7A8694">
              Замещающий менеджер по заявке:
            </Typography>
            <Typography color="#292929">
              {joinFullName(
                substituteManager.lastName,
                substituteManager.firstName,
                substituteManager.middleName
              )}
            </Typography>
            {'\n'}
            <Typography>
              с{' '}
              {formatServerDate(
                businessRequest.assignedManagers?.kam?.vacationStartDate,
                'dd.MM.yyyy'
              )}{' '}
              по{' '}
              {formatServerDate(
                businessRequest.assignedManagers?.kam?.vacationEndDate,
                'dd.MM.yyyy'
              )}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
