import classNames from 'classnames';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  ChevronRight,
  RoundedChevronDown,
  RoundedChevronRight,
} from 'src/assets/icons';
import { Box, Button, Typography, Stack } from 'src/components/ui';
import { TFilterItem, TFilterValue } from 'src/components/ui/new-filters/types';
import styles from './block-filter.module.scss';

type TProps<FilterKeys extends string = string> = {
  filterValue?: TFilterValue<FilterKeys>;
  config: TFilterItem<FilterKeys>;
  onFilter: (selectedFilter: TFilterValue<FilterKeys>) => void;
  error?: string;
  showFilter?: boolean;
};

export const BlockFilter = <FilterKeys extends string = string>({
  config,
  filterValue,
  onFilter,
  error,
  showFilter = true,
}: TProps<FilterKeys>) => {
  const [showAllVariantsList, setShowAllVariantsList] = useState(false);

  const Component = config.render({
    filter: filterValue,
    updateFilterValue: onFilter,
    filterKey: config.key,
    variantsList: config.variantsList ?? [],
    defaultTabValue: config.defaultTabValue,
    showAllVariantsList,
    closeAllVariantsList: () => setShowAllVariantsList(false),
    allVariantsTitle: config.label,
  });

  if (!showFilter) {
    return null;
  }

  const { pathname } = useLocation();

  const [open, setOpen] = useState(
    localStorage.getItem(`${pathname}-${config.key}`) === 'true'
  );

  const handleClickTitle = () => {
    localStorage.setItem(`${pathname}-${config.key}`, String(!open));
    setOpen(!open);
  };

  const showAllVariantsButton =
    config.filterFormType === 'array' &&
    config.variantsList &&
    config.variantsList?.length > 10;

  const handleClickAllVariantsButton = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setShowAllVariantsList(true);
  };

  return (
    <Stack
      direction="column"
      className={classNames(styles.itemFilterContainer)}>
      <Stack
        direction="row"
        gap="8px"
        alignItems="center"
        marginBottom={open ? '12px' : '0px'}
        onClick={handleClickTitle}>
        <Stack
          direction="row"
          gap="8px"
          alignItems="center"
          flex="1"
          className={styles.title}>
          {open ? <RoundedChevronDown /> : <RoundedChevronRight />}
          <Typography fontWeight="500" fontSize="14px" lineHeight="20px">
            {config.label}
          </Typography>
        </Stack>
        {showAllVariantsButton && (
          <Button
            color="secondary"
            size="small"
            className={styles.allVariantsButton}
            endIcon={<ChevronRight />}
            applyNewIconSizing
            onClick={handleClickAllVariantsButton}>
            Все
          </Button>
        )}
      </Stack>
      {open && Component}
      {error && <Box color="error.dark">{error}</Box>}
    </Stack>
  );
};
