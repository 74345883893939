import { MobileDrawer } from 'src/components/ui';
import { useAppSelector } from 'src/store';
import { selectUser } from 'src/store/user';
import { ActionsBlock, MenuBlock, UserBlock } from './components';
import styles from './mobile-menu.module.scss';

interface IMobileMenuProps {
  open: boolean;
  onClose: () => void;
}

export const MobileMenu = ({ open, onClose }: IMobileMenuProps) => {
  const user = useAppSelector(selectUser);

  return (
    <MobileDrawer open={open} close={onClose} title="Меню">
      <div className={styles.wrapper}>
        <UserBlock user={user} />
        <MenuBlock user={user} onClick={onClose} />
        <ActionsBlock user={user} onClick={onClose} />
      </div>
    </MobileDrawer>
  );
};
