import { TFile } from 'src/components/ui/file-upload';
import { TPhoneInputValue } from 'src/components/ui/phone-input';
import { ROLES } from 'src/constants';
import { IUser, TDocument } from 'src/models';

export enum TContactStatuses {
  'ACTIVE' = 'ACTIVE',
  'LOCKED' = 'LOCKED',
  'DELETED' = 'DELETED',
}

export type TTemporaryContact = {
  id: number;
  organizationId: number;
  roles: ROLES[];
  mail: string;
  firstName: string;
  lastName: string;
  middleName: string;
  number: string;
  position: string;
  isTemporary: boolean;
};

export const isContactTemporary = (
  contact?: TContact | TContactWithSigningBasis | TTemporaryContact
): contact is TTemporaryContact =>
  !!contact && (contact as TTemporaryContact).isTemporary;

export type TContact = {
  id: number;
  email: string;
  lastName: string;
  firstName: string;
  middleName: string;
  position: string;
  amocrmId: string | null;
  contactType:
    | 'MAIN_CUSTOMER'
    | 'PLAIN_CUSTOMER'
    | 'MAIN_CONTRACTOR'
    | 'PLAIN_CONTRACTOR';
  createdAt: string;
  notificationChannels: Array<string>;
  contactInfoDto: {
    phones: Array<TPhoneInputValue>;
  };
  isMainContact?: boolean;
  isSendEmail?: boolean;
  userId: number;
  contactStatus: TContactStatuses;
  domainCheckDto: {
    id: number;
    domainName: string;
    checkDate: string | null;
    domainRegDate: string | null;
    whoisAnswer: string | null;
    taxpayerInn: string | null;
    privatePerson: boolean;
    sharedMail: boolean;
  };
  isTrusted?: boolean;
};

export type TContactWithSigningBasis = TContact & {
  hasSigningRights: boolean;
  currentSigningBasis?: TSigningBasis;
  allSigningBasisList?: TSigningBasis[];
};

export type TSigningBasis = {
  id: number;
  signingBasisName: string;
  documentId?: number;
  isDeleted?: boolean;
  startDate: string;
  endDate: string;
  isExpired: boolean;
  document?: TDocument;
  fileName?: string;
};

export type TContactInfo = {
  id: number;
  organizationName?: string;
  inn?: string;
  ogrn?: string;
};

export type TFormInput = Pick<
  IUser,
  | 'login'
  | 'lastName'
  | 'firstName'
  | 'middleName'
  | 'position'
  | 'contactInfoDto'
> & {
  isMainContact?: boolean;
  isSendEmail?: boolean; // TODO: WTF?
  contactCompany?: TContactInfo;
  notificationChannels?: string[];
  currentSigningBasis?: TSigningBasis;
  hasSigningRights?: boolean;
  signingBasisName?: string;
  startDate?: Date | null;
  endDate?: Date | null;
  file?: TFile[];
};

export type TUpdateContact = {
  notificationChannels: Array<string>;
  contactType:
    | 'MAIN_CUSTOMER'
    | 'PLAIN_CUSTOMER'
    | 'MAIN_CONTRACTOR'
    | 'PLAIN_CONTRACTOR';
};
