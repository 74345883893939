import { Phone } from 'src/assets/icons';
import {
  Link,
  PhoneInput,
  Stack,
  SvgIcon,
  Typography,
} from 'src/components/ui';
import { TVariantContactItem } from 'src/components/widgets/contact-item/types';
import { TCardManager } from 'src/components/widgets/manager-card/manager-card';

interface IPhoneLabelProps {
  user: TVariantContactItem | TCardManager['manager'];
}

export const PhoneLabel = ({ user }: IPhoneLabelProps) => (
  <Stack
    direction="row"
    alignItems="center"
    gap="8px"
    justifyContent="space-between">
    {!!user?.contactInfoDto.phones[0] && (
      <Stack direction="column">
        {!!user?.contactInfoDto?.phones?.length &&
          user?.contactInfoDto?.phones.map((phone) => (
            <Stack direction="row" alignItems="center" gap="8px">
              {phone.number ? (
                <Link href={`tel:${phone?.number}`}>
                  <SvgIcon
                    width="16px"
                    height="16px"
                    strokeHexColor="#0041A0"
                    icon={Phone}
                  />
                </Link>
              ) : (
                <SvgIcon
                  width="16px"
                  height="16px"
                  strokeHexColor="#0041A0"
                  icon={Phone}
                />
              )}
              {/* TODO: Почему в PhoneInput кликабелен только Mobile? */}
              {phone.type !== 'MOBILE' ? (
                <Link href={`tel:${phone?.number}`}>
                  <Typography fontSize="12px" color="#000000">
                    <PhoneInput
                      displayType="text"
                      value={phone}
                      textSx={{
                        fontSize: 12,
                        color: '#000',
                      }}
                    />
                  </Typography>
                </Link>
              ) : (
                <Typography fontSize="12px" color="#000000">
                  <PhoneInput
                    displayType="text"
                    value={phone}
                    textSx={{
                      fontSize: 12,
                      color: '#000',
                    }}
                  />
                </Typography>
              )}
            </Stack>
          ))}
      </Stack>
    )}

    {!user?.contactInfoDto.phones[0] && (
      <Stack direction="row" gap="8px">
        <SvgIcon
          width="16px"
          height="16px"
          strokeHexColor="#0041A0"
          icon={Phone}
        />
        <Typography fontSize="12px" color="#000000">
          Не указан
        </Typography>
      </Stack>
    )}
  </Stack>
);
