import { IPositionProps } from './position.types';
import { PositionEdit } from './edit';
import { PositionView } from './view';

export const Position = ({
  position,
  isEditMode,
  businessRequestId,
  handleChange,
  index,
  form,
  isCreateMode,
  handleGetOkeiOptionsList,
  handleRemovePosition,
  view = 'row',
}: IPositionProps) =>
  isEditMode ? (
    <PositionEdit
      position={position}
      isEditMode={isEditMode}
      isCreateMode={isCreateMode}
      businessRequestId={businessRequestId}
      handleChange={handleChange}
      index={index}
      form={form}
      handleGetOkeiOptionsList={handleGetOkeiOptionsList}
      handleRemovePosition={handleRemovePosition}
    />
  ) : (
    <PositionView position={position} index={index} view={view} />
  );
