import { Link, Typography } from 'src/components/ui';
import { Stack } from '@mui/material';
import { AuthService } from 'src/services';
import { useAppSelector } from 'src/store';
import { selectUser } from 'src/store/user';
import { IUserState } from 'src/store/user/user-slice';
import { Link as RouterLink } from 'react-router-dom';
import { ROLES } from 'src/constants';
import styles from './menu-block.module.scss';
import { ROLE_TO_ITEMS_MAP } from './config';

interface IMenuBlockProps {
  user: IUserState;
  onClick?: () => void;
}

export const MenuBlock = ({ user, onClick }: IMenuBlockProps) => {
  const items = ROLE_TO_ITEMS_MAP(user)[AuthService.currentRole!];

  return (
    <div className={styles.menuBlock}>
      {items?.length &&
        items.map((item) => (
          <Link
            key={item.title}
            component={RouterLink}
            to={item.path}
            className={styles.menuItem}>
            <Stack alignItems="center" onClick={onClick} gap="8px">
              <div className={styles.iconWrapper}>{item.icon}</div>
              <Typography
                color="#000000"
                fontSize="10px"
                noWrap
                lineHeight="10px">
                {item.title}
              </Typography>
            </Stack>
          </Link>
        ))}
    </div>
  );
};
