// Удаляем "_o" только в конце имени перед расширением
export const getBaseName = (fileName: string) => {
  // Разделяем имя файла и расширение
  const lastDotIndex = fileName.lastIndexOf('.');
  const namePart =
    lastDotIndex === -1 ? fileName : fileName.slice(0, lastDotIndex);

  // Удаляем "_o" только в конце имени (перед расширением)
  return namePart.replace(/_o$/, '');
};
