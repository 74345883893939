import { RequestInfoBlock } from 'src/components/layout/bids-layout/main-bids-layout/components/request-info-block';
import { Stack, Typography } from 'src/components/ui';
import {
  ContractorStatusCode,
  IContractorRequest,
  IStatusHistory,
} from 'src/models';
import { formatServerDate } from 'src/utils';
import { isExpiredCommercialOfferDueDate } from 'src/utils/business-request';

export const ContractorRequestStatus = ({
  contractorRequest,
  statusHistory,
}: {
  contractorRequest: IContractorRequest;
  statusHistory: IStatusHistory;
}) => {
  const currentStatus = contractorRequest.status?.code;

  if (contractorRequest.exclusion) {
    return (
      <RequestInfoBlock title="Вы были исключены из участия в запросе">
        <Stack direction="column" gap="10px" sx={{ padding: '12px' }}>
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Причина
            </Typography>
            <Typography lineHeight="20px">
              {contractorRequest.exclusion.cause}
            </Typography>
          </Stack>
        </Stack>
      </RequestInfoBlock>
    );
  }

  if (
    currentStatus ===
    ContractorStatusCode.SPECIFICATION_SIGNED_WITH_ANOTHER_CONTRACTOR
  ) {
    return (
      <RequestInfoBlock
        title={`По запросу подписана спецификация с другим исполнителем: ${formatServerDate(
          statusHistory.passedStatuses[statusHistory.passedStatuses.length - 1]
            ?.createdAt,
          'dd.MM.yyyy HH:mm'
        )}`}
      />
    );
  }

  if (
    isExpiredCommercialOfferDueDate(contractorRequest.commercialOfferDueDate) &&
    [
      ContractorStatusCode.NEW_REQUEST,
      ContractorStatusCode.IN_PROGRESS,
      ContractorStatusCode.REBIDDING_IN_PROGRESS,
    ].includes(currentStatus as ContractorStatusCode)
  ) {
    return (
      <RequestInfoBlock
        title={`Вышел срок подачи КП: ${formatServerDate(
          contractorRequest.commercialOfferDueDate,
          'dd.MM.yyyy HH:mm'
        )}`}
      />
    );
  }

  return null;
};
