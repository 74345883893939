import { Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  TRefusalListParams,
  useGetListAllMutation,
} from 'src/api/admin/refusal-reason';
import {
  useMoveRequestToExpectedMutation,
  useMoveRequestToExpectedWithApprovalMutation,
} from 'src/api/business-request/business-request';
import { ClockPerspective } from 'src/assets/icons';
import { ERefusalReasonType } from 'src/components/pages/admin/directory/refusal-reason/types';
import {
  Autocomplete,
  Button,
  DatePicker,
  Dialog,
  DialogContent,
  DialogTitle,
  Form,
  OutlinedInput,
  Stack,
} from 'src/components/ui';
import { DIRECTION } from 'src/components/ui/filters/types';
import { FIELD_LENGTH } from 'src/constants';
import { KamStatusCode, TVariantBusinessRequest } from 'src/models';
import { TRefusalData } from 'src/models/admin/refusal-reason';
import { getErrorMessage } from 'src/store/utils';
import { date, dateNoDifference, maxLength } from 'src/utils/form-rules';
import styles from './move-request-to-expected.module.scss';

type TMoveRequestToExpectedForm = {
  feedbackDate: Date | null;
  cause: TRefusalData;
  comment: string;
};

export interface IMoveRequestToExpectedProps {
  businessRequest: TVariantBusinessRequest;
}

export const MoveRequestToExpected = ({
  businessRequest,
}: IMoveRequestToExpectedProps) => {
  const [showModal, setShowModal] = useState(false);
  const [reasonOptions, setReasonOptions] = useState<TRefusalData[]>([]);

  const [moveRequestToExpected, { isLoading: isMoveToExpectedLoading }] =
    useMoveRequestToExpectedMutation();
  const [
    moveRequestToExpectedWithApproval,
    { isLoading: isMoveToExpectedWithApprovalLoading },
  ] = useMoveRequestToExpectedWithApprovalMutation();

  const getMaxDate = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    return new Date(year + 1, month, day);
  };

  const form = useForm<TMoveRequestToExpectedForm>({
    defaultValues: {
      feedbackDate: null,
    },
  });

  const params = useMemo<TRefusalListParams>(
    () => ({
      sort: {
        field: 'STATUS',
        order: DIRECTION.ASC,
      },
      paging: {
        page: 1,
        limit: 100,
      },
      searchQuery: [],
    }),
    []
  );

  const [getReasonList, { isLoading: isReasonsLoading }] =
    useGetListAllMutation();

  const fetchApi = async (value: string) => {
    const result = await getReasonList({
      body: params,
      type: ERefusalReasonType.REQUEST_PROSPECT,
    }).unwrap();

    return result.items.filter((reason) =>
      reason.name.toLowerCase().includes(value.toLowerCase())
    );
  };

  useEffect(() => {
    getReasonList({
      body: params,
      type: ERefusalReasonType.REQUEST_PROSPECT,
    })
      .unwrap()
      .then((data) => {
        setReasonOptions(data.items);
      });
  }, []);

  const needApproval = ![
    KamStatusCode.NEW_REQUEST,
    KamStatusCode.COMMERCIAL_QUALIFICATION,
  ].includes(businessRequest.status?.code as KamStatusCode);

  const handleSubmit = async (data: TMoveRequestToExpectedForm) => {
    try {
      if (needApproval) {
        await moveRequestToExpectedWithApproval({
          businessRequestId: businessRequest.businessRequestId,
          causeId: data.cause.id,
          comment: data.comment,
          prospectAnswerDuedate: data.feedbackDate!,
        }).unwrap();
      } else {
        await moveRequestToExpected({
          businessRequestId: businessRequest.businessRequestId,
          causeId: data.cause.id,
          comment: data.comment,
          prospectAnswerDuedate: data.feedbackDate!,
        }).unwrap();
      }
      setShowModal(false);
    } catch (error) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(
          error,
          'Произошла ошибка при переводе заявки на перспективу'
        ),
      });
    }
  };

  const expectedRequestInfo =
    'prospect' in businessRequest &&
    businessRequest.prospect.length !== 0 &&
    businessRequest.prospect[businessRequest.prospect.length - 1];

  const showButton =
    [
      KamStatusCode.NEW_REQUEST,
      KamStatusCode.COMMERCIAL_QUALIFICATION,
      KamStatusCode.TECHNICAL_QUALIFICATION,
      KamStatusCode.QUALIFIED,
      KamStatusCode.SENT_TO_CONTRACTORS,
      KamStatusCode.COMMERCIAL_OFFER_RECEIVED,
      KamStatusCode.BEST_COMMERCIAL_OFFER_RECEIVED,
      KamStatusCode.NO_BEST_COMMERCIAL_OFFER,
      KamStatusCode.AUCTION_IN_PROGRESS,
      KamStatusCode.REBIDDING_IN_PROGRESS,
      KamStatusCode.COMMERCIAL_OFFER_SENT_TO_CUSTOMER,
      KamStatusCode.COMMERCIAL_OFFER_RECEIVED,
    ].includes(businessRequest.status?.code as KamStatusCode) &&
    'type' in businessRequest &&
    businessRequest.type === 'REAL' &&
    (!expectedRequestInfo ||
      expectedRequestInfo.approveStatus === 'REFUSED' ||
      expectedRequestInfo.approveStatus === 'RETURNED');

  if (!showButton) return null;

  return (
    <>
      <Button
        applyNewIconSizing
        size="medium"
        color="secondary"
        startIcon={<ClockPerspective />}
        onClick={() => setShowModal(true)}
        sx={{ flex: 1, svg: { path: { fill: 'currentColor' } } }}>
        Перспектива
      </Button>
      <Dialog
        open={showModal}
        close={() => setShowModal(false)}
        PaperProps={{
          sx: {
            minWidth: `800px`,
            maxWidth: `800px`,
            width: '100%',
          },
        }}>
        <DialogTitle>
          <Typography fontWeight={600} fontSize="20px" lineHeight="28px">
            Вы уверены, что хотите перевести заявку на перспективу?
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" gap="16px">
            <Typography lineHeight="20px">
              Для перевода на перспективу укажите срок предоставления обратной
              связи и причину перевода. Если по заявке были получены или
              отправлены КП, все они будут отклонены.
            </Typography>
            <Form
              form={form}
              preloading={isReasonsLoading}
              skipDirtyCheck
              onSubmit={handleSubmit}
              onCancel={() => setShowModal(false)}
              loading={
                isMoveToExpectedLoading || isMoveToExpectedWithApprovalLoading
              }
              submitButtonProps={{
                text: 'Перевести',
              }}>
              <Form.Item
                name="feedbackDate"
                label="Срок обратной связи"
                rules={{
                  required: 'Укажите срок предоставления обратной связи',
                  validate: {
                    date,
                    dateNoDifference,
                    maxDate: (dateValue) => {
                      const currentDateValue = dateValue as Date | null;

                      const maxDate = getMaxDate();
                      if (currentDateValue && currentDateValue > maxDate) {
                        return 'Введите срок не более года от текущей даты';
                      }
                      return true;
                    },
                  },
                }}
                controlInputClassName={styles.picker}>
                <DatePicker minDate={new Date()} maxDate={getMaxDate()} />
              </Form.Item>
              <Form.Item
                name="cause"
                label="Причина перевода"
                rules={{ required: 'Укажите причину перевода на перспективу' }}>
                <Autocomplete<TRefusalData>
                  fetchApi={fetchApi}
                  placeholder="Выбрать"
                  options={reasonOptions}
                  getOptionLabel={(option) => option.name}
                  fetchOnEmptyInput
                />
              </Form.Item>
              <Form.Item
                noMargin
                name="comment"
                label="Комментарий"
                rules={{
                  maxLength: maxLength(FIELD_LENGTH.moveToExpected),
                }}>
                <OutlinedInput
                  placeholder="Введите комментарий"
                  multiline
                  rows={6}
                  maxLength={FIELD_LENGTH.moveToExpected}
                />
              </Form.Item>
              {needApproval && (
                <Typography
                  color="#B75200"
                  marginTop="16px"
                  marginBottom="12px">
                  Для перевода заявки на перспективу потребуется согласование
                  ответственного КАМ+.
                </Typography>
              )}
            </Form>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
