export const COUNT_PER_PAGE = {
  CUSTOMERS: 10,
  CONTRACTOR_ALL_REQUESTS: 11,
  COMPANIES: 12,
  USERS: 12,
  EVENTS: 100,
  LIST_OF_PERFORMERS: 3,
  REFUSAL_REASON: 12,
  NEWS_CATEGORIES_FIRST_PAGE: 7,
  TECHNICAL_OPPORTUNITIES: 100,
  NEWS_CATEGORIES: 7,
  NEWS: 5,
  NOTIFICATIONS: 10,
  SHIPPING: 10,
  PAYMENTS: 10,
  AUCTION_BIDS: 7,
  REQUEST: 10,
  COMMUNICATIONS: 20,
  CONTRACT_TEMPLATES: 10,
  PAYMENT_TEMPLATES: 999,
};

export const PAGES_LIMIT_OPTIONS = [5, 10, 20, 30];
