import { Box, IconButton } from 'src/components/ui';
import cn from 'classnames';
import {
  DAY_TYPES,
  PAYMENT_MOMENTS,
  PAYMENT_TYPES,
} from 'src/components/pages/business-request-detail/components/payment-conditions/payment-conditions.const';
import styles from './styles.module.scss';
import { IPaymentConditionProps } from './payment-condition.types';

export const PaymentConditionView = (props: IPaymentConditionProps) => {
  const {
    paymentType,
    paymentPercentage,
    paymentInDays,
    dayType,
    paymentMoment,
    maxWidth,
    index = 0,
  } = props;

  return (
    <Box display="flex" className={styles.row} gap="12px" alignItems="center">
      <Box width="140px" className={cn(styles.item, styles.hasWidth)}>
        <strong>{index + 1} часть</strong>
      </Box>
      <Box className={styles.item} maxWidth={maxWidth}>
        {PAYMENT_TYPES.find((type) => type.value === paymentType)?.label}{' '}
        {paymentPercentage}% в течении {paymentInDays}{' '}
        {DAY_TYPES.find((type) => type.value === dayType)?.short} дней с момента{' '}
        {
          PAYMENT_MOMENTS.find((moment) => moment.value === paymentMoment)
            ?.label
        }
      </Box>
    </Box>
  );
};
