import { useState } from 'react';
import {
  useRestoreBusinessApplicationMutation,
  useRestoreContractorBusinessApplicationMutation,
} from 'src/api/business-request/business-request';
import {
  FiltersBackArrow,
  RoundedChevronRight,
  SquareRoundedX,
} from 'src/assets/icons';
import { RequestInfoBlock } from 'src/components/layout/bids-layout/main-bids-layout/components/request-info-block';
import { ROLES } from 'src/constants';
import {
  IContractorBusinessRequest,
  IStatusHistory,
  KamStatusCode,
  TVariantBusinessRequest,
} from 'src/models';
import {
  Stack,
  Box,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  InfoRow,
  DialogActions,
  Dialog,
} from 'src/components/ui';
import { AuthService } from 'src/services';
import { getErrorMessage } from 'src/store/utils';
import { formatServerDate, joinFullName } from 'src/utils';
import { isRequestRefusalByContractor } from 'src/utils/business-request';

export interface IRejectedApplicationBlockProps {
  businessRequest: TVariantBusinessRequest;
  statusHistory: IStatusHistory;
  contractorMode?: boolean;
  showRestoreButton?: boolean;
}

export const RejectedApplicationBlock = ({
  businessRequest,
  statusHistory,
  contractorMode,
  showRestoreButton,
}: IRejectedApplicationBlockProps) => {
  const statusDescription =
    'businessRequestStatus' in businessRequest &&
    isRequestRefusalByContractor(businessRequest.businessRequestStatus.code)
      ? 'Вы отказались от участия в запросе:'
      : 'Заявка была отклонена:';

  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [restoreBusinessApplication, { isLoading, error }] =
    useRestoreBusinessApplicationMutation();

  const [
    restoreContractorBusinessApplication,
    { isLoading: isContractorRequestLoading, error: contractorRequestError },
  ] = useRestoreContractorBusinessApplicationMutation();

  const previousStatus =
    statusHistory.passedStatuses[statusHistory.passedStatuses.length - 2];
  const currentStatus =
    statusHistory.passedStatuses[statusHistory.passedStatuses.length - 1];

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleRestore = async () => {
    try {
      if (contractorMode) {
        await restoreContractorBusinessApplication({
          contractorRequestId: businessRequest.contractorRequestId!,
        }).unwrap();

        handleClose();
      } else {
        await restoreBusinessApplication({
          businessApplicationId: businessRequest.businessApplicationId!,
          businessRequestId: businessRequest.businessRequestId!,
        }).unwrap();

        handleClose();
      }
    } catch {
      // ignore
    }
  };

  const canRestoreRequest =
    !(
      contractorMode &&
      (businessRequest as IContractorBusinessRequest)?.businessRequestStatus
        ?.code === KamStatusCode.REQUEST_REFUSAL
    ) && currentStatus?.status.code === 'REQUEST_REFUSAL';

  if (!businessRequest.refusal) {
    return null;
  }

  return (
    <RequestInfoBlock
      title={
        <>
          {statusDescription}
          <br />
          {formatServerDate(
            businessRequest.refusal?.createdAt,
            'dd.MM.yyyy HH:mm'
          )}
        </>
      }>
      <Stack direction="column" gap="10px" sx={{ padding: '12px' }}>
        {showRestoreButton && canRestoreRequest && (
          <Button
            startIcon={<FiltersBackArrow />}
            onClick={() => setShowDialog(true)}
            size="medium"
            sx={{ width: '100%' }}
            color="white">
            Вернуть в работу
          </Button>
        )}
        <Stack direction="column" gap="4px">
          <Typography color="secondary" fontSize="12px" lineHeight="16px">
            Дата отмены
          </Typography>
          <Typography lineHeight="20px">
            {formatServerDate(
              businessRequest.refusal.createdAt,
              'dd.MM.yyyy HH:mm'
            )}
          </Typography>
        </Stack>
        {businessRequest.refusal.internalCause && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Внутренняя причина отказа:
            </Typography>
            <Typography lineHeight="20px">
              {businessRequest.refusal.internalCause}
            </Typography>
          </Stack>
        )}
        {businessRequest.refusal.cause && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Причина:
            </Typography>
            <Typography lineHeight="20px">
              {businessRequest.refusal.cause}
            </Typography>
          </Stack>
        )}

        {businessRequest.refusal.internalComment && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Внутренний комментарий:
            </Typography>
            <Typography lineHeight="20px">
              {businessRequest.refusal.internalComment}
            </Typography>
          </Stack>
        )}
        {businessRequest.refusal.comment && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Комментарий:
            </Typography>
            <Typography lineHeight="20px">
              {businessRequest.refusal.comment}
            </Typography>
          </Stack>
        )}

        {businessRequest.refusal.customerCause && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Причина отказа для заказчика:
            </Typography>
            <Typography lineHeight="20px">
              {businessRequest.refusal.customerCause}
            </Typography>
          </Stack>
        )}

        {businessRequest.refusal.customerComment && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Комментарий Заказчику:
            </Typography>
            <Typography lineHeight="20px">
              {businessRequest.refusal.customerComment}
            </Typography>
          </Stack>
        )}

        {businessRequest.refusal.contractorCause && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Причина отказа для исполнителя:
            </Typography>
            <Typography lineHeight="20px">
              {businessRequest.refusal.contractorCause}
            </Typography>
          </Stack>
        )}

        {AuthService.currentRole! === ROLES.CONTRACTOR &&
          businessRequest.refusal.contractorComment && (
            <Stack direction="column" gap="4px">
              <Typography color="secondary" fontSize="12px" lineHeight="16px">
                Комментарий Исполнителю:
              </Typography>
              <Typography lineHeight="20px">
                {businessRequest.refusal.contractorComment}
              </Typography>
            </Stack>
          )}

        {businessRequest.refusal.refusalApprovingUser && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Согласовано:
            </Typography>
            <Typography lineHeight="20px">
              {`${businessRequest.refusal.refusalApprovingUser.lastName} ${
                businessRequest.refusal.refusalApprovingUser.firstName
              } ${
                businessRequest.refusal.refusalApprovingUser.position
                  ? `, ${businessRequest.refusal.refusalApprovingUser.position}`
                  : ''
              }`}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Dialog
        PaperProps={{
          sx: {
            width: '600px',
          },
        }}
        open={showDialog}
        close={handleClose}>
        <DialogTitle>
          {contractorMode
            ? 'Вы уверены, что хотите вернуть заявку в работу?'
            : 'Вы уверены, что хотите вернуть заявку?'}
        </DialogTitle>

        <DialogContent>
          {!contractorMode && (
            <>
              <Typography variant="h4" mb="24px">
                Сразу после подтверждения заявка вернется в тот статус, который
                был до отмены
              </Typography>
              <InfoRow
                label="Статус заявки до отмены"
                value={previousStatus?.status.displayName}
              />
              <InfoRow
                label="Дата перехода в данный статус"
                value={
                  previousStatus &&
                  formatServerDate(
                    previousStatus.createdAt,
                    'dd.MM.yyyy HH:mm:ss'
                  )
                }
              />
            </>
          )}

          {contractorMode && (
            <>
              <InfoRow
                label="Дата отмены"
                value={formatServerDate(
                  businessRequest.refusal?.createdAt,
                  'dd.MM.yyyy HH:mm'
                )}
              />
              <InfoRow label="Причина" value={businessRequest.refusal?.cause} />
              <InfoRow
                label="Комментарий"
                value={businessRequest.refusal?.comment}
              />
            </>
          )}

          {contractorRequestError && (
            <Typography color="error">
              {getErrorMessage(
                contractorRequestError,
                'Произошла ошибка возврата заявки из отказа'
              )}
            </Typography>
          )}
          {error && (
            <Typography color="error">
              {getErrorMessage(
                error,
                'Произошла ошибка возврата заявки из отказа'
              )}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          {contractorMode && <Button onClick={handleClose}>Отмена</Button>}
          <Button
            loading={isLoading || isContractorRequestLoading}
            onClick={handleRestore}>
            {!contractorMode ? 'Вернуть заявку' : 'Подтвердить'}
          </Button>
        </DialogActions>
      </Dialog>
    </RequestInfoBlock>
  );
};
