import { useCallback, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { IApplicationListQueryParams } from 'src/api/communications/models';
import { IManagerRejectApplicationForm } from 'src/components/layout/bids-layout/main-bids-layout/components/header/components/reject-application/components/manager/types';
import { Autocomplete, Form, HintText, OutlinedInput } from 'src/components/ui';
import { HintTextVariant } from 'src/components/ui/hint-text/hint-text.types';
import { FIELD_LENGTH } from 'src/constants';
import { maxLength, required } from 'src/utils/form-rules';
import styles from './reasons-manager.module.scss';

interface IReasonsManagerProps {
  items:
    | {
        id: number;
        name: string;
        nameForCustomer: string;
        nameForContractor: string;
        status: string;
      }[]
    | undefined;
  onKamReasonsChanged: () => void;
  reason: IManagerRejectApplicationForm['managerSelectedReason'] | null;
  businessApplicationId: number;
  form: UseFormReturn<IManagerRejectApplicationForm>;
}

const DEFAULT_SEARCH_PARAMS: IApplicationListQueryParams = {
  page: 0,
  size: 100,
  sort: 'id,DESC',
  searchValue: '',
  organizationIds: [],
  rebindingApplicationId: '',
};

export const ReasonsManager = ({
  items,
  onKamReasonsChanged,
  reason,
  businessApplicationId,
  form,
}: IReasonsManagerProps) => {
  const fetchApiFilter = useCallback(
    (value: string) =>
      Promise.resolve(
        (value
          ? items?.filter((element) =>
              element.name.toLowerCase().includes(value.toLowerCase())
            )
          : items) || []
      ),
    [items]
  );

  const [isApprovalNeeded, setIsApprovalNeeded] = useState<boolean>(false);

  // const [getApplicationList, { data: applicationList }] =
  //   useLazyGetApplicationListV2Query();
  //
  // const [searchParams, setSearchParams] = useState(DEFAULT_SEARCH_PARAMS);
  //
  // useEffect(() => {
  //   const params = {
  //     ...searchParams,
  //     rebindingApplicationId: `${businessApplicationId}` || '',
  //   };
  //
  //   getApplicationList(params);
  // }, [getApplicationList]);
  //
  // const fetchBusinessApplicationApiFilter = useCallback(
  //   (value: string) => {
  //     const content = applicationList?.content || [];
  //
  //     const filteredItems =
  //       (value
  //         ? content?.filter((element) =>
  //             element.name?.toLowerCase().includes(value.toLowerCase())
  //           )
  //         : content) || [];
  //
  //     return Promise.resolve(filteredItems);
  //   },
  //   [applicationList]
  // );
  //
  // useEffect(() => {
  //   if (
  //     reason?.name === 'Дубль/Коммуникационный лид' &&
  //     !applicationList?.content?.length
  //   ) {
  //     form.setError('root.serverError', {
  //       type: 'custom',
  //       message:
  //         'У данного заказчика нет других заявок в работе. Объедините текущую компанию с постоянной для прикрепления коммуникаций',
  //     });
  //   }
  // }, [reason, applicationList]);

  return (
    <>
      <Form.Item
        noMargin
        name="managerSelectedReason"
        label="Выберите внутреннюю причину отказа"
        rules={{
          required,
        }}
        help={
          isApprovalNeeded && (
            <HintText variant={HintTextVariant.WARNING}>
              Для отклонения заявки по указанной причине необходимо
              подтверждение Руководителя ТО
            </HintText>
          )
        }>
        <Autocomplete
          popupIcon
          options={items}
          fetchApi={fetchApiFilter}
          getOptionLabel={(opt) => opt.name}
          onChange={(item) => {
            onKamReasonsChanged();
            setIsApprovalNeeded(false);
            // @ts-ignore
            if (item && 'approvalNeeded' in item) {
              setIsApprovalNeeded(!!item.approvalNeeded);
            }
          }}
          fetchOnEmptyInput
          placeholder="Выбрать"
        />
      </Form.Item>

      {/* TODO: ID ? */}
      {/* {reason?.name === 'Дубль/Коммуникационный лид' && */}
      {/* !!applicationList?.content?.length ? ( */}
      {/*   <Form.Item */}
      {/*     noMargin */}
      {/*     name="businessApplicationForAttachment" */}
      {/*     label="Выберите заявку для привязки коммуникаций" */}
      {/*     rules={{ */}
      {/*       required, */}
      {/*     }}> */}
      {/*     <Autocomplete<IApplicationToBind> */}
      {/*       popupIcon */}
      {/*       options={applicationList?.content || []} */}
      {/*       fetchApi={fetchBusinessApplicationApiFilter} */}
      {/*       getOptionLabel={(opt) => opt.name} */}
      {/*       fetchOnEmptyInput */}
      {/*       placeholder="Выбрать" */}
      {/*     /> */}
      {/*   </Form.Item> */}
      {/* ) : ( */}
      {/*   <div className={styles.indent} /> */}
      {/* )} */}
      <div className={styles.indent} />
      <Form.Item
        noMargin
        name="managerComment"
        label="Напишите внутренний комментарий"
        rules={{
          required: !!reason?.requiredComment,
          maxLength: maxLength(FIELD_LENGTH.rejectApplicationComment),
        }}>
        <OutlinedInput
          placeholder="Введите комментарий"
          multiline
          rows={6}
          maxLength={FIELD_LENGTH.rejectApplicationComment}
        />
      </Form.Item>
    </>
  );
};
