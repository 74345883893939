import { useState } from 'react';
import {
  useApprovalRefusalRequestMutation,
  useDiscardRefusalRequestMutation,
} from 'src/api/business-request/business-request';
import { Check } from 'src/assets/icons';
import { RequestInfoBlock } from 'src/components/layout/bids-layout/main-bids-layout/components/request-info-block';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from 'src/components/ui';
import { TVariantBusinessRequest } from 'src/models';
import { AuthService } from 'src/services';
import { formatServerDate, joinFullName } from 'src/utils';

export interface IRejectedWithApprovalApplicationBlockProps {
  businessRequest: TVariantBusinessRequest;
  businessRequestId: number;
  applicationId: number;
}

export const RejectedWithApprovalApplicationBlock = ({
  businessRequest,
  businessRequestId,
  applicationId,
}: IRejectedWithApprovalApplicationBlockProps) => {
  const isNeedMyApprove =
    AuthService.userId === businessRequest.refusal?.refusalApprovingUser?.id;
  const [showRefusalRejectModal, setShowRefusalRejectModal] = useState(false);
  const [approvalRefusalRequest, { isLoading: isApprovalLoading }] =
    useApprovalRefusalRequestMutation();
  const [discardRefusalRequest, { isLoading: isDiscardLoading }] =
    useDiscardRefusalRequestMutation();

  const handleCloseModal = () => {
    setShowRefusalRejectModal(false);
  };

  const handleApprovalRefusalRequest = () => {
    approvalRefusalRequest({
      applicationId,
      businessRequestId,
    }).then(handleCloseModal);
  };

  const handleDiscardRefusalRequest = () => {
    discardRefusalRequest({
      applicationId,
      businessRequestId,
    }).then(handleCloseModal);
  };

  if (!businessRequest.refusal) {
    return null;
  }

  return (
    <RequestInfoBlock
      title={
        <>
          Заявка ожидает согласования отклонения:{' '}
          {formatServerDate(
            businessRequest.refusal?.createdAt,
            'dd.MM.yyyy HH:mm'
          )}
        </>
      }>
      <Stack direction="column" gap="10px" sx={{ padding: '12px' }}>
        {isNeedMyApprove && (
          <Button
            sx={{ width: '100%' }}
            color="error"
            applyNewIconSizing
            startIcon={<Check />}
            size="medium"
            onClick={() => setShowRefusalRejectModal(true)}>
            Согласовать
          </Button>
        )}

        <Stack direction="column" gap="4px">
          <Typography color="secondary" fontSize="12px" lineHeight="16px">
            Дата направления на согласование:
          </Typography>
          <Typography lineHeight="20px">
            {formatServerDate(
              businessRequest.refusal.createdAt,
              'dd.MM.yyyy HH:mm'
            )}
          </Typography>
        </Stack>
        <Stack direction="column" gap="4px">
          <Typography color="secondary" fontSize="12px" lineHeight="16px">
            Ожидает согласования от:
          </Typography>
          <Typography lineHeight="20px">
            {joinFullName(
              businessRequest.refusal.refusalApprovingUser?.lastName,
              businessRequest.refusal.refusalApprovingUser?.firstName,
              businessRequest.refusal.refusalApprovingUser?.middleName
            )}
          </Typography>
          {!!businessRequest.refusal.refusalApprovingUser?.position.length && (
            <Typography lineHeight="20px">
              {businessRequest.refusal.refusalApprovingUser.position}
            </Typography>
          )}
        </Stack>

        {businessRequest.refusal.internalCause && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Внутренняя причина:
            </Typography>
            <Typography lineHeight="20px">
              {businessRequest.refusal.internalCause}
            </Typography>
          </Stack>
        )}

        {businessRequest.refusal.internalComment && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Внутренний комментарий:
            </Typography>
            <Typography lineHeight="20px">
              {businessRequest.refusal.internalComment}
            </Typography>
          </Stack>
        )}

        {businessRequest.refusal.customerCause && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Причина отказа для заказчика:
            </Typography>
            <Typography lineHeight="20px">
              {businessRequest.refusal.customerCause}
            </Typography>
          </Stack>
        )}

        {businessRequest.refusal.customerComment && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Комментарий Заказчику:
            </Typography>
            <Typography lineHeight="20px">
              {businessRequest.refusal.customerComment}
            </Typography>
          </Stack>
        )}

        {businessRequest.refusal.contractorCause && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Причина отказа для исполнителя:
            </Typography>
            <Typography lineHeight="20px">
              {businessRequest.refusal.contractorCause}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Dialog
        open={showRefusalRejectModal}
        fullWidth
        width="600px"
        close={handleCloseModal}>
        <DialogTitle>Согласование отклонения заявки</DialogTitle>
        <DialogContent>
          <Stack direction="column" gap="20px">
            {businessRequest.refusal.internalCause && (
              <Box>
                <Typography variant="body2" color="#9FA8B3" fontSize="12px">
                  Причина внутреннего отказа
                </Typography>
                <Typography variant="body1" fontSize="14px">
                  {businessRequest.refusal.internalCause}
                </Typography>
              </Box>
            )}
            {businessRequest.refusal.internalCause && (
              <Box>
                <Typography variant="body2" color="#9FA8B3" fontSize="12px">
                  Внутренний комментарий
                </Typography>
                <Typography variant="body1" fontSize="14px">
                  {businessRequest.refusal.internalComment}
                </Typography>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleApprovalRefusalRequest}
            disabled={isApprovalLoading || isDiscardLoading}
            autoFocus>
            Подтвердить отклонение
          </Button>
          <Button
            onClick={handleDiscardRefusalRequest}
            color="secondary"
            disabled={isApprovalLoading || isDiscardLoading}>
            Отменить отклонение
          </Button>
        </DialogActions>
      </Dialog>
    </RequestInfoBlock>
  );
};
