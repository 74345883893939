import classNames from 'classnames';
import { MouseEvent, useCallback, useState } from 'react';
import { businessRequestApi } from 'src/api';
import { useLazyGetContactsQuery } from 'src/api/contacts';
import {
  ClosedLock,
  Ellipsis,
  Pencil,
  SigningBasisDocument,
} from 'src/assets/icons';
import { AddItem } from 'src/components/pages/company/contacts/components';
import {
  TContact,
  TContactStatuses,
  TContactWithSigningBasis,
} from 'src/components/pages/company/contacts/types';
import {
  Drawer,
  IconButton,
  InfoRow,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  OverflowTooltip,
  PhoneInput,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from 'src/components/ui';
import { PHONES } from 'src/components/ui/phone-input';
import { Bids } from 'src/components/widgets/contact-item/components/bids';
import { Contractor } from 'src/components/widgets/contact-item/components/contractor';
import { SigningBasisTooltip } from 'src/components/widgets/contact-item/components/signing-basis-tooltip';
import { IContactItemProps } from 'src/components/widgets/contact-item/contact-item';
import { TVariantContactItem } from 'src/components/widgets/contact-item/types';
import {
  isBids,
  isContractor,
} from 'src/components/widgets/contact-item/utils';
import { Whois } from 'src/components/widgets/whois';
import {
  CompanyTypes,
  IBidsCustomerContact,
  IBusinessRequestCustomerInfo,
} from 'src/models';
import { useAppDispatch } from 'src/store';
import { noop } from 'src/utils';
import styles from './regular-contact-card.module.scss';

const ContactItemLayout = ({
  item,
  businessRequest,
  showSignerInfo,
  onEdit,
}: {
  showSignerInfo?: boolean;
  item: TVariantContactItem;
  businessRequest?: IBusinessRequestCustomerInfo;
  onEdit?: (item?: TContact) => void;
}) => (
  <div className={styles.container}>
    {/* {RolePermissionService.can(PERMISSIONS.VIEW_CRM_LINK) && ( */}
    {/*  <InfoRow */}
    {/*    label="ID AmoCRM" */}
    {/*    value={ */}
    {/*      item?.amocrmId && ( */}
    {/*        <Link */}
    {/*          className={styles.crmLink} */}
    {/*          href={`${process.env.REACT_APP_AMO_CRM_URL}/contacts/detail/${item.amocrmId}`} */}
    {/*          target="_blank"> */}
    {/*          {`${item.amocrmId} (открыть AmoCRM)`} */}
    {/*          <ExternalLink /> */}
    {/*        </Link> */}
    {/*      ) */}
    {/*    } */}
    {/*  /> */}
    {/* )} */}
    <InfoRow
      label="Фамилия"
      value={
        <div className={styles.surnameContainer}>
          {item.contactStatus === TContactStatuses.LOCKED && (
            <Tooltip title="Пользователь заблокирован">
              <SvgIcon
                backgroundFill
                iconPadding="2px"
                width="14px"
                height="14px"
                strokeHexColor="#FF0000"
                icon={ClosedLock}
              />
            </Tooltip>
          )}
          {item.lastName}
        </div>
      }
      overflowTooltip
    />
    <InfoRow label="Имя" value={item.firstName} overflowTooltip />
    <InfoRow label="Отчество" value={item.middleName} overflowTooltip />
    <InfoRow label="Должность" value={item.position} overflowTooltip />

    <div
      className={styles.phonesWrapper}
      style={{ flexGrow: showSignerInfo ? 0 : 1 }}>
      {item.contactInfoDto?.phones.map((phone) => (
        <InfoRow
          key={phone.number}
          label={PHONES[phone.type]}
          value={<PhoneInput displayType="text" value={phone} />}
        />
      ))}
    </div>

    <InfoRow
      label="Электронный адрес"
      value={
        <Stack
          direction="row"
          gap="4px"
          className={styles.zeroMinWidthContainer}>
          <Link
            className={styles.zeroMinWidthContainer}
            href={`mailto:${item.email}`}>
            <OverflowTooltip text={item.email} />
          </Link>
          {!!item.domainCheckDto && (
            <Whois
              className={styles.whois}
              item={item}
              businessRequest={businessRequest}
            />
          )}
        </Stack>
      }
    />
    {showSignerInfo && (
      <>
        <InfoRow
          label="Подписант"
          value={
            (item as TContactWithSigningBasis).currentSigningBasis &&
            (item as TContactWithSigningBasis).hasSigningRights
              ? 'Является подписантом'
              : 'Не является подписантом'
          }
          overflowTooltip
        />

        {(item as TContactWithSigningBasis).currentSigningBasis &&
          (item as TContactWithSigningBasis).hasSigningRights && (
            <InfoRow
              label="Действует на основании"
              value={
                <Tooltip
                  title={
                    <SigningBasisTooltip
                      item={item as TContactWithSigningBasis}
                      onEditClick={onEdit}
                    />
                  }>
                  <Stack direction="row" gap="4px" alignItems="center">
                    <SigningBasisDocument />
                    <Typography color="primary" fontSize="14px">
                      {
                        (item as TContactWithSigningBasis).currentSigningBasis!
                          .signingBasisName
                      }
                    </Typography>
                  </Stack>
                </Tooltip>
              }
            />
          )}
      </>
    )}
  </div>
);

export const RegularContactCard = ({
  item,
  onDelete,
  countElements,
  onChange,
  onEdit,
  businessRequest,
  showSignerInfo,
  permissions,
}: Omit<IContactItemProps, 'item'> & {
  item: TContact | TContactWithSigningBasis;
}) => {
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const [openAddForm, setOpenAddForm] = useState(false);
  const [itemForEdit, setItemForEdit] = useState<IBidsCustomerContact>();

  const toggleOpenAddForm = useCallback(() => {
    setOpenAddForm((prevOpen) => !prevOpen);
    setItemForEdit(undefined);
  }, []);

  const [getContacts] = useLazyGetContactsQuery();

  const onEditClick = async () => {
    handleCloseMenu();

    if (businessRequest?.organizationId) {
      await getContacts({
        companyType: CompanyTypes.CUSTOMER,
        idCompany: businessRequest?.organizationId,
      })
        .unwrap()
        .then((res) => {
          const currentContactData = res?.find(
            (contact) => contact.id === item?.id
          );

          if (currentContactData) {
            setItemForEdit(currentContactData);
            setOpenAddForm(true);
          }
        })
        .catch(noop);
    }
  };

  const handleOnSave = () => {
    toggleOpenAddForm();

    if (businessRequest?.businessRequestId) {
      dispatch(
        businessRequestApi.util?.invalidateTags([
          {
            type: 'getCustomerInfo',
            id: businessRequest?.businessRequestId,
          },
        ])
      );
    }
  };

  return (
    <>
      <div
        className={classNames(
          styles.card,
          item.isMainContact ? styles.active : ''
        )}>
        {isBids(item) && (
          <Bids item={item} permissions={permissions}>
            <ContactItemLayout
              item={item}
              businessRequest={businessRequest}
              showSignerInfo={showSignerInfo}
              onEdit={onEdit}
            />
            <IconButton onClick={handleOpenMenu} className={styles.actionBtn}>
              <Ellipsis />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}>
              <MenuItem onClick={onEditClick}>
                <ListItemIcon>
                  <Pencil fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <Typography color="primary.dark">Редактировать</Typography>
                </ListItemText>
              </MenuItem>
            </Menu>
          </Bids>
        )}

        {isContractor(item) && (
          <Contractor
            item={item}
            permissions={permissions}
            onChange={onChange}
            onDelete={onDelete}
            onEdit={onEdit}
            countElements={countElements}>
            <ContactItemLayout
              item={item}
              businessRequest={businessRequest}
              showSignerInfo={showSignerInfo}
              onEdit={onEdit}
            />
          </Contractor>
        )}
      </div>

      <Drawer
        title="Редактировать контакт"
        open={openAddForm}
        close={toggleOpenAddForm}>
        <AddItem
          defaultItem={itemForEdit}
          onSave={handleOnSave}
          onCancel={toggleOpenAddForm}
          companyTypeDefined={CompanyTypes.CUSTOMER}
        />
      </Drawer>
    </>
  );
};
