import { ReactNode, useCallback, useEffect, useState, Ref } from 'react';
import classNames from 'classnames';
import {
  Button,
  Typography,
  FileList,
  useFormControl,
  Box,
  ConfirmDialog,
  Stack,
} from 'src/components/ui';
import { useAppSelector } from 'src/store';
import { selectUser } from 'src/store/user';
import { plural } from 'src/utils';
import {
  getDataByExtension,
  getHelp,
  validateSizeByType,
} from 'src/components/ui/file-upload/utils';
import { useGetConfigQuery } from 'src/api/document';
import { AuthService } from 'src/services';
import {
  ContractDocumentTypes,
  DocumentEntityTypes,
  DocumentTypes,
} from 'src/models';
import { TTypeConfig } from 'src/models/document';
import styles from './file-upload-cleaned.module.scss';
import { FileDropZone } from '../file-drop-zone';

export type TFile = {
  name: string;
  sizeBytes: number;
  createdAt: string;
  createdByUser: {
    lastName: string;
    firstName: string;
    middleName?: string;
  };
  id: number;
  error?: string;
  deleted?: boolean;
  clenead?: boolean;
  isWatched?: boolean | null;
  primary?: boolean;
  entityType?: DocumentEntityTypes | 'CONTRACTOR' | 'CUSTOMER';
  documentType?: DocumentTypes | ContractDocumentTypes;
  entityId?: number;
} & ({ isNew: true; file: File } | { isNew?: never; file?: never });

export type TFileUpload = {
  value?: TFile[];
  fullScreen?: boolean;
  onChange?: (files: TFile[]) => void;
  onDownload?: (fileId: number) => void;
  onRemove?: (fileId: number) => void;
  removeMode?: 'default' | 'soft';
  documentType: DocumentTypes | ContractDocumentTypes;
  helpText?: string;
  showAvailableExtList?: boolean;
  showDownloadButton?: boolean;
  title?: ReactNode;
  actions?: ReactNode;
  subtitle?: ReactNode;
  className?: string;
  inputRef?: Ref<any>;
  imagesPreview?: boolean;
  variant?: 'default' | 'compact';
  showCleanButton?: boolean;
};

export type TLimit = {
  countOtherFiles: number | null;
  countArchive: number | null;
};
// TODO Sasha
// https://github.com/react-dropzone/react-dropzone/blob/v14.2.3/src/utils/index.js#L127
const isEventWithFiles = (event: any) => {
  if (!event.dataTransfer) {
    return !!event.target?.files;
  }

  return Array.prototype.some.call(
    event.dataTransfer.types,
    (type) => type === 'Files' || type === 'application/x-moz-file'
  );
};

const variantConfig = {
  default: {
    variant: 'h3',
    lineHeight: undefined,
    marginBottom: '19px',
  },
  compact: {
    variant: 'body2',
    lineHeight: '20px',
    marginBottom: undefined,
  },
} as const;

export const FileUploadCleaned = ({
  value,
  fullScreen = true,
  onChange,
  onDownload,
  onRemove,
  removeMode = 'default',
  documentType,
  helpText,
  showAvailableExtList = false,
  showDownloadButton = true,
  title,
  actions,
  subtitle,
  className,
  inputRef,
  imagesPreview,
  variant = 'default',
  showCleanButton,
}: TFileUpload) => {
  const [limit, setLimit] = useState<TLimit>({
    countOtherFiles: null,
    countArchive: null,
  });
  const [errorValidate, setErrorValidate] = useState<string>();
  const [isDisable, setIsDisable] = useState(false);
  const user = useAppSelector(selectUser);
  const { error } = useFormControl() || {};
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);

  const role = AuthService.currentRole;

  const { data: dataConfig } = useGetConfigQuery();

  const valueCounter = value?.filter((element) => !element.deleted).length;

  useEffect(() => {
    if (dataConfig && role && dataConfig[role]?.[documentType]) {
      const countOtherFiles = Number(
        dataConfig[role][documentType][TTypeConfig.OTHER_FILE_TYPES].count
      );
      const countArchive = Number(
        dataConfig[role][documentType][TTypeConfig.ARCHIVE].count
      );

      setLimit({
        countOtherFiles,
        countArchive,
      });

      if (
        value &&
        value.filter((elem) => !elem.deleted).length >=
          countOtherFiles + countArchive
      ) {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
    }
  }, [dataConfig, role, value, documentType]);

  const validateFiles = useCallback(
    (
      acceptedFiles: File[],
      isCleaned?: boolean
    ): {
      isValidate: boolean;
      messageError?: string;
    } => {
      const { countOtherFiles, countArchive } = limit;

      if (dataConfig && role && dataConfig[role]?.[documentType]) {
        const configByDocumentType = dataConfig[role][documentType];

        const validateExtensions = getDataByExtension(
          configByDocumentType,
          acceptedFiles
        );

        if (validateExtensions.error) {
          return {
            isValidate: false,
            messageError: 'Недопустимый формат файла',
          };
        }

        const prevFiles =
          value &&
          dataConfig &&
          role &&
          getDataByExtension(configByDocumentType, value);
        const acceptFiles =
          validateExtensions.dropFiles[TTypeConfig.OTHER_FILE_TYPES];
        const acceptArchive = validateExtensions.dropFiles[TTypeConfig.ARCHIVE];

        const fullFilesArray = prevFiles
          ? [
              ...acceptFiles,
              ...prevFiles.dropFiles[TTypeConfig.OTHER_FILE_TYPES].filter(
                (elem) =>
                  !elem.deleted && (isCleaned ? elem.clenead : !elem.clenead)
              ),
            ]
          : acceptFiles;

        const fullArchiveArray = prevFiles
          ? [
              ...acceptArchive,
              ...prevFiles.dropFiles[TTypeConfig.ARCHIVE].filter(
                (elem) =>
                  !elem.deleted && (isCleaned ? elem.clenead : !elem.clenead)
              ),
            ]
          : acceptArchive;

        if (fullFilesArray.length > Number(countOtherFiles)) {
          return {
            isValidate: false,
            messageError: `Можно загрузить до ${plural(
              Number(countOtherFiles),
              '$d файла',
              '$d файлов',
              '$d файлов'
            )}`,
          };
        }

        if (fullArchiveArray.length > Number(countArchive)) {
          return {
            isValidate: false,
            messageError: `Можно загрузить до ${plural(
              Number(countArchive),
              '$d архива',
              '$d архивов',
              '$d архивов'
            )}`,
          };
        }

        const validatedSizeFiles = validateSizeByType(
          acceptFiles,
          configByDocumentType,
          TTypeConfig.OTHER_FILE_TYPES
        );
        if (validatedSizeFiles) {
          return {
            isValidate: validatedSizeFiles.isValidate,
            messageError: validatedSizeFiles.messageError,
          };
        }

        const validatedSizeArchive = validateSizeByType(
          acceptArchive,
          configByDocumentType,
          TTypeConfig.ARCHIVE
        );
        if (validatedSizeArchive) {
          return {
            isValidate: validatedSizeArchive.isValidate,
            messageError: validatedSizeArchive.messageError,
          };
        }
      }

      return {
        isValidate: true,
      };
    },
    [limit, value, dataConfig, role, documentType]
  );

  const [showCleanedConfirm, setShowCleanedConfirm] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles: File[], isCleaned?: boolean) => {
      // TODO useDropzone поддерживает только один параметр проверки на превышение максимального кол-ва файлов
      // TODO функция validator не имеет доступа ко всем файлам
      const { isValidate, messageError } = validateFiles(
        acceptedFiles,
        isCleaned
      );

      if (!isValidate) {
        setErrorValidate(messageError);
      } else if (onChange) {
        setErrorValidate(undefined);

        const resultFiles: TFile[] = acceptedFiles.map((file) => ({
          name: file.name,
          sizeBytes: file.size,
          createdAt: new Date().toISOString(),
          createdByUser: {
            lastName: user.lastName,
            firstName: user.firstName,
            middleName: user.middleName,
          },
          id: Math.random(),
          isNew: true,
          file,
          clenead: !!isCleaned,
        }));

        onChange(value ? resultFiles.concat(...value) : resultFiles);
      }
    },
    [onChange, user, value, validateFiles]
  );

  const [cleanedFilesToDrop, setCleanedFilesToDrop] = useState<File[]>([]);

  const handleOnDropCleaned = (acceptedFiles: File[]) => {
    setCleanedFilesToDrop([...acceptedFiles]);

    setShowCleanedConfirm(true);
  };

  const handleClearAllDocument = () => {
    if (!onChange || !value) return;

    onChange(
      value
        .filter((element) => !element.isNew)
        .map((element) => ({
          ...element,
          deleted: true,
        }))
    );
  };

  const help =
    helpText ||
    (dataConfig && role && dataConfig[role]?.[documentType]
      ? getHelp(dataConfig[role][documentType], showAvailableExtList)
      : '');

  const getBottomOffset = () => {
    if (variant === 'default' && !title && !actions) {
      return undefined;
    }

    return variantConfig[variant].marginBottom;
  };

  const filesCount = value?.filter((item) => !item.deleted)?.length ?? 0;

  const renderSection = (files: TFile[], isCleanedFileList?: boolean) => (
    <div
      className={classNames(
        styles.wrapper,
        (error || errorValidate) && styles.error,
        className
      )}>
      {!valueCounter ? null : (
        <Box mt="12px">
          <FileList
            value={files}
            onChange={onChange}
            onDownload={onDownload}
            onRemove={onRemove}
            removeMode={removeMode}
            showDownloadButton={showDownloadButton}
            imagesPreview={imagesPreview}
            showCleanButton={showCleanButton}
            isCleanedFileList={isCleanedFileList}
          />
        </Box>
      )}
    </div>
  );

  return (
    <Stack>
      <Box mb="12px">
        <Typography
          variant={variantConfig[variant].variant}
          lineHeight={variantConfig[variant].lineHeight}>
          {title}
        </Typography>
      </Box>

      <Box mb="12px">
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="600">Всего файлов: {filesCount}</Typography>

          <Box display="flex" gap="24px" alignItems="center">
            {actions}

            {value && value.length > 1 && (
              <Button
                onClick={() => setShowRemoveConfirm(true)}
                aria-label="clear all"
                title="Очистить все"
                variant="text">
                <Typography color="#7A8694">Удалить все файлы</Typography>
              </Button>
            )}
          </Box>
        </Stack>
      </Box>

      {subtitle}

      <Stack direction="row" gap="20px" mb="8px">
        <Typography fontSize="16px" fontWeight="500" flexBasis="50%">
          Необработанные
        </Typography>
        <Typography fontSize="16px" fontWeight="500" flexBasis="50%">
          Обработанные
        </Typography>
      </Stack>

      <Stack direction="row" gap="20px" mb="8px">
        <Stack flexBasis="50%">
          <Box maxHeight="52px">
            <FileDropZone
              onDrop={(files) => onDrop(files, false)}
              isDisable={isDisable}
              inputRef={inputRef}
            />
          </Box>

          <Typography className={styles.help} color="text.secondary">
            {errorValidate || help}
          </Typography>
        </Stack>

        <Stack flexBasis="50%">
          <Box maxHeight="52px">
            <FileDropZone
              onDrop={handleOnDropCleaned}
              isDisable={isDisable}
              inputRef={inputRef}
              isCleaned
            />
          </Box>

          <Typography className={styles.help} color="text.secondary">
            {errorValidate || help}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" gap="20px" className={styles.sectionWrapper}>
        {renderSection(value || [])}
        {renderSection(value || [], true)}
      </Stack>

      {showRemoveConfirm && (
        <ConfirmDialog
          open
          close={() => setShowRemoveConfirm(false)}
          title="Удалить все файлы?"
          confirmText="Да, удалить"
          onConfirm={() => {
            setShowRemoveConfirm(false);
            handleClearAllDocument();
          }}
        />
      )}

      {showCleanedConfirm && (
        <ConfirmDialog
          open
          close={() => {
            setShowCleanedConfirm(false);
            setCleanedFilesToDrop([]);
          }}
          title="Загрузить файл в очищенные?"
          confirmText="Продолжить"
          onConfirm={() => {
            onDrop(cleanedFilesToDrop, true);
            setShowCleanedConfirm(false);
            setCleanedFilesToDrop([]);
          }}>
          <Typography>
            Вы уверены в том, что очистили файл? Данный файл будет доступен
            исполнителю.
          </Typography>
        </ConfirmDialog>
      )}
    </Stack>
  );
};
