import { ReactNode } from 'react';
import { Tooltip, Typography } from 'src/components/ui';
import styles from './notification-dot.module.scss';

interface INotificationDotProps {
  children: ReactNode;
  message?: string;
  isShown?: boolean;
  counter?: number;
  color?: string;
}

export const NotificationDot = ({
  children,
  message,
  isShown = false,
  counter,
  color,
}: INotificationDotProps) => (
  <div className={styles.wrapper}>
    {children}
    {isShown && (
      <Tooltip title={message} placement="top-end">
        <div className={styles.redDot} />
      </Tooltip>
    )}
    {!!counter && (
      <span className={styles.counterWrapper}>
        <Typography fontSize={12} color={color || '#0041A0'}>
          {counter}
        </Typography>
      </span>
    )}
  </div>
);
