import { FC, useState } from 'react';
import {
  businessRequestApi,
  useStartContractorRequestMutation,
} from 'src/api/business-request/business-request';
import { useConfirmLeavePage } from 'src/components/layout/bids-layout/main-bids-layout/hooks/use-confirm-leave-page';
import { Button, ConfirmDialog, Typography } from 'src/components/ui';
import { ROLES } from 'src/constants';
import { ContractorStatusCode, IContractorRequest } from 'src/models';
import { AuthService } from 'src/services';
import { getErrorMessage } from 'src/store/utils';
import { isExpiredCommercialOfferDueDate } from 'src/utils/business-request';
import { useAppDispatch } from 'src/store';
import { formatServerDate } from 'src/utils';
import { AddIcon, SquareRoundedCheck } from 'src/assets/icons';

interface ITakeWorkProps {
  contractorRequest: IContractorRequest;
}

export const TakeWorkContractorRequest: FC<ITakeWorkProps> = ({
  contractorRequest,
}) => {
  const dispatch = useAppDispatch();
  const [startContractorRequest, { isLoading, error }] =
    useStartContractorRequestMutation();

  const canTakeInWork = !(
    contractorRequest.status?.code !== ContractorStatusCode.NEW_REQUEST ||
    isExpiredCommercialOfferDueDate(contractorRequest.commercialOfferDueDate)
  );

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const [showConfirmLeavePage, handleLeavePage, setClearNavigationBlock] =
    useConfirmLeavePage(
      AuthService.currentRole === ROLES.CONTRACTOR && canTakeInWork
    );

  const toggleShowConfirmModal = () => {
    setShowConfirmModal((prevState) => !prevState);
  };

  const handleSubmit = async () => {
    try {
      const result = await startContractorRequest({
        id: contractorRequest.contractorRequestId,
      }).unwrap();

      toggleShowConfirmModal();

      dispatch(
        businessRequestApi.util.updateQueryData(
          'getContractorRequest',
          contractorRequest.contractorRequestId,
          (cache) => ({ ...cache, status: result.currentStatus })
        )
      );
    } catch {
      // ignore
    }
  };

  if (
    contractorRequest.status?.code !== ContractorStatusCode.NEW_REQUEST ||
    isExpiredCommercialOfferDueDate(contractorRequest.commercialOfferDueDate)
  ) {
    return null;
  }

  return (
    <>
      <Button
        startIcon={<SquareRoundedCheck />}
        size="medium"
        applyNewIconSizing
        sx={{ flex: 1 }}
        id="take-in-work-contractor-request-button"
        onClick={toggleShowConfirmModal}
        color="success">
        Взять в работу
      </Button>

      <ConfirmDialog
        open={showConfirmModal}
        close={toggleShowConfirmModal}
        title="Вы уверены, что хотите взять запрос в проработку?"
        confirmText="Да, уверен"
        settingsBtn={{ loading: isLoading }}
        maxWidth={600}
        onConfirm={() => {
          setClearNavigationBlock(true);
          handleSubmit();
        }}>
        <Typography variant="h4">
          {`КП необходимо отправить в срок до ${formatServerDate(
            new Date(contractorRequest.commercialOfferDueDate),
            'dd.MM.yyyy'
          )}`}
        </Typography>
        {error && (
          <Typography color="error">
            {getErrorMessage(
              error,
              'Произошла ошибка при взятии запроса в проработку'
            )}
          </Typography>
        )}
      </ConfirmDialog>

      <ConfirmDialog
        open={showConfirmLeavePage}
        close={handleLeavePage}
        title="Взять запрос в проработку?"
        confirmText="Взять в проработку"
        cancelText="Закрыть"
        settingsBtn={{ loading: isLoading }}
        maxWidth={600}
        onConfirm={() => {
          setClearNavigationBlock(true);
          handleSubmit();
        }}>
        <Typography variant="h4">
          {`По истечении срока подачи до ${formatServerDate(
            new Date(contractorRequest.commercialOfferDueDate),
            'dd.MM.yyyy'
          )} КП отправить будет невозможно. По результатам проработки есть возможность сделать отказ.`}
        </Typography>
        {error && (
          <Typography color="error" marginTop="10px">
            {getErrorMessage(
              error,
              'Произошла ошибка при взятии запроса в проработку'
            )}
          </Typography>
        )}
      </ConfirmDialog>
    </>
  );
};
