import { useState } from 'react';
import { useRejectRefusalRequestMutation } from 'src/api/business-request/business-request';
import { Check, FiltersBackArrow } from 'src/assets/icons';
import { ManagerRejectApplication } from 'src/components/layout/bids-layout/main-bids-layout/components/header/components/reject-application/components';
import { RequestInfoBlock } from 'src/components/layout/bids-layout/main-bids-layout/components/request-info-block';
import {
  Button,
  ConfirmDialog,
  OverflowTooltip,
  Stack,
  Typography,
} from 'src/components/ui';
import { ROLES } from 'src/constants';
import { BusinessRequestMode, TVariantBusinessRequest } from 'src/models';
import { AuthService } from 'src/services';
import { getErrorMessage } from 'src/store/utils';
import { formatServerDate } from 'src/utils';

export interface IRejectedByCustomerApplicationBlockProps {
  businessRequest: TVariantBusinessRequest;
  mode: BusinessRequestMode;
}

export const RejectedByCustomerApplicationBlock = ({
  businessRequest,
  mode,
}: IRejectedByCustomerApplicationBlockProps) => {
  const [showRefusalConfirmModal, setShowRefusalConfirmModal] = useState(false);
  const [showRefusalRejectModal, setShowRefusalRejectModal] = useState(false);

  const statusDescription =
    AuthService.currentRole! === ROLES.CUSTOMER
      ? 'Отклонение заявки находится на согласовании у менеджера: с'
      : 'Заказчик хочет отклонить заявку от:';

  const refusalRequestData =
    'customerBusinessApplicationRefusalRequest' in businessRequest
      ? businessRequest.customerBusinessApplicationRefusalRequest
      : null;

  const [rejectRefusalRequest, { isLoading, error }] =
    useRejectRefusalRequestMutation();

  if (
    !refusalRequestData ||
    !businessRequest.status ||
    businessRequest.status.code === 'REQUEST_REFUSAL'
  ) {
    return null;
  }

  return (
    <RequestInfoBlock
      title={
        <>
          {statusDescription}{' '}
          {formatServerDate(refusalRequestData.createdAt, 'dd.MM.yyyy HH:mm')}
        </>
      }>
      <Stack direction="column" gap="10px" sx={{ padding: '12px' }}>
        {mode === BusinessRequestMode.MANAGER && (
          <Stack direction="row" gap="8px" sx={{ flex: 1 }} flexWrap="wrap">
            <Button
              size="medium"
              color="error"
              sx={{ flex: 1 }}
              startIcon={<Check />}
              onClick={() => setShowRefusalConfirmModal(true)}>
              Подтвердить
            </Button>
            <Button
              startIcon={<FiltersBackArrow />}
              onClick={() => setShowRefusalRejectModal(true)}
              size="medium"
              sx={{ flex: 1 }}
              color="white">
              Вернуть в работу
            </Button>
          </Stack>
        )}
        <Stack direction="column" gap="4px">
          <Typography color="secondary" fontSize="12px" lineHeight="16px">
            Причина
          </Typography>
          <Typography lineHeight="20px">{refusalRequestData.cause}</Typography>
        </Stack>
        <Stack direction="column" gap="4px">
          <Typography color="secondary" fontSize="12px" lineHeight="16px">
            Комментарий:
          </Typography>
          <OverflowTooltip text={refusalRequestData.comment} />
        </Stack>
      </Stack>
      {showRefusalConfirmModal && (
        <ManagerRejectApplication
          isShowModalWindow={showRefusalConfirmModal}
          handleShowModalWindow={() => setShowRefusalConfirmModal(false)}
          businessApplicationId={businessRequest.businessApplicationId!}
          businessRequestId={businessRequest.businessRequestId!}
          isCustomerBusinessApplicationRefusal
        />
      )}

      <ConfirmDialog
        open={showRefusalRejectModal}
        close={() => setShowRefusalRejectModal(false)}
        confirmText="Да, уверен"
        onConfirm={async () => {
          await rejectRefusalRequest({
            applicationId: businessRequest.businessApplicationId!,
            businessRequestId: businessRequest.businessRequestId!,
          })
            .unwrap()
            .catch(() => {});
          setShowRefusalRejectModal(false);
        }}
        settingsBtn={{ loading: isLoading }}
        title="Вы уверены, что хотите отклонить запрос на отмену заявки?">
        {error && (
          <Typography color="error">{getErrorMessage(error)}</Typography>
        )}
      </ConfirmDialog>
    </RequestInfoBlock>
  );
};
