import IconButton from '@mui/material/IconButton';
import { FC, useState } from 'react';
import { History } from 'src/assets/icons';
import {
  Box,
  Form,
  Grid,
  InfoRow,
  NumberInput,
  Switch,
  Typography,
} from 'src/components/ui';
import { ChangeLogsWindow } from 'src/components/widgets/change-logs-window';
import { FIELD_LENGTH } from 'src/constants';
import { IChangeLogs } from 'src/models/changelogs';
import {
  maxLength,
  notNegativeNumber,
  positiveNumber,
  required,
} from 'src/utils/form-rules';
import { IComponentsProp } from '../../types';

interface IFinanceProps extends IComponentsProp {
  shippingIncluded?: boolean;
  costsLogisticsIsManually?: boolean;
  isAuction?: boolean;
  costByContractorHistory?: IChangeLogs;
  readonlyCostByContractor?: boolean;
}

export const Finance: FC<IFinanceProps> = ({
  isEditing,
  mode,
  checkRequired = true,
  offerFromContractor,
  shippingIncluded,
  costsLogisticsIsManually,
  costByContractorHistory,
  isAuction,
  readonlyCostByContractor = false,
}) => {
  const [isChangeModalOpen, setIsChangeModalOpen] = useState<boolean>(false);
  const toggleChangeModalOpen = () => {
    setIsChangeModalOpen(!isChangeModalOpen);
  };
  return (
    <>
      <Typography variant="h3" mb="24px">
        Финансы
      </Typography>

      {isEditing && mode === 'customer' && (
        <Form.Item
          viewMode={!isEditing}
          label="С логистикой"
          name="shippingIncluded"
          isControlLabel>
          <Switch />
        </Form.Item>
      )}

      <Grid container columnSpacing="24px">
        <Grid item xs={6}>
          <Form.Item
            required
            label="Стоимость исполнителя, с НДС"
            viewMode={
              offerFromContractor ||
              isAuction ||
              !isEditing ||
              readonlyCostByContractor
            }
            name="costByContractor"
            rules={{
              required: checkRequired ? required : undefined,
              maxLength: maxLength(FIELD_LENGTH.commercialOffer.price),
              validate: {
                positiveNumber: positiveNumber(),
              },
            }}
            renderView={(value) => (
              <InfoRow
                label="Стоимость исполнителя, с НДС"
                value={
                  <>
                    <NumberInput
                      value={value || 0}
                      mode="price"
                      displayType="text"
                    />
                    {mode === 'contractor' &&
                      !!costByContractorHistory?.history.length && (
                        <IconButton onClick={toggleChangeModalOpen}>
                          <History />
                        </IconButton>
                      )}
                  </>
                }
              />
            )}>
            <NumberInput mode="price" />
          </Form.Item>
        </Grid>

        {/* {mode === 'contractor' && (
        <Grid item xs={6}>
          <Form.Item
            required
            help={
              shippingIncluded &&
              costsLogisticsIsManually && (
                <Typography color="#368DFF">
                  Значение указано вручную
                </Typography>
              )
            }
            label="Стоимость логистики, с НДС"
            viewMode={!isEditing}
            name="logistics.costsLogistics"
            rules={{
              required:
                checkRequired && shippingIncluded ? required : undefined,
              maxLength: maxLength(FIELD_LENGTH.commercialOffer.costsLogistics),

              validate: shippingIncluded
                ? {
                    positiveNumber: positiveNumber(),
                  }
                : {},
            }}
            renderViewValue={(value) =>
              shippingIncluded ? (
                <NumberInput
                  value={value || 0}
                  mode="price"
                  displayType="text"
                />
              ) : (
                'Не учитывается'
              )
            }>
            <NumberInput disabled={!shippingIncluded} mode="price" />
          </Form.Item>
        </Grid>
      )} */}
        {mode === 'customer' && (
          <>
            <Grid item xs={6}>
              <Form.Item
                required
                label="Стоимость логистики, с НДС"
                viewMode={!isEditing}
                name="shippingCost"
                rules={{
                  required:
                    checkRequired && shippingIncluded ? required : undefined,
                  maxLength: maxLength(
                    FIELD_LENGTH.commercialOffer.costsLogistics
                  ),

                  validate: shippingIncluded
                    ? {
                        positiveNumber: positiveNumber(),
                      }
                    : {},
                }}
                renderView={(value) => (
                  <InfoRow
                    label="Стоимость логистики, с НДС"
                    value={
                      shippingIncluded ? (
                        <NumberInput
                          value={value || 0}
                          mode="price"
                          displayType="text"
                        />
                      ) : (
                        'Не учитывается'
                      )
                    }
                  />
                )}>
                <NumberInput disabled={!shippingIncluded} mode="price" />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Form.Item
                required
                label="Стоимость для заказчика, с НДС"
                viewMode={!isEditing}
                name="costForCustomer"
                rules={{
                  required: checkRequired ? required : undefined,
                  maxLength: maxLength(FIELD_LENGTH.commercialOffer.price),
                  validate: {
                    positiveNumber: positiveNumber(),
                    checkPrice: (value, values: any) =>
                      !value ||
                      !values.costByContractor ||
                      Number(value) >= Number(values.costByContractor) ||
                      'Стоимость для заказчика не может быть меньше стоимости исполнителя',
                  },
                }}
                renderView={(value) => (
                  <InfoRow
                    label="Стоимость для заказчика, с НДС"
                    value={
                      <NumberInput
                        value={value || 0}
                        mode="price"
                        displayType="text"
                        allowNegative
                      />
                    }
                  />
                )}>
                <NumberInput mode="price" disabled={readonlyCostByContractor} />
              </Form.Item>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <Form.Item
                    required
                    label="Выручка, руб"
                    viewMode={!isEditing}
                    name="income"
                    rules={{
                      required: checkRequired ? required : undefined,
                      maxLength: maxLength(FIELD_LENGTH.commercialOffer.price),
                      validate: {
                        positiveNumber: notNegativeNumber(),
                      },
                    }}
                    renderView={(value) => (
                      <InfoRow
                        label="Выручка, руб."
                        value={
                          <NumberInput
                            value={value || 0}
                            mode="price"
                            displayType="text"
                            allowNegative
                          />
                        }
                      />
                    )}>
                    <NumberInput
                      mode="price"
                      allowNegative
                      disabled={readonlyCostByContractor}
                    />
                  </Form.Item>
                </Grid>

                <Grid item xs={4}>
                  <Form.Item
                    required
                    rules={{
                      validate: {
                        positiveNumber: notNegativeNumber(),
                      },
                    }}
                    label="Выручка, %"
                    viewMode={!isEditing}
                    name="incomePercent"
                    renderView={(val) => (
                      <InfoRow
                        label={
                          <Box
                            sx={{
                              whiteSpace: 'nowrap',
                            }}>
                            Выручка, %
                          </Box>
                        }
                        value={
                          <NumberInput
                            value={val || 0}
                            mode="price"
                            displayType="text"
                            allowNegative
                          />
                        }
                      />
                    )}>
                    <NumberInput
                      allowNegative
                      mode="price"
                      disabled={readonlyCostByContractor}
                      inputProps={{ maxLength: 18 }}
                    />
                  </Form.Item>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}

        <ChangeLogsWindow
          onClose={toggleChangeModalOpen}
          changeHistory={costByContractorHistory?.history}
          title="Стоимость исполнителя"
          open={isChangeModalOpen}
        />
      </Grid>
    </>
  );
};
