const isValidNumber = (value?: number | null): value is number =>
  typeof value === 'number' && !Number.isNaN(value);

export const calculatePriceWithoutVat = (
  pricePerPosition?: number | null,
  vat?: number | null
) => {
  if (!isValidNumber(pricePerPosition) || !isValidNumber(vat)) {
    return null;
  }

  return pricePerPosition / (1 + vat / 100);
};
