import { Box, Typography } from 'src/components/ui';
import cn from 'classnames';
import styles from '../../styles.module.scss';
import {
  DAY_TYPES,
  PAYMENT_MOMENTS,
  PAYMENT_TYPES,
} from '../../payment-conditions.const';
import { IPaymentConditionProps } from './payment-condition.types';

type TPaymentConditionViewProps = Pick<
  IPaymentConditionProps,
  | 'paymentType'
  | 'paymentPercentage'
  | 'paymentInDays'
  | 'dayType'
  | 'paymentMoment'
  | 'maxWidth'
  | 'index'
  | 'isCustomerRequestPage'
>;

export const PaymentConditionView = (props: TPaymentConditionViewProps) => {
  const {
    paymentType,
    paymentPercentage,
    paymentInDays,
    dayType,
    paymentMoment,
    maxWidth,
    index = 0,
    isCustomerRequestPage = false,
  } = props;

  return (
    <Box display="flex" className={styles.row} gap="12px" alignItems="center">
      <Box width="80px" className={cn(styles.item, styles.hasWidth)}>
        <Typography color="primary">{index + 1} часть</Typography>
      </Box>
      <Box
        className={cn(
          styles.item,
          isCustomerRequestPage && styles.itemWithoutMaxWidth
        )}
        maxWidth={maxWidth}>
        {PAYMENT_TYPES.find((type) => type.value === paymentType)?.label}{' '}
        {paymentPercentage}% в течении {paymentInDays}{' '}
        {DAY_TYPES.find((type) => type.value === dayType)?.short} дней с момента{' '}
        {
          PAYMENT_MOMENTS.find((moment) => moment.value === paymentMoment)
            ?.label
        }
      </Box>
    </Box>
  );
};
