import { Stack } from '@mui/material';
import { ReactNode } from 'react';
import { Card, Empty, Typography } from 'src/components/ui';
import { ColumnsType } from 'src/components/ui/new-table/types';
import { Cell } from './components';

interface NewTableMobileProps<RecordType> {
  columns: ColumnsType<RecordType>;
  data?: RecordType[];
  emptyText?: ReactNode;
  page: number;
}

export const NewTableMobile = <RecordType extends { key: number | string }>({
  data,
  columns,
  emptyText,
  page,
}: NewTableMobileProps<RecordType>) =>
  data?.length ? (
    <Stack gap="8px">
      {data.map((item, rowIndex) => (
        <Card noMargin noShadow>
          <Stack gap="12px">
            {columns.map((column, index) => (
              <Cell<RecordType>
                key={String(column.title)}
                column={column}
                index={index}
                record={item}
                rowIndex={rowIndex}
                page={page}
              />
            ))}
          </Stack>
        </Card>
      ))}
    </Stack>
  ) : (
    <Empty>
      {emptyText ? (
        <div>{emptyText}</div>
      ) : (
        <>
          <Typography variant="h2">Ничего не найдено</Typography>
          <Typography fontSize="16" align="center">
            Попробуйте задать другие фильтры <br /> и повторите запрос.
          </Typography>
        </>
      )}
    </Empty>
  );
