import { useEffect, useState } from 'react';
import {
  FILE_UPLOAD_ERRORS,
  MAX_FILE_SIZE,
  MAX_FILE_SUM_SIZE,
  MAX_FILES_COUNT,
} from 'src/components/pages/communications/config';
import { useUploadFileMutation } from 'src/api/communications';
import {
  TFile,
  TUploadingFile,
} from 'src/components/pages/communications/components/emails/components/email-dialog/components/send-block/components';
import { saveFiles } from 'src/components/pages/communications/utils';

const DEFAULT_SENDER_ACCOUNT = 'zakaz@cometal.com';

export const useFiles = (
  showUploadError: (error: { title: string; body: string }) => void,
  handleOpenPopover: () => void,
  handleClosePopover: () => void,
  sender?: string
) => {
  const [uploadFile] = useUploadFileMutation();

  const [uploadedFiles, setUploadedFiles] = useState<TUploadingFile[] | null>(
    null
  );

  useEffect(() => {
    if (uploadedFiles?.length === 1) {
      const isFileSizeError = uploadedFiles?.some((file) => file.isError);

      if (isFileSizeError) {
        showUploadError(FILE_UPLOAD_ERRORS.max_file_size);

        return;
      }
    } else {
      const fileSizeSum = uploadedFiles?.reduce(
        (acc, file) => acc + file.sizeBytes,
        0
      );
      const isFileSizeSumError =
        !!fileSizeSum && fileSizeSum > MAX_FILE_SUM_SIZE;

      if (isFileSizeSumError) {
        showUploadError(FILE_UPLOAD_ERRORS.max_file_sum_size);

        return;
      }
    }

    handleClosePopover();
  }, [handleOpenPopover, uploadedFiles]);

  const onChange = (files: TFile[], isRemoveMode?: boolean) => {
    const notValidSizeFiles: TUploadingFile[] = [];
    const validSizeFiles: TUploadingFile[] = [];

    let filesToUpload = files;
    let allFiles;

    if (!isRemoveMode) {
      if (uploadedFiles && uploadedFiles.length) {
        const uploadedFilesNameList = uploadedFiles.map((file) => file.name);
        filesToUpload = files.filter(
          (file) => !uploadedFilesNameList.includes(file.name)
        );

        if (!filesToUpload.length) {
          return;
        }
      }

      allFiles =
        uploadedFiles && uploadedFiles.length
          ? [...filesToUpload, ...uploadedFiles]
          : filesToUpload;
    } else {
      allFiles = [...files];
    }

    if (allFiles.length > MAX_FILES_COUNT) {
      showUploadError(FILE_UPLOAD_ERRORS.max_count);
      return;
    }

    const fileSizeSum = allFiles?.reduce(
      (acc, file) => acc + file.sizeBytes,
      0
    );
    const isFileSizeSumError = !!fileSizeSum && fileSizeSum > MAX_FILE_SUM_SIZE;

    if (isFileSizeSumError) {
      const tmp = allFiles.map((file) => ({
        ...file,
        isError: true,
      })) as TUploadingFile[];

      notValidSizeFiles.push(...tmp);
    } else {
      allFiles.forEach((file) => {
        if (file.sizeBytes > MAX_FILE_SIZE) {
          notValidSizeFiles.push({ ...file, isError: true });
        } else {
          validSizeFiles.push({ ...file, isError: false });
        }
      });
    }

    setUploadedFiles(
      [...notValidSizeFiles, ...validSizeFiles].map((file) => ({
        ...file,
        isLoaded: !!file.isLoaded,
      }))
    );

    if (!validSizeFiles.length) {
      return;
    }

    saveFiles({
      files: validSizeFiles,
      account: sender || DEFAULT_SENDER_ACCOUNT,
      uploadHandler: (file) => uploadFile(file).unwrap(),
    }).then(({ saveResult, error }) => {
      const loadedFiles = saveResult.map((file) => ({
        ...file,
        isLoaded: true,
        isError: false,
      }));

      setUploadedFiles([...notValidSizeFiles, ...loadedFiles]);

      // TODO: Add error handler
      return error ? Promise.reject() : Promise.resolve();
    });
  };

  const handleRemoveFile = (fileName: string) => {
    if (uploadedFiles && uploadedFiles.length) {
      const filteredUploadedFiles = uploadedFiles.filter(
        (file) => file?.name !== fileName
      );

      onChange(filteredUploadedFiles, true);
    }
  };

  return {
    uploadedFiles,
    setUploadedFiles,
    handleRemoveFile,
    onChange,
  };
};

export type UseFilesHookResult = ReturnType<typeof useFiles>;
