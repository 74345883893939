import { FC, useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import { DatePicker, Form, Box } from 'src/components/ui';
import { TConfigFilter, TVariant } from 'src/components/ui/filters/types';
import { getServerDate } from 'src/utils';

import { isValid } from 'date-fns';
import { date } from 'src/utils/form-rules';
import styles from './date-picker.module.scss';

type TFormInput = Record<string, Date | null | string>;

export const INVALID_DATE = 'Invalid Date';

export const getLabelChip = (
  nameFields: Array<string>,
  values: TFormInput,
  inputDate: any
): string => {
  if (!inputDate) {
    return '';
  }
  return nameFields
    .map((nameField) => {
      if (values?.[nameField] && isValid(values[nameField])) {
        const dateValue = values[nameField] || '';
        return getServerDate(dateValue, 'dd.MM.yyyy');
      }
      return '';
    })
    .join(' - ');
};

const getDefaultValues = (
  namesFields: Array<string>,
  values: Array<string | null | undefined>
) => {
  const defaultValue: Record<string, any> = {};

  namesFields.forEach((namesField, i) => {
    defaultValue[namesField] = values[i] ? new Date(values[i]!) : null;
  });

  return defaultValue;
};

const getDate = (value: Date | null | string) => {
  if (value) {
    const dateValue = value || '';
    return getServerDate(dateValue);
  }
  return null;
};

export type DatePickerFormProps = {
  filter: TConfigFilter;
  onFilter: (selectedFilters: Record<string, Array<TVariant>>) => void;
  clear: boolean;
  error?: Record<string, string>;
};

export const DatePickerForm: FC<DatePickerFormProps> = ({
  filter,
  onFilter,
  clear,
  error,
}) => {
  const [namesFields, fieldValues] = useMemo(() => {
    const names = filter.variantList.map((variant) => variant.value);
    const values = filter.variantList.map((variant) => variant.date);

    return [names, values];
  }, [filter.variantList]);

  const form = useForm<TFormInput>({
    defaultValues: getDefaultValues(namesFields, fieldValues),
  });

  const handleChange = (inputDate: unknown, value: string) => {
    form.clearErrors(value);
    const values = form.getValues();
    const labelChip = getLabelChip(namesFields, values, inputDate);
    onFilter({
      [filter.name]: filter.variantList.map((variant) => {
        const currentDate = isValid(values[variant.value])
          ? getDate(values[variant.value])
          : INVALID_DATE;
        return {
          ...variant,
          date: values[variant.value] ? currentDate : null,
          label: labelChip,
          checked: true,
        };
      }),
    });

    if (!inputDate || isValid(inputDate)) {
      form.clearErrors(value);
    } else {
      form.setError(value, { type: 'custom' });
    }
  };

  useEffect(() => {
    if (clear) {
      namesFields.forEach((namesField) => {
        form.clearErrors(namesFields);
        form.setValue(`${namesField}`, null, { shouldDirty: true });
      });
      onFilter({ [filter.name]: [] });
    }
  }, [clear, filter.name, onFilter, namesFields, form]);

  return (
    <div className={styles.datePickerContainer}>
      <Form form={form} footer={false}>
        {filter.variantList.map((variant, index) => (
          <Form.Item
            noMargin
            name={variant.value}
            key={index}
            rules={{
              validate: date,
            }}>
            <DatePicker
              maxDate={new Date()}
              placeholder={variant.placeHolder}
              onChange={(dateValue) => handleChange(dateValue, variant.value)}
            />
          </Form.Item>
        ))}
      </Form>
      {error && error?.[filter.name] && (
        <Box color="error.dark">{error?.[filter.name]}</Box>
      )}
    </div>
  );
};
