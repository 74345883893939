import { FC } from 'react';
import {
  isContactTemporary,
  TContact,
  TContactWithSigningBasis,
  TTemporaryContact,
} from 'src/components/pages/company/contacts/types';
import {
  RegularContactCard,
  TemporaryContactCard,
} from 'src/components/widgets/contact-item/components';
import { TCompanyPermissions } from 'src/configs/company';
import { IBusinessRequestCustomerInfo } from 'src/models';

export interface IContactItemProps {
  item: TContact | TContactWithSigningBasis | TTemporaryContact;
  permissions?: TCompanyPermissions;
  onChange?: (x: TContact, field: keyof TContact, value: any) => void;
  onDelete?: (id: number) => void;
  onEdit?: (item?: TContact) => void;
  countElements?: number;
  businessRequest?: IBusinessRequestCustomerInfo;
  showSignerInfo?: boolean;
}

export const ContactItem: FC<IContactItemProps> = ({
  item,
  permissions,
  onChange,
  onDelete,
  onEdit,
  countElements,
  businessRequest,
  showSignerInfo,
}) => {
  if (isContactTemporary(item)) {
    return <TemporaryContactCard item={item} />;
  }

  return (
    <RegularContactCard
      item={item}
      onDelete={onDelete}
      onChange={onChange}
      countElements={countElements}
      permissions={permissions}
      onEdit={onEdit}
      businessRequest={businessRequest}
      showSignerInfo={showSignerInfo}
    />
  );
};
