import classNames from 'classnames';
import {
  useDeleteTemporaryContactMutation,
  useRegisterTemporaryContactMutation,
} from 'src/api/contacts';
import { useCompanyContext } from 'src/components/layout/admin/company-layout';
import { TTemporaryContact } from 'src/components/pages/company/contacts/types';
import {
  Box,
  Button,
  InfoRow,
  Link,
  OverflowTooltip,
  PhoneInput,
  Stack,
  Typography,
} from 'src/components/ui';
import { PHONES } from 'src/components/ui/phone-input';
import { ROLES } from 'src/constants';
import styles from './temporary-contact-card.module.scss';

export const TemporaryContactCard = ({ item }: { item: TTemporaryContact }) => {
  const { companyData } = useCompanyContext();

  const [
    registerTemporaryContact,
    { isLoading: isRegisterTemporaryContactLoading },
  ] = useRegisterTemporaryContactMutation();
  const [
    deleteTemporaryContact,
    { isLoading: isDeleteTemporaryContactLoading },
  ] = useDeleteTemporaryContactMutation();

  const handleRegisterTemporaryContact = () => {
    registerTemporaryContact({
      temporaryContactId: item.id,
      organizationId: companyData.id,
    });
  };

  const handleDeleteTemporaryContact = () => {
    deleteTemporaryContact(item.id);
  };

  const getRoleLabel = () => {
    if (item.roles.length > 1) {
      return 'Заказчик, Исполнитель';
    }

    if (item.roles[0] === ROLES.CUSTOMER) {
      return 'Заказчик';
    }

    if (item.roles[0] === ROLES.CONTRACTOR) {
      return 'Исполнитель';
    }

    return 'Не определено';
  };

  return (
    <div className={classNames(styles.card)}>
      <div className={styles.container}>
        <Typography
          color="secondary"
          sx={{ width: '100%', textAlign: 'end', marginBottom: '16px' }}
          fontSize="12px"
          lineHeight="16px">
          Ожидает подтверждения
        </Typography>
        <InfoRow
          label="Фамилия"
          value={
            <Box display="flex" gap="5px">
              {item.lastName}
            </Box>
          }
          overflowTooltip
        />

        <InfoRow label="Имя" value={item.firstName} overflowTooltip />
        <InfoRow label="Отчество" value={item.middleName} overflowTooltip />
        <InfoRow label="Должность" value={item.position} overflowTooltip />

        <div>
          <InfoRow
            key={item.number}
            label={PHONES.MOBILE}
            value={
              <PhoneInput
                displayType="text"
                value={{ number: item.number, type: 'MOBILE' }}
              />
            }
          />
        </div>

        <InfoRow
          label="Электронный адрес"
          value={
            <Stack
              direction="row"
              gap="4px"
              className={styles.zeroMinWidthContainer}>
              <Link
                className={styles.zeroMinWidthContainer}
                href={`mailto:${item.mail}`}>
                <OverflowTooltip text={item.mail} />
              </Link>
            </Stack>
          }
        />

        <InfoRow
          label="Роль"
          value={<Typography>{getRoleLabel()}</Typography>}
        />
      </div>
      <Stack direction="column" gap="16px">
        <Button
          sx={{ width: '100%' }}
          onClick={handleRegisterTemporaryContact}
          loading={isRegisterTemporaryContactLoading}>
          Подтвердить регистрацию
        </Button>
        <Button
          sx={{ width: '100%' }}
          color="secondary"
          onClick={handleDeleteTemporaryContact}
          loading={isDeleteTemporaryContactLoading}>
          Удалить контакт
        </Button>
      </Stack>
    </div>
  );
};
