import { InputAdornment } from '@mui/material';
import cn from 'classnames';
import { TrashDeleteTableItemIcon } from 'src/assets/icons';
import { buttonStyles } from 'src/components/pages/business-request-detail/components/payment-conditions/components/payment-condition/payment-condition.const';
import {
  Autocomplete,
  Box,
  Form,
  IconButton,
  InfoRow,
  NumberInput,
  OutlinedInput,
} from 'src/components/ui';
import { IOkei } from 'src/models';
import { maxNumber, minNumber, number, required } from 'src/utils/form-rules';
import { IPositionProps } from './position.types';
import styles from './styles.module.scss';

export const ContractorPosition = ({
  index = 0,
  position,
  handleChange,
  form,
  isEditMode,
  isCreateMode = false,
  handleGetOkeiOptionsList,
  handleRemovePosition,
  isHavePositions,
  shortView,
}: IPositionProps) => {
  const isValidNumber = (value?: number | null): value is number =>
    typeof value === 'number' && !Number.isNaN(value);

  const calculateNetPrice = (
    pricePerPosition?: number | null,
    vat?: number | null
  ) => {
    if (!isValidNumber(pricePerPosition) || !isValidNumber(vat)) {
      return null;
    }
    return pricePerPosition + (pricePerPosition / 100) * vat;
  };

  const calculateNetTotal = (
    netPrice?: number | null,
    quantity?: number | null
  ) => {
    if (!isValidNumber(netPrice) || !isValidNumber(quantity)) {
      return null;
    }
    return netPrice * quantity;
  };

  const calculateNetPriceFromNetTotal = (
    netTotal?: number | null,
    quantity?: number | null
  ) => {
    if (!isValidNumber(netTotal) || !isValidNumber(quantity)) {
      return null;
    }
    return netTotal / quantity;
  };

  const calculateVatFromNetPrice = (
    pricePerPosition?: number | null,
    netPrice?: number | null
  ) => {
    if (!isValidNumber(pricePerPosition) || !isValidNumber(netPrice)) {
      return null;
    }
    return ((netPrice - pricePerPosition) / pricePerPosition) * 100;
  };

  const handleChangeVat = () => {
    const vat = Number(form!.getValues(`positions.${index}.vat`) || 0);
    const pricePerPosition = Number(
      form!.getValues(`positions.${index}.price`) || 0
    );
    const totalPrice = Number(form!.getValues(`positions.${index}.total`) || 0);
    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );

    if (
      !isValidNumber(vat) ||
      !isValidNumber(pricePerPosition) ||
      !isValidNumber(quantity) ||
      !isValidNumber(totalPrice)
    ) {
      return null;
    }

    const netPrice = calculateNetPrice(pricePerPosition, vat);
    const netTotal = calculateNetTotal(netPrice, quantity);

    form!.setValue(
      `positions.${index}.netPrice`,
      parseFloat(netPrice!.toFixed(2))
    );
    form!.setValue(
      `positions.${index}.netTotal`,
      parseFloat(netTotal!.toFixed(2))
    );
    const revenuePerPosition = Number((netTotal! - totalPrice).toFixed(2));
    form!.setValue(`positions.${index}.revenuePerPosition`, revenuePerPosition);

    handleChange?.();
    return null;
  };

  const handleChangePrice = () => {
    const price = Number(form!.getValues(`positions.${index}.price`) || 0);
    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );

    if (!isValidNumber(quantity)) {
      return null;
    }

    const total = parseFloat((price * quantity).toFixed(2));
    form!.setValue(`positions.${index}.total`, total);
    handleChangeVat();
    handleChange?.(String(position.id!), total);
    return null;
  };

  const handleChangeTotal = () => {
    const total = parseFloat(
      Number(form!.getValues(`positions.${index}.total`) || 0).toFixed(2)
    );
    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );

    if (!isValidNumber(quantity)) {
      return null;
    }

    const price = parseFloat((total / quantity).toFixed(2));
    form!.setValue(`positions.${index}.price`, price);
    handleChangeVat();
    handleChange?.(String(position.id!), total);
    return null;
  };

  const handleChangeNetPrice = () => {
    const netPrice = Number(
      form!.getValues(`positions.${index}.netPrice`) || 0
    );
    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );
    const pricePerPosition = Number(
      form!.getValues(`positions.${index}.price`) || 0
    );
    const totalPrice = Number(form!.getValues(`positions.${index}.total`) || 0);

    if (
      !isValidNumber(netPrice) ||
      !isValidNumber(quantity) ||
      !isValidNumber(pricePerPosition) ||
      !isValidNumber(totalPrice)
    ) {
      return null;
    }

    const vat = calculateVatFromNetPrice(pricePerPosition, netPrice);
    const netTotal = calculateNetTotal(netPrice, quantity);

    form!.setValue(`positions.${index}.vat`, parseFloat(vat!.toFixed(2)));

    form!.setValue(
      `positions.${index}.netTotal`,
      parseFloat(netTotal!.toFixed(2))
    );

    const revenuePerPosition = Number((netTotal! - totalPrice).toFixed(2));
    form!.setValue(`positions.${index}.revenuePerPosition`, revenuePerPosition);

    handleChange?.();
    return null;
  };

  const handleChangeNetTotal = () => {
    const netTotal = Number(
      form!.getValues(`positions.${index}.netTotal`) || 0
    );
    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );
    const pricePerPosition = Number(
      form!.getValues(`positions.${index}.price`) || 0
    );
    const totalPrice = Number(form!.getValues(`positions.${index}.total`) || 0);

    if (
      !isValidNumber(netTotal) ||
      !isValidNumber(quantity) ||
      !isValidNumber(pricePerPosition) ||
      !isValidNumber(totalPrice)
    ) {
      return null;
    }

    const netPrice = calculateNetPriceFromNetTotal(netTotal, quantity);
    const vat = calculateVatFromNetPrice(pricePerPosition, netPrice);

    form!.setValue(
      `positions.${index}.netPrice`,
      parseFloat(netPrice!.toFixed(2))
    );
    form!.setValue(`positions.${index}.vat`, parseFloat(vat!.toFixed(2)));
    const revenuePerPosition = Number((netTotal - totalPrice).toFixed(2));
    form!.setValue(`positions.${index}.revenuePerPosition`, revenuePerPosition);

    handleChange?.();
    return null;
  };

  if (shortView) {
    return (
      <Box
        display="flex"
        className={styles.row}
        alignItems="flex-start"
        gap="12px">
        <Box className={styles.item}>
          <Form.Item
            rules={{
              required,
            }}
            name={`positions.${index}.name`}
            className={styles.formInput}
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Наименование позиции"
                value={`${index + 1}. ${value}`}
              />
            )}
            label="Наименование позиции"
            viewMode={!isCreateMode}>
            <OutlinedInput
              placeholder="Введите наименование"
              startAdornment={
                isCreateMode ? (
                  <InputAdornment
                    sx={{ paddingTop: '2px' }}
                    position="start">{`${index + 1}.`}</InputAdornment>
                ) : null
              }
            />
          </Form.Item>
        </Box>
        <Box width="80px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            viewMode={!isCreateMode}
            renderView={(value) => (
              <InfoRow variant="secondary" label="Кол-во" value={value} />
            )}
            name={`positions.${index}.quantity`}
            label="Кол-во"
            className={styles.formInput}>
            <NumberInput
              placeholder="Введите количество"
              mode="noDecimal"
              onBlur={() => {
                handleChangePrice();
                handleChangeVat();
              }}
            />
          </Form.Item>
        </Box>
        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            viewMode={!isCreateMode || isHavePositions}
            name={`positions.${index}.price`}
            className={styles.formInput}
            label="Цена за ед."
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Цена за ед."
                value={`${value} руб.`}
              />
            )}>
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={() => handleChangePrice()}
            />
          </Form.Item>
        </Box>
        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
            }}
            name={`positions.${index}.okei`}
            viewMode={!isCreateMode}
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Ед. изм"
                value={position.okei?.measure ?? position.okeiDto?.measure}
              />
            )}
            key={`okei_row_${index}`}
            label="Ед. изм."
            className={styles.formInput}>
            <Autocomplete<IOkei>
              key={`okei_${index}`}
              fullWidth
              popupIcon
              options={[]}
              fetchApi={async (value) =>
                handleGetOkeiOptionsList ? handleGetOkeiOptionsList(value) : []
              }
              getOptionLabel={(option) => option.measure || ''}
              placeholder="Выберите Ед. изм."
              noOptionsText="Начните вводить название"
            />
          </Form.Item>
        </Box>
        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Стоимость не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Стоимость не может быть меньше 1`)(value),
              },
            }}
            name={`positions.${index}.total`}
            className={styles.formInput}
            viewMode={!isCreateMode || isHavePositions}
            label="Общая стоимость"
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Общая стоимость"
                value={`${value} руб.`}
              />
            )}>
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={() => handleChangeTotal()}
            />
          </Form.Item>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Box
        display="flex"
        className={cn(styles.row, styles.inner)}
        alignItems="flex-start"
        gap="12px">
        <Box className={styles.item}>
          <Form.Item
            rules={{
              required,
            }}
            name={`positions.${index}.name`}
            className={styles.formInput}
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Наименование позиции"
                value={`${index + 1}. ${value}`}
              />
            )}
            label="Наименование позиции"
            viewMode={!isCreateMode}>
            <OutlinedInput
              placeholder="Введите наименование"
              startAdornment={
                isCreateMode ? (
                  <InputAdornment
                    sx={{ paddingTop: '2px' }}
                    position="start">{`${index + 1}.`}</InputAdornment>
                ) : null
              }
            />
          </Form.Item>
        </Box>
        {isCreateMode && !!handleRemovePosition && (
          <Box
            width="44px"
            alignSelf="flex-start"
            className={cn(styles.item, styles.hasWidth)}>
            <IconButton
              color="secondary"
              onClick={() => {
                handleRemovePosition(index);
              }}
              style={{
                marginTop: '24px',
              }}>
              <Box {...buttonStyles}>
                <TrashDeleteTableItemIcon width={18} height={18} />
              </Box>
            </IconButton>
          </Box>
        )}
      </Box>
      <Box
        display="flex"
        className={cn(styles.row, styles.inner)}
        alignItems="flex-start"
        gap="12px">
        <Box width="80px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            viewMode={!isCreateMode}
            renderView={(value) => (
              <InfoRow variant="secondary" label="Кол-во" value={value} />
            )}
            name={`positions.${index}.quantity`}
            label="Кол-во"
            className={styles.formInput}>
            <NumberInput
              placeholder="Введите количество"
              mode="noDecimal"
              onBlur={() => {
                handleChangePrice();
                handleChangeVat();
              }}
            />
          </Form.Item>
        </Box>
        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            // isHavePositions ???
            viewMode={!isCreateMode}
            name={`positions.${index}.price`}
            className={styles.formInput}
            label="Цена за ед."
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Цена за ед."
                value={`${value} руб.`}
              />
            )}>
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={() => handleChangePrice()}
            />
          </Form.Item>
        </Box>
        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
            }}
            name={`positions.${index}.okei`}
            viewMode={!isCreateMode}
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Ед. изм"
                value={position.okei?.measure ?? position.okeiDto?.measure}
              />
            )}
            key={`okei_row_${index}`}
            label="Ед. изм."
            className={styles.formInput}>
            <Autocomplete<IOkei>
              key={`okei_${index}`}
              fullWidth
              popupIcon
              options={[]}
              fetchApi={async (value) =>
                handleGetOkeiOptionsList ? handleGetOkeiOptionsList(value) : []
              }
              getOptionLabel={(option) => option.measure || ''}
              placeholder="Выберите Ед. изм."
              noOptionsText="Начните вводить название"
            />
          </Form.Item>
        </Box>
        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Стоимость не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Стоимость не может быть меньше 1`)(value),
              },
            }}
            name={`positions.${index}.total`}
            className={styles.formInput}
            // isHavePositions ???
            viewMode={!isCreateMode}
            label="Общая стоимость"
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Общая стоимость"
                value={`${value} руб.`}
              />
            )}>
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={() => handleChangeTotal()}
            />
          </Form.Item>
        </Box>
      </Box>
      <Box
        display="flex"
        className={cn(styles.row, styles.haveBorder)}
        alignItems="flex-start"
        gap="12px">
        <Box width="80px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
              },
            }}
            name={`positions.${index}.vat`}
            className={styles.formInput}
            viewMode={!isEditMode}
            label="Наценка, %"
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Наценка, %"
                value={`${value ?? '-'} %`}
              />
            )}>
            <NumberInput
              placeholder="0"
              mode="price"
              allowNegative
              onBlur={() => handleChangeVat()}
            />
          </Form.Item>
        </Box>
        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            label="Цена для заказчика"
            name={`positions.${index}.netPrice`}
            className={styles.formInput}
            viewMode={!isEditMode}
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Цена для заказчика"
                value={`${value ?? '-'} руб.`}
              />
            )}>
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={() => handleChangeNetPrice()}
            />
          </Form.Item>
        </Box>
        <Box
          className={cn(styles.item, styles.equal)}
          alignItems="center"
          display="flex">
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            label="Выручка за позицию"
            viewMode={!isEditMode}
            renderView={(value) => (
              <InfoRow
                label="Выручка за позицию"
                variant="secondary"
                value={`${
                  !Number.isNaN(value) && Number.isFinite(value)
                    ? `${Number(value)} руб.`
                    : '-'
                }`}
              />
            )}
            name={`positions.${index}.revenuePerPosition`}
            className={styles.formInput}>
            <NumberInput mode="price" placeholder="0" disabled />
          </Form.Item>
        </Box>
        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            label="Стоимость для заказчика"
            name={`positions.${index}.netTotal`}
            className={styles.formInput}
            viewMode={!isEditMode}
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Стоимость для заказчика"
                value={`${value ?? '-'} руб.`}
              />
            )}>
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={() => handleChangeNetTotal()}
            />
          </Form.Item>
        </Box>
      </Box>
    </>
  );
};
