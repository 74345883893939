import { IconButton as MuiIconButton, IconButtonProps } from '@mui/material';
import classNames from 'classnames';
import { forwardRef } from 'react';
import { Loader } from 'src/components/ui/loader';
import styles from './icon-button.module.scss';

type MyIconButtonProps = IconButtonProps & {
  loading?: boolean;
};

export const IconButton = forwardRef<any, MyIconButtonProps>((props, ref) => (
  <MuiIconButton
    {...props}
    ref={ref}
    className={classNames(props.className, props.loading && styles.loading)}>
    {props.loading ? <Loader /> : props.children}
  </MuiIconButton>
));
