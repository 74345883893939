import classNames from 'classnames';
import {
  CSSProperties,
  HTMLAttributes,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { RoundedChevronRight } from 'src/assets/icons';
import { Box, Typography, Stack } from 'src/components/ui';
import styles from './new-card.module.scss';

interface INewCardProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title'> {
  title?: ReactNode;
  actions?: ReactNode;
  noContentPadding?: boolean;
  collapsible?: boolean;
  isCollapsed?: boolean;
  headerStyle?: CSSProperties;
  headerClassName?: string;
  wrapperStyle?: CSSProperties;
  contentStyle?: CSSProperties;
  cardRef?: React.Ref<any>;
  onCollapseChange?: (val: boolean) => void;
  onHeaderClick?: () => void;
  titleFooter?: ReactNode;
  id?: string;
}

export const NewCard = ({
  title,
  children,
  actions,
  noContentPadding,
  className,
  collapsible = false,
  isCollapsed = false,
  headerStyle,
  headerClassName,
  cardRef,
  onCollapseChange,
  wrapperStyle,
  contentStyle,
  onHeaderClick,
  titleFooter,
  id,
  ...rest
}: INewCardProps) => {
  const [collapsed, setCollapsed] = useState(collapsible && isCollapsed);

  const handleChangeCollapse = () => {
    setCollapsed((prev) => {
      onCollapseChange?.(!prev);
      return !prev;
    });
  };

  useEffect(() => {
    setCollapsed(collapsible && isCollapsed);
  }, [collapsible, isCollapsed]);

  return (
    <div
      {...rest}
      ref={cardRef}
      className={classNames(styles.wrapper, className)}
      style={wrapperStyle}
      id={id}>
      {title && (
        <div
          className={classNames(
            styles.header,
            collapsed && styles.headerNoBottomBorder,
            headerClassName
          )}
          style={{
            ...headerStyle,
            cursor: onHeaderClick ? 'pointer' : undefined,
          }}
          onClick={handleChangeCollapse}>
          <Stack
            direction="row"
            flex={1}
            gap="16px"
            alignItems="center"
            width="100%">
            {collapsible && (
              <Box
                sx={(theme) => ({
                  display: 'flex',
                  color: theme.palette.primary.main,
                  transform: collapsed ? undefined : 'rotate(270deg)',
                })}>
                <RoundedChevronRight />
              </Box>
            )}
            <Typography
              variant="h2"
              flex="1 1 auto"
              maxWidth="100%"
              minWidth={0}>
              {title}
            </Typography>
            {actions && (
              <div
                onClick={(event) => {
                  event.stopPropagation();
                }}>
                {actions}
              </div>
            )}
          </Stack>
          {titleFooter && (
            <div onClick={(e) => e.stopPropagation()} style={{ width: '100%' }}>
              {titleFooter}
            </div>
          )}
        </div>
      )}
      {!collapsed && children && (
        <div
          className={classNames(
            styles.content,
            noContentPadding && styles.contentNoPadding
          )}
          style={contentStyle}>
          {children}
        </div>
      )}
    </div>
  );
};
