import { Key } from 'react';
import { Checkbox, Stack, Typography } from 'src/components/ui';
import { ColumnType } from 'src/components/ui/new-table/types';

interface CellType<RecordType> {
  key: Key | keyof RecordType;
  record: RecordType;
  column: ColumnType<RecordType>;
  index: number;
  page: number;
  rowIndex: number;
}

export const Cell = <RecordType,>({
  column,
  index,
  record,
  page,
  rowIndex,
}: CellType<RecordType>) => {
  const data = record[column.key] as string;
  const Component =
    column.render && column.render(record, index, page, rowIndex);

  const handleSelected = () => {
    console.log('selected');
  };

  return (
    // {...column.setting} className={styles.cell}
    <Stack gap="12px" alignItems="flex-start">
      {/* checked={isItemSelected} */}
      {index === 0 && (
        <Stack direction="row">
          <Checkbox onClick={handleSelected} />
          <Typography>Выбрать</Typography>
        </Stack>
      )}
      <Stack
        alignItems={column.inlineTitleOnMobile ? 'center' : 'flex-start'}
        direction={column.inlineTitleOnMobile ? 'row-reverse' : 'column'}
        gap={column.inlineTitleOnMobile ? '8px' : '4px'}>
        <Typography
          fontSize="12px"
          sx={
            column.inlineTitleOnMobile
              ? {}
              : (theme) => ({
                  color: theme.palette.text.secondary,
                })
          }>
          {column.title}
        </Typography>
        {Component || data}
      </Stack>
    </Stack>
  );
};
