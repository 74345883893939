import cn from 'classnames';
import { useEffect } from 'react';
import { TrashDeleteTableItemIcon } from 'src/assets/icons';
import {
  Autocomplete,
  Box,
  Button,
  Form,
  InfoRow,
  NumberInput,
  OutlinedInput,
} from 'src/components/ui';
import { IOkei } from 'src/models';
import { maxNumber, minNumber, number, required } from 'src/utils/form-rules';
import styles from '../styles.module.scss';
import { IPositionProps } from './position.types';

export const Position = ({
  position,
  isEditMode,
  handleChange,
  index = 0,
  form,
  isCreateMode,
  handleGetOkeiOptionsList,
  handleRemovePosition,
}: IPositionProps) => {
  const isValidNumber = (value?: number | null): value is number =>
    typeof value === 'number' && !Number.isNaN(value);

  const calculateNetPrice = (
    pricePerPosition?: number | null,
    vat?: number | null
  ) => {
    if (!isValidNumber(pricePerPosition) || !isValidNumber(vat)) {
      return null;
    }

    // return pricePerPosition + (pricePerPosition / 100) * vat;

    return pricePerPosition / (1 + vat / 100);
  };

  const calculateNetTotal = (
    netPrice?: number | null,
    quantity?: number | null
  ) => {
    if (!isValidNumber(netPrice) || !isValidNumber(quantity)) {
      return null;
    }
    return netPrice * quantity;
  };

  const calculateNetPriceFromNetTotal = (
    netTotal?: number | null,
    quantity?: number | null
  ) => {
    if (!isValidNumber(netTotal) || !isValidNumber(quantity)) {
      return null;
    }
    return netTotal / quantity;
  };

  const calculateVatFromNetPrice = (
    pricePerPosition?: number | null,
    netPrice?: number | null
  ) => {
    if (!isValidNumber(pricePerPosition) || !isValidNumber(netPrice)) {
      return null;
    }
    return ((netPrice - pricePerPosition) / pricePerPosition) * 100;
  };

  useEffect(() => {
    const vat = Number(form!.getValues(`positions.${index}.vat`) || 0);
    const pricePerPosition = Number(
      form?.getValues(`positions.${index}.price`) || 0
    );

    if (!vat || !pricePerPosition) {
      return;
    }

    const netPrice = calculateNetPrice(pricePerPosition, vat);

    if (netPrice) {
      form!.setValue(
        `positions.${index}.priceWithoutVat`,
        parseFloat(netPrice!.toFixed(2))
      );
    }
  }, []);

  // Вызывается при изменении "НДС", а также "Цена с НДС" и "Сумма с НДС"
  const handleChangeVat = () => {
    const vat = Number(form!.getValues(`positions.${index}.vat`) || 0);
    const pricePerPosition = Number(
      form!.getValues(`positions.${index}.price`) || 0
    );
    const totalPrice = Number(form!.getValues(`positions.${index}.total`) || 0);
    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );

    if (
      !isValidNumber(vat) ||
      !isValidNumber(pricePerPosition) ||
      !isValidNumber(quantity) ||
      !isValidNumber(totalPrice)
    ) {
      return null;
    }

    const netPrice = calculateNetPrice(pricePerPosition, vat);
    const netTotal = calculateNetTotal(netPrice, quantity);

    // form!.setValue(`positions.${index}.netPrice`, netPrice!);
    form!.setValue(
      `positions.${index}.priceWithoutVat`,
      parseFloat(netPrice!.toFixed(2))
    );

    form!.setValue(
      `positions.${index}.netTotal`,
      parseFloat(netTotal!.toFixed(2))
    );
    const revenuePerPosition = Number((netTotal! - totalPrice).toFixed(2));
    form!.setValue(`positions.${index}.revenuePerPosition`, revenuePerPosition);

    handleChange?.();
    return null;
  };

  // Вызывается при изменении "Цена с НДС"
  // Устанавливает "Сумма с НДС" исходя из ЦЕНА * КОЛИЧЕСТВО - ВЕРНО (вроде)
  // МЕНЯЕТ "VAT" (НДС) ???
  const handleChangePrice = () => {
    const price = Number(form!.getValues(`positions.${index}.price`) || 0);
    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );

    if (!isValidNumber(quantity)) {
      return null;
    }

    const total = parseFloat((price * quantity).toFixed(2));
    form!.setValue(`positions.${index}.total`, total);

    handleChangeVat();

    handleChange?.(String(position.id!), total);

    return null;
  };

  // Вызывается при изменении "Сумма с НДС"
  // Меняет "Цена с НДС" исходя из СУММА / КОЛИЧЕСТВО - ВЕРНО
  // МЕНЯЕТ "VAT" (НДС) ???
  const handleChangeTotal = () => {
    const total = parseFloat(
      Number(form!.getValues(`positions.${index}.total`) || 0).toFixed(2)
    );
    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );

    if (!isValidNumber(quantity)) {
      return null;
    }

    const price = total / quantity;
    form!.setValue(`positions.${index}.price`, parseFloat(price.toFixed(2)));
    handleChangeVat();

    handleChange?.(String(position.id!), total);

    return null;
  };

  const handleChangeNetPrice = () => {
    const netPrice = Number(
      form!.getValues(`positions.${index}.netPrice`) || 0
    );
    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );
    const pricePerPosition = Number(
      form!.getValues(`positions.${index}.price`) || 0
    );
    const totalPrice = Number(form!.getValues(`positions.${index}.total`) || 0);

    if (
      !isValidNumber(netPrice) ||
      !isValidNumber(quantity) ||
      !isValidNumber(pricePerPosition) ||
      !isValidNumber(totalPrice)
    ) {
      return null;
    }

    const vat = calculateVatFromNetPrice(pricePerPosition, netPrice);
    const netTotal = calculateNetTotal(netPrice, quantity);

    form!.setValue(`positions.${index}.vat`, vat!);
    form!.setValue(`positions.${index}.netTotal`, netTotal!);
    const revenuePerPosition = Number((netTotal! - totalPrice).toFixed(2));
    form!.setValue(`positions.${index}.revenuePerPosition`, revenuePerPosition);

    handleChange?.();
    return null;
  };

  const handleChangeNetTotal = () => {
    const netTotal = Number(
      form!.getValues(`positions.${index}.netTotal`) || 0
    );
    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );
    const pricePerPosition = Number(
      form!.getValues(`positions.${index}.price`) || 0
    );
    const totalPrice = Number(form!.getValues(`positions.${index}.total`) || 0);

    if (
      !isValidNumber(netTotal) ||
      !isValidNumber(quantity) ||
      !isValidNumber(pricePerPosition) ||
      !isValidNumber(totalPrice)
    ) {
      return null;
    }

    const netPrice = calculateNetPriceFromNetTotal(netTotal, quantity);
    const vat = calculateVatFromNetPrice(pricePerPosition, netPrice);

    form!.setValue(`positions.${index}.netPrice`, netPrice!);
    form!.setValue(`positions.${index}.vat`, vat!);
    const revenuePerPosition = Number((netTotal - totalPrice).toFixed(2));
    form!.setValue(`positions.${index}.revenuePerPosition`, revenuePerPosition);

    handleChange?.();
    return null;
  };

  return (
    <>
      <Box
        display="flex"
        className={cn(styles.row, styles.inner)}
        alignItems="center"
        gap="12px">
        {/* Наименование (name) */}
        <Box className={styles.item}>
          <Form.Item
            rules={{
              required,
            }}
            name={`positions.${index}.name`}
            className={styles.formInput}
            renderView={(value) => (
              <InfoRow variant="secondary" label="Наименование" value={value} />
            )}
            label="Наименование позиции"
            viewMode={!isCreateMode}>
            <OutlinedInput placeholder="Введите наименование" />
          </Form.Item>
        </Box>

        {/* Кол-во (quantity) */}
        <Box width="100px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            viewMode={!isCreateMode}
            renderView={(value) => (
              <InfoRow variant="secondary" label="Количество" value={value} />
            )}
            name={`positions.${index}.quantity`}
            label="Кол-во"
            className={styles.formInput}>
            <NumberInput
              mode="noDecimal"
              placeholder="Введите количество"
              onBlur={() => handleChangePrice()}
            />
          </Form.Item>
        </Box>

        {/* Ед. изм (okei) */}
        <Box width="100px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
            }}
            name={`positions.${index}.okei`}
            viewMode={!isCreateMode}
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Ед. изм"
                value={position.okei?.measure ?? position.okeiDto?.measure}
              />
            )}
            label="Ед. изм."
            className={styles.formInput}>
            <Autocomplete<IOkei>
              key="okei"
              fullWidth
              popupIcon
              options={[]}
              fetchApi={async (value) =>
                handleGetOkeiOptionsList ? handleGetOkeiOptionsList(value) : []
              }
              getOptionLabel={(option) => option.measure || ''}
              placeholder="Выберите Ед. изм."
              noOptionsText="Начните вводить название"
            />
          </Form.Item>
        </Box>

        {isCreateMode && !!handleRemovePosition && (
          <Box
            className={cn(styles.row, styles.inner)}
            display="flex"
            justifyContent="flex-end"
            paddingTop="18px">
            <Button
              variant="text"
              applyNewIconSizing
              startIcon={<TrashDeleteTableItemIcon width={18} height={18} />}
              onClick={() => {
                handleRemovePosition(index);
              }}
            />
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        className={cn(styles.row, styles.haveBorder)}
        alignItems="flex-start"
        gap="12px">
        {/* Цена с НДС (price) */}
        <Box width="200px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Цена не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Цена не может быть меньше 1`)(value),
              },
            }}
            viewMode={!isCreateMode}
            name={`positions.${index}.price`}
            className={styles.formInput}
            label="Цена (с НДС)"
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Цена исполнителя (с НДС)"
                value={`${value} руб.`}
              />
            )}>
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={() => handleChangePrice()}
            />
          </Form.Item>
        </Box>

        {/* Цена без НДС (priceWithoutVat) */}
        <Box
          className={cn(styles.item, styles.equal)}
          alignItems="center"
          display="flex">
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            label="Цена (без НДС)"
            viewMode={!isCreateMode}
            renderView={(value) => (
              <InfoRow
                label="Цена исполнителя без НДС"
                variant="secondary"
                value={`${
                  !Number.isNaN(value) && Number.isFinite(value)
                    ? `${Number(value).toFixed(2)} руб.`
                    : '-'
                }`}
              />
            )}
            name={`positions.${index}.priceWithoutVat`}
            className={styles.formInput}>
            <NumberInput mode="price" placeholder="0" disabled />
          </Form.Item>
        </Box>

        {/* Сумма с НДС (total) */}
        <Box width="200px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Стоимость не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Стоимость не может быть меньше 1`)(value),
              },
            }}
            name={`positions.${index}.total`}
            className={styles.formInput}
            viewMode={!isCreateMode}
            label="Сумма (с НДС)"
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Сумма исполнителя (с НДС)"
                value={`${value} руб.`}
              />
            )}>
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={() => handleChangeTotal()}
            />
          </Form.Item>
        </Box>

        {/* НДС (vat) */}
        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
              },
            }}
            name={`positions.${index}.vat`}
            className={styles.formInput}
            viewMode={!isEditMode}
            label="НДС"
            renderView={(value) => {
              let processedValue;

              if (value) {
                processedValue = !Number.isNaN(value)
                  ? parseFloat(value).toFixed(2)
                  : value.toFixed(2);
              }

              return (
                <InfoRow
                  variant="secondary"
                  label="НДС исполнителя"
                  value={`${processedValue} %`}
                />
              );
            }}>
            <NumberInput
              placeholder="0"
              mode="price"
              allowNegative
              onBlur={() => handleChangeVat()}
            />
          </Form.Item>
        </Box>
      </Box>
    </>
  );
};
