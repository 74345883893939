import { NotificationType } from 'src/models/notifications';
import { TParams } from '../types';

export const useModifyNotificationParams = (
  params: TParams,
  template: string,
  notificationType?: NotificationType
) => {
  let modifiedTemplate = template;

  if (notificationType === 'CONTRACTOR_SIGNING_SUCCESS') {
    modifiedTemplate = modifiedTemplate.replace(
      /заявкам платформы/g,
      '{allRequestsLink}'
    );
  }

  const modifiedParams =
    notificationType === 'CONTRACTOR_SIGNING_SUCCESS'
      ? { ...params, allRequestsLink: 'all-requests' }
      : params;

  return [modifiedTemplate, modifiedParams] as [string, TParams];
};
