import { ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';
import { InfoIcon } from 'src/assets/icons';
import { SvgIcon } from '../svg-icon';

interface IEmptyMessageProps {
  title?: ReactNode;
  children?: ReactNode;
}

export const EmptyMessage = ({
  title = 'Нет данных',
  children,
}: IEmptyMessageProps) => (
  <Stack
    direction="row"
    gap="12px"
    alignItems="center"
    style={{
      padding: '14px 16px',
      backgroundColor: '#CDE3FF',
      borderRadius: '16px',
    }}>
    <SvgIcon icon={InfoIcon} />
    <Stack gap="4px">
      <Typography fontSize="16px" fontWeight="600">
        {title}
      </Typography>
      {children && <Typography fontSize="16px">{children}</Typography>}
    </Stack>
  </Stack>
);
