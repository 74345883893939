import { Box, Drawer as MuiDrawer, Stack, Typography } from '@mui/material';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import { FiltersBackArrow } from 'src/assets/icons';
import styles from './mobile-drawer.module.scss';

export type IMobileDrawerProps = DrawerProps & {
  close?: () => void;
  titleActions?: React.ReactNode;
  title?: string;
};

export const MobileDrawer = ({
  close,
  children,
  title,
  titleActions,
  ...props
}: IMobileDrawerProps) => (
  <MuiDrawer
    {...props}
    style={{ zIndex: 1300 }}
    PaperProps={{
      className: styles.paper,
    }}>
    <Stack direction="column" height="100%">
      <Stack
        direction="row"
        justifyContent="space-between"
        className={styles.header}>
        <Stack
          direction="row"
          gap="12px"
          flex="1"
          className={styles.titleWrapper}>
          <Box
            color="text.primary"
            display="flex"
            alignItems="center"
            onClick={close}
            className={styles.mobileCloseButton}>
            <FiltersBackArrow />
          </Box>
          <Typography
            fontWeight={500}
            fontSize="16px"
            lineHeight="20px"
            flex={1}>
            {title}
          </Typography>
          {titleActions}
        </Stack>
      </Stack>
      <Box className={styles.content}>{children}</Box>
    </Stack>
  </MuiDrawer>
);
