import { useMemo } from 'react';
import classNames from 'classnames';
import { Pencil } from 'src/assets/icons';
import { IconButton, Typography, Stack } from 'src/components/ui';
import { TVariantBusinessRequest } from 'src/models';
import { isAdmin, isLogistic, isManager, joinFullName } from 'src/utils';
import styles from './request-logistician.module.scss';

export interface IRequestLogisticianProps {
  businessRequest: TVariantBusinessRequest;
  onEditButtonClick: () => void;
}

export const RequestLogistician = ({
  businessRequest,
  onEditButtonClick,
}: IRequestLogisticianProps) => {
  const name = useMemo(
    () =>
      joinFullName(
        businessRequest.assignedManagers?.logistic?.lastName,
        businessRequest.assignedManagers?.logistic?.firstName,
        businessRequest.assignedManagers?.logistic?.middleName
      ),
    [businessRequest]
  );

  const isShowEditLogisticForRequest = !businessRequest.orderProcessingStarted;

  const isUserCanChangeLogistic = isLogistic() || isAdmin() || isManager();

  return (
    <Stack direction="column" gap="10px">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="column" gap="4px">
          <Typography variant="body2" color="#7A8694">
            Логист:
          </Typography>
          <Typography color="#292929">{name || '-'}</Typography>
        </Stack>
        <Stack direction="row" gap="4px">
          <IconButton
            className={classNames(
              styles.actionButton,
              (!isShowEditLogisticForRequest || !isUserCanChangeLogistic) &&
                styles.actionButtonDisabled
            )}
            disabled={!isShowEditLogisticForRequest || !isUserCanChangeLogistic}
            onClick={onEditButtonClick}>
            <Pencil
              sx={{
                color:
                  !isShowEditLogisticForRequest || !isUserCanChangeLogistic
                    ? '#7A8694'
                    : '#292929',
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
