import cn from 'classnames';
import {
  Box,
  Form,
  OutlinedInput,
  MenuItem,
  Select,
  Typography,
  Button,
  Stack,
} from 'src/components/ui';
import { useFormContext } from 'react-hook-form';
import { maxNumber, minNumber, number, required } from 'src/utils/form-rules';
import { TrashDeleteTableItemIcon } from 'src/assets/icons';
import {
  DAY_TYPES,
  PAYMENT_MOMENTS,
  PAYMENT_MOMENTS_BY_TYPE,
  PAYMENT_TYPES,
} from 'src/components/pages/business-request-detail/components/payment-conditions/payment-conditions.const';
import { PaymentType } from 'src/models';
import { IPaymentConditionProps } from './payment-condition.types';
import styles from './styles.module.scss';

export const AddPaymentCondition = (props: IPaymentConditionProps) => {
  const { index = 0 } = props;

  const { getValues } = useFormContext();

  return (
    <>
      <Box
        display="flex"
        className={styles.row}
        gap="12px"
        alignItems="flex-start">
        <Box width="160px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
            }}
            name={`metadata.${index}.paymentType`}
            label="Тип оплаты"
            className={styles.formInput}>
            <Select
              placeholder="Тип оплаты"
              displayEmpty
              renderValue={() => {
                const selectedPaymentType = getValues(
                  `metadata.${index}.paymentType`
                );

                const selectedItem = selectedPaymentType
                  ? PAYMENT_TYPES.find(
                      (type) => type.value === selectedPaymentType
                    )
                  : '';

                if (!selectedItem) {
                  return '';
                }

                return (
                  <Stack direction="row" gap="2px">
                    <Typography color="#7a8694">{`${index + 1}.`}</Typography>
                    <Typography>{`${selectedItem.label}`}</Typography>
                  </Stack>
                );
              }}>
              {PAYMENT_TYPES.map((pt) => (
                <MenuItem key={pt.value} value={pt.value}>
                  {pt.label}
                </MenuItem>
              ))}
            </Select>
          </Form.Item>
        </Box>
        <Box width="160px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(100, `% не может быть выше 100`)(value),
                minNumber: (value) =>
                  minNumber(1, `% не может быть меньше 1`)(value),
              },
            }}
            label="% оплаты"
            name={`metadata.${index}.paymentPercentage`}
            className={styles.formInput}>
            <OutlinedInput placeholder="% оплаты" />
          </Form.Item>
        </Box>

        <Box
          display="flex"
          gap="12px"
          alignItems="flex-start"
          className={styles.item}>
          <Form.Item
            name={`metadata.${index}.dayType`}
            rules={{
              required,
            }}
            label="Выберите тип дня"
            className={styles.formInput}>
            <Select
              placeholder="Выберите тип дня"
              displayEmpty
              autoWidth={false}
              fullWidth>
              {DAY_TYPES.map((dt) => (
                <MenuItem key={dt.value} value={dt.value}>
                  {dt.label}
                </MenuItem>
              ))}
            </Select>
          </Form.Item>
        </Box>
      </Box>
      <Box
        display="flex"
        className={styles.row}
        gap="12px"
        alignItems="flex-start">
        <Box
          display="flex"
          gap="12px"
          alignItems="flex-start"
          width="160px"
          className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(365, `Дней не может быть больше 365`)(value),
                minNumber: (value) =>
                  minNumber(1, `Дней не может быть меньше 1`)(value),
              },
            }}
            name={`metadata.${index}.paymentInDays`}
            label="Количество дней"
            className={styles.formInput}>
            <OutlinedInput placeholder="Количество дней" />
          </Form.Item>
        </Box>

        <Box className={cn(styles.item)} style={{ flexShrink: 1 }}>
          <Form.Item
            rules={{
              required,
            }}
            name={`metadata.${index}.paymentMoment`}
            label="Момент оплаты"
            className={styles.formInput}>
            <Select
              placeholder="Момент оплаты"
              autoWidth={false}
              fullWidth
              displayEmpty
              disabled={!getValues(`metadata.${index}.paymentType`)}>
              {(getValues(`metadata.${index}`).paymentType
                ? PAYMENT_MOMENTS_BY_TYPE[
                    getValues(`metadata.${index}`).paymentType as PaymentType
                  ]
                : PAYMENT_MOMENTS
              ).map((pm) => (
                <MenuItem key={pm.value} value={pm.value}>
                  {pm.label}
                </MenuItem>
              ))}
            </Select>
          </Form.Item>
        </Box>
      </Box>
      {!!props?.handleRemovePaymentCondition && (
        <Box
          className={cn(styles.row, styles.inner)}
          display="flex"
          justifyContent="flex-end"
          paddingBottom="12px">
          <Button
            variant="text"
            applyNewIconSizing
            startIcon={<TrashDeleteTableItemIcon width={18} height={18} />}
            onClick={() => {
              props!.handleRemovePaymentCondition(index);
            }}>
            Удалить условие оплаты
          </Button>
        </Box>
      )}
    </>
  );
};
