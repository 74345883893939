import { FC, Fragment, useCallback, useEffect, useRef, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { IconButton } from 'src/components/ui';
import { ArrowDown, ArrowUp } from 'src/assets/icons';
import classNames from 'classnames';
import styles from './financial-table.module.scss';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F4F5F7',
    color: '#7A8694',
    fontSize: 14,
  },
}));

const ArrowCell: FC<{
  isExpanded: boolean;
  onClick: () => void;
  showArrow?: boolean;
  className?: string;
}> = ({ isExpanded, onClick, showArrow, className }) => (
  <TableCell
    padding="checkbox"
    className={classNames(styles.stickyRight, className)}>
    {showArrow && (
      <IconButton onClick={showArrow ? onClick : () => {}}>
        {isExpanded ? <ArrowUp /> : <ArrowDown />}
      </IconButton>
    )}
  </TableCell>
);

interface IRow {
  id: string;
  items: string[];
  children?: IRow[];
  isOpened?: boolean;
}

interface ITableTProps {
  headerColumns: {
    key: string;
    value: string;
  }[];
  rows: IRow[];
}

// Рекурсивная функция для обновления состояния строк
const updateRows = (rows: IRow[], targetId: string): IRow[] =>
  rows.map((row) => ({
    ...row,
    ...(row.id === targetId && { isOpened: !row.isOpened }),
    ...(row.children && {
      children: updateRows(row.children, targetId),
    }),
  }));

export const FinancialTable: FC<ITableTProps> = ({ headerColumns, rows }) => {
  const [processedRows, setProcessedRows] = useState<IRow[]>(rows);
  const wrapperRef = useRef<HTMLDivElement>(null);

  // Рекурсивный рендеринг строк
  const renderRows = useCallback(
    (rowItems: IRow[], level = 0): JSX.Element[] =>
      rowItems.map((row) => (
        <Fragment key={row.id}>
          <TableRow
            sx={{
              backgroundColor: level === 0 ? '#F4F5F7' : '#FFFFFF',
            }}>
            {row.items.map((cell, index) => (
              <TableCell
                component={index === 0 ? 'th' : 'td'}
                scope="row"
                key={index}
                style={{
                  paddingLeft: index === 0 ? `${12 + level * 20}px` : undefined,
                }}
                className={classNames(
                  level === 0 ? styles.bgDark : styles.bgLight,
                  index === 0 && styles.sticky,
                  index === 1 && styles.stickySecond
                )}>
                {index < 2
                  ? cell
                  : (Number(cell) &&
                      new Intl.NumberFormat('ru-RU').format(+cell)) ||
                    cell}
              </TableCell>
            ))}
            <ArrowCell
              isExpanded={!!row.isOpened}
              onClick={() =>
                setProcessedRows((prev) => updateRows(prev, row.id))
              }
              showArrow={!!row.children?.length}
              className={classNames(
                level === 0 ? styles.bgDark : styles.bgLight
              )}
            />
          </TableRow>
          {row.isOpened && row.children && renderRows(row.children, level + 1)}
        </Fragment>
      )),
    []
  );

  useEffect(() => {
    setProcessedRows(rows);
  }, [rows]);

  useEffect(() => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollLeft = wrapperRef.current?.scrollWidth;
    }
  }, []);

  return (
    <TableContainer ref={wrapperRef}>
      <Table
        sx={() => ({
          [`& .${tableCellClasses.root}`]: {
            fontSize: '14px',
            overflowX: 'auto',
          },
        })}>
        <TableHead>
          <TableRow>
            {headerColumns.map((column) => (
              <StyledTableCell
                style={
                  column.key === 'NAME'
                    ? { fontSize: '10px' }
                    : { fontSize: '14px' }
                }
                className={classNames(
                  styles.bgDark,
                  ['NAME', 'CODE'].includes(column.key) && styles.sticky,
                  column.key === 'CODE' && styles.stickySecond
                )}
                key={column.key}>
                <div
                  style={
                    column.key === 'NAME' ? { width: 168, maxWidth: 168 } : {}
                  }>
                  {column.value}
                </div>
              </StyledTableCell>
            ))}
            <StyledTableCell />
          </TableRow>
        </TableHead>
        <TableBody>{renderRows(processedRows)}</TableBody>
      </Table>
    </TableContainer>
  );
};
