import cn from 'classnames';
import { Box, IconButton } from 'src/components/ui';
import {
  PencilEditTableItemIcon,
  TrashDeleteTableItemIcon,
} from 'src/assets/icons';
import { IPaymentConditionProps } from './payment-condition.types';
import styles from '../../styles.module.scss';
import {
  DAY_TYPES,
  PAYMENT_MOMENTS,
  PAYMENT_TYPES,
} from '../../payment-conditions.const';
import { buttonStyles } from './payment-condition.const';

export const PaymentConditionDefault = (props: IPaymentConditionProps) => {
  const {
    id,
    isEditModeEnabled,
    handleEditPaymentCondition,
    handleRemovePaymentCondition,
    paymentType,
    paymentPercentage,
    paymentInDays,
    dayType,
    paymentMoment,
    index = 0,
  } = props;
  return (
    <Box display="flex" className={styles.row} gap="12px" alignItems="center">
      <Box width="80px" className={cn(styles.item, styles.hasWidth)}>
        {index + 1} часть
      </Box>
      <Box className={styles.item}>
        {PAYMENT_TYPES.find((type) => type.value === paymentType)?.label}
      </Box>
      <Box width="80px" className={cn(styles.item, styles.hasWidth)}>
        {paymentPercentage}%
      </Box>
      <Box width="340px" className={cn(styles.item, styles.hasWidth)}>
        В течении {paymentInDays}{' '}
        {DAY_TYPES.find((type) => type.value === dayType)?.short} дней
      </Box>
      <Box width="240px" className={cn(styles.item, styles.hasWidth)}>
        {
          PAYMENT_MOMENTS.find((moment) => moment.value === paymentMoment)
            ?.label
        }
      </Box>
      <Box
        display="flex"
        width="90px"
        className={cn(styles.item, styles.hasWidth)}
        justifyContent="flex-end"
        gap="8px">
        {isEditModeEnabled ? (
          <>
            <IconButton
              color="secondary"
              onClick={() => handleEditPaymentCondition(id)}>
              <Box {...buttonStyles}>
                <PencilEditTableItemIcon width={20} height={20} />
              </Box>
            </IconButton>
            <IconButton
              color="secondary"
              onClick={() => handleRemovePaymentCondition(id)}>
              <Box {...buttonStyles}>
                <TrashDeleteTableItemIcon width={18} height={18} />
              </Box>
            </IconButton>
          </>
        ) : null}
      </Box>
    </Box>
  );
};
