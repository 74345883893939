import cn from 'classnames';
import { useEffect } from 'react';
import { TrashDeleteTableItemIcon } from 'src/assets/icons';
import {
  Autocomplete,
  Box,
  Button,
  Form,
  InfoRow,
  NumberInput,
  OutlinedInput,
} from 'src/components/ui';
import { IOkei } from 'src/models';
import { maxNumber, minNumber, number, required } from 'src/utils/form-rules';
import { IPositionProps } from './position.types';
import styles from './styles.module.scss';
import {
  calculateContractorPriceWithoutNds,
  calculateCustomerPriceWithMargin,
  calculatePriceWithoutVat,
} from './utils';

export const Position = ({
  position,
  isEditMode,
  handleChange,
  index = 0,
  form,
  isCreateMode,
  handleGetOkeiOptionsList,
  handleRemovePosition,
}: IPositionProps) => {
  const isValidNumber = (value?: number | null): value is number =>
    typeof value === 'number' && !Number.isNaN(value);

  // Заполнение вычисляемых полей начальными значениями
  useEffect(() => {
    if (!position) {
      return;
    }

    const customerPrice = Number(
      form!.getValues(`positions.${index}.price`) || 0
    );

    const contractorPrice = Number(
      form!.getValues(`positions.${index}.contractorPrice`) || 0
    );

    const margin = ((customerPrice - contractorPrice!) / contractorPrice) * 100;

    form!.setValue(`positions.${index}.margin`, margin!);

    const contractorNds = Number(
      form!.getValues(`positions.${index}.contractorNds`) || 0
    );

    if (!isValidNumber(contractorPrice) || !isValidNumber(contractorNds)) {
      return;
    }

    const updatedContractorPriceWithoutNds = calculateContractorPriceWithoutNds(
      contractorPrice!,
      contractorNds
    );

    // Установка цены исполнителя без НДС
    form!.setValue(
      `positions.${index}.priceWithoutVat`,
      updatedContractorPriceWithoutNds!
    );
  }, []);

  const handleChangeContractorTotal = () => {
    // Сумма исполнителя (с НДС)
    const contractorTotal = Number(
      form!.getValues(`positions.${index}.netTotal`) || 0
    );

    const customerTotal = Number(
      form!.getValues(`positions.${index}.total`) || 0
    );

    const updatedRevenuePerPosition = customerTotal - contractorTotal;

    // Установка выручки за позицию
    form!.setValue(
      `positions.${index}.revenuePerPosition`,
      updatedRevenuePerPosition!
    );

    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );

    const updatedContractorPrice = contractorTotal / quantity;

    // Установка цены исполнителя
    form!.setValue(
      `positions.${index}.contractorPrice`,
      updatedContractorPrice!
    );
  };

  const handleCustomerPriceWithoutNds = () => {
    const customerPriceWithoutNds = Number(
      form!.getValues(`positions.${index}.netPrice`) || 0
    );

    const customerPrice = Number(
      form!.getValues(`positions.${index}.price`) || 0
    );

    const updatedPlatformNds = customerPrice - customerPriceWithoutNds;

    // Установка ндс платформы
    form!.setValue(`positions.${index}.vat`, updatedPlatformNds!);
  };

  const handleChangePlatformVat = () => {
    const platformNds = Number(form!.getValues(`positions.${index}.vat`) || 0);

    const customerPriceWithNds = Number(
      form!.getValues(`positions.${index}.price`) || 0
    );

    const updatedCustomerPriceWithoutNds = customerPriceWithNds - platformNds;

    // Установка цены заказчика без ндс
    form!.setValue(
      `positions.${index}.netPrice`,
      updatedCustomerPriceWithoutNds!
    );
  };

  // Меняет "Цена для заказчика с НДС"
  const handleChangeCustomerTotal = () => {
    const customerTotal = Number(
      form!.getValues(`positions.${index}.total`) || 0
    );

    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );

    const updatedCustomerPrice = customerTotal / quantity;

    // Установка цены заказчика с НДС
    form!.setValue(`positions.${index}.price`, updatedCustomerPrice);

    const contractorTotal = Number(
      form!.getValues(`positions.${index}.netTotal`) || 0
    );

    const updatedRevenuePerPosition = customerTotal - contractorTotal;

    // Установка выручки за позицию
    form!.setValue(
      `positions.${index}.revenuePerPosition`,
      updatedRevenuePerPosition
    );
    handleChange?.();
    return null;
  };

  const handleChangeContractorNds = () => {
    const contractorPrice = Number(
      form!.getValues(`positions.${index}.contractorPrice`) || 0
    );

    const contractorNds = Number(
      form!.getValues(`positions.${index}.contractorNds`) || 0
    );

    if (!isValidNumber(contractorPrice) || !isValidNumber(contractorNds)) {
      return null;
    }

    const updatedContractorPriceWithoutNds = calculateContractorPriceWithoutNds(
      contractorPrice!,
      contractorNds
    );

    // Установка цены исполнителя без НДС
    form!.setValue(
      `positions.${index}.priceWithoutVat`,
      updatedContractorPriceWithoutNds!
    );

    handleChange?.();

    return null;
  };

  const handleChangeCustomerPrice = () => {
    const customerPrice = Number(
      form!.getValues(`positions.${index}.price`) || 0
    );

    const customerPriceWithoutVat = Number(
      form!.getValues(`positions.${index}.netPrice`) || 0
    );

    if (
      !isValidNumber(customerPrice) ||
      !isValidNumber(customerPriceWithoutVat)
    ) {
      return null;
    }

    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );

    const updatedCustomerTotal = quantity * customerPrice!;

    form!.setValue(
      `positions.${index}.total`,
      parseFloat(updatedCustomerTotal!.toFixed(2))
    );

    handleChangePlatformVat();
    handleChangeCustomerTotal();
    handleChange?.();

    return null;
  };

  // Изменение цены исполнителя (с НДС)
  const handleChangeContractorPrice = () => {
    // Цена исполнителя (с Ндс)
    const contractorPrice = Number(
      form!.getValues(`positions.${index}.contractorPrice`) || 0
    );

    const margin = Number(form!.getValues(`positions.${index}.margin`) || 0);

    const updatedCustomerPrice = calculateCustomerPriceWithMargin(
      contractorPrice,
      margin
    );

    // Обновление цены заказчика с ндс
    form!.setValue(`positions.${index}.price`, updatedCustomerPrice!);

    const contractorNds = Number(
      form!.getValues(`positions.${index}.contractorNds`) || 0
    );

    if (contractorNds) {
      const updatedPriceWithoutNds = calculatePriceWithoutVat(
        contractorPrice!,
        contractorNds!
      );

      // Обновление цены исполнителя без ндс
      form!.setValue(
        `positions.${index}.priceWithoutVat`,
        updatedPriceWithoutNds!
      );
    }

    const quantity = Number(
      form!.getValues(`positions.${index}.quantity`) || 0
    );

    if (!isValidNumber(quantity)) {
      return null;
    }

    // Пересчет "Сумма Исполнителя" из цены исп и кол-ва
    const total = parseFloat((contractorPrice * quantity).toFixed(2));
    form!.setValue(`positions.${index}.netTotal`, total);

    // handleChange?.(String(position.id!), total);

    // Вызов обработчика задизейбленного поля
    handleChangeCustomerPrice();
    return null;
  };

  // Должно менять "ЦЕНА ДЛЯ ЗАКАЗЧИКА С НДС"
  const handleChangeMargin = () => {
    const contractorPrice = Number(
      form!.getValues(`positions.${index}.contractorPrice`) || 0
    );

    const margin = Number(form!.getValues(`positions.${index}.margin`) || 0);

    if (!isValidNumber(contractorPrice) || !isValidNumber(margin)) {
      return null;
    }

    const price = calculateCustomerPriceWithMargin(contractorPrice, margin);

    form!.setValue(`positions.${index}.price`, price!);
    handleChangeCustomerPrice();
    return null;
  };

  return (
    <>
      <Box
        display="flex"
        className={cn(styles.row, styles.inner)}
        alignItems="center"
        gap="12px">
        <Box className={styles.item}>
          <Form.Item
            rules={{
              required,
            }}
            name={`positions.${index}.name`}
            className={styles.formInput}
            renderView={(value) => (
              <InfoRow variant="secondary" label="Наименование" value={value} />
            )}
            label="Наименование позиции"
            viewMode={!isCreateMode}>
            <OutlinedInput placeholder="Введите наименование" />
          </Form.Item>
        </Box>

        <Box
          width="100px"
          minWidth="100px"
          className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            viewMode={!isCreateMode}
            renderView={(value) => (
              <InfoRow variant="secondary" label="Количество" value={value} />
            )}
            name={`positions.${index}.quantity`}
            label="Кол-во"
            className={styles.formInput}>
            <NumberInput
              mode="noDecimal"
              placeholder="Введите количество"
              onBlur={() => handleChangeContractorPrice()}
            />
          </Form.Item>
        </Box>

        <Box width="100px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
            }}
            name={`positions.${index}.okei`}
            viewMode={!isCreateMode}
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Ед. изм"
                value={position.okei?.measure ?? position.okeiDto?.measure}
              />
            )}
            label="Ед. изм."
            className={styles.formInput}>
            <Autocomplete<IOkei>
              key="okei"
              fullWidth
              popupIcon
              options={[]}
              fetchApi={async (value) =>
                handleGetOkeiOptionsList ? handleGetOkeiOptionsList(value) : []
              }
              getOptionLabel={(option) => option.measure || ''}
              placeholder="Выберите Ед. изм."
              noOptionsText="Начните вводить название"
            />
          </Form.Item>
        </Box>

        {isCreateMode && !!handleRemovePosition && (
          <Box
            className={cn(styles.row, styles.inner)}
            display="flex"
            justifyContent="flex-end"
            paddingTop="18px">
            <Button
              variant="text"
              applyNewIconSizing
              startIcon={<TrashDeleteTableItemIcon width={18} height={18} />}
              onClick={() => {
                handleRemovePosition(index);
              }}
            />
          </Box>
        )}
      </Box>

      <Box
        display="flex"
        className={cn(styles.row, styles.inner)}
        alignItems="flex-start"
        gap="12px">
        <Box width="200px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Цена не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Цена не может быть меньше 1`)(value),
              },
            }}
            viewMode={!isCreateMode}
            name={`positions.${index}.contractorPrice`}
            className={styles.formInput}
            label="Цена исполнителя (с НДС)"
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Цена исполнителя (с НДС)"
                value={`${value} руб.`}
              />
            )}>
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={() => handleChangeContractorPrice()}
            />
          </Form.Item>
        </Box>

        <Box
          className={cn(styles.item, styles.equal)}
          alignItems="center"
          display="flex">
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Цена не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Цена не может быть меньше 1`)(value),
              },
            }}
            label="Цена исполнителя без НДС"
            viewMode={!isCreateMode}
            renderView={(value) => (
              <InfoRow
                label="Цена исполнителя без НДС"
                variant="secondary"
                value={`${
                  !Number.isNaN(value) && Number.isFinite(value)
                    ? `${Number(value).toFixed(2)} руб.`
                    : '-'
                }`}
              />
            )}
            name={`positions.${index}.priceWithoutVat`}
            className={styles.formInput}>
            <NumberInput mode="price" placeholder="0" disabled />
          </Form.Item>
        </Box>

        <Box width="200px" className={cn(styles.item, styles.hasWidth)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Стоимость не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Стоимость не может быть меньше 1`)(value),
              },
            }}
            name={`positions.${index}.netTotal`}
            className={styles.formInput}
            viewMode={!isCreateMode}
            label="Сумма исполнителя (с НДС)"
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Сумма исполнителя (с НДС)"
                value={`${value} руб.`}
              />
            )}>
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={() => handleChangeContractorTotal()}
            />
          </Form.Item>
        </Box>
      </Box>

      <Box
        display="flex"
        className={cn(styles.row, styles.inner)}
        alignItems="flex-start"
        gap="12px">
        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
              },
            }}
            name={`positions.${index}.contractorNds`}
            className={styles.formInput}
            viewMode={!isCreateMode}
            label="НДС Исполнителя"
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="НДС Исполнителя"
                value={`${
                  isValidNumber(value) ? Number(value).toFixed(2) : 0
                } %`}
              />
            )}>
            <NumberInput
              placeholder="0"
              mode="price"
              allowNegative
              onBlur={() => handleChangeContractorNds()}
            />
          </Form.Item>
        </Box>

        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
              },
            }}
            name={`positions.${index}.margin`}
            className={styles.formInput}
            viewMode={!isEditMode}
            label="Наценка (%)"
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Наценка (%)"
                value={`${value} %`}
              />
            )}>
            <NumberInput
              placeholder="0"
              mode="price"
              allowNegative
              onBlur={() => handleChangeMargin()}
            />
          </Form.Item>
        </Box>

        <Box
          className={cn(styles.item, styles.equal)}
          alignItems="center"
          display="flex">
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            label="Выручка за позицию"
            viewMode={!isEditMode}
            renderView={(value) => (
              <InfoRow
                label="Выручка за позицию"
                variant="secondary"
                value={`${
                  isValidNumber(value) ? `${Number(value)} руб.` : '-'
                }`}
              />
            )}
            name={`positions.${index}.revenuePerPosition`}
            className={styles.formInput}>
            <NumberInput mode="price" placeholder="0" disabled />
          </Form.Item>
        </Box>

        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
              },
            }}
            name={`positions.${index}.vat`}
            className={styles.formInput}
            viewMode={!isCreateMode}
            label="НДС платформы"
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="НДС платформы"
                value={`${
                  isValidNumber(value) ? Number(value).toFixed(2) : 0
                } %`}
              />
            )}>
            <NumberInput
              placeholder="0"
              mode="price"
              allowNegative
              onBlur={() => handleChangePlatformVat()}
            />
          </Form.Item>
        </Box>
      </Box>

      <Box
        display="flex"
        className={cn(styles.row, styles.haveBorder)}
        alignItems="flex-start"
        gap="12px">
        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            label="Цена для заказчика (с НДС)"
            name={`positions.${index}.price`}
            className={styles.formInput}
            viewMode={!isEditMode}
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Цена для заказчика (с НДС)"
                value={`${value} руб.`}
              />
            )}>
            <NumberInput mode="price" placeholder="0" disabled />
          </Form.Item>
        </Box>

        <Box
          className={cn(styles.item, styles.equal)}
          alignItems="center"
          display="flex">
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Цена не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Цена не может быть меньше 1`)(value),
              },
            }}
            label="Цена для заказчика без НДС"
            viewMode={!isCreateMode}
            renderView={(value) => (
              <InfoRow
                label="Цена для заказчика без НДС"
                variant="secondary"
                value={`${
                  isValidNumber(value)
                    ? `${Number(value).toFixed(2)} руб.`
                    : '-'
                }`}
              />
            )}
            name={`positions.${index}.netPrice`}
            className={styles.formInput}>
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={() => handleCustomerPriceWithoutNds()}
            />
          </Form.Item>
        </Box>

        <Box className={cn(styles.item, styles.equal)}>
          <Form.Item
            rules={{
              required,
              validate: {
                number,
                maxNumber: (value) =>
                  maxNumber(
                    999999999,
                    `Количество не может быть выше 999 999 999`
                  )(value),
                minNumber: (value) =>
                  minNumber(1, `Количество не может быть меньше 1`)(value),
              },
            }}
            label="Сумма для заказчика (с НДС)"
            name={`positions.${index}.total`}
            className={styles.formInput}
            viewMode={!isEditMode}
            renderView={(value) => (
              <InfoRow
                variant="secondary"
                label="Сумма для заказчика (с НДС)"
                value={`${value} руб.`}
              />
            )}>
            <NumberInput
              mode="price"
              placeholder="0"
              onBlur={() => handleChangeCustomerTotal()}
            />
          </Form.Item>
        </Box>
      </Box>
    </>
  );
};
