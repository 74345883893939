import { generatePath } from 'react-router-dom';
import { SvgIcon } from 'src/components/ui/svg-icon';
import {
  MenuHome,
  MenuAllBids,
  MenuBids,
  MenuCommunications,
  MenuCompanies,
  MenuContractors,
  MenuCustomers,
  MenuNews,
  MenuPayments,
  MenuSettings,
  MenuShipments,
  MenuUsers,
} from 'src/assets/icons';
import { ROLES } from 'src/constants';
import { IUserState } from 'src/store/user/user-slice';

const SVG_ICON_PROPS = {
  color: '#0041A0',
  width: '24px',
  height: '24px',
};

type TMenuItemKeys =
  | 'HOME'
  | 'BIDS'
  | 'ALL_BIDS'
  | 'SHIPMENTS'
  | 'PAYMENTS'
  | 'COMPANIES'
  | 'USERS'
  | 'COMMUNICATIONS'
  | 'NEWS'
  | 'SETTINGS'
  | 'CONTRACTORS'
  | 'CUSTOMERS'
  | 'CHAT';

type TMenuItemKeyToPropsMap = Record<
  TMenuItemKeys,
  { title: string; icon: any; path: string }
>;

const MENU_ITEM_KEY_TO_PROPS_MAP: TMenuItemKeyToPropsMap = {
  HOME: {
    title: 'Домашняя',
    icon: <SvgIcon icon={MenuHome} {...SVG_ICON_PROPS} />,
    path: '/',
  },
  BIDS: {
    title: 'Заявки',
    icon: <SvgIcon icon={MenuBids} {...SVG_ICON_PROPS} />,
    path: '/requests?statusGroup=ACTIVE',
  },
  ALL_BIDS: {
    title: 'Все заявки',
    icon: <SvgIcon icon={MenuAllBids} {...SVG_ICON_PROPS} />,
    path: '/all-requests',
  },
  SHIPMENTS: {
    title: 'Отгрузки',
    icon: <SvgIcon icon={MenuShipments} {...SVG_ICON_PROPS} />,
    path: '/shipments',
  },
  PAYMENTS: {
    title: 'Оплаты',
    icon: <SvgIcon icon={MenuPayments} {...SVG_ICON_PROPS} />,
    path: '/payments',
  },
  COMPANIES: {
    title: 'Компании',
    icon: <SvgIcon icon={MenuCompanies} {...SVG_ICON_PROPS} />,
    path: '/companies',
  },
  USERS: {
    title: 'Пользователи',
    icon: <SvgIcon icon={MenuUsers} {...SVG_ICON_PROPS} />,
    path: '/users',
  },
  COMMUNICATIONS: {
    title: 'Коммуникации',
    icon: <SvgIcon icon={MenuCommunications} {...SVG_ICON_PROPS} />,
    path: '/communications/all',
  },
  CHAT: {
    title: 'Чат',
    icon: <SvgIcon icon={MenuCommunications} {...SVG_ICON_PROPS} />,
    path: '/chat',
  },
  NEWS: {
    title: 'Новости',
    icon: <SvgIcon icon={MenuNews} {...SVG_ICON_PROPS} />,
    path: '/news',
  },
  SETTINGS: {
    title: 'Настройки',
    icon: <SvgIcon icon={MenuSettings} {...SVG_ICON_PROPS} />,
    path: '/system-settings/queues',
  },
  CONTRACTORS: {
    title: 'Исполнители',
    icon: <SvgIcon icon={MenuContractors} {...SVG_ICON_PROPS} />,
    path: '/companies/contractors?organizationStatusCodes=ACTIVE',
  },
  CUSTOMERS: {
    title: 'Заказчики',
    icon: <SvgIcon icon={MenuCustomers} {...SVG_ICON_PROPS} />,
    path: '/companies/customers',
  },
};

export const ROLE_TO_ITEMS_MAP = (
  user: IUserState
): Record<ROLES, Array<TMenuItemKeyToPropsMap[TMenuItemKeys]>> => ({
  [ROLES.ADMIN]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.SHIPMENTS,
    MENU_ITEM_KEY_TO_PROPS_MAP.PAYMENTS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMPANIES,
    MENU_ITEM_KEY_TO_PROPS_MAP.USERS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMMUNICATIONS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
    MENU_ITEM_KEY_TO_PROPS_MAP.SETTINGS,
  ],
  [ROLES.SYSTEM]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.SHIPMENTS,
    MENU_ITEM_KEY_TO_PROPS_MAP.PAYMENTS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMPANIES,
    MENU_ITEM_KEY_TO_PROPS_MAP.USERS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMMUNICATIONS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
    MENU_ITEM_KEY_TO_PROPS_MAP.SETTINGS,
  ],
  [ROLES.KAM]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.CONTRACTORS,
    MENU_ITEM_KEY_TO_PROPS_MAP.CUSTOMERS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMMUNICATIONS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
  ],
  [ROLES.KAM_PLUS]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.CONTRACTORS,
    MENU_ITEM_KEY_TO_PROPS_MAP.CUSTOMERS,
    MENU_ITEM_KEY_TO_PROPS_MAP.COMMUNICATIONS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
  ],
  [ROLES.CUSTOMER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.CHAT,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
    {
      ...MENU_ITEM_KEY_TO_PROPS_MAP.COMPANIES,
      title: 'Моя компания',
      path: user.organizationId
        ? generatePath('/companies/:id', {
            id: user.organizationId.toString(10),
          })
        : '',
    },
  ],
  [ROLES.CONTRACTOR]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    { ...MENU_ITEM_KEY_TO_PROPS_MAP.BIDS, title: 'Мои заявки' },
    MENU_ITEM_KEY_TO_PROPS_MAP.ALL_BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.CHAT,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
    {
      ...MENU_ITEM_KEY_TO_PROPS_MAP.COMPANIES,
      title: 'Моя компания',
      path: user.organizationId
        ? generatePath('/companies/:id', {
            id: user.organizationId.toString(10),
          })
        : '',
    },
  ],

  [ROLES.TECH_EXPERT]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
  ],
  [ROLES.BACK_OFFICE]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
  ],
  [ROLES.COMMERCIAL_DIRECTOR]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
  ],
  [ROLES.LOGISTIC]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
  ],
  [ROLES.PARTNER_RELATIONS_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
  ],
  [ROLES.GENERAL_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
  ],
  [ROLES.FINANCIAL_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
  ],
  [ROLES.OFFICE_MANAGER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
  ],
  [ROLES.LAWYER]: [
    MENU_ITEM_KEY_TO_PROPS_MAP.HOME,
    MENU_ITEM_KEY_TO_PROPS_MAP.BIDS,
    MENU_ITEM_KEY_TO_PROPS_MAP.NEWS,
  ],
});
