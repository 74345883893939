import { format, differenceInCalendarDays } from 'date-fns';

export const getFormattedDateTime = (date?: number | string | Date | null) => {
  if (date) {
    const includedDay = differenceInCalendarDays(new Date(), new Date(date));
    const formatDate = includedDay >= 1 ? 'dd.MM.yyyy' : 'HH:mm';

    return format(new Date(date), formatDate);
  }

  return null;
};

export const getFormattedTime = (date?: number | string | Date | null) => {
  if (date) {
    return format(new Date(date), 'HH:mm');
  }

  return null;
};
