import { TFile } from 'src/components/ui/file-upload';
import { getBaseName } from './get-base-name';

export const prepareFiles = (uncleaned: TFile[], cleaned: TFile[]) => {
  const pairsMap = new Map<string, { left?: TFile; right?: TFile }>();
  const unpairedRight: TFile[] = [];

  // Собираем все возможные пары
  uncleaned.forEach((leftFile) => {
    const base = getBaseName(leftFile.name);
    const matchingRight = cleaned.find(
      (rightFile) =>
        getBaseName(rightFile.name) === base || rightFile.name === leftFile.name
    );

    if (matchingRight) {
      pairsMap.set(leftFile.id.toString(), {
        left: leftFile,
        right: matchingRight,
      });
    } else {
      pairsMap.set(leftFile.id.toString(), { left: leftFile });
    }
  });

  // Добавляем непарные правые файлы
  cleaned.forEach((rightFile) => {
    const isAlreadyPaired = Array.from(pairsMap.values()).some(
      (p) => p.right?.id === rightFile.id
    );

    if (!isAlreadyPaired) {
      unpairedRight.push(rightFile);
    }
  });

  // Сортировка
  const sortedPairs = Array.from(pairsMap.values()).sort((a, b) =>
    a.left!.name.localeCompare(b.left!.name)
  );

  const sortedUnpairedRight = unpairedRight.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  // Формируем итоговые списки
  return {
    left: sortedPairs.map((p) => p.left!),
    right: [
      ...(sortedPairs.map((p) => p.right).filter(Boolean) as TFile[]),
      ...sortedUnpairedRight,
    ],
  };
};
