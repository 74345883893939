import { useState } from 'react';
import { CheckboxCheck, PencilEditTableItemIcon } from 'src/assets/icons';
import {
  ApproveExpectedRequestInfoModal,
  EditExpectedRequestInfoModal,
} from 'src/components/layout/bids-layout/main-bids-layout/components/expected-request-info/components';
import { RequestInfoBlock } from 'src/components/layout/bids-layout/main-bids-layout/components/request-info-block';
import { Button, Stack, Typography } from 'src/components/ui';
import { KamStatusCode, TVariantBusinessRequest } from 'src/models';
import { useAppSelector } from 'src/store';
import { formatServerDate, isAdmin, isKamPlus } from 'src/utils';

export interface IExpectedRequestInfoProps {
  businessRequest: TVariantBusinessRequest;
}

export const ExpectedRequestInfo = ({
  businessRequest,
}: IExpectedRequestInfoProps) => {
  const currentUser = useAppSelector((state) => state.user);

  const [openEditInfoModal, setOpenEditInfoModal] = useState(false);

  const [openApproveModal, setOpenApproveModal] = useState(false);

  const requestRefused =
    businessRequest.status?.code === KamStatusCode.REQUEST_REFUSAL;

  const requestInExpectedStatus =
    'prospect' in businessRequest &&
    businessRequest.prospect &&
    businessRequest.prospect.length !== 0 &&
    businessRequest.prospect[businessRequest.prospect.length - 1]
      .approveStatus !== 'RETURNED' &&
    businessRequest.status.code !== KamStatusCode.REQUEST_FROM_PROSPECT;

  if (!requestInExpectedStatus || requestRefused) {
    return null;
  }

  const expectedRequestInfo =
    businessRequest.prospect[businessRequest.prospect.length - 1];

  const title =
    expectedRequestInfo.approveStatus === 'CREATED'
      ? `Заявка ожидает согласование на перспективу: с ${formatServerDate(
          new Date(expectedRequestInfo.createdAt),
          'dd.MM.yyyy'
        )}`
      : 'Заявка переведена на перспективу';

  const canApproveExpectedStatus =
    isAdmin() ||
    (isKamPlus() &&
      currentUser.id === businessRequest.assignedManagers?.kamPlus?.id);

  if (expectedRequestInfo.approveStatus === 'REFUSED') {
    return (
      <RequestInfoBlock
        title={`Последний запрос на перспективу был направлен ${formatServerDate(
          new Date(expectedRequestInfo.createdAt),
          'dd.MM.yyyy'
        )}`}
        color="warning"
      />
    );
  }

  return (
    <RequestInfoBlock title={title} color="warning">
      <Stack direction="column" gap="10px" sx={{ padding: '12px' }}>
        {(!expectedRequestInfo.approveStatus ||
          expectedRequestInfo.approveStatus === 'APPROVED') && (
          <Button
            color="white"
            size="medium"
            startIcon={<PencilEditTableItemIcon />}
            applyNewIconSizing
            sx={{ width: '100%' }}
            onClick={() => setOpenEditInfoModal(true)}>
            Изменить
          </Button>
        )}

        {!canApproveExpectedStatus && expectedRequestInfo.createdAt && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Дата направления на согласование
            </Typography>
            <Typography lineHeight="20px">
              {formatServerDate(
                new Date(expectedRequestInfo.createdAt),
                'dd.MM.yyyy'
              )}
            </Typography>
          </Stack>
        )}

        {!canApproveExpectedStatus &&
          businessRequest.assignedManagers?.kamPlus && (
            <Stack direction="column" gap="4px">
              <Typography color="secondary" fontSize="12px" lineHeight="16px">
                Ожидает согласования от
              </Typography>
              <Typography lineHeight="20px">
                {`${businessRequest.assignedManagers.kamPlus.lastName} ${businessRequest.assignedManagers.kamPlus.firstName}`}
              </Typography>
            </Stack>
          )}

        {canApproveExpectedStatus &&
          expectedRequestInfo.approveStatus &&
          expectedRequestInfo.approveStatus === 'CREATED' && (
            <Button
              color="white"
              startIcon={<CheckboxCheck />}
              size="medium"
              sx={{ width: '100%' }}
              onClick={() => setOpenApproveModal(true)}>
              Согласовать
            </Button>
          )}

        {expectedRequestInfo.actionUser && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              ФИО инициатора
            </Typography>
            <Typography lineHeight="20px">
              {`${expectedRequestInfo.actionUser.lastName} ${
                expectedRequestInfo.actionUser.firstName
              } ${
                expectedRequestInfo.actionUser.position
                  ? `, ${expectedRequestInfo.actionUser.position}`
                  : ''
              }`}
            </Typography>
          </Stack>
        )}

        {expectedRequestInfo.prospectAnswerDuedate && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Срок обратной связи
            </Typography>
            <Typography lineHeight="20px">
              {formatServerDate(
                new Date(expectedRequestInfo.prospectAnswerDuedate),
                'dd.MM.yyyy'
              )}
            </Typography>
          </Stack>
        )}

        {expectedRequestInfo.causeName && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Причина
            </Typography>
            <Typography lineHeight="20px">
              {expectedRequestInfo.causeName}
            </Typography>
          </Stack>
        )}

        {expectedRequestInfo.comment && (
          <Stack direction="column" gap="4px">
            <Typography color="secondary" fontSize="12px" lineHeight="16px">
              Комментарий
            </Typography>
            <Typography lineHeight="20px">
              {expectedRequestInfo.comment}
            </Typography>
          </Stack>
        )}
      </Stack>
      <EditExpectedRequestInfoModal
        open={openEditInfoModal}
        onClose={() => setOpenEditInfoModal(false)}
        feedbackDate={new Date(expectedRequestInfo.prospectAnswerDuedate)}
        businessRequestId={businessRequest.businessRequestId}
      />
      <ApproveExpectedRequestInfoModal
        open={openApproveModal}
        onClose={() => setOpenApproveModal(false)}
        expectedRequestInfo={expectedRequestInfo}
      />
    </RequestInfoBlock>
  );
};
