import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { TAssignedManager } from 'src/api/admin/company';
import {
  IRecipientAccountListQueryParams,
  TRecipientAccountListResult,
} from 'src/api/communications/models';
import { IUser, IVacation } from 'src/models/user';
import { AuthService } from 'src/services';
import { baseQueryWithReauth } from 'src/store/utils';

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['User', 'DigestUser'],
  endpoints: (builder) => ({
    getCurrentUser: builder.query<IUser, void>({
      query: () => ({
        url: 'users-service/api/v1/user/profile',
      }),
      providesTags: ['User'],
    }),
    updateCurrentUser: builder.mutation<IUser, Partial<IUser>>({
      query: (body) => ({
        url: `mph-security-provider/api/v1/user/${AuthService.userId}`,
        method: 'PUT',
        body,
      }),
    }),
    changePassword: builder.mutation<
      void,
      { oldPassword: string; newPassword: string }
    >({
      query: (data) => ({
        url: 'mph-security-provider/api/v1/password/change-password',
        method: 'POST',
        body: data,
      }),
    }),

    addContactSendLetter: builder.query<unknown, { userId: number }>({
      query: (body) => ({
        url: 'mph-security-provider/api/v1/registration/email',
        method: 'POST',
        body,
      }),
    }),

    getRecipientAccountList: builder.query<
      TRecipientAccountListResult,
      IRecipientAccountListQueryParams
    >({
      query: (params) => ({
        url: 'users-service/api/v1/user/search-for-aggregator',
        method: 'GET',
        params,
      }),
    }),

    updateActivity: builder.mutation<void, void>({
      query: () => ({
        url: `mph-security-provider/api/v1/user/update-activity/${AuthService.userId}`,
        method: 'PUT',
      }),
    }),

    getUser: builder.query<{ login: string }, { login: string }>({
      query: (params) => ({
        url: 'users-service/api/v1/user',
        method: 'GET',
        params,
      }),
    }),

    getUserListByRole: builder.query<TAssignedManager[], string>({
      query: (role) => ({
        url: `users-service/api/v1/user/with-role?userRole=${role}`,
        method: 'GET',
      }),
    }),

    createVacation: builder.mutation<void, IVacation>({
      invalidatesTags: ['User'],
      query: (body) => ({
        url: `business-requests/api/v1/vacations`,
        method: 'POST',
        body,
      }),
    }),

    updateVacation: builder.mutation<void, IVacation>({
      invalidatesTags: ['User'],
      query: ({ id, ...body }) => ({
        url: `business-requests/api/v1/vacations/${id}`,
        method: 'PUT',
        body,
      }),
    }),

    deleteVacation: builder.mutation<void, number>({
      invalidatesTags: ['User'],
      query: (id) => ({
        url: `business-requests/api/v1/vacations/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGetCurrentUserQuery,
  useLazyGetCurrentUserQuery,
  useUpdateCurrentUserMutation,
  useChangePasswordMutation,
  useLazyAddContactSendLetterQuery,
  useLazyGetRecipientAccountListQuery,
  useUpdateActivityMutation,
  useLazyGetUserQuery,
  useGetUserListByRoleQuery,
  useLazyGetUserListByRoleQuery,
  useCreateVacationMutation,
  useDeleteVacationMutation,
  useUpdateVacationMutation,
} = userApi;
