import React from 'react';
import { Auction } from 'src/assets/icons';
import { Box, Stack, Typography } from 'src/components/ui';
import { IContractorRequest, IManagerBusinessRequest } from 'src/models';
import { formatServerDate } from 'src/utils';

export const AuctionInfo = ({
  businessRequest,
}: {
  businessRequest: IManagerBusinessRequest | IContractorRequest;
}) => {
  if (
    businessRequest.refusal ||
    (businessRequest as IContractorRequest).exclusion ||
    !businessRequest.auction
  ) {
    return null;
  }

  const {
    auction: { auctionInfo },
  } = businessRequest;

  if (!auctionInfo || !auctionInfo.startAt || !auctionInfo.endAt) {
    return null;
  }

  if (new Date() > new Date(auctionInfo.endAt)) {
    return null;
  }

  return (
    <Stack
      direction="column"
      sx={{ backgroundColor: '#EAF3FF', borderRadius: '8px' }}>
      <Stack
        direction="row"
        gap="12px"
        sx={{
          flex: 1,
          padding: '12px',
          alignItems: 'center',
        }}>
        <Box
          sx={{
            height: '44px',
            width: '44px',
            display: 'flex',
            backgroundColor: '#CDE3FF',
            borderRadius: '8px',
            alignItems: 'center',
            justifyContent: 'center',
            flexShrink: 0,
            color: '#0041A0',
            svg: {
              height: '24px !important',
              width: '24px !important',
            },
          }}>
          <Auction />
        </Box>
        <Typography flex="1">
          Проводится аукцион: c{' '}
          {formatServerDate(auctionInfo.startAt, 'dd.MM.yyyy')} по{' '}
          {formatServerDate(auctionInfo.endAt, 'dd.MM.yyyy')}
        </Typography>
      </Stack>
    </Stack>
  );
};
