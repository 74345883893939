import { FC } from 'react';
import { Box } from 'src/components/ui';
import {
  ConversationEntityType,
  DocumentEntityTypes,
  DocumentTypes,
  IPaymentCondition,
  IPaymentConditionCreate,
  IPosition,
} from 'src/models';
import { AttachFiles, OpenChat } from 'src/components/widgets';
import { arrayRequired } from 'src/utils/form-rules';
import { UseFormReturn } from 'react-hook-form';
import { Finance, Deadlines, Comments } from '../components';
import { PaymentConditions } from '../components/payment-conditions';
import { ContractorPositions } from '../components/positions';
import { TPositionsFormData } from '../components/positions/position/position.types';

interface ICustomerCommercialOfferProps {
  isEditing: boolean;
  commercialOfferId?: number;
  contractorCommercialOfferId?: number;
  businessRequestId: number;
  checkRequired: boolean;
  orderProcessingStarted?: boolean;
  shippingIncluded?: boolean;
  paymentConditions?: IPaymentCondition[];
  positions?: IPosition[];
  positionsForm?: UseFormReturn<TPositionsFormData>;
  paymentConditionsForm?: UseFormReturn<IPaymentConditionCreate>;
  handleSetCostForCustomer?: () => void;
  isCreatePositionMode?: boolean;
  isHavePositions?: boolean;
  isCreatePaymentConditionsMode?: boolean;
}

const mode = 'customer';

export const CustomerCommercialOffer: FC<ICustomerCommercialOfferProps> = ({
  isEditing,
  commercialOfferId,
  businessRequestId,
  checkRequired,
  orderProcessingStarted,
  shippingIncluded,
  contractorCommercialOfferId,
  paymentConditions,
  positions,
  positionsForm,
  paymentConditionsForm,
  handleSetCostForCustomer,
  isCreatePositionMode = false,
  isCreatePaymentConditionsMode = false,
  isHavePositions = false,
}) => (
  <>
    <Finance
      isEditing={isEditing}
      mode={mode}
      checkRequired={checkRequired}
      offerFromContractor={Boolean(contractorCommercialOfferId)}
      shippingIncluded={shippingIncluded}
      readonlyCostByContractor={!!positionsForm?.getValues('positions').length}
    />

    <Deadlines
      isEditing={isEditing}
      mode={mode}
      checkRequired={checkRequired}
    />

    {!orderProcessingStarted && (
      <OpenChat
        entityType={ConversationEntityType.BUSINESS_REQUEST}
        entityId={businessRequestId}
        mode="manager"
        sx={{ marginBottom: '24px' }}
      />
    )}

    <ContractorPositions
      positions={positions}
      isEditMode={isEditing}
      form={positionsForm}
      businessRequestId={businessRequestId}
      contractorCommercialOfferId={contractorCommercialOfferId}
      handleSetCostForCustomer={handleSetCostForCustomer}
      isCreatePositionMode={isCreatePositionMode}
      isHavePositions={isHavePositions}
    />

    <PaymentConditions
      isEditMode={isEditing}
      businessRequestId={businessRequestId}
      paymentConditions={paymentConditions}
      maxWidth="480px"
      isCreatePaymentConditionsMode={isCreatePaymentConditionsMode}
      form={paymentConditionsForm}
      view="line"
    />

    <Comments
      isPaymentConditionsEmpty={
        !paymentConditions?.length &&
        !paymentConditionsForm?.getValues('metadata').length
      }
      isEditing={isEditing}
      checkRequired={checkRequired}
      mode={mode}
    />

    <Box mb="24px">
      <AttachFiles
        noMargin
        formItemName="documents"
        isEditMode={isEditing}
        documentOptions={{
          entityType: DocumentEntityTypes.COMMERCIAL_OFFER_FOR_CUSTOMER,
          entityId: commercialOfferId || 0,
          documentType: DocumentTypes.COMMERCIAL_OFFER,
        }}
        fullScreen={false}
        rules={{ validate: checkRequired ? arrayRequired : undefined }}
      />
    </Box>
  </>
);
