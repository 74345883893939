import { useQueryParams, StringParam } from 'use-query-params';

export const useNotificationsQueryParams = () => {
  const params = useQueryParams({
    filter: StringParam,
    status: StringParam,
  });

  return params;
};
