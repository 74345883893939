import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useHandleExpectedRequestApprovalMutation } from 'src/api/business-request/business-request';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Form,
  FormControlLabel,
  InfoRow,
  OutlinedInput,
  Radio,
  RadioGroup,
} from 'src/components/ui';
import { FIELD_LENGTH } from 'src/constants';
import { IProspectInfo } from 'src/models/bids-details';
import { getErrorMessage } from 'src/store/utils';
import { formatServerDate } from 'src/utils';
import { maxLength, required } from 'src/utils/form-rules';

type TApproveExpectedRequestInfoForm = {
  approve?: string;
  comment?: string;
};

export const ApproveExpectedRequestInfoModal = ({
  open,
  onClose,
  expectedRequestInfo,
}: {
  open: boolean;
  onClose: () => void;
  expectedRequestInfo: IProspectInfo;
}) => {
  const [approveExpectedRequest, { isLoading: isApproveLoading }] =
    useHandleExpectedRequestApprovalMutation();

  const form = useForm<TApproveExpectedRequestInfoForm>({
    defaultValues: {
      approve: 'false',
    },
  });

  const handleSubmit = (data: TApproveExpectedRequestInfoForm) => {
    try {
      approveExpectedRequest({
        businessRequestProspectId: expectedRequestInfo.id,
        status: data.approve === 'true' ? 'APPROVED' : 'REFUSED',
        refusalComment: data.comment,
      });
      onClose();
    } catch (error) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(
          error,
          'Произошла ошибка при согласовании перевода заявки на перспективу'
        ),
      });
    }
  };

  return (
    <Dialog
      open={open}
      close={onClose}
      PaperProps={{
        sx: {
          minWidth: `640px`,
          maxWidth: `640px`,
          width: '100%',
        },
      }}>
      <DialogTitle>
        <Typography fontWeight={600} fontSize="20px" lineHeight="28px">
          Согласование перевода заявки на перспективу
        </Typography>
      </DialogTitle>
      <DialogContent>
        <InfoRow
          label="Причина перевода"
          value={expectedRequestInfo.causeName}
        />
        <InfoRow
          label="Срок обратной связи"
          value={formatServerDate(
            new Date(expectedRequestInfo.prospectAnswerDuedate),
            'dd.MM.yyyy'
          )}
        />
        <Form
          form={form}
          skipDirtyCheck
          loading={isApproveLoading}
          onSubmit={handleSubmit}
          submitButtonProps={{
            text: 'Отправить',
          }}
          onCancel={onClose}>
          <Form.Item name="approve" required>
            <RadioGroup row>
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Согласовать"
                sx={{
                  maxWidth: '230px',
                  width: '100%',
                }}
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Отклонить"
                sx={{
                  maxWidth: '230px',
                  width: '100%',
                }}
              />
            </RadioGroup>
          </Form.Item>
          {form.watch('approve') === 'false' && (
            <Form.Item
              noMargin
              name="comment"
              label="Напишите комментарий"
              rules={{
                required,
                maxLength: maxLength(FIELD_LENGTH.moveToExpected),
              }}>
              <OutlinedInput
                placeholder="Введите комментарий"
                multiline
                rows={6}
                maxLength={FIELD_LENGTH.moveToExpected}
              />
            </Form.Item>
          )}
        </Form>
      </DialogContent>
    </Dialog>
  );
};
