const isValidNumber = (value?: number | null): value is number =>
  typeof value === 'number' && !Number.isNaN(value);

// Считает "Цена для заказчика с НДС" - ВЕРНО (из цены исп с НДС и наценки)
export const calculateCustomerPriceWithMargin = (
  pricePerPosition?: number | null,
  margin?: number | null
) => {
  if (!isValidNumber(pricePerPosition) || !isValidNumber(margin)) {
    return null;
  }
  return pricePerPosition + (pricePerPosition / 100) * margin;
};

// Считает "Цена для заказчика с НДС" - ВЕРНО (из "Сумма для заказчика с НДС" и "Кол-во")
export const calculateNetPriceFromNetTotal = (
  netTotal?: number | null,
  quantity?: number | null
) => {
  if (!isValidNumber(netTotal) || !isValidNumber(quantity)) {
    return null;
  }

  return netTotal / quantity;
};
