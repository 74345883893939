import {
  ICreatePositionsParams,
  IGetPositionsFilters,
  IPosition,
  IUpdatePositionsParams,
} from 'src/models';
import { businessRequestApi } from '../business-request';

export const positionsApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getRequestPositions: builder.query<IPosition[], IGetPositionsFilters>({
      query: (params) => ({
        url: `business-requests/api/v1/positions`,
        method: 'GET',
        params,
      }),
      providesTags: (result, error, arg) => [
        { type: 'getRequestPositions', id: arg.businessRequestId },
      ],
    }),

    updateRequestPositions: builder.mutation<
      IPosition[],
      IUpdatePositionsParams
    >({
      query: (body) => ({
        url: `business-requests/api/v1/positions`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['getRequestPositions'],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            positionsApi.util.updateQueryData(
              'getRequestPositions',
              {
                businessRequestId:
                  body.positions[0].positionRequest.entityRef.id,
              },
              () => data
            )
          );
        } catch {
          // ignore
        }
      },
    }),

    createRequestPositions: builder.mutation<
      IPosition[],
      ICreatePositionsParams
    >({
      query: (body) => ({
        url: `business-requests/api/v1/positions`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['getRequestPositions'],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            positionsApi.util.updateQueryData(
              'getRequestPositions',
              {
                businessRequestId: body.positions[0].entityRef.id,
              },
              () => data
            )
          );
        } catch {
          // ignore
        }
      },
    }),

    deleteRequestPositions: builder.mutation<void, { positionsIds: number[] }>({
      query: (body) => ({
        url: `business-requests/api/v1/positions?positionsIds=${body.positionsIds.join(
          ','
        )}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['getRequestPositions'],
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            positionsApi.util.updateQueryData(
              'getRequestPositions',
              {},
              () => data
            )
          );
        } catch {
          // ignore
        }
      },
    }),
  }),
});

export const {
  useGetRequestPositionsQuery,
  useLazyGetRequestPositionsQuery,
  useUpdateRequestPositionsMutation,
  useCreateRequestPositionsMutation,
  useDeleteRequestPositionsMutation,
} = positionsApi;
