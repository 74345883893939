import { ReactNode } from 'react';

export enum HintTextVariant {
  DEFAULT = 'DEFAULT',
  WARNING = 'WARNING',
}

export interface IHintTextProps {
  variant?: HintTextVariant;
  children?: ReactNode;
}
