import { Checkbox, TableCell } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import { ReactNode, useMemo } from 'react';
import { ColumnsType } from 'src/components/ui/new-table/types';
import { Cell } from './components';

import styles from './row.module.scss';

interface RowProps<RecordType> {
  columns: ColumnsType<RecordType>;
  record: RecordType;
  selected?: Array<RecordType>;
  setSelected?: (select: RecordType[]) => void;
  onRowClick?: (row: RecordType) => void;
  page: number;
  rowIndex: number;
  renderSubRow?: () => ReactNode;
  subRowId?: number;
}

export const Row = <RecordType extends { key: number | string }>({
  columns,
  record,
  selected,
  setSelected,
  onRowClick,
  page,
  rowIndex,
  renderSubRow,
  subRowId,
}: RowProps<RecordType>) => {
  const isItemSelected = useMemo(
    () => selected?.some((item) => item.key === record.key),
    [selected, record]
  );

  const handleSelected = () => {
    if (!selected || !setSelected) return;

    let newSelected: Array<RecordType> = [];
    const selectedIndex = selected?.findIndex(
      (item) => item.key === record.key
    );

    if (selectedIndex === -1) {
      newSelected = [...selected, record];
    } else {
      newSelected = [
        ...selected.slice(0, selectedIndex),
        ...selected.slice(selectedIndex + 1),
      ];
    }

    setSelected(newSelected);
  };

  return (
    <>
      <TableRow
        className={styles.row}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
        }}>
        {setSelected ? (
          <TableCell className={styles.cell}>
            <Checkbox
              onClick={() => handleSelected()}
              checked={isItemSelected}
            />
          </TableCell>
        ) : null}

        {columns.map((column, index) => (
          <Cell<RecordType>
            key={String(column.title)}
            column={column}
            index={index}
            record={record}
            onClick={() => {
              if (onRowClick) {
                onRowClick(record);
              }
            }}
            page={page}
            rowIndex={rowIndex}
          />
        ))}
      </TableRow>

      {renderSubRow && subRowId === record.key && (
        <TableRow
          className={styles.row}
          sx={{
            '&:last-child td, &:last-child th': { border: 0 },
          }}>
          <TableCell key={`subrow_${record.key}`} colSpan={2} padding="none">
            {renderSubRow()}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
