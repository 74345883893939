import { Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  useEditRequestExpectedInfoMutation,
  useReturnRequestFromExpectedStatusMutation,
} from 'src/api/business-request/business-request';
import styles from 'src/components/layout/bids-layout/main-bids-layout/components/header/components/move-request-to-expected/move-request-to-expected.module.scss';
import {
  Button,
  DatePicker,
  Dialog,
  DialogContent,
  DialogTitle,
  Form,
} from 'src/components/ui';
import { getErrorMessage } from 'src/store/utils';
import { date, dateNoDifference, required } from 'src/utils/form-rules';

type TEditExpectedRequestInfoForm = {
  feedbackDate: null | Date;
};
export const EditExpectedRequestInfoModal = ({
  open,
  onClose,
  feedbackDate,
  businessRequestId,
}: {
  open: boolean;
  onClose: () => void;
  feedbackDate: null | Date;
  businessRequestId: number;
}) => {
  const [editExpectedRequestInfo, { isLoading: isEditLoading }] =
    useEditRequestExpectedInfoMutation();

  const [returnRequestFromExpectedStatus, { isLoading: isReturnLoading }] =
    useReturnRequestFromExpectedStatusMutation();

  const form = useForm<TEditExpectedRequestInfoForm>({
    defaultValues: {
      feedbackDate,
    },
  });

  const handleSubmit = async (data: TEditExpectedRequestInfoForm) => {
    try {
      await editExpectedRequestInfo({
        businessRequestId,
        prospectAnswerDuedate: data.feedbackDate!,
      }).unwrap();
      onClose();
    } catch (error) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(
          error,
          'Произошла ошибка при изменении срока предоставления обратной связи'
        ),
      });
    }
  };

  const getMaxDate = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    return new Date(year + 1, month, day);
  };

  const handleReturnRequestFromExpectedStatus = async () => {
    try {
      await returnRequestFromExpectedStatus(businessRequestId).unwrap();
      onClose();
    } catch (error) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(
          error,
          'Произошла ошибка при возврате заявки с перспективы'
        ),
      });
    }
  };

  return (
    <Dialog
      open={open}
      close={onClose}
      PaperProps={{
        sx: {
          minWidth: `800px`,
          maxWidth: `800px`,
          width: '100%',
        },
      }}>
      <DialogTitle>
        <Typography fontWeight={600} fontSize="20px" lineHeight="28px">
          Изменить срок предоставления обратной связи?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Form
          form={form}
          skipDirtyCheck
          loading={isEditLoading}
          onSubmit={handleSubmit}
          submitButtonProps={{
            text: 'Сохранить',
          }}
          onCancel={onClose}
          extraActions={
            <Button
              color="secondary"
              loading={isReturnLoading}
              onClick={handleReturnRequestFromExpectedStatus}>
              Вернуть с перспективы
            </Button>
          }>
          <Form.Item
            name="feedbackDate"
            label="Срок обратной связи"
            rules={{
              required,
              validate: {
                date,
                dateNoDifference,
                maxDate: (dateValue) => {
                  const currentDateValue = dateValue as Date | null;

                  const maxDate = getMaxDate();
                  if (currentDateValue && currentDateValue > maxDate) {
                    return 'Введите срок не более года от текущей даты';
                  }
                  return true;
                },
              },
            }}
            controlInputClassName={styles.picker}>
            <DatePicker minDate={new Date()} maxDate={getMaxDate()} />
          </Form.Item>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
