import { differenceInCalendarDays } from 'date-fns';
import { useMemo, useState } from 'react';
import { businessRequestApi } from 'src/api';
import {
  useLockContactMutation,
  useTrustContactMutation,
} from 'src/api/contacts';
import { CheckedIcon, ExclamationIcon } from 'src/assets/icons';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  SvgIcon,
  Typography,
} from 'src/components/ui';
import { TVariantContactItem } from 'src/components/widgets/contact-item/types';
import { ROLES } from 'src/constants';
import {
  IBusinessRequestCustomerInfo,
  IManagerBusinessRequest,
} from 'src/models';
import { useAppDispatch, useAppSelector } from 'src/store';
import vars from 'src/theme/scss/_vars.module.scss';
import { noop } from 'src/utils';
import styles from './whois.module.scss';

type DomainCheckErrors =
  | 'SHARED_EMAIL'
  | 'PRIVATE_PERSON'
  | 'DOMAIN_TOO_NEW'
  | 'DOMAIN_REG_DATE_UNAVAILABLE';

const ERROR_KEY_TO_DESCRIPTION_MAP = {
  SHARED_EMAIL: 'Почта не является корпаративной',
  PRIVATE_PERSON: 'Домен почты зарегистрирован на частное лицо',
  DOMAIN_TOO_NEW: 'Домен почты зарегестрирован менее чем пол года назад',
  DOMAIN_REG_DATE_UNAVAILABLE: 'Не удалось проверить дату регистрации домена',
};

const MINIMAL_VALID_DAYS_OF_REGISTRATION = 180;

interface IWhoisProps {
  item: TVariantContactItem;
  businessRequest?: IManagerBusinessRequest | IBusinessRequestCustomerInfo;
  className?: string;
}

interface IWhoisDialogContentProps {
  item: TVariantContactItem;
  errors: DomainCheckErrors[];
  isCorporateDomain: boolean;
  onTrust: () => void;
  onBlock: () => void;
}

const WhoisDialogContent = ({
  item,
  errors,
  isCorporateDomain,
  onTrust,
  onBlock,
}: IWhoisDialogContentProps) => {
  if (item?.isTrusted) {
    return (
      <Stack
        gap="12px"
        sx={{
          p: '16px',
          borderRadius: '8px',
          backgroundColor: '#F3F6FB',
        }}>
        <Stack direction="row" gap="12px" alignItems="center">
          <SvgIcon
            icon={CheckedIcon}
            strokeHexColor="#13B81A"
            width="24"
            height="24"
          />
          <div>
            <Typography>Доверенный контакт</Typography>
          </div>
        </Stack>
      </Stack>
    );
  }

  if (item.contactStatus === 'LOCKED') {
    return (
      <Stack
        gap="12px"
        sx={{
          p: '16px',
          borderRadius: '8px',
          backgroundColor: '#FFE6E6',
        }}>
        <Stack direction="row" gap="12px" alignItems="flex-start">
          <SvgIcon
            icon={ExclamationIcon}
            strokeHexColor={vars.warningText}
            width="24"
            height="24"
          />
          <div>
            <Typography>Контакт заблокирован</Typography>
            <Typography>Причина: Подозрительные контактные данные</Typography>
          </div>
        </Stack>
      </Stack>
    );
  }

  if (errors.includes('SHARED_EMAIL')) {
    return (
      <Stack
        gap="12px"
        sx={{
          p: '16px',
          borderRadius: '8px',
          backgroundColor: '#FFE6E6',
        }}>
        <Stack direction="row" gap="12px" alignItems="flex-start">
          <SvgIcon
            icon={ExclamationIcon}
            strokeHexColor={vars.warningText}
            width="24"
            height="24"
          />
          <Stack gap="12px">
            <div>
              <Typography display="inline">Почта пользователя&nbsp;</Typography>
              <Typography display="inline" fontWeight="600">
                {item.email}
              </Typography>
              <Typography display="inline">
                &nbsp;является подозрительной, так как не является доменной
                почтой. Вы можете заблокировать данного пользователя.
              </Typography>
            </div>
            <Stack direction="row" gap="12px">
              <Button className={styles.acceptButton} onClick={onTrust}>
                Доверять
              </Button>
              <Button className={styles.declineButton} onClick={onBlock}>
                Заблокировать
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  if (errors?.length > 0 && isCorporateDomain) {
    return (
      <Stack
        gap="12px"
        sx={{
          p: '16px',
          borderRadius: '8px',
          backgroundColor: '#FFE6E6',
        }}>
        <Stack direction="row" gap="12px" alignItems="flex-start">
          <SvgIcon
            icon={ExclamationIcon}
            strokeHexColor={vars.warningText}
            width="24"
            height="24"
          />
          <Stack gap="12px">
            <div>
              <Typography>
                У доменного имени контакта обнаружены подозрительные данные:
              </Typography>
              <ul style={{ margin: '8px 0', paddingLeft: '24px' }}>
                {errors.map((error) => (
                  <li key={error}>
                    <Typography>
                      {ERROR_KEY_TO_DESCRIPTION_MAP[error]}
                    </Typography>
                  </li>
                ))}
              </ul>
              <Typography>
                Вы можете заблокировать все контакты компании с данным доменным
                именем.
              </Typography>
            </div>
            <Typography>{item?.domainCheckDto?.whoisAnswer}</Typography>
            <Stack direction="row" gap="12px">
              <Button className={styles.acceptButton} onClick={onTrust}>
                Доверять
              </Button>
              <Button className={styles.declineButton} onClick={onBlock}>
                Заблокировать
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  if (!errors.length) {
    return (
      <Typography>
        {item?.domainCheckDto?.whoisAnswer || 'Нет данных'}
      </Typography>
    );
  }

  return null;
};

export const Whois = ({ item, businessRequest, className }: IWhoisProps) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector((state) => state.user);

  const [openDomainCheckInfo, setOpenDomainCheckInfo] = useState(false);

  const isCorporateDomain = useMemo(
    () => item?.domainCheckDto && !item.domainCheckDto.sharedMail,
    [item]
  );

  const errors = useMemo(() => {
    const result = [] as DomainCheckErrors[];

    if (item?.isTrusted) {
      return result;
    }

    if (item.domainCheckDto?.sharedMail) {
      result.push('SHARED_EMAIL');
    }

    if (item?.domainCheckDto?.privatePerson) {
      result.push('PRIVATE_PERSON');
    }

    if (!item?.domainCheckDto?.domainRegDate) {
      result.push('DOMAIN_REG_DATE_UNAVAILABLE');
    }

    if (item?.domainCheckDto?.domainRegDate) {
      const diff = differenceInCalendarDays(
        new Date(),
        new Date(item.domainCheckDto.domainRegDate)
      );

      if (diff < MINIMAL_VALID_DAYS_OF_REGISTRATION) {
        result.push('DOMAIN_TOO_NEW');
      }
    }

    return result;
  }, [item]);

  const isDomainCheckValid = useMemo(() => {
    if (!item?.domainCheckDto) {
      return false;
    }

    return !errors?.length;
  }, [item, errors]);

  const [trustContact] = useTrustContactMutation();

  const handleTrust = () => {
    if (!item?.domainCheckDto?.id) {
      return;
    }

    const params = {
      contactId: item.id,
    };

    trustContact(params)
      .unwrap()
      .then(() => {
        setOpenDomainCheckInfo(false);

        if (businessRequest) {
          dispatch(
            businessRequestApi.util.invalidateTags([
              {
                type: 'getCustomerInfo',
                id: businessRequest.businessRequestId,
              },
            ])
          );
        }
      })
      .catch(noop);
  };

  const [lockContact] = useLockContactMutation();

  const handleLockContact = () => {
    const params = {
      userId: item.userId,
    };

    lockContact(params)
      .unwrap()
      .then(() => {
        setOpenDomainCheckInfo(false);

        if (businessRequest) {
          dispatch(
            businessRequestApi.util.invalidateTags([
              {
                type: 'getCustomerInfo',
                id: businessRequest.businessRequestId,
              },
            ])
          );
        }
      })
      .catch(noop);
  };

  return (
    <>
      {!user.roles.includes(ROLES.CONTRACTOR) &&
        !user.roles.includes(ROLES.CUSTOMER) && (
          <div
            className={`${
              styles[
                `whoisWrapper--${isDomainCheckValid ? 'valid' : 'invalid'}`
              ]
            } ${className}`}
            onClick={() => setOpenDomainCheckInfo(true)}
            role="presentation">
            <Typography fontSize="12px">whois</Typography>
          </div>
        )}

      <Dialog
        open={openDomainCheckInfo}
        close={() => setOpenDomainCheckInfo(false)}
        PaperProps={{
          sx: {
            width: '600px',
          },
        }}>
        <DialogTitle>
          <Typography variant="h3">
            Проверка доменного имени контакта
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ mb: '24px' }}>
          <WhoisDialogContent
            item={item}
            errors={errors}
            isCorporateDomain={isCorporateDomain}
            onTrust={handleTrust}
            onBlock={handleLockContact}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
