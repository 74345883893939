import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CompanyTypes } from 'src/models';
import {
  TApplicationContractorWithContacts,
  TApplicationGroupWithInnerRoles,
} from 'src/models/bid';

interface IRequestContactsInfo {
  customers?: Record<number, number[]>;
  contractors?: Record<number, number[]>;
  innerRolesGroups?: Record<number, number[]>;
}

interface IInitialState {
  requests: Record<number, IRequestContactsInfo | undefined>;
  lastSelectedClientTab: CompanyTypes;
}

const saveState = (state: Record<number, IRequestContactsInfo | undefined>) => {
  const storedRequestsData = localStorage.getItem(
    'request-communications-contacts-ids'
  );
  let parsedStoredRequests: Record<number, IRequestContactsInfo | undefined>[] =
    storedRequestsData ? JSON.parse(storedRequestsData) : [];
  if (parsedStoredRequests.length > 30) {
    Object.entries(state).forEach(([key, value]) => {
      const index = parsedStoredRequests.findIndex(
        (parsedObject) => key in parsedObject
      );
      if (index !== -1) {
        parsedStoredRequests[index] = {
          [key]: { ...parsedStoredRequests[index][key as any], ...value },
        };
      } else {
        parsedStoredRequests.push({
          [key]: value,
        });
        parsedStoredRequests = parsedStoredRequests.splice(
          1,
          parsedStoredRequests.length - 1
        );
      }
    });
  } else {
    Object.entries(state).forEach(([key, value]) => {
      const index = parsedStoredRequests.findIndex(
        (parsedObject) => key in parsedObject
      );
      if (index !== -1) {
        parsedStoredRequests[index] = {
          [key]: { ...parsedStoredRequests[index][key as any], ...value },
        };
      } else {
        parsedStoredRequests.push({
          [key]: value,
        });
      }
    });
  }

  localStorage.setItem(
    'request-communications-contacts-ids',
    JSON.stringify(parsedStoredRequests)
  );
};
const getInitialState = (): IInitialState => {
  const storedRequestsData = localStorage.getItem(
    'request-communications-contacts-ids'
  );

  if (storedRequestsData) {
    const parsedStoredRequests: Record<
      number,
      IRequestContactsInfo | undefined
    >[] = JSON.parse(storedRequestsData);

    let mergedRequests: Record<number, IRequestContactsInfo | undefined> = {};
    parsedStoredRequests.forEach((request) => {
      mergedRequests = {
        ...mergedRequests,
        ...request,
      };
    });

    return {
      requests: mergedRequests,
      lastSelectedClientTab: CompanyTypes.CUSTOMER,
    };
  }

  return {
    requests: {},
    lastSelectedClientTab: CompanyTypes.CUSTOMER,
  };
};

const initialState: IInitialState = getInitialState();

const requestCommunicationsSlice = createSlice({
  name: 'requestCommunications',
  initialState,
  reducers: {
    setLastSelectedClientTab(state, action: PayloadAction<CompanyTypes>) {
      const { payload } = action;
      state.lastSelectedClientTab = payload;
    },
    setRequestOrganizations(
      state,
      action: PayloadAction<{
        requestId: number;
        organizations: TApplicationContractorWithContacts[];
        organizationType: CompanyTypes;
      }>
    ) {
      const { payload } = action;
      const stateOrganizationKey =
        payload.organizationType === CompanyTypes.CUSTOMER
          ? 'customers'
          : 'contractors';

      let isAnyCompanyPresent = false;

      if (
        state.requests[payload.requestId] &&
        state.requests[payload.requestId]?.[stateOrganizationKey]
      ) {
        Object.keys(
          state.requests[payload.requestId]![stateOrganizationKey]!
        ).forEach((stateOrganizationId) => {
          payload.organizations.forEach((organization) => {
            if (stateOrganizationId === String(organization.organizationId)) {
              isAnyCompanyPresent = true;
            }
          });
        });
      }

      if (isAnyCompanyPresent) {
        payload.organizations.forEach((organization) => {
          if (
            state.requests[payload.requestId] &&
            state.requests[payload.requestId]![stateOrganizationKey]
          ) {
            delete state.requests[payload.requestId]![stateOrganizationKey]![
              organization.organizationId
            ];
          }
        });
      } else {
        state.requests[payload.requestId] = {
          ...state.requests[payload.requestId],
          [stateOrganizationKey]: {},
        };
        payload.organizations.forEach((organization) => {
          state.requests[payload.requestId]![stateOrganizationKey]![
            organization.organizationId
          ] = organization.contacts.map((contact) => contact.id);
        });
      }
      saveState({ ...state.requests });
    },
    setRequestGroups(
      state,
      action: PayloadAction<{
        requestId: number;
        groups: TApplicationGroupWithInnerRoles[];
      }>
    ) {
      const { payload } = action;

      let isAnyGroupPresent = false;

      if (
        state.requests[payload.requestId] &&
        state.requests[payload.requestId]?.innerRolesGroups
      ) {
        Object.keys(
          state.requests[payload.requestId]!.innerRolesGroups!
        ).forEach((stateGroupId) => {
          payload.groups.forEach((group) => {
            if (stateGroupId === String(group.id)) {
              isAnyGroupPresent = true;
            }
          });
        });
      }

      if (isAnyGroupPresent) {
        payload.groups.forEach((group) => {
          if (
            state.requests[payload.requestId] &&
            state.requests[payload.requestId]!.innerRolesGroups
          ) {
            delete state.requests[payload.requestId]!.innerRolesGroups![
              group.id
            ];
          }
        });
      } else {
        state.requests[payload.requestId] = {
          ...state.requests[payload.requestId],
          innerRolesGroups: {},
        };
        payload.groups.forEach((group) => {
          state.requests[payload.requestId]!.innerRolesGroups![group.id] =
            group.contacts.map((contact) => contact.id);
        });
      }
      saveState({ ...state.requests });
    },
    setRequestSelectedContactsIds(
      state,
      action: PayloadAction<{
        requestId: number;
        companyId: number;
        contactIds: number[];
        organizationType: CompanyTypes;
      }>
    ) {
      const { payload } = action;
      const stateOrganizationKey =
        payload.organizationType === CompanyTypes.CUSTOMER
          ? 'customers'
          : 'contractors';

      if (
        state.requests[payload.requestId] &&
        state.requests[payload.requestId]![stateOrganizationKey] &&
        state.requests[payload.requestId]![stateOrganizationKey]![
          payload.companyId
        ] &&
        state.requests[payload.requestId]![stateOrganizationKey]![
          payload.companyId
        ].length !== 0
      ) {
        state.requests[payload.requestId] = {
          ...state.requests[payload.requestId],
          [stateOrganizationKey]: {
            ...state.requests[payload.requestId]![stateOrganizationKey],
            [payload.companyId]: [],
          },
        };
      } else {
        state.requests[payload.requestId] = {
          ...state.requests[payload.requestId],
          [stateOrganizationKey]: {
            ...state.requests[payload.requestId]?.[stateOrganizationKey],
            [payload.companyId]: payload.contactIds,
          },
        };
      }
      saveState({ ...state.requests });
    },
    setGroupSelectedContactsIds(
      state,
      action: PayloadAction<{
        requestId: number;
        groupId: number;
        contactIds: number[];
      }>
    ) {
      const { payload } = action;
      if (
        state.requests[payload.requestId] &&
        state.requests[payload.requestId]!.innerRolesGroups &&
        state.requests[payload.requestId]!.innerRolesGroups![payload.groupId] &&
        state.requests[payload.requestId]!.innerRolesGroups![payload.groupId]
          .length !== 0
      ) {
        state.requests[payload.requestId] = {
          ...state.requests[payload.requestId],
          innerRolesGroups: {
            ...state.requests[payload.requestId]?.innerRolesGroups,
            [payload.groupId]: [],
          },
        };
      } else {
        state.requests[payload.requestId] = {
          ...state.requests[payload.requestId],
          innerRolesGroups: {
            ...state.requests[payload.requestId]?.innerRolesGroups,
            [payload.groupId]: payload.contactIds,
          },
        };
      }
      saveState({ ...state.requests });
    },
    addContactIdToRequest(
      state,
      action: PayloadAction<{
        requestId: number;
        companyId: number;
        contactId: number;
        organizationType: CompanyTypes;
        noToggleMode?: boolean;
      }>
    ) {
      const { payload } = action;
      const stateOrganizationKey =
        payload.organizationType === CompanyTypes.CUSTOMER
          ? 'customers'
          : 'contractors';

      if (
        state.requests[payload.requestId] &&
        state.requests[payload.requestId]![stateOrganizationKey] &&
        state.requests[payload.requestId]![stateOrganizationKey]![
          payload.companyId
        ] &&
        state.requests[payload.requestId]![stateOrganizationKey]![
          payload.companyId
        ].length !== 0 &&
        state.requests[payload.requestId]![stateOrganizationKey]![
          payload.companyId
        ].includes(payload.contactId)
      ) {
        if (!payload.noToggleMode) {
          state.requests[payload.requestId]![stateOrganizationKey] = {
            ...state.requests[payload.requestId]![stateOrganizationKey],
            [payload.companyId]: [
              ...state.requests[payload.requestId]![stateOrganizationKey]![
                payload.companyId
              ].filter((id) => id !== payload.contactId),
            ],
          };
        }

        saveState({ ...state.requests });
        return;
      }

      if (
        state.requests[payload.requestId] &&
        state.requests[payload.requestId]![stateOrganizationKey] &&
        state.requests[payload.requestId]![stateOrganizationKey]![
          payload.companyId
        ] &&
        state.requests[payload.requestId]![stateOrganizationKey]![
          payload.companyId
        ].length !== 0
      ) {
        state.requests[payload.requestId]![stateOrganizationKey] = {
          ...state.requests[payload.requestId]![stateOrganizationKey],
          [payload.companyId]: [
            ...state.requests[payload.requestId]![stateOrganizationKey]![
              payload.companyId
            ],
            payload.contactId,
          ],
        };

        saveState({ ...state.requests });
        return;
      }

      state.requests[payload.requestId] = {
        ...state.requests[payload.requestId],
        [stateOrganizationKey]: {
          ...state.requests[payload.requestId]?.[stateOrganizationKey],
          [payload.companyId]: [payload.contactId],
        },
      };
      saveState({ ...state.requests });
    },
    addGroupContactIdToRequest(
      state,
      action: PayloadAction<{
        requestId: number;
        groupId: number;
        contactId: number;
        noToggleMode?: boolean;
      }>
    ) {
      const { payload } = action;
      if (
        state.requests[payload.requestId] &&
        state.requests[payload.requestId]!.innerRolesGroups &&
        state.requests[payload.requestId]!.innerRolesGroups![payload.groupId] &&
        state.requests[payload.requestId]!.innerRolesGroups![payload.groupId]
          .length !== 0 &&
        state.requests[payload.requestId]!.innerRolesGroups![
          payload.groupId
        ].includes(payload.contactId)
      ) {
        if (!payload.noToggleMode) {
          state.requests[payload.requestId]!.innerRolesGroups = {
            ...state.requests[payload.requestId]!.innerRolesGroups,
            [payload.groupId]: [
              ...state.requests[payload.requestId]!.innerRolesGroups![
                payload.groupId
              ].filter((id) => id !== payload.contactId),
            ],
          };
        }

        saveState({ ...state.requests });
        return;
      }

      if (
        state.requests[payload.requestId]!.innerRolesGroups &&
        state.requests[payload.requestId]!.innerRolesGroups![payload.groupId] &&
        state.requests[payload.requestId]!.innerRolesGroups![payload.groupId]
          .length !== 0
      ) {
        state.requests[payload.requestId]!.innerRolesGroups = {
          ...state.requests[payload.requestId]!.innerRolesGroups,
          [payload.groupId]: [
            ...state.requests[payload.requestId]!.innerRolesGroups![
              payload.groupId
            ],
            payload.contactId,
          ],
        };

        saveState({ ...state.requests });
        return;
      }

      state.requests[payload.requestId] = {
        ...state.requests[payload.requestId],
        innerRolesGroups: {
          ...state.requests[payload.requestId]!.innerRolesGroups,
          [payload.groupId]: [payload.contactId],
        },
      };
      saveState({ ...state.requests });
    },
  },
});

export const {
  setLastSelectedClientTab,
  setRequestSelectedContactsIds,
  addContactIdToRequest,
  setRequestOrganizations,
  setGroupSelectedContactsIds,
  setRequestGroups,
  addGroupContactIdToRequest,
} = requestCommunicationsSlice.actions;
export const requestCommunicationsReducer = requestCommunicationsSlice.reducer;
