import { FC, useEffect } from 'react';
import {
  ConversationEntityType,
  DayType,
  DocumentEntityTypes,
  DocumentTypes,
  ICustomerCommercialOffer,
  IPaymentCondition,
  IPaymentConditionCreate,
  PaymentMoment,
  PaymentType,
} from 'src/models';
import { InfoRow, NumberInput, Typography } from 'src/components/ui';
import { AttachFiles, OpenChat } from 'src/components/widgets/index';
import { formatServerDate } from 'src/utils';

import { useGetRequestPositionsQuery } from 'src/api/business-request/positions';
import { useForm } from 'react-hook-form';
import { useGetPaymentConditionsQuery } from 'src/api/business-request/payment_conditions/payment-conditions';
import styles from './customer-commercial-offer.module.scss';
import { ContractorPositions } from '../../manager/components/positions';
import { TPositionsFormData } from '../../manager/components/positions/position/position.types';
import { PaymentConditions } from '../../manager/components/payment-conditions';

export interface ICommercialOfferProps {
  offer: ICustomerCommercialOffer;
  businessRequestId: number;
  orderProcessingStarted: boolean;
  preview?: boolean;
}

export const CustomerCommercialOffer: FC<ICommercialOfferProps> = ({
  offer,
  businessRequestId,
  orderProcessingStarted,
  preview,
}) => {
  const { data: positionsData } = useGetRequestPositionsQuery({
    commercialOfferForCustomerId: offer.commercialOfferId,
  });

  const { data: paymentConditionsData } = useGetPaymentConditionsQuery({
    commercialOfferForCustomerIds: [offer.commercialOfferId],
  });

  const positionsForm = useForm<TPositionsFormData>({
    defaultValues: {
      positions: [],
    },
  });

  useEffect(() => {
    if (positionsData) {
      if (offer?.commercialOfferId) {
        positionsForm.reset({
          positions: positionsData.map((position) => ({
            name: position.name,
            quantity: position.quantity ?? 0,
            okei: position.okeiDto,
            // price: position.price ?? 0,
            price: position.netPrice ?? 0,
            // total: position.total ?? 0,
            total: position.netTotal ?? 0,
            id: position.id ?? undefined,
          })),
        });
      } else {
        positionsForm.reset({
          positions: positionsData.map((position) => ({
            name: position.name,
            quantity: position.quantity ?? 0,
            okei: position.okeiDto,
            // price: position.price ?? 0,
            price: position.netPrice ?? 0,
            // total: position.total ?? 0,
            total: position.netTotal ?? 0,
            id: undefined,
          })),
        });
      }
    }
  }, [positionsData]);

  const paymentConditionsForm = useForm<IPaymentConditionCreate>({
    defaultValues: {
      metadata: [],
    },
  });

  useEffect(() => {
    const subscription = paymentConditionsForm.watch((values) => {
      const total = values?.metadata?.reduce(
        (sum, condition) => sum + Number(condition!.paymentPercentage || 0),
        0
      );
      if (total !== 100) {
        paymentConditionsForm.setError('metadata', {
          type: 'manual',
          message: 'Сумма всех процентов должна быть равна 100',
        });
      } else {
        paymentConditionsForm.clearErrors('metadata');
      }
      if (!values?.metadata?.length) {
        paymentConditionsForm.clearErrors('metadata');
      }
    });

    return () => subscription.unsubscribe();
  }, [paymentConditionsForm]);

  return (
    <>
      <div className={styles.contentWrapper}>
        <div className={styles.block}>
          <div className={styles.contentBlock}>
            <Typography variant="h3" mb="24px">
              Сумма поставки
            </Typography>
            <div className={styles.inputBlock}>
              <div className={styles.container}>
                <InfoRow
                  label="Стоимость, с НДС"
                  value={
                    <NumberInput
                      mode="price"
                      displayType="text"
                      value={offer.cost}
                    />
                  }
                />
              </div>
              <div className={styles.container}>
                <InfoRow
                  label="Логистика"
                  value={offer.shippingIncluded ? 'Включена' : 'Не включена'}
                />
              </div>
            </div>
          </div>
          {orderProcessingStarted || preview ? null : (
            <OpenChat
              entityType={ConversationEntityType.BUSINESS_REQUEST}
              entityId={businessRequestId}
              mode="contragent"
              sx={{ marginBottom: '24px' }}
            />
          )}
        </div>
        <div className={styles.block}>
          <div className={styles.contentBlock}>
            <Typography variant="h3" mb="24px">
              Сроки
            </Typography>
            <div className={styles.inputBlock}>
              <div className={styles.container}>
                <InfoRow
                  noMargin
                  label="Действие КП"
                  value={
                    offer.dueDateAt
                      ? `до ${formatServerDate(offer.dueDateAt, 'dd.MM.yyyy')}`
                      : 'Дата не указана'
                  }
                />
              </div>
              <div className={styles.container}>
                <InfoRow
                  noMargin
                  label="Поставка, в к.д."
                  value={offer.supplyDuration}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContractorPositions
        positions={positionsData || []}
        isEditMode={false}
        businessRequestId={businessRequestId}
        contractorCommercialOfferId={offer.commercialOfferId}
        isCreatePositionMode={false}
        form={positionsForm}
        shortView
      />

      {paymentConditionsData?.content.length ? (
        <PaymentConditions
          isEditMode={false}
          businessRequestId={businessRequestId}
          paymentConditions={paymentConditionsData?.content ?? []}
          maxWidth="720px"
          isCreatePaymentConditionsMode={false}
          form={paymentConditionsForm}
        />
      ) : (
        <InfoRow
          label="Условия оплаты"
          value={
            offer.paymentConditions && (
              <Typography whiteSpace="pre-wrap">
                {offer.paymentConditions}
              </Typography>
            )
          }
        />
      )}

      <InfoRow
        label="Комментарий"
        value={
          offer.comment && (
            <Typography whiteSpace="pre-wrap">{offer.comment}</Typography>
          )
        }
      />

      <AttachFiles
        documentOptions={{
          entityType: DocumentEntityTypes.COMMERCIAL_OFFER_FOR_CUSTOMER,
          documentType: DocumentTypes.COMMERCIAL_OFFER,
          entityId: offer.commercialOfferId,
        }}
        formItemName="documents.COMMERCIAL_OFFER"
        showDownloadAllButton={!preview}
      />
    </>
  );
};
