import { IOkei } from 'src/models';
import { businessRequestApi } from '../business-request';

export const okeiApi = businessRequestApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllOkeiList: builder.query<IOkei[], {}>({
      query: () => ({
        url: 'business-requests/api/v1/okei',
        method: 'GET',
      }),
      providesTags: (result, error, arg) => [{ type: 'getOkei' }],
    }),

    getFilteredOkeiList: builder.query<
      IOkei[],
      {
        measure: string;
      }
    >({
      query: (body) => ({
        url: `business-requests/api/v1/okei/search?measure=${body.measure}`,
        method: 'GET',
      }),
    }),

    getOkeiByMeasureName: builder.query<
      IOkei[],
      {
        measure: string;
      }
    >({
      query: (body) => ({
        url: `business-requests/api/v1/okei/measure/${body.measure}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAllOkeiListQuery,
  useLazyGetFilteredOkeiListQuery,
  useLazyGetOkeiByMeasureNameQuery,
} = okeiApi;
