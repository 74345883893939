import { PERMISSIONS } from 'src/constants';
import { TMenuItem } from 'src/models/menu';

export const mainMenuItems: TMenuItem[] = [
  {
    label: 'Заявки',
    path: '/requests?isAssigned=true&statusGroup=ACTIVE',
    permission: [PERMISSIONS.VIEW_MANAGER_BUSINESS_REQUEST_LIST],
  },
  {
    label: 'Заявки',
    path: '/requests?statusGroup=ACTIVE',
    permission: [PERMISSIONS.VIEW_CUSTOMER_BUSINESS_REQUEST_LIST],
  },
  {
    label: 'Заявки',
    path: '/requests?isAssigned=true&statusGroup=ACTIVE',
    permission: [PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST],
  },
  {
    label: 'Подключение исполнителей',
    path: '/contractors-signing',
    permission: [PERMISSIONS.VIEW_CONTRACTORS_SIGNING_KANBAN],
  },
  {
    label: 'Все заявки',
    path: '/all-requests?statusGroup=ACTIVE',
    permission: [PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST],
  },
  {
    label: 'Мои заявки',
    path: '/requests',
    permission: [PERMISSIONS.VIEW_CONTRACTOR_BUSINESS_REQUEST_LIST],
  },
  {
    label: 'Все заявки',
    path: '/all-requests',
    permission: [PERMISSIONS.VIEW_CONTRACTOR_BUSINESS_REQUEST_LIST],
  },
  {
    label: 'Исполнители',
    path: '/companies/contractors?contractorStatus=SIGNED',
    permission: [PERMISSIONS.VIEW_CONTRACTOR_LIST],
  },
  {
    label: 'Заказчики',
    path: '/companies/customers',
    permission: [PERMISSIONS.VIEW_CUSTOMER_LIST],
  },
  {
    label: 'Компании',
    path: '/companies',
    getSearchParamsHandler: (
      pathname: string,
      searchParams: URLSearchParams
    ) => {
      if (pathname === '/companies') {
        return `?${searchParams.toString()}`;
      }

      return '';
    },
    permission: [PERMISSIONS.VIEW_ALL_COMPANIES],
    end: true,
  },
  {
    label: 'Документы',
    path: '/contract-templates',
    permission: [PERMISSIONS.VIEW_TEMPLATES],
  },
  // Для бекофиса шаблоны были перенесены
  {
    label: 'Документы',
    path: '/system-settings',
    permission: [PERMISSIONS.VIEW_SYSTEM_SETTINGS],
  },
  {
    label: 'Отгрузки',
    path: '/shipments',
    permission: [PERMISSIONS.VIEW_SHIPMENTS],
  },
  {
    label: 'Оплаты',
    path: '/payments',
    permission: [PERMISSIONS.VIEW_PAYMENTS],
  },
  {
    label: 'Новости',
    path: '/news',
  },
  {
    label: 'Чат',
    path: '/chat',
  },
  {
    label: 'Коммуникации',
    path: '/communications/all',
    permission: [PERMISSIONS.VIEW_COMMUNICATION_PAGE],
  },
  {
    label: 'Текущая роль:',
    path: '#',
    permission: [PERMISSIONS.CHANGE_ROLE],
    type: 'ACTION',
  },
];
