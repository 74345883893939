import { ReactNode } from 'react';
import styles from './empty.module.css';

interface IEmptyProps {
  children?: ReactNode;
}

export const Empty = ({ children = 'Нет данных' }: IEmptyProps) => (
  <div className={styles.wrapper}>{children}</div>
);
