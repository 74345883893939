import { TableCell } from '@mui/material';
import { Key, MouseEvent } from 'react';
import { ColumnType } from 'src/components/ui/new-table/types';

import styles from './cell.module.scss';

interface CellType<RecordType> {
  key: Key | keyof RecordType;
  record: RecordType;
  column: ColumnType<RecordType>;
  index: number;
  onClick?: (x: MouseEvent<any>) => void;
  page: number;
  rowIndex: number;
}

export const Cell = <RecordType,>({
  column,
  index,
  record,
  onClick,
  page,
  rowIndex,
}: CellType<RecordType>) => {
  const data = record[column.key] as string;
  const Component =
    column.render && column.render(record, index, page, rowIndex);

  return (
    <TableCell onClick={onClick} {...column.setting} className={styles.cell}>
      {Component || data}
    </TableCell>
  );
};
