import { useMemo, useState } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import {
  useGetPaymentConditionsTemplatesQuery,
  useLazyGetPaymentConditionsTemplatesQuery,
} from 'src/api/business-request/payment_conditions/payment-conditions';
import { PlusRounded } from 'src/assets/icons';
import {
  Autocomplete,
  Box,
  Button,
  ConfirmDialog,
  Form,
  Stack,
  Typography,
} from 'src/components/ui';
import { LinearCounterProgress } from 'src/components/widgets/linear-counter-progress';
import {
  IPaymentCondition,
  IPaymentConditionCreate,
  IPaymentConditionTemplate,
} from 'src/models';
import { isContractor, isCustomer } from 'src/utils';
import { PaymentCondition } from './payment-condition/payment-condition';
import styles from './styles.module.scss';

interface IPaymentConditionsProps {
  isEditMode: boolean;
  businessRequestId: number;
  paymentConditions?: IPaymentCondition[];
  maxWidth?: string;
  isCreatePaymentConditionsMode?: boolean;
  form?: UseFormReturn<IPaymentConditionCreate>;
  title?: string;
}

export const PaymentConditions = ({
  isEditMode,
  businessRequestId,
  paymentConditions,
  maxWidth,
  form,
  title,
  isCreatePaymentConditionsMode = false,
}: IPaymentConditionsProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<
    IPaymentConditionTemplate | undefined
  >(undefined);

  const { control, reset } = form || {};

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'metadata',
  });

  const {
    data: paymentConditionsTemplates,
    isLoading: isPaymentConditionsTemplatesLoading,
  } = useGetPaymentConditionsTemplatesQuery({
    templateNamePattern: '',
  });

  const [fetchPaymentConditionsTemplates] =
    useLazyGetPaymentConditionsTemplatesQuery();

  const fetchApi = async (searchQuery: string) =>
    fetchPaymentConditionsTemplates({
      templateNamePattern: searchQuery,
    }).unwrap();

  const handleChangePaymentConditionsTemplate = (
    template?: IPaymentConditionTemplate
  ) => {
    if (!template) {
      return;
    }

    if (form?.getValues('metadata') && form?.getValues('metadata').length > 0) {
      setSelectedTemplate(template);
      setShowConfirmModal(true);
    } else {
      form?.setValue('metadata', template.paymentConditions);
    }
  };

  const totalPercentage = useMemo(
    () =>
      fields?.reduce(
        (sum, condition) => sum + (condition?.paymentPercentage || 0),
        0
      ) || 0,
    [fields]
  );

  const handleAddPaymentCondition = () => {
    append({
      id: null,
      paymentType: null,
      paymentPercentage: null,
      paymentInDays: null,
      dayType: null,
      paymentMoment: null,
      businessRequestId: null,
      contractorCommercialOfferId: null,
      commercialOfferForCustomerId: null,
      contractorSpecificationId: null,
      customerSpecificationId: null,
    } as any);
  };

  const handleRemovePaymentCondition = () => {
    if (fields.length > 0) {
      if (fields.length === 1) {
        remove();
        if (reset) {
          reset({
            metadata: [],
          });
        }
      } else {
        remove(fields.length - 1);
      }
    }
  };

  if (
    !isCreatePaymentConditionsMode &&
    !isEditMode &&
    !paymentConditions?.length &&
    !fields.length
  ) {
    return null;
  }

  const metadata = form?.watch('metadata');

  const paymentCounter = metadata?.reduce(
    (acc, curr) => acc + Number(curr?.paymentPercentage ?? 0),
    0
  );

  return (
    <div>
      <Stack direction="column" gap="16px">
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h3" mb="12px">
            {title ?? 'Условия оплаты'}
          </Typography>

          {isCreatePaymentConditionsMode && (
            <Box
              mb="16px"
              gap="16px"
              display="flex"
              justifyContent="space-between">
              <Button
                variant="text"
                applyNewIconSizing
                startIcon={<PlusRounded />}
                onClick={handleAddPaymentCondition}>
                Добавить условие оплаты
              </Button>
            </Box>
          )}
        </Box>

        {isEditMode && (
          <Stack direction="column" gap="16px" width="100%" mb="16px">
            <Typography color="secondary" fontSize="12px" lineHeight="12px">
              Вы можете выбрать шаблон условий оплаты или ввести условия вручную
            </Typography>
            <Autocomplete<IPaymentConditionTemplate>
              fetchApi={fetchApi}
              getOptionLabel={(option) => option.templateName}
              options={paymentConditionsTemplates || []}
              placeholder="Выбрать шаблон"
              onChange={(value) =>
                handleChangePaymentConditionsTemplate(
                  value as IPaymentConditionTemplate
                )
              }
            />
          </Stack>
        )}
      </Stack>

      {isEditMode && form && !(isCustomer() || isContractor()) && (
        <LinearCounterProgress
          value={paymentCounter ?? 0}
          className={styles.linearProgress}
        />
      )}

      <Box className={styles.content}>
        {form && isCreatePaymentConditionsMode ? (
          <Form
            form={form}
            footer={null}
            btnFollowScroll={false}
            preloading={isPaymentConditionsTemplatesLoading}>
            {fields.map((field, index) => (
              <PaymentCondition
                {...field}
                mode="create"
                handleEditPaymentCondition={() => {}}
                isEditPaymentCondition
                isEditModeEnabled
                handleCancelEditPaymentCondition={() => {}}
                handleSubmit={() => {}}
                businessRequestId={businessRequestId}
                maxWidth={maxWidth}
                index={index}
                handleRemovePaymentCondition={handleRemovePaymentCondition}
                key={field.id}
                maxPercentage={100 - totalPercentage}
              />
            ))}
          </Form>
        ) : (
          paymentConditions?.map((paymentCondition, index) => (
            <PaymentCondition
              {...paymentCondition}
              mode="view"
              handleRemovePaymentCondition={() => {}}
              handleEditPaymentCondition={() => {}}
              isEditPaymentCondition={false}
              handleCancelEditPaymentCondition={() => {}}
              handleSubmit={() => {}}
              businessRequestId={businessRequestId}
              maxWidth={maxWidth}
              index={index}
            />
          ))
        )}
      </Box>

      {!!form && (
        <Box mb="16px" gap="16px" display="flex" justifyContent="space-between">
          {form.formState.errors.metadata &&
            isEditMode &&
            isCreatePaymentConditionsMode && (
              <p style={{ color: 'red' }}>
                {form.formState.errors.metadata.message}
              </p>
            )}
        </Box>
      )}

      <ConfirmDialog
        title="Применение шаблона"
        confirmText="Продолжить"
        open={showConfirmModal}
        close={() => setShowConfirmModal(false)}
        onConfirm={() => {
          form?.setValue('metadata', selectedTemplate!.paymentConditions);
          setShowConfirmModal(false);
        }}>
        Вы уверены в том, что хотите применить шаблон “
        {selectedTemplate?.templateName || ''}”? В этом случае все данные по
        оплате, введенные вами ранее, будут удалены.
      </ConfirmDialog>
    </div>
  );
};
