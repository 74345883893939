import classNames from 'classnames';
import { useMemo } from 'react';
import { Pencil } from 'src/assets/icons';
import { IconButton, Typography, Stack } from 'src/components/ui';
import { PERMISSIONS } from 'src/constants';
import { TVariantBusinessRequest } from 'src/models';
import { AuthService, RolePermissionService } from 'src/services';
import {
  formatServerDate,
  isAdmin,
  isManager,
  isTechExpert,
  joinFullName,
} from 'src/utils';
import styles from './request-kam-plus.module.scss';

export interface IRequestKamPlusProps {
  businessRequest: TVariantBusinessRequest;
  onEditButtonClick: () => void;
}

export const RequestKamPlus = ({
  businessRequest,
  onEditButtonClick,
}: IRequestKamPlusProps) => {
  const isShowEditManagerForOrder = RolePermissionService.can(
    PERMISSIONS.EDIT_MANAGER_ORDER
  );

  const isAssignedKAMPlusUserSpecificRole =
    AuthService!.isSpecificRole() &&
    businessRequest.assignedManagers?.kamPlus?.id === AuthService.userId;

  // Техэксперт также имеет право назначать КАМ+ по просьбе Алексея
  const isUserCanChangeKAMPlus =
    isAssignedKAMPlusUserSpecificRole ||
    isAdmin() ||
    isManager() ||
    isTechExpert();

  const nameKamPlus = useMemo(
    () =>
      joinFullName(
        businessRequest.assignedManagers?.kamPlus?.lastName,
        businessRequest.assignedManagers?.kamPlus?.firstName,
        businessRequest.assignedManagers?.kamPlus?.middleName
      ),
    [businessRequest]
  );

  const substituteManager =
    businessRequest.assignedManagers?.kamPlus?.substitute;

  const editButtonDisabled =
    !isShowEditManagerForOrder ||
    !isUserCanChangeKAMPlus ||
    !!substituteManager;

  return (
    <Stack direction="column" gap="10px">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        className={classNames(!!substituteManager && styles.managerDisabled)}>
        <Stack className={styles.managerTitle} direction="column" gap="4px">
          <Typography variant="body2" color="#7A8694">
            Менеджер по заказу:
          </Typography>
          <Typography color="#292929">{nameKamPlus || '-'}</Typography>
        </Stack>
        <Stack direction="row" gap="4px">
          <IconButton
            className={classNames(
              styles.actionButton,
              editButtonDisabled && styles.actionButtonDisabled
            )}
            disabled={editButtonDisabled}
            onClick={onEditButtonClick}>
            <Pencil
              sx={{
                color:
                  !isShowEditManagerForOrder || !isUserCanChangeKAMPlus
                    ? '#7A8694'
                    : '#292929',
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
      {substituteManager && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between">
          <Stack direction="column" gap="4px">
            <Typography variant="body2" color="#7A8694">
              Замещающий менеджер по заказу:
            </Typography>
            <Typography color="#292929">
              {' '}
              {joinFullName(
                substituteManager.lastName,
                substituteManager.firstName,
                substituteManager.middleName
              )}
            </Typography>
            {'\n'}
            <Typography>
              с{' '}
              {formatServerDate(
                businessRequest.assignedManagers?.kamPlus?.vacationStartDate,
                'dd.MM.yyyy'
              )}{' '}
              по{' '}
              {formatServerDate(
                businessRequest.assignedManagers?.kamPlus?.vacationEndDate,
                'dd.MM.yyyy'
              )}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
